<div class="grid-action-button-container">
  <button
    pButton
    pRipple
    type="button"
    icon="pi pi-info-circle"
    class="p-button-rounded p-button-text p-button-help"
    [disabled]="!!disabled"
    (click)="rowInfoClicked.emit()"
    [attr.data-ele]="'info-circle-btn'"
  ></button>
</div>
