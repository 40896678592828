<ng-container *transloco="let t; read: translateScope">
    <span
            class="sersi-input-wrapper p-fluid icon-suffix"
            [class.p-input-icon-right]="props['iconSuffix'] || props['textIconSuffix']"
            (click)="emitUPCWeight()"
    >
    <i *ngIf="props['iconSuffix']" [className]="'pi ' + props['iconSuffix']"></i>
    <p-inputNumber
            class="weight-field"
            mode="decimal"
            data-ele="animal-weight"
            [formControl]="formControl"
            [formlyAttributes]='field'
            [useGrouping]="false"
            [placeholder]="t('weight')"
            [min]="props['minNum'] ?? 0"
            [max]="props['maxNum'] ?? MAX_NUMBER_INPUT"
            [readonly]="true"
            (keydown)="onCaptureWeight($event)"
    />

   <span class="input-text-icon-suffix">
      <span *ngIf="(loadingWeight$ | async) === false; else spinnerTemplate">
        <span *ngIf="props['textIconSuffix'] && (formControl.value || !props.placeholder)">
          {{ props['textIconSuffix'] }}
        </span>
      </span>
      <ng-template #spinnerTemplate>
        <p-progressSpinner [style]="{width: '20px', height: '20px'}"></p-progressSpinner>
      </ng-template>
    </span>

  </span>
</ng-container>

