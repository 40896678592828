import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AH_FMS_UPC_CONFIG, UPC_CONFIG, UpcThermometerService } from '@ifhms/common/angular/data-access/upc-api';
import { FormlyModule } from '@ngx-formly/core';
import { InputNumberModule } from 'primeng/inputnumber';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FormTemperatureFieldComponent } from './components';
import { UpcThermometerSharedFormComponent } from './components/upc-thermometer-shared-form/upc-thermometer-shared-form.component';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { scopeLoader } from '@common/angular/translation';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule,
    InputNumberModule,
    ProgressSpinnerModule,
    TranslocoModule
  ],
  declarations: [FormTemperatureFieldComponent, UpcThermometerSharedFormComponent],
  providers: [
    {
      provide: AH_FMS_UPC_CONFIG,
      useValue: UPC_CONFIG,
      multi: false
    },
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'thermometer',
        alias: 'thermometer',
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        )
      }
    },
    UpcThermometerService
  ],
  exports: [FormTemperatureFieldComponent, UpcThermometerSharedFormComponent]
})
export class IfhmsCommonAngularUpcThermometerModule { }
