import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AH_FMS_UPC_CONFIG } from './tokens';
import { UPC_CONFIG } from './config';
import {
  UpcApplicationSettingsService,
  UpcDosingGunsService,
  UpcGateControllerService,
  UpcRfidService,
  UpcScaleService,
  UpcThermometerService
} from './services';

@NgModule({
  imports: [CommonModule],
  providers: [
    UpcApplicationSettingsService,
    UpcDosingGunsService,
    UpcScaleService,
    UpcThermometerService,
    UpcRfidService,
    UpcGateControllerService,
    {
      provide: AH_FMS_UPC_CONFIG,
      useValue: UPC_CONFIG,
      multi: false
    }
  ]
})
export class IfhmsCommonAngularDataAccessUpcApiModule {}
