import { InjectionToken } from '@angular/core';

export interface AhFmsUPCConfig {
  upcSignalRUrl: string;
}

export const getUpcUrl = (appConfig: AhFmsUPCConfig): string => {
  return appConfig.upcSignalRUrl || 'http://host.docker.internal:3210';
};

export const AH_FMS_UPC_CONFIG = new InjectionToken<AhFmsUPCConfig>('AH_FMS_UPC_CONFIG');
