import { Product, ProductMetadataDto } from '@ifhms/models/feedlot';
import { pick } from 'lodash-es';

export function getProductMetadataValue(metadata: ProductMetadataDto, product: Product): ProductMetadataDto {
  return {
    ...pick(metadata, PRODUCT_METADATA_KEYS),
    productId: product.productId!,
    adminLocationId: metadata.adminLocationId || product.adminLocationId
  } as ProductMetadataDto;
}

export function getMetadataDialogProps(product: Product): ProductMetadataDto | null {
  const hasLocalOverrides = PRODUCT_METADATA_KEYS.some(key => !!product[key]);
  return hasLocalOverrides ? pick(product, PRODUCT_METADATA_KEYS) as ProductMetadataDto : null;
}

export const PRODUCT_METADATA_KEYS: (keyof ProductMetadataDto)[] = ['adminLocationId', 'productLotNumber', 'productSerialNumber', 'expireDate', 'isCarryOver'];

