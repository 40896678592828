import { APP_CONFIG_BASE } from './app-config-base';
import { AppConfig } from '@ifhms/feedlot/web/core/config';

export const APP_CONFIG_FMS_STAGING: AppConfig = {
  ...APP_CONFIG_BASE,

  environmentName: 'staging',
  production: false,
  apiUrl: 'https://admin-api.staging.fms.feedlothealth.com/api',
  signalRUrl: 'https://feedlot-api.staging.fms.feedlothealth.com/PushNotification',
  showDevFeature: false,
  authServerUrl: 'https://identity.staging.fms.feedlothealth.com',
  feedlotApiUrl: 'https://feedlot-api.staging.fms.feedlothealth.com/api',
  upcSignalRUrl: 'http://localhost:3210',
  adminAppUrl: 'https://admin.staging.fms.feedlothealth.com',
  integrations: {
    heapAppId: '4291407184'
  }
};
