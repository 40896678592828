import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FormlyModule } from '@ngx-formly/core';

import { SersiAngularUiSpinModule } from '@sersi/angular/ui/spin';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';

import { FEATURE_NAME, reducer } from './+state';
import { AnimalAvailabilityEffects } from './+state/animal-availability.effects';
import { AnimalAvailabilityDialogComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    ButtonModule,
    DialogModule,
    SersiAngularUiSpinModule,
    TranslocoModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([AnimalAvailabilityEffects]),
    RouterLink
  ],
  declarations: [
    AnimalAvailabilityDialogComponent
  ],
  exports: [
    AnimalAvailabilityDialogComponent
  ]
})
export class IfhmsFeedlotFrontEndSharedComponentsAnimalAvailabilityModule {
}
