import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {
  SortGroupOutcomeListItemDto,
  SortGroupOutcomeSortBy
} from '@ifhms/models/feedlot';
import { TranslocoService } from '@jsverse/transloco';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { FEATURE_NAME, ListFacade } from '../../+state';

@Component({
  selector: 'ifhms-sort-group-outcome-table-sort',
  templateUrl: './sort-group-outcome-table-sort.component.html',
  styleUrls: ['./sort-group-outcome-table-sort.component.scss']
})
export class SortGroupOutcomeTableSortComponent implements OnInit, OnChanges {
  readonly translateScope = `${FEATURE_NAME}.components.sort-group-outcome-table-sort`;
  sortOptions: SersiSelectListItem[];

  @Input() singleRecord: SortGroupOutcomeListItemDto | undefined;
  @Input() facade: ListFacade;

  constructor(private translateService: TranslocoService) {}

  ngOnInit(): void {
    this.initSortOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['singleRecord']) {
      this.initSortOptions();
    }
  }

  onSortChanged(sort: SersiSelectListItem): void {
    this.facade.sort(sort.id as SortGroupOutcomeSortBy);
  }

  private initSortOptions(): void {
    let sortOptions = Object.keys(SortGroupOutcomeSortBy)
      .filter((key) => isNaN(Number(key)))
      .map((key) => ({
        id: SortGroupOutcomeSortBy[key as keyof typeof SortGroupOutcomeSortBy],
        code: key,
        description: this.getTranslationString(key)
      }));

    if (!this.singleRecord?.breed) {
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.BreedAscending
      );
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.BreedDescending
      );
    }
    if (!this.singleRecord?.breedType) {
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.BreedTypeAscending
      );
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.BreedTypeDescending
      );
    }
    if (!this.singleRecord?.weight) {
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.WeightAscending
      );
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.WeightDescending
      );
    }
    if (!this.singleRecord?.age) {
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.AgeAscending
      );
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.AgeDescending
      );
    }
    if (!this.singleRecord?.ageClass) {
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.AgeClassAscending
      );
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.AgeClassDescending
      );
    }
    if (!this.singleRecord?.temperature) {
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.TemperatureAscending
      );
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.TemperatureDescending
      );
    }
    if (!this.singleRecord?.dentition) {
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.DCodeAscending
      );
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.DCodeDescending
      );
    }
    if (!this.singleRecord?.gender) {
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.GenderAscending
      );
      sortOptions = sortOptions.filter(
        (item) => item.id !== SortGroupOutcomeSortBy.GenderDescending
      );
    }

    this.sortOptions = sortOptions;
  }

  private getTranslationString(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }
}
