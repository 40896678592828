import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FEATURE_NAME } from '../../+state';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { Product } from '@ifhms/models/feedlot';
import { ProductViewItem } from '../../interfaces';
import { of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifhms-sort-group-products-table',
  templateUrl: './sort-group-products-table.component.html'
})

export class SortGroupProductsTableComponent extends FieldType<FieldTypeConfig> implements OnInit {
  translateScope = `${FEATURE_NAME}.components.sort-group-products-table`;

  productsList: ProductViewItem[];

  constructor(protected referenceDataFacade: ReferenceDataFacade) {
    super();
  }

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(val => this.transformTableData(val));
  }

  private transformTableData(products: Product[]): void {
    this.productsList = (products || []).map((product: Product) => {
      const { productId, productTypeId } = product;
      return {
        ...product,
        productTypes$: this.referenceDataFacade.productTypesWithProducts$,
        products$: productTypeId ? this.referenceDataFacade.getProductsForProductTypes(productTypeId) : of([]),
        routes$: this.referenceDataFacade.getRoutesForProducts(productId!)
      };
    });
  }

}
