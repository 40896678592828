import { createReducer, on } from '@ngrx/store';

import { ProductMetadataDto } from '@ifhms/models/feedlot';

import { eventProductMetadataActions } from './event-product-metadata.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  metaData: ProductMetadataDto | null;
}

const initialState: State = {
  loading: false,
  loaded: false,
  metaData: null
};
export const reducer = createReducer(
  initialState,

  on(eventProductMetadataActions.getProductMetadata, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(eventProductMetadataActions.getProductMetadataSuccess, (state, { metaData }) => ({
    ...state,
    metaData,
    loading: false,
    loaded: true
  })),

  on(eventProductMetadataActions.error, (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    errMsg: action.errMsg
  }))
);

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getMetadataMap = (state: State): ProductMetadataDto | null => state.metaData;
