import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SortGroupOutcomeDetailSelectors } from './sort-group-outcome-detail.selectors';
import { Store } from '@ngrx/store';
import { SortGroupOutcomeDetailActions } from './sort-group-outcome-detail.actions';
import { SortGroupOutcomeDto } from '@ifhms/models/feedlot';

@Injectable()
export class SortGroupOutcomeDetailFacade {
  isLoading$: Observable<boolean> = this.store.select(
    SortGroupOutcomeDetailSelectors.selectLoading
  );

  isLoaded$: Observable<boolean> = this.store.select(
    SortGroupOutcomeDetailSelectors.selectLoaded
  );

  detail$: Observable<SortGroupOutcomeDto | null> = this.store.select(
    SortGroupOutcomeDetailSelectors.selectDetail
  );

  modal$: Observable<boolean> = this.store.select(
    SortGroupOutcomeDetailSelectors.selectModal
  );

  constructor(private readonly store: Store) {}

  get(id: string): void {
    this.store.dispatch(SortGroupOutcomeDetailActions.get({ id }));
  }

  next(outcome: SortGroupOutcomeDto, showCopyTo: boolean): void {
    this.store.dispatch(SortGroupOutcomeDetailActions.next({ outcome, showCopyTo  }));
  }

  bulk(outcome: SortGroupOutcomeDto, showCopyTo: boolean): void {
    this.store.dispatch(
      SortGroupOutcomeDetailActions.bulk({ outcome, showCopyTo })
    );
  }

  back(): void {
    this.store.dispatch(SortGroupOutcomeDetailActions.back());
  }

  setDefault(outcome: SortGroupOutcomeDto): void {
    this.store.dispatch(SortGroupOutcomeDetailActions.setDefault({ outcome }));
  }

  applyDefault(): void {
    this.store.dispatch(SortGroupOutcomeDetailActions.applyDefault());
  }

  clearDefault(): void {
    this.store.dispatch(SortGroupOutcomeDetailActions.clearDefault());
  }

  showModal(): void {
    this.store.dispatch(SortGroupOutcomeDetailActions.showModal());
  }

  updateModal(): void {
    this.store.dispatch(SortGroupOutcomeDetailActions.updateModal());
  }

  hideModal(): void {
    this.store.dispatch(SortGroupOutcomeDetailActions.hideModal());
  }
}
