<sersi-spin [spin]="!!(isLoading$ | async)">
  <ng-container *transloco="let t; read: scopedNamespace">
    <div class="animal-availability-dialog">
      <p class="flex align-items-center gap-1">
        @if (animalAvailabilityDisplayModel?.link) {
          {{ animalAvailabilityDisplayModel?.message }}
          <a
            *ngIf="animalAvailabilityDisplayModel?.link as link"
            pButton
            icon="pi pi-external-link"
            iconPos="right"
            [label]="animalAvailabilityDisplayModel?.type || ''"
            class="p-button-link button-pristine button-link mt-0.5"
            href="{{ link }}"
            target="_blank"
            (click)="closeDialog()"
          ></a>
        } @else {
          {{ animalAvailabilityDisplayModel?.type }}
        }
      </p>

      <div class="container-footer-buttons">
        <button
          pButton
          type="button"
          [rounded]="true"
          [label]="t('cancel-button-label')"
          (click)="closeDialog()"
        ></button>
      </div>
    </div>
  </ng-container>
</sersi-spin>
