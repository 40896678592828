import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { TxGroupsActions } from './tx-groups.actions';
import * as reducer from './tx-groups.reducer';
import { TxGroupsSelectors } from './tx-groups.selectors';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';

@Injectable()
export class TxGroupsFacade {

  loaded$ = this.store.select(TxGroupsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(TxGroupsSelectors.selectTxGroups), this.loaded$);

  constructor(private store: Store<reducer.State>) {}

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(TxGroupsActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(TxGroupsActions.reset());
  }
}