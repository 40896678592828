import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { scopeLoader } from '@common/angular/translation';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'common-angular-ui',
        alias: 'common-angular-ui',
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class IfhmsCommonAngularUiModule {}
