<ng-container *transloco="let t; read: translateScope">
  <sersi-table-filter
    #tableFilter
    [btnLabel]="t('title')"
    [modalTitle]="t('title')"
    [btnClearLabel]="t('clear-all')"
    [btnApplyLabel]="t('apply')"
    [btnCloseLabel]="t('cancel')"
    [modalViewHeight]="'80vh'"
    [modalViewWidth]="'70vw'"
    (applyFilters)="applyFilter()"
    [roundedBtn]="true"
  >
    <ifhms-sort-group-outcome-table-filter-form
      [singleRecord]="(facade.list$ | async)?.[0]"
      [detail]="(facade.filterBy$ | async)"
      [facade]="facade"
      #filterForm
    ></ifhms-sort-group-outcome-table-filter-form>
  </sersi-table-filter>
</ng-container>
