// @ts-nocheck
import { Injectable } from '@angular/core';
import { SortGroupsService } from '@ifhms/common/angular/data-access/feedlot-api';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { catchError, exhaustMap, filter, map, tap, withLatestFrom } from 'rxjs';
import { FEATURE_NAME } from '..';
import { SortGroupDetailFacade } from '../sort-group-detail/sort-group-detail.facade';
import { SortGroupOutcomeListFacade } from '../sort-group-outcome-list/sort-group-outcome-list.facade';
import { SortGroupOutcomeCopyGridListActions } from './sort-group-outcome-copy-grid-list.actions';
import { SortGroupOutcomeCopyGridListFacade } from './sort-group-outcome-copy-grid-list.facade';

@Injectable()
export class SortGroupOutcomeCopyGridListEffects {
  translateScope = `${FEATURE_NAME}.state.effects`;

  constructor(
    private actions$: Actions,
    private feedlotFacade: FeedlotFacade,
    private facade: SortGroupOutcomeCopyGridListFacade,
    private sortGroupDetailFacade: SortGroupDetailFacade,
    private sortGroupOutcomeListFacade: SortGroupOutcomeListFacade,
    private sortGroupsService: SortGroupsService,
    private messageService: MessageService,
    private translateService: TranslocoService
  ) {}

  apply$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeCopyGridListActions.apply),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.sortGroupDetailFacade.detail$,
        this.facade.state$,
        this.facade.selected$
      ),
      filter(([, , detail]) => !!detail),
      exhaustMap(([, feedlotId, detail, state, selected]) => {
        const { copyTo, copyFrom } = state;
        const selectedIds = selected.filter((x) => x.selected).map((x) => x.id);
        const id = copyTo ? copyTo : selected[0].id;
        const list = copyTo ? selectedIds : copyFrom;

        return this.sortGroupsService
          .copyOutcome(feedlotId, detail.sortGroupId, id, list)
          .pipe(
            map((result) =>
              SortGroupOutcomeCopyGridListActions.applySuccess({
                outcomes: result
              })
            ),
            catchError(() => {
              if (copyTo) {
                this.messageService.add({
                  severity: 'error',
                  summary: this.getTranslationString('fail'),
                  detail: this.getTranslationString('copy-to-fail')
                });
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: this.getTranslationString('fail'),
                  detail: this.getTranslationString('copy-from-fail')
                });
              }
              return of(SortGroupOutcomeListActions.error());
            })
          );
      })
    )
  );
  applySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SortGroupOutcomeCopyGridListActions.applySuccess),
        withLatestFrom(this.facade.state$),
        tap(([, state]) => {
          const { copyTo } = state;
          if (copyTo) {
            this.messageService.add({
              severity: 'success',
              summary: this.getTranslationString('success'),
              detail: this.getTranslationString('copy-to-success')
            });
          } else {
            this.messageService.add({
              severity: 'success',
              summary: this.getTranslationString('success'),
              detail: this.getTranslationString('copy-from-success')
            });
          }
        })
      ),
    { dispatch: false }
  );

  filterBy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeCopyGridListActions.filter),
      map(({ filter }) =>
        SortGroupOutcomeCopyGridListActions.filterSuccess({ filter })
      )
    )
  );

  filterBySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SortGroupOutcomeCopyGridListActions.filterSuccess),
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: this.getTranslationString('success'),
            detail: this.getTranslationString('apply-filter-success')
          });
        })
      ),
    { dispatch: false }
  );

  sortBy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeCopyGridListActions.sortBy),
      map(({ sortBy }) =>
        SortGroupOutcomeCopyGridListActions.sortBySuccess({ sortBy })
      )
    )
  );

  select$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeCopyGridListActions.select),
      map(({ outcome }) =>
        SortGroupOutcomeCopyGridListActions.selectSuccess({ outcome })
      )
    )
  );

  selectAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeCopyGridListActions.selectAll),
      withLatestFrom(
        this.facade.filteredList$,
        this.facade.filteredSelectedLength$,
        this.facade.filteredTotalLength$
      ),
      map(([, list, selectedLength, total]) => {
        const select = !!total && selectedLength != total;
        return SortGroupOutcomeCopyGridListActions.selectAllSuccess({
          updates: list.map((x) => ({
            ...x,
            changes: {
              selected: select
            }
          }))
        });
      })
    )
  );

  showCopyFromModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeCopyGridListActions.showCopyFromModal),
      withLatestFrom(this.sortGroupOutcomeListFacade.list$),
      map(([{ copyFrom }, list]) =>
        SortGroupOutcomeCopyGridListActions.showCopyFromModalSuccess({
          copyFrom,
          list: this.filterAndUnSelect(list)
        })
      )
    )
  );

  showCopyFromModalFromForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeCopyGridListActions.showCopyFromModalFromForm),
      withLatestFrom(this.sortGroupOutcomeListFacade.list$),
      map(([{ copyFrom }, list]) =>
        SortGroupOutcomeCopyGridListActions.showCopyFromModalFromFormSuccess({
          copyFrom,
          list: this.filterAndUnSelect(list)
        })
      )
    )
  );

  showCopyToModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeCopyGridListActions.showCopyToModal),
      withLatestFrom(this.sortGroupOutcomeListFacade.list$),
      map(([{ copyTo }, list]) =>
        SortGroupOutcomeCopyGridListActions.showCopyToModalSuccess({
          copyTo,
          list: this.mapToUnSelect(list)
        })
      )
    )
  );

  mapToUnSelect = (
    x: SortGroupOutcomeListItemDto[]
  ): SortGroupOutcomeListItemDto[] => x.map((x) => ({ ...x, selected: false }));

  filterAndUnSelect = (
    x: SortGroupOutcomeListItemDto[]
  ): SortGroupOutcomeListItemDto[] =>
    this.mapToUnSelect(x.filter((x) => x.status));

  private getTranslationString(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }
}
