import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FEATURE_NAME } from '../../+state';
import { SortGroupOutcomeListFacade } from '../../+state/sort-group-outcome-list/sort-group-outcome-list.facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { SortGroupFormModalComponent } from '../sort-group-form-modal/sort-group-form-modal.component';
import { SortGroupDetailFacade } from '../../+state/sort-group-detail/sort-group-detail.facade';
import { SortGroupOutcomeListItemDto } from '@ifhms/models/feedlot';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

@UntilDestroy()
@Component({
  selector: 'ifhms-sort-group-outcome-modal',
  templateUrl: './sort-group-outcome-modal.component.html',
  styleUrls: ['./sort-group-outcome-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortGroupModalComponent implements OnInit {
  translateScope = `${FEATURE_NAME}.components.sort-group-outcome-modal`;

  display = false;
  loading$: Observable<boolean>;
  sortOptions: SersiSelectListItem[];
  table: Observable<SortGroupOutcomeListItemDto[]>;

  @ViewChild(SortGroupFormModalComponent, { static: false })
    formlyForm: SortGroupFormModalComponent | null;

  @Output() viewClick = new EventEmitter<SortGroupOutcomeListItemDto>();
  @Output() createOutput = new EventEmitter<SortGroupOutcomeListItemDto>();

  @Input() onModelClosed: (isConfirmed: boolean) => void;

  constructor(
    public sortGroupOutcomeListFacade: SortGroupOutcomeListFacade,
    public sortGroupDetailFacade: SortGroupDetailFacade,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.sortGroupOutcomeListFacade.modal$
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.display = val;
        this.cdr.detectChanges();
      });
  }

  onCancel(): void {
    this.sortGroupOutcomeListFacade.hideModal();
    this.onModelClosed?.(false);
  }

  onComplete(): void {
    this.sortGroupOutcomeListFacade.hideModal();
    this.onModelClosed?.(true);
  }
}
