import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AnimalHistoryDto } from '@ifhms/models/feedlot';

import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';

@Injectable()
export class FeedlotEventsAnimalHistoryService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) { }

  getAnimalHistory(feedlotId: string, animalId: string, showPending = false): Observable<AnimalHistoryDto> {
    const params = new HttpParams().set('showPendingEvents', showPending);
    return this.http.get<AnimalHistoryDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Animals/Details/${animalId}/History`, { params });
  }

}
