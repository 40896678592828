import {
  AnimalSelectorItemDto,
  AnimalSelectorRequestDto
} from '@ifhms/models/feedlot';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[CATTLE SELECTOR - LEFT STATE]';

// Replaces all
const replace = createAction(`${NAMESPACE} Replace`);

const replaceSuccess = createAction(
  `${NAMESPACE} Replace Success`,
  props<{ animals: AnimalSelectorItemDto[] }>()
);

// Adds many from the other side
const add = createAction(
  `${NAMESPACE} Add`,
  props<{ animals: AnimalSelectorItemDto[] }>()
);

const addSuccess = createAction(
  `${NAMESPACE} Add Success`,
  props<{ animals: AnimalSelectorItemDto[] }>()
);

// Selects-DeSelects from the given id
const select = createAction(
  `${NAMESPACE} Select`,
  props<{ animal: Update<AnimalSelectorItemDto> }>()
);

const selectMultiple = createAction(
  `${NAMESPACE} Select Multiple`,
  props<{ animals: Update<AnimalSelectorItemDto>[] }>()
);

const selectSuccess = createAction(
  `${NAMESPACE} Select Success`,
  props<{ animal: Update<AnimalSelectorItemDto> }>()
);

// Selects-DeSelects All
const selectAll = createAction(`${NAMESPACE} Select All`);

const selectAllSuccess = createAction(
  `${NAMESPACE} Select All Success`,
  props<{ selected: boolean }>()
);

// Filter
const filter = createAction(
  `${NAMESPACE} Filter`,
  props<{ filter: AnimalSelectorRequestDto }>()
);

const filterSuccess = createAction(
  `${NAMESPACE} Filter Success`,
  props<{ filter: AnimalSelectorRequestDto }>()
);

// Clear Filter
const clearFilter = createAction(`${NAMESPACE} Clear Filter`);

const clearFilterSuccess = createAction(`${NAMESPACE} Clear Filter Success`);

// Removes many
const remove = createAction(`${NAMESPACE} Remove`);

const removeSuccess = createAction(
  `${NAMESPACE} Remove Success`,
  props<{ animals: AnimalSelectorItemDto[] }>()
);

const error = createAction(`${NAMESPACE} Error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const CattleSelectorLeftActions = {
  replace,
  replaceSuccess,
  add,
  addSuccess,
  select,
  selectSuccess,
  selectMultiple,
  selectAll,
  selectAllSuccess,
  filter,
  filterSuccess,
  clearFilter,
  clearFilterSuccess,
  remove,
  removeSuccess,
  error,
  reset
};
