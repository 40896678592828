import { Injectable } from '@angular/core';

import { filterSelectListByAttribute } from '@common/angular/utils';

import { LabourChargesTypes, UnitOfMeasureTypes } from '@ifhms/models/feedlot';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { map, Observable } from 'rxjs';
import { AcquisitionTypesFacade } from './acquisition-types/acquisition-types.facade';
import { AgeClassesFacade } from './age-classes/age-classess.facade';
import { AlleysFacade } from './alleys/alleys.facade';
import { AnimalStatusFacade } from './animal-status/animal-status.facade';
import { BreedTypesFacade } from './breed-types/breed-types.facade';
import { BreedsFacade } from './breeds/breeds.facade';
import { CciaPremiseIdsFacade } from './ccia-premise-ids/ccia-premise-ids.facade';
import { CoatColourFacade } from './coat-colours/coat-colour.facade';
import { CustomFieldsFacade } from './custom-fields/custom-fields.facade';
import { DentitionsFacade } from './dentitions/dentitions.facade';
import { DiagnosisFacade } from './diagnosis/diagnosis.facade';
import { FacilitiesFacade } from './facilities/facilities.facade';
import { FeedingPlansFacade } from './feeding-plans/feeding-plans.facade';
import { FeedlotUsersFacade } from './feedlot-users/feedlot-users.facade';
import { GendersFacade } from './genders/genders.facade';
import { InjectionLocationsFacade } from './injection-locations/injection-locations.facade';
import { LabourChargesFacade } from './labour-charges/labour-charges.facade';
import { LocationsFacade } from './locations/locations.facade';
import { LotOwnersFacade } from './lot-owners/lot-owners.facade';
import { LotSubGroupsFacade } from './lot-sub-groups/lot-sub-groups.facade';
import { LotsFacade } from './lots/lots.facade';
import { MarketingPlansFacade } from './marketing-plans/marketing-plans.facade';
import { OtherFeesFacade } from './other-fees/other-fees.facade';
import { PenTypesFacade } from './pen-types/pen-types.facade';
import { PricingMethodsFacade } from './pricing-methods/pricing-methods.facade';
import { ProceduresFacade } from './procedures/procedures.facade';
import { ProcessMethodsFacade } from './process-methods/process-methods.facade';
import { ProductRoutesFacade } from './product-routes/product-routes.facade';
import { ProductTypesFacade } from './product-types/product-types.facade';
import { ProductsFacade } from './products/products.facade';
import { PurchasersFacade } from './purchasers/purchasers.facade';
import { RailDestinationsFacade } from './rail-destinations/rail-destinations.facade';
import { ReHandlingWorkOrderActionsFacade } from './rehandling-work-order-actions/rehandling-work-order-actions.facade';
import { RiskCategoriesFacade } from './risk-categories/risk-categories.facade';
import { SortTypesFacade } from './sort-types/sort-types.facade';
import { SourcesFacade } from './sources/sources.facade';
import { TagColorsFacade } from './tag-colors/tag-colors.facade';
import { TagLocationsFacade } from './tag-locations/tag-locations.facade';
import { TxGroupsFacade } from './tx-groups/tx-groups.facade';
import { UnitOfMeasuresFacade } from './unit-of-measures/unit-of-measures.facade';
import { WorkOrderMoveActionsFacade } from './work-order-move-actions/work-order-move-actions.facade';

@Injectable()
export class ReferenceDataFacade {
  readonly translateScope = 'domain.state.select-lists';

  acquisitionTypes$: Observable<SersiSelectListItem[]>;
  ageClassess$: Observable<SersiSelectListItem[]>;
  alleys$: Observable<SersiSelectListItem[]>;
  animalStatus$: Observable<SersiSelectListItem[]>;
  breeds$: Observable<SersiSelectListItem[]>;
  breedTypes$: Observable<SersiSelectListItem[]>;
  cciaPremiseIds$: Observable<SersiSelectListItem[]>;
  customFields$: Observable<SersiSelectListItem[]>;
  dentitions$: Observable<SersiSelectListItem[]>;
  diagnosis$: Observable<SersiSelectListItem[]>;
  facilities$: Observable<SersiSelectListItem[]>;
  feedingPlans$: Observable<SersiSelectListItem[]>;
  feedlotUsers$: Observable<SersiSelectListItem[]>;
  feedlotDvmUsers$: Observable<SersiSelectListItem[]>;
  genders$: Observable<SersiSelectListItem[]>;
  homeLocations$: Observable<SersiSelectListItem[]>;
  homePenTypeId$: Observable<string>;
  injectionLocations$: Observable<SersiSelectListItem[]>;
  labourCharges$: Observable<SersiSelectListItem[]>;
  locations$: Observable<SersiSelectListItem[]>;
  lots$: Observable<SersiSelectListItem[]>;
  lotsWithCloseoutInProgressIncluded$: Observable<SersiSelectListItem[]>;
  lotSubGroups$: Observable<SersiSelectListItem[]>;
  marketingPlans$: Observable<SersiSelectListItem[]>;
  otherFees$: Observable<SersiSelectListItem[]>;
  penTypes$: Observable<SersiSelectListItem[]>;
  pricingMethods$: Observable<SersiSelectListItem[]>;
  procedures$: Observable<SersiSelectListItem[]>;
  processMethods$: Observable<SersiSelectListItem[]>;
  productList$: Observable<SersiSelectListItem[]>;
  productRoutes$: Observable<SersiSelectListItem[]>;
  productTypes$: Observable<SersiSelectListItem[]>;
  productTypesWithProducts$: Observable<SersiSelectListItem[]>;
  productWithRoutes$: Observable<SersiSelectListItem[]>;
  purchasers$: Observable<SersiSelectListItem[]>;
  railDestinations$: Observable<SersiSelectListItem[]>;
  reHandlingWorkOrderActions$: Observable<SersiSelectListItem[]>;
  riskCategories$: Observable<SersiSelectListItem[]>;
  route$: Observable<SersiSelectListItem[]>;
  sources$: Observable<SersiSelectListItem[]>;
  tagColors$: Observable<SersiSelectListItem[]>;
  tagLocations$: Observable<SersiSelectListItem[]>;
  unitOfMeasures$: Observable<SersiSelectListItem[]>;
  unitTypes$: Observable<SersiSelectListItem[]>;
  workOrderMoveActions$: Observable<SersiSelectListItem[]>;
  coatColours$: Observable<SersiSelectListItem[]>;
  lotOwners$: Observable<SersiSelectListItem[]>;
  rehandlingProcessMethod$: Observable<SersiSelectListItem[]>;
  arrivalProcessMethod$: Observable<SersiSelectListItem[]>;
  sortTypes$: Observable<SersiSelectListItem[]>;
  txGroups$: Observable<SersiSelectListItem[]>;

  constructor(
    private acquisitionTypesFacade: AcquisitionTypesFacade,
    private ageClassesFacade: AgeClassesFacade,
    private alleysFacade: AlleysFacade,
    private animalStatusFacade: AnimalStatusFacade,
    private breedsFacade: BreedsFacade,
    private breedTypesFacade: BreedTypesFacade,
    private cciaPremiseIdsFacade: CciaPremiseIdsFacade,
    private customFieldsFacade: CustomFieldsFacade,
    private dentitionsFacade: DentitionsFacade,
    private diagnosisFacade: DiagnosisFacade,
    private facilitiesFacade: FacilitiesFacade,
    private feedingPlansFacade: FeedingPlansFacade,
    private feedlotUsersFacade: FeedlotUsersFacade,
    private gendersFacade: GendersFacade,
    private injectionLocationsFacade: InjectionLocationsFacade,
    private labourChargesFacade: LabourChargesFacade,
    private locationsFacade: LocationsFacade,
    private lotsFacade: LotsFacade,
    private lotSubGroupsFacade: LotSubGroupsFacade,
    private marketingPlansFacade: MarketingPlansFacade,
    private otherFeesFacade: OtherFeesFacade,
    private penTypeFacade: PenTypesFacade,
    private pricingMethodsFacade: PricingMethodsFacade,
    private proceduresFacade: ProceduresFacade,
    private processMethodsFacade: ProcessMethodsFacade,
    private productsFacade: ProductsFacade,
    private productRoutesFacade: ProductRoutesFacade,
    private productTypesFacade: ProductTypesFacade,
    private purchasersFacade: PurchasersFacade,
    private railDestinationsFacade: RailDestinationsFacade,
    private reHandlingWorkOrderActionsFacade: ReHandlingWorkOrderActionsFacade,
    private riskCategoriesFacade: RiskCategoriesFacade,
    private sourcesFacade: SourcesFacade,
    private tagColorsFacade: TagColorsFacade,
    private tagLocationsFacade: TagLocationsFacade,
    private unitOfMeasuresFacade: UnitOfMeasuresFacade,
    private workOrderMoveActionsFacade: WorkOrderMoveActionsFacade,
    private coatColourFacade: CoatColourFacade,
    private lotOwnersFacade: LotOwnersFacade,
    private sortTypesFacade: SortTypesFacade,
    private txGroupsFacade: TxGroupsFacade
  ) {
    this.acquisitionTypes$ = this.acquisitionTypesFacade.items$;
    this.ageClassess$ = this.ageClassesFacade.items$;
    this.alleys$ = this.alleysFacade.items$;
    this.animalStatus$ = this.animalStatusFacade.items$;
    this.breeds$ = this.breedsFacade.items$;
    this.breedTypes$ = this.breedTypesFacade.items$;
    this.cciaPremiseIds$ = this.cciaPremiseIdsFacade.items$;
    this.customFields$ = this.customFieldsFacade.items$;
    this.dentitions$ = this.dentitionsFacade.items$;
    this.diagnosis$ = this.diagnosisFacade.items$;
    this.facilities$ = this.facilitiesFacade.items$;
    this.feedingPlans$ = this.feedingPlansFacade.items$;
    this.feedlotUsers$ = this.feedlotUsersFacade.feedlotUsers$;
    this.feedlotDvmUsers$ = this.feedlotUsersFacade.dvms$;
    this.genders$ = this.gendersFacade.items$;
    this.injectionLocations$ = this.injectionLocationsFacade.items$;
    this.labourCharges$ = this.labourChargesFacade.items$;
    this.locations$ = this.locationsFacade.items$;
    this.lots$ = this.lotsFacade.items$;
    this.lotsWithCloseoutInProgressIncluded$ = this.lotsFacade.itemsWithCloseoutInProgressIncluded$;
    this.lotSubGroups$ = this.lotSubGroupsFacade.items$;
    this.marketingPlans$ = this.marketingPlansFacade.items$;
    this.otherFees$ = this.otherFeesFacade.items$;
    this.penTypes$ = this.penTypeFacade.items$;
    this.pricingMethods$ = this.pricingMethodsFacade.items$;
    this.procedures$ = this.proceduresFacade.items$;
    this.productList$ = this.productsFacade.items$;
    this.productRoutes$ = this.productRoutesFacade.items$;
    this.productTypes$ = this.productTypesFacade.items$;
    this.purchasers$ = this.purchasersFacade.items$;
    this.railDestinations$ = this.railDestinationsFacade.items$;
    this.riskCategories$ = this.riskCategoriesFacade.items$;
    this.sources$ = this.sourcesFacade.items$;
    this.tagColors$ = this.tagColorsFacade.items$;
    this.tagLocations$ = this.tagLocationsFacade.items$;
    this.unitOfMeasures$ = this.unitOfMeasuresFacade.items$;
    this.processMethods$ = this.processMethodsFacade.items$;
    this.workOrderMoveActions$ = this.workOrderMoveActionsFacade.items$;
    this.reHandlingWorkOrderActions$ =
      this.reHandlingWorkOrderActionsFacade.items$;
    this.coatColours$ = this.coatColourFacade.items$;
    this.lotOwners$ = this.lotOwnersFacade.items$;
    this.sortTypes$ = this.sortTypesFacade.items$;
    this.txGroups$ = this.txGroupsFacade.items$;

    this.homeLocations$ = this.locationsFacade.getHomeLocations();
    this.productTypesWithProducts$ =
      this.productTypesFacade.getWithActiveProducts();
    this.productWithRoutes$ = this.productsFacade.getProductWithRoutes();
    this.arrivalProcessMethod$ = this.getArrivalProcessMethod();
    this.rehandlingProcessMethod$ = this.getReHandlingProcessMethod();

    this.homePenTypeId$ = this.penTypeFacade.items$.pipe(
      map((data: SersiSelectListItem[]) => {
        // TODO: use custom attributes instead of code hardcoding
        const homePenType = data.find((result) => result.code === 'Home');
        return homePenType ? homePenType.id.toString() : '';
      })
    );
  }

  getArrivalProcessMethod(): Observable<SersiSelectListItem[]> {
    return this.processMethods$.pipe(
      map((data: SersiSelectListItem[]) => {
        return data;
      })
    )
  }

  getReHandlingProcessMethod(): Observable<SersiSelectListItem[]> {
    return this.processMethods$.pipe(
      map((data: SersiSelectListItem[]) => {
        return data;
      })
    )
  }

  getLabourChargesItems(
    type: LabourChargesTypes
  ): Observable<SersiSelectListItem[]> {
    return filterSelectListByAttribute(
      this.labourChargesFacade.items$,
      'labourChargeType',
      type
    );
  }

  getFacilityLocations(facilityId: string): Observable<SersiSelectListItem[]> {
    return this.locationsFacade.getFacilityLocations(facilityId);
  }

  getLotsSubGroupByLotId(lotId: string): Observable<SersiSelectListItem[]> {
    return filterSelectListByAttribute(
      this.lotSubGroupsFacade.items$,
      'lotId',
      lotId
    );
  }

  getTagColorByCode(tagCode: string): Observable<string | null> {
    return this.tagColorsFacade.getTagColorByCode(tagCode);
  }

  getProductsForProductTypes(
    productTypeId: string
  ): Observable<SersiSelectListItem[]> {
    return this.productsFacade.getProductsForProductTypes(productTypeId);
  }

  getRoutesForProducts(productId: string): Observable<SersiSelectListItem[]> {
    return this.productRoutesFacade.getRoutesForProducts(productId);
  }

  getAcquisitionTypes(): Observable<SersiSelectListItem[]> {
    return this.acquisitionTypesFacade.get();
  }

  getAgeClassess(): Observable<SersiSelectListItem[]> {
    return this.ageClassesFacade.get();
  }

  getAlleys(): Observable<SersiSelectListItem[]> {
    return this.alleysFacade.get();
  }

  getAnimalStatus(): Observable<SersiSelectListItem[]> {
    return this.animalStatusFacade.get();
  }

  getBreeds(): Observable<SersiSelectListItem[]> {
    return this.breedsFacade.get();
  }

  getBreedTypes(): Observable<SersiSelectListItem[]> {
    return this.breedTypesFacade.get();
  }

  getCoatColours(): Observable<SersiSelectListItem[]> {
    return this.coatColourFacade.get();
  }

  getCciaPremiseIds(): Observable<SersiSelectListItem[]> {
    return this.cciaPremiseIdsFacade.get();
  }

  getCustomFields(): Observable<SersiSelectListItem[]> {
    return this.customFieldsFacade.get();
  }

  getDentitions(): Observable<SersiSelectListItem[]> {
    return this.dentitionsFacade.get();
  }

  getDiagnosis(): Observable<SersiSelectListItem[]> {
    return this.diagnosisFacade.get();
  }

  getFacilities(): Observable<SersiSelectListItem[]> {
    return this.facilitiesFacade.get();
  }

  getFeedingPlans(): Observable<SersiSelectListItem[]> {
    return this.feedingPlansFacade.get();
  }

  getFeedlotUsers(): Observable<SersiSelectListItem[]> {
    return this.feedlotUsersFacade.get();
  }

  getGenders(): Observable<SersiSelectListItem[]> {
    return this.gendersFacade.get();
  }

  getInjectionLocations(): Observable<SersiSelectListItem[]> {
    return this.injectionLocationsFacade.get();
  }

  getLabourCharges(): Observable<SersiSelectListItem[]> {
    return this.labourChargesFacade.get();
  }

  getLocations(): Observable<SersiSelectListItem[]> {
    return this.locationsFacade.get();
  }

  getLots(): Observable<SersiSelectListItem[]> {
    return this.lotsFacade.get();
  }

  getLotSubGroups(): Observable<SersiSelectListItem[]> {
    return this.lotSubGroupsFacade.get();
  }

  getMarketingPlans(): Observable<SersiSelectListItem[]> {
    return this.marketingPlansFacade.get();
  }

  getOtherFees(): Observable<SersiSelectListItem[]> {
    return this.otherFeesFacade.get();
  }

  getPenTypes(): Observable<SersiSelectListItem[]> {
    return this.penTypeFacade.get();
  }

  getPricingMethods(): Observable<SersiSelectListItem[]> {
    return this.pricingMethodsFacade.get();
  }

  getProcedures(): Observable<SersiSelectListItem[]> {
    return this.proceduresFacade.get();
  }

  getProductList(
    workOrderCreatedDate?: string,
    force?: boolean
  ): Observable<SersiSelectListItem[]> {
    this.productsFacade.get(workOrderCreatedDate, force);
    return this.productsFacade.items$;
  }

  getProductRoutes(
    workOrderCreatedDate?: string,
    force?: boolean
  ): Observable<SersiSelectListItem[]> {
    this.productRoutesFacade.get(workOrderCreatedDate, force);
    return this.productRoutesFacade.items$;
  }

  getProductTypes(): Observable<SersiSelectListItem[]> {
    return this.productTypesFacade.get();
  }

  getPurchasers(): Observable<SersiSelectListItem[]> {
    return this.purchasersFacade.get();
  }

  getRailDestinations(): Observable<SersiSelectListItem[]> {
    return this.railDestinationsFacade.get();
  }

  getRiskCatgeories(): Observable<SersiSelectListItem[]> {
    return this.riskCategoriesFacade.get();
  }

  getSortTypes(): Observable<SersiSelectListItem[]> {
    return this.sortTypesFacade.get();
  }

  getSources(): Observable<SersiSelectListItem[]> {
    return this.sourcesFacade.get();
  }

  getTagColors(): Observable<SersiSelectListItem[]> {
    return this.tagColorsFacade.get();
  }

  getTagLocations(): Observable<SersiSelectListItem[]> {
    return this.tagLocationsFacade.get();
  }

  getUnitOfMeasures(): Observable<SersiSelectListItem[]> {
    return this.unitOfMeasuresFacade.get();
  }

  getUnitOfMeasuresByType(
    type: UnitOfMeasureTypes
  ): Observable<SersiSelectListItem[]> {
    return this.unitOfMeasuresFacade.getByType(type);
  }

  getLotOwners(): Observable<SersiSelectListItem[]> {
    return this.lotOwnersFacade.get();
  }

  getTxGroups(): Observable<SersiSelectListItem[]> {
    return this.txGroupsFacade.get();
  }

  getProductById(
    productId: string
  ): Observable<SersiSelectListItem | undefined> {
    return this.productsFacade.getProductById(productId);
  }

  getRouteById(routeId: string): Observable<SersiSelectListItem | undefined> {
    return this.productRoutesFacade.getRouteById(routeId);
  }

  reset(): void {
    this.acquisitionTypesFacade.reset();
    this.ageClassesFacade.reset();
    this.alleysFacade.reset();
    this.animalStatusFacade.reset();
    this.breedsFacade.reset();
    this.breedTypesFacade.reset();
    this.cciaPremiseIdsFacade.reset();
    this.customFieldsFacade.reset();
    this.dentitionsFacade.reset();
    this.diagnosisFacade.reset();
    this.facilitiesFacade.reset();
    this.feedingPlansFacade.reset();
    this.feedlotUsersFacade.reset();
    this.gendersFacade.reset();
    this.injectionLocationsFacade.reset();
    this.labourChargesFacade.reset();
    this.locationsFacade.reset();
    this.lotsFacade.reset();
    this.lotSubGroupsFacade.reset();
    this.marketingPlansFacade.reset();
    this.otherFeesFacade.reset();
    this.penTypeFacade.reset();
    this.pricingMethodsFacade.reset();
    this.proceduresFacade.reset();
    this.productsFacade.reset();
    this.productRoutesFacade.reset();
    this.productTypesFacade.reset();
    this.purchasersFacade.reset();
    this.railDestinationsFacade.reset();
    this.riskCategoriesFacade.reset();
    this.sourcesFacade.reset();
    this.tagColorsFacade.reset();
    this.tagLocationsFacade.reset();
    this.unitOfMeasuresFacade.reset();
    this.coatColourFacade.reset();
    this.lotOwnersFacade.reset();
    this.sortTypesFacade.reset();
    this.txGroupsFacade.reset();
  }
}
