import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AcquisitionTypesEffects } from './+state/acquisition-types/acquisition-types.effects';
import { AcquisitionTypesFacade } from './+state/acquisition-types/acquisition-types.facade';
import { AgeClassesEffects } from './+state/age-classes/age-classes.effects';
import { AgeClassesFacade } from './+state/age-classes/age-classess.facade';
import { AlleysEffects } from './+state/alleys/alleys.effects';
import { AlleysFacade } from './+state/alleys/alleys.facade';
import { AnimalStatusEffects } from './+state/animal-status/animal-status.effects';
import { AnimalStatusFacade } from './+state/animal-status/animal-status.facade';
import { BreedTypesEffects } from './+state/breed-types/breed-types.effects';
import { BreedTypesFacade } from './+state/breed-types/breed-types.facade';
import { BreedsEffects } from './+state/breeds/breeds.effects';
import { BreedsFacade } from './+state/breeds/breeds.facade';
import { CciaPremiseIdsEffects } from './+state/ccia-premise-ids/ccia-premise-ids.effects';
import { CciaPremiseIdsFacade } from './+state/ccia-premise-ids/ccia-premise-ids.facade';
import { CoatColourEffects } from './+state/coat-colours/coat-colour.effects';
import { CoatColourFacade } from './+state/coat-colours/coat-colour.facade';
import { CustomFieldsEffects } from './+state/custom-fields/custom-fields.effects';
import { CustomFieldsFacade } from './+state/custom-fields/custom-fields.facade';
import { DentitionsEffects } from './+state/dentitions/dentitions.effects';
import { DentitionsFacade } from './+state/dentitions/dentitions.facade';
import { DiagnosisEffects } from './+state/diagnosis/diagnosis.effects';
import { DiagnosisFacade } from './+state/diagnosis/diagnosis.facade';
import { ReferenceDataEffects } from './+state/effects';
import { ReferenceDataFacade } from './+state/facade';
import { FacilitiesEffects } from './+state/facilities/facilities.effects';
import { FacilitiesFacade } from './+state/facilities/facilities.facade';
import { FeedingPlansEffects } from './+state/feeding-plans/feeding-plans.effects';
import { FeedingPlansFacade } from './+state/feeding-plans/feeding-plans.facade';
import { FeedlotUsersEffects } from './+state/feedlot-users/feedlot-users.effects';
import { FeedlotUsersFacade } from './+state/feedlot-users/feedlot-users.facade';
import { GendersEffects } from './+state/genders/genders.effects';
import { GendersFacade } from './+state/genders/genders.facade';
import { InjectionLocationsEffects } from './+state/injection-locations/injection-locations.effects';
import { InjectionLocationsFacade } from './+state/injection-locations/injection-locations.facade';
import { LabourChargesEffects } from './+state/labour-charges/labour-charges.effects';
import { LabourChargesFacade } from './+state/labour-charges/labour-charges.facade';
import { LocationsEffects } from './+state/locations/locations.effects';
import { LocationsFacade } from './+state/locations/locations.facade';
import { LotSubGroupsEffects } from './+state/lot-sub-groups/lot-sub-groups.effects';
import { LotSubGroupsFacade } from './+state/lot-sub-groups/lot-sub-groups.facade';
import { LotsEffects } from './+state/lots/lots.effects';
import { LotsFacade } from './+state/lots/lots.facade';
import { MarketingPlansEffects } from './+state/marketing-plans/marketing-plans.effects';
import { MarketingPlansFacade } from './+state/marketing-plans/marketing-plans.facade';
import { OtherFeesEffects } from './+state/other-fees/other-fees.effects';
import { OtherFeesFacade } from './+state/other-fees/other-fees.facade';
import { PenTypesEffects } from './+state/pen-types/pen-types.effects';
import { PenTypesFacade } from './+state/pen-types/pen-types.facade';
import { PricingMethodsFacade } from './+state/pricing-methods/pricing-methods.facade';
import { ProceduresEffects } from './+state/procedures/procedures.effects';
import { ProceduresFacade } from './+state/procedures/procedures.facade';
import { ProcessMethodsFacade } from './+state/process-methods/process-methods.facade';
import { ProductRoutesEffects } from './+state/product-routes/product-routes.effects';
import { ProductRoutesFacade } from './+state/product-routes/product-routes.facade';
import { ProductTypesEffects } from './+state/product-types/product-types.effects';
import { ProductTypesFacade } from './+state/product-types/product-types.facade';
import { ProductsEffects } from './+state/products/products.effects';
import { ProductsFacade } from './+state/products/products.facade';
import { PurchasersEffects } from './+state/purchasers/purchasers.effects';
import { PurchasersFacade } from './+state/purchasers/purchasers.facade';
import { RailDestinationsEffects } from './+state/rail-destinations/rail-destinations.effects';
import { RailDestinationsFacade } from './+state/rail-destinations/rail-destinations.facade';
import { FEATURE_NAME, reducer } from './+state/reducer';
import {
  ReHandlingWorkOrderActionsFacade
} from './+state/rehandling-work-order-actions/rehandling-work-order-actions.facade';
import { RiskCategoriesEffects } from './+state/risk-categories/risk-categories.effects';
import { RiskCategoriesFacade } from './+state/risk-categories/risk-categories.facade';
import { SortTypesEffects } from './+state/sort-types/sort-types.effects';
import { SortTypesFacade } from './+state/sort-types/sort-types.facade';
import { SourcesEffects } from './+state/sources/sources.effects';
import { SourcesFacade } from './+state/sources/sources.facade';
import { TagColorsEffects } from './+state/tag-colors/tag-colors.effects';
import { TagColorsFacade } from './+state/tag-colors/tag-colors.facade';
import { TagLocationsEffects } from './+state/tag-locations/tag-locations.effects';
import { TagLocationsFacade } from './+state/tag-locations/tag-locations.facade';
import { UnitOfMeasuresEffects } from './+state/unit-of-measures/unit-of-measures.effects';
import { UnitOfMeasuresFacade } from './+state/unit-of-measures/unit-of-measures.facade';
import { WorkOrderMoveActionsFacade } from './+state/work-order-move-actions/work-order-move-actions.facade';
import { LotOwnersEffects } from './+state/lot-owners/lot-owners.effects';
import { LotOwnersFacade } from './+state/lot-owners/lot-owners.facade';
import { TxGroupsEffects } from './+state/tx-groups/tx-groups.effects';
import { TxGroupsFacade } from './+state/tx-groups/tx-groups.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([
      AcquisitionTypesEffects,
      AgeClassesEffects,
      AlleysEffects,
      AnimalStatusEffects,
      BreedsEffects,
      CoatColourEffects,
      BreedTypesEffects,
      CciaPremiseIdsEffects,
      CustomFieldsEffects,
      DentitionsEffects,
      DiagnosisEffects,
      FacilitiesEffects,
      FeedingPlansEffects,
      FeedlotUsersEffects,
      GendersEffects,
      InjectionLocationsEffects,
      LabourChargesEffects,
      LocationsEffects,
      LotsEffects,
      LotSubGroupsEffects,
      MarketingPlansEffects,
      OtherFeesEffects,
      PenTypesEffects,
      ProceduresEffects,
      ProductsEffects,
      ProductTypesEffects,
      ProductRoutesEffects,
      PurchasersEffects,
      RailDestinationsEffects,
      ReferenceDataEffects,
      RiskCategoriesEffects,
      SourcesEffects,
      TagColorsEffects,
      TagLocationsEffects,
      UnitOfMeasuresEffects,
      LotOwnersEffects,
      SortTypesEffects,
      TxGroupsEffects
    ])
  ],
  providers: [
    AcquisitionTypesFacade,
    AgeClassesFacade,
    AlleysFacade,
    AnimalStatusFacade,
    BreedsFacade,
    BreedTypesFacade,
    CoatColourFacade,
    CciaPremiseIdsFacade,
    CustomFieldsFacade,
    DentitionsFacade,
    DiagnosisFacade,
    FacilitiesFacade,
    FeedingPlansFacade,
    FeedlotUsersFacade,
    GendersFacade,
    InjectionLocationsFacade,
    LabourChargesFacade,
    LocationsFacade,
    LotsFacade,
    LotSubGroupsFacade,
    MarketingPlansFacade,
    OtherFeesFacade,
    PenTypesFacade,
    PricingMethodsFacade,
    ProceduresFacade,
    ProcessMethodsFacade,
    ProductsFacade,
    ProductTypesFacade,
    ProductRoutesFacade,
    PurchasersFacade,
    RailDestinationsFacade,
    ReferenceDataFacade,
    ReHandlingWorkOrderActionsFacade,
    RiskCategoriesFacade,
    SourcesFacade,
    TagColorsFacade,
    TagLocationsFacade,
    UnitOfMeasuresFacade,
    WorkOrderMoveActionsFacade,
    LotOwnersFacade,
    SortTypesFacade,
    TxGroupsFacade
  ]
})
export class IfhmsFeedlotFrontEndSharedDomainStateReferenceDataModule {
}
