import { GenericEnum } from '@common/angular/interfaces';

export function getEnumValueCaseInsensitive<T>(key: string, enumObj: GenericEnum): T | null {
  const enumKey = Object.keys(enumObj).find(iter => iter.toLocaleLowerCase() === key.toLocaleString())
  return enumKey ? enumObj[enumKey] as T : null;
}

export function getEnumKeyByValue(val: string|number, enumObj: GenericEnum): string | null {
  const enumKey = Object.keys(enumObj).find(key => enumObj[key] === val);
  return enumKey || null;
}
