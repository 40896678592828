import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UpcScaleActions } from './upc-scale.actions';
import { UpcScaleSelectors } from './upc-scale.selectors';
import * as reducer from './upc-scale.reducer';
import { UpcScaleService } from '@ifhms/common/angular/data-access/upc-api';
import { ScaleDate } from '@ifhms/models/feedlot';

@Injectable()
export class UpcScaleFacade {

  loading$: Observable<boolean> = this.store.select(UpcScaleSelectors.selectLoading);
  error$: Observable<string | null | undefined> = this.store.select(UpcScaleSelectors.selectError);
  state$: Observable<reducer.State> = this.store.select(UpcScaleSelectors.selectState);
  weightCaptured$: Observable<boolean> = this.store.select(UpcScaleSelectors.selectWeightCaptured);
  weight$: Observable<ScaleDate | null> = this.upcScaleService.scaleData$;

  constructor(private readonly store: Store, private upcScaleService: UpcScaleService) {

  }

  getScaleStatusFromUpc(): void {
    this.store.dispatch(UpcScaleActions.getScaleStatusFromUpc());
  }

  captureWeight(): void {
    this.store.dispatch(UpcScaleActions.captureWeight());
  }

  resetWeightCapture(): void {
    this.store.dispatch(UpcScaleActions.resetWeightCapture());
  }
  resetError(): void {
    this.store.dispatch(UpcScaleActions.resetError());
  }

}
