import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, of, withLatestFrom } from 'rxjs';

import { FeedlotListDto } from '@ifhms/models/feedlot';
import { getPreviousValue } from '@common/angular/utils';
import { FeedlotService } from '@ifhms/common/angular/data-access/feedlot-api';

import { feedlotsActions } from './feedlots.actions';
import { FeedlotsFacade } from './feedlots.facade';

@Injectable()
export class FeedlotsEffects {

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(feedlotsActions.get),
      withLatestFrom(getPreviousValue(this.feedlotsFacade.state$)),
      filter(([action, state]) => {
        if (action.forceReload) return true;
        return !state.loaded && !state.loading;
      }),
      exhaustMap(() => {
        return this.feedlotService.getAll()
          .pipe(
            map((feedlots: FeedlotListDto) => feedlotsActions.getSuccess({ feedlots })),
            catchError(() => of(feedlotsActions.error()) )
          )
      })
    ));

  getCached$ = createEffect(() =>
    this.actions$.pipe(
      ofType(feedlotsActions.get),
      withLatestFrom(getPreviousValue(this.feedlotsFacade.state$)),
      filter(([action, state]) => {
        return !action.forceReload && state.loaded;
      }),
      map(() => feedlotsActions.getSuccessCached())
    ));

  constructor(
    private feedlotService: FeedlotService,
    private feedlotsFacade: FeedlotsFacade,
    private actions$: Actions
  ) {}
}
