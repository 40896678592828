import { createReducer, on } from '@ngrx/store';
import { SortGroupOutcomeDto } from '@ifhms/models/feedlot';
import { SortGroupOutcomeDetailActions } from './sort-group-outcome-detail.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  modal: boolean;
  detail: SortGroupOutcomeDto | null;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  modal: false,
  detail: null
};

export const reducer = createReducer(
  initialState,

  on(
    SortGroupOutcomeDetailActions.get,
    SortGroupOutcomeDetailActions.updateModal,
    SortGroupOutcomeDetailActions.next,
    SortGroupOutcomeDetailActions.back,
    SortGroupOutcomeDetailActions.bulk,
    SortGroupOutcomeDetailActions.applyDefault,
    SortGroupOutcomeDetailActions.clearDefault,
    SortGroupOutcomeDetailActions.setDefault,
    (state) => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),

  on(
    SortGroupOutcomeDetailActions.getSuccess,
    SortGroupOutcomeDetailActions.updateModalSuccess,
    SortGroupOutcomeDetailActions.setDefaultSuccess,
    SortGroupOutcomeDetailActions.applyDefaultSuccess,
    (state, action) => ({
      ...state,
      detail: action.outcome,
      loaded: true,
      loading: false
    })
  ),

  on(
    SortGroupOutcomeDetailActions.clearDefaultSuccess,
    SortGroupOutcomeDetailActions.bulkSuccess,
    SortGroupOutcomeDetailActions.nextSuccess,
    SortGroupOutcomeDetailActions.backSuccess,
    (state) => ({
      ...state,
      loaded: true,
      loading: false
    })
  ),

  on(SortGroupOutcomeDetailActions.showModal, (state) => ({
    ...state,
    modal: true
  })),

  on(SortGroupOutcomeDetailActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),

  on(
    SortGroupOutcomeDetailActions.hideModal,
    SortGroupOutcomeDetailActions.reset,
    () => initialState
  )
);

export const getLoading = (state: State): boolean => state.loading;

export const getLoaded = (state: State): boolean => state.loaded;

export const getOutcome = (state: State): SortGroupOutcomeDto | null =>
  state.detail;

export const getOutcomeId = (state: State): string | undefined =>
  state.detail?.id;

export const getModal = (state: State): boolean => state.modal;

export const getDetail = (state: State): SortGroupOutcomeDto | null =>
  state.detail;
