<ng-container *transloco="let t; read: translateScope">
  <p-table
    *transloco="let t; read: translateScope"
    [tableStyle]="{ 'min-width': '50rem' }"
    [value]="data?.list || []"
    [scrollable]="false"
    [virtualScrollItemSize]="50"
    styleClass="p-datatable-lg p-datatable-striped"
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center"></th>
        <th class="text-center">
          {{ t('description') }}
        </th>
        <th class="text-center">
          {{ t('breed') }}
        </th>
        <th class="text-center">
          {{ t('breed-type') }}
        </th>
        <th class="text-center">
          {{ t('weight') }}
        </th>
        <th class="text-center">
          {{ t('age-class') }}
        </th>
        <th class="text-center">
          {{ t('age') }}
        </th>
        <th class="text-center">
          {{ t('gender') }}
        </th>
        <th class="text-center">
          {{ t('dCode') }}
        </th>
        <th class="text-center">
          {{ t('temperature') }}
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <div class="w-30rem">
          {{ t('empty') }}
        </div>
      </tr>
    </ng-template>
    
    <ng-template let-managementGroup let-index="rowIndex" pTemplate="body">
      <tr
        [attr.data-management-group-id-ele]="managementGroup.id"
        (click)="onRowClick(managementGroup.id)"
        sersiCursorHover
        [className]="managementGroup.id === selectedId && 'selected'"
      >
        <td class="text-center">
          <i *ngIf="managementGroup.id === selectedId" class="pi pi-check"></i>
        </td>
        <td class="text-center">
          {{ managementGroup.description }}
        </td>
        <ng-container
          *ngFor="
            let attribute of [
              SortGroupTemplateAttributes.Breed,
              SortGroupTemplateAttributes.BreedType,
              SortGroupTemplateAttributes.Weight,
              SortGroupTemplateAttributes.AgeClass,
              SortGroupTemplateAttributes.Age,
              SortGroupTemplateAttributes.Gender,
              SortGroupTemplateAttributes.Dentition,
              SortGroupTemplateAttributes.Temperature
            ]
          "
        >
          <td class="text-center">
            <i
              *ngIf="managementGroup.attributes.includes(attribute)"
              class="pi pi-check"
            ></i>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
