import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormRfidFieldComponent } from './components';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AH_FMS_UPC_CONFIG, UPC_CONFIG, UpcRfidService } from '@ifhms/common/angular/data-access/upc-api';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';

@NgModule({
  imports: [CommonModule, FormlyModule, ReactiveFormsModule, InputTextModule, InputNumberModule],
  declarations: [FormRfidFieldComponent],
  providers: [
    {
      provide: AH_FMS_UPC_CONFIG,
      useValue: UPC_CONFIG,
      multi: false
    },
    UpcRfidService
  ],
  exports: [FormRfidFieldComponent]
})
export class IfhmsCommonAngularUpcRfidModule {}
