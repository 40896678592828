export enum QuickSearchEntityTypeEnum {
  AnimalElectronicTag = 'AnimalElectronicTag',
  AnimalTag = 'AnimalTag',
  ArrivalWorkOrder = 'ArrivalWorkOrder',
  LotTransferWorkOrder = 'LotTransferWorkOrder',
  MovementWorkOrder = 'MovementWorkOrder',
  RailWorkOrder = 'RailWorkOrder',
  ReHandlingWorkOrder = 'ReHandlingWorkOrder',
  ShipmentWorkOrder = 'ShipmentWorkOrder'
}
