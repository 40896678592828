import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TableFilters } from './table-filter.types';
import { isArray, isObject } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class TableFilterService {

  set appliedFilters(filters: TableFilters<unknown>) {
    const filtersCount = Object.values(filters)
      .filter(val => {
        if (isArray(val)) {
          // Edge case for range slider. If values are manually set back to 0 or null return 0
          if (this.isRangeSlider(val) && this.hasRangeVal(val)) return 0
          return val.length
        }
        // if object is a Date, add 1 to filter count
        if (this.isDateVal(val)) return true
        if (isObject(val)) return Object.keys(val).length;
        return !!val;
      })
      .length;
    this._appliedFilters = { ...filters };
    this.appliedFiltersSubject.next(filtersCount);
  }
  get appliedFilters(): TableFilters<unknown> {
    return { ...this._appliedFilters };
  }

  appliedFiltersCount$: Observable<number>;
  isModalVisible$: Observable<boolean>;

  private _appliedFilters: TableFilters<unknown>;
  private modalVisibleSubject = new BehaviorSubject<boolean>(false);
  private appliedFiltersSubject = new BehaviorSubject<number>(0);

  constructor() {
    this.isModalVisible$ = this.modalVisibleSubject.asObservable()
    this.appliedFiltersCount$ = this.appliedFiltersSubject.asObservable()
  }

  toggleModalVisibility(isVisible: boolean): void {
    this.modalVisibleSubject.next(isVisible);
  }

  private isRangeSlider(arr: any[]): boolean {
    const isNumbersArray = arr.every(val => val === null || Number.isInteger(val));
    const isRangeArraySize = arr.length === 2;
    return isNumbersArray && isRangeArraySize;
  }

  private hasRangeVal(arr: any[]): boolean {
    return !arr[0] && !arr[1]
  }

  private isDateVal(val: any): boolean {
    return val instanceof Date
  }
}
