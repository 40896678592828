import { Injectable } from '@angular/core';
import {
  filterSelectListByAttribute,
  getLoadedSelectItems
} from '@common/angular/utils';
import { LocationTypeCode } from '@ifhms/models/feedlot';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { map, Observable } from 'rxjs';
import { LocationsActions } from './locations.actions';
import * as reducer from './locations.reducer';
import { LocationsSelectors } from './locations.selectors';

@Injectable()
export class LocationsFacade {
  loaded$ = this.store.select(LocationsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(
    this.store.select(LocationsSelectors.selectItems),
    this.loaded$
  );

  constructor(private store: Store<reducer.State>) {}

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(LocationsActions.get());
    return this.items$;
  }

  getHomeLocations(): Observable<SersiSelectListItem[]> {
    return filterSelectListByAttribute(
      this.items$,
      'penTypeCode',
      LocationTypeCode[LocationTypeCode.Home]
    );
  }

  getFacilityLocations(facilityId: string): Observable<SersiSelectListItem[]> {
    return this.items$.pipe(
      map((locations) =>
        locations.filter((location) => {
          const facilities =
            location.attributes &&
            (location.attributes['facilities'] as string[]);
          return Array.isArray(facilities) && facilities.includes(facilityId);
        })
      )
    );
  }

  reset(): void {
    this.store.dispatch(LocationsActions.reset());
  }
}
