import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  MovementWorkOrderDto,
  MovementWorkOrderUpdateDto,
  WorkOrderMovementListDto,
  WorkOrderMovementListRequestDto,
  WorkOrderMovementStatus
} from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';

@Injectable()
export class MovementWorkOrderService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {
  }

  getList(feedlotId: string, request: WorkOrderMovementListRequestDto): Observable<WorkOrderMovementListDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<WorkOrderMovementListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Movement/WorkOrder`, {
      params
    });
  }

  createMovement(feedlotId: string, dto: unknown): Observable<MovementWorkOrderDto> {
    return this.http.post<MovementWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Movement`, dto);
  }

  getMovementWorkOrder(feedlotId: string, workOrderId: string): Observable<MovementWorkOrderDto> {
    return this.http.get<MovementWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Movement/WorkOrder/${workOrderId}`);
  }

  update(feedlotId: string, movementWorkOrderUpdate: MovementWorkOrderUpdateDto): Observable<MovementWorkOrderDto> {
    return this.http.put<MovementWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Movement/WorkOrder`, movementWorkOrderUpdate);
  }

  updateStatus(feedlotId: string, workOrderId: string, status: WorkOrderMovementStatus): Observable<MovementWorkOrderDto> {
    const requestStatus = status === WorkOrderMovementStatus.Scheduled ? 'Schedule' : status === WorkOrderMovementStatus.WaitingForApproval ? 'RequestApproval' : 'Finalize';
    return this.http.put<MovementWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Movement/WorkOrder/${workOrderId}/${requestStatus}`, null);
  }

  requestApproval(feedlotId: string, workOrderId: string, workEventDate: string): Observable<MovementWorkOrderDto> {
    return this.http.put<MovementWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Movement/WorkOrder/${workOrderId}/RequestApproval`, { workEventDate });
  }

  generateReport(feedlotId: string, workOrderId: string): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            levelId: feedlotId ?? '',
            workOrderId: workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/Reports/MovementWorkOrder`, options);
  }

  finalize(feedlotId: string, workOrderId: string): Observable<MovementWorkOrderDto> {
    return this.http.put<MovementWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Movement/WorkOrder/${workOrderId}/Finalize`, null);
  }

  deleteWorkOrder(feedlotId: string, workOrderId: string): Observable<MovementWorkOrderDto> {
    return this.http.delete<MovementWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Movement/WorkOrder/${workOrderId}`);
  }
}
