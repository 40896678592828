import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { scopeLoader } from '@common/angular/translation';
import {
  AhCommonDialogService,
  removeBodyOverflow
} from '@common/angular/utils';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { AnimalSelectorItemDto } from '@ifhms/models/feedlot';
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { take } from 'rxjs';
import { FEATURE_NAME } from '../../+state';
import { CattleSelectorLeftFacade } from '../../+state/left/left.facade';
import { CattleSelectorRightFacade } from '../../+state/right/right.facade';
import { CattleSelectorWOAttributes } from '../../interfaces';
import { CattleSelectorTableComponent } from '../cattle-selector-table';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ifhms-cattle-selector-modal',
  templateUrl: './cattle-selector-modal.component.html',
  styleUrls: ['./cattle-selector-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: FEATURE_NAME,
        alias: FEATURE_NAME,
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class CattleSelectorModalComponent implements OnInit, OnDestroy {
  translateScope = `${FEATURE_NAME}.components.cattle-selector-modal`;

  changes = false;
  display = false;
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  @HostBinding('class') class = 'cattle-selector';
  @ViewChild(CattleSelectorTableComponent, { static: false })
    left: CattleSelectorTableComponent;

  @Input() save: (_: AnimalSelectorItemDto[]) => void;
  @Input() woAttributes: CattleSelectorWOAttributes;

  constructor(
    public leftFacade: CattleSelectorLeftFacade,
    public rightFacade: CattleSelectorRightFacade,
    private dialogService: AhCommonDialogService,
    private referenceDataFacade: ReferenceDataFacade,
    private translationService: TranslocoService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadRefData();
    this.rightFacade.all$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((animals) => {
        if (animals.length) {
          this.changes = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.cleanState();
  }

  moveToRight(): void {
    this.changes = true;
    this.leftFacade.remove();
  }

  moveToLeft(): void {
    this.changes = true;
    this.rightFacade.remove();
  }

  toggleSearch(event: Event): void {
    this.left.toggleSearch(event);
  }

  showDialog = (): void => {
    this.display = true;
    this.changes = false;
    this.cdr.detectChanges();
  };

  hideDialog = (): void => {
    this.display = false;
    removeBodyOverflow();
    this.cleanState();
  };

  cleanState(): void {
    this.changes = false;
    this.rightFacade.replace([]);
    this.leftFacade.clearFilter();
  }

  onSave(): void {
    this.rightFacade.all$.pipe(take(1)).subscribe((x) => {
      this.save(x);
      this.hideDialog();
    });
    this.leftFacade.clearFilter();
  }

  onCancel(): void {
    if (this.changes) {
      this.dialogService.openDefaultModal({
        header: this.getTranslation('confirmation'),
        message: this.getTranslation('dialog-close-message'),
        icon: 'warning',
        accept: () => {
          this.hideDialog();
        }
      });
    } else {
      this.hideDialog();
    }
  }

  private loadRefData(): void {
    this.referenceDataFacade.getLots();
    this.referenceDataFacade.getLocations();
    this.referenceDataFacade.getLotSubGroups();
  }

  private getTranslation(key: string): string {
    return this.translationService.translate(`${this.translateScope}.${key}`);
  }
}
