import { createReducer, on } from '@ngrx/store';

import { SortGroupTemplateResultDto } from '@ifhms/models/feedlot';
import { SortGroupTemplateSortBy } from '@ifhms/models/shared';
import { SortOrder } from '@ifhms/models/shared';

import { SortGroupListActions } from './sort-group-list.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  modal: boolean;
  search: string;
  table: SortGroupTemplateResultDto;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  modal: false,
  search: '',
  table: {
    list: [],
    sortOrder: SortOrder.None,
    sortBy: SortGroupTemplateSortBy.None,
    pageIndex: 0,
    pageSize: 0,
    attributes: [],
    filteredCount: 0,
    totalCount: 0
  }
};

export const reducer = createReducer(
  initialState,
  on(SortGroupListActions.get, SortGroupListActions.search, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(SortGroupListActions.searchSuccess, (state, { search }) => ({
    ...state,
    search,
    loading: false,
    loaded: true
  })),

  on(SortGroupListActions.getSuccess, (state, action) => ({
    ...state,
    table: {
      ...action.result,
      list: action.result.list
    },
    loading: false,
    loaded: true
  })),

  on(SortGroupListActions.showModal, (state) => ({
    ...state,
    modal: true
  })),

  on(SortGroupListActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),

  on(
    SortGroupListActions.hideModal,
    SortGroupListActions.reset,
    () => initialState
  )
);

export const getLoading = (state: State): boolean => state.loading;

export const getLoaded = (state: State): boolean => state.loaded;

export const getModal = (state: State): boolean => state.modal;

export const getTable = (state: State): SortGroupTemplateResultDto =>
  state.table;

export const getSearch = (state: State): string => state.search;
