import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { USE_DEFAULT_ERROR_HANDLER } from '@common/angular/error-handling';
import {
  AnimalSearchByIdDto,
  AnimalSearchByTagDto,
  AnimalSearchParamDto,
  EventsListDto,
  TreatmentEventAnimalDetailsDto,
  TreatmentEventDto,
  TreatmentEventListDto,
  TreatmentEventListRequestDto,
  TreatmentEventUpdateDto,
  TreatmentProtocolDto
} from '@ifhms/models/feedlot';
import { CustomFieldDto, GUID_EMPTY } from '@ifhms/models/shared';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';

@Injectable()
export class EventsTreatmentService {
  private context = new HttpContext().set(USE_DEFAULT_ERROR_HANDLER, false);

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {}

  batchComplete(feedlotId: string, eventIds: string[]): Observable<void> {
    return this.http.put<void>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Treatment/BatchComplete`,
      eventIds
    );
  }

  batchPreview(feedlotId: string, eventIds: string[]): Observable<any> {
    return this.http.put(
      `${this.apiUrl}/Reports/AnimalTreatmentReview/${feedlotId}`,
      eventIds,
      { responseType: 'arraybuffer' }
    );
  }

  getList(
    feedlotId: string,
    request: TreatmentEventListRequestDto
  ): Observable<TreatmentEventListDto> {
    const params = new HttpParams({
      fromObject: request as unknown as Record<string, any>
    });
    return this.http.get<TreatmentEventListDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Treatment`,
      {
        params
      }
    );
  }

  getEvent(feedlotId: string, eventId: string): Observable<TreatmentEventDto> {
    return this.http.get<TreatmentEventDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Treatment/${eventId}`
    );
  }

  getAllEvents(
    feedlotId: string,
    eventId: string,
    facilityId?: string
  ): Observable<EventsListDto> {
    let params = new HttpParams();
    if (facilityId) {
      params = params.set('facilityId', facilityId);
    }
    return this.http.get<EventsListDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Treatment/GotoEventList/${eventId}`,
      { params }
    );
  }

  getHasPreviousEvent(
    feedlotId: string,
    currentEventId: string,
    facilityId?: string
  ): Observable<boolean> {
    let params = new HttpParams();
    if (facilityId) {
      params = params.set('facilityId', facilityId);
    }
    const eventId = currentEventId == 'draft' ? GUID_EMPTY : currentEventId;

    return this.http.get<boolean>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Treatment/${eventId}/HasPreviousEvent`,
      { params }
    );
  }

  createEvent(
    feedlotId: string,
    eventUpdate: TreatmentEventUpdateDto
  ): Observable<TreatmentEventDto> {
    return this.http.post<TreatmentEventDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Treatment/`,
      eventUpdate,
      { context: this.context }
    );
  }

  updateEvent(
    feedlotId: string,
    eventId: string,
    eventUpdate: TreatmentEventUpdateDto
  ): Observable<TreatmentEventDto> {
    return this.http.put<TreatmentEventDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Treatment/${eventId}`,
      eventUpdate,
      { context: this.context }
    );
  }

  deleteEvent(
    feedlotId: string,
    eventId: string,
    facilityId: string
  ): Observable<string> {
    return this.http.delete<string>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Treatment/${eventId}/Delete/${facilityId}`
    );
  }

  getNextEventId(
    feedlotId: string,
    eventId: string,
    facilityId?: string
  ): Observable<string> {
    let params = new HttpParams();
    if (facilityId) {
      params = params.set('facilityId', facilityId);
    }
    return this.http.get<string>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Treatment/${eventId}/Next`,
      { params }
    );
  }

  getPreviousEventId(
    feedlotId: string,
    eventId: string,
    facilityId?: string
  ): Observable<string> {
    let params = new HttpParams();
    if (facilityId) {
      params = params.set('facilityId', facilityId);
    }
    return this.http.get<string>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Treatment/${eventId}/Back`,
      { params }
    );
  }

  getInProgressEventsCount(feedlotId: string): Observable<number> {
    return this.http.get<number>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Treatment/GetInProgressEventsCount`
    );
  }

  searchAnimal(
    feedlotId: string,
    searchParam: AnimalSearchParamDto
  ): Observable<TreatmentEventAnimalDetailsDto> {
    let params = new HttpParams();
    const { animalTag } = <AnimalSearchByTagDto>searchParam;
    const { nationalId } = <AnimalSearchByIdDto>searchParam;
    if (searchParam.eventDate) {
      params = params.set('eventDate', searchParam.eventDate);
    }
    if (animalTag) {
      params = params.set('tag', animalTag);
    }
    if (nationalId) {
      params = params.set('nationalId', nationalId);
    }
    return this.http.get<TreatmentEventAnimalDetailsDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Treatment/SearchAnimal`,
      {
        params,
        context: this.context
      }
    );
  }

  getTxProtocol(
    feedlotId: string,
    diagnosisId: string,
    animalId: string,
    weight: number | null,
    temperature: number | null,
    treatmentDate: string,
    daysOnFeedlot: number | null
  ): Observable<TreatmentProtocolDto> {
    let params = new HttpParams();
    if (weight && temperature) {
      params = params.set('animalId', animalId);
      params = params.set('weight', weight);
      params = params.set('temperature', temperature);
      params = params.set('treatmentDate', treatmentDate);
    }
    if (daysOnFeedlot) {
      params = params.set('daysOnFeedlot', daysOnFeedlot);
    }

    return this.http.get<TreatmentProtocolDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Treatment/TreatmentsProtocol/${diagnosisId}`,
      { params }
    );
  }

  getCustomFields(feedlotId: string): Observable<CustomFieldDto[]> {
    return this.http.get<CustomFieldDto[]>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Treatment/CustomFields`
    );
  }
}
