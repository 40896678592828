import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import * as reducer from './sort-types.reducer';
import { getLoadedSelectItems } from '@common/angular/utils';
import { SortTypesSelectors } from './sort-types.selectors';
import { SortTypesActions } from './sort-types.actions';

@Injectable()
export class SortTypesFacade {

  loaded$ = this.store.select(SortTypesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(SortTypesSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(SortTypesActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(SortTypesActions.reset());
  }
}
