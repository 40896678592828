import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  IfhmsFeedlotFrontEndSharedDomainStateFeedlotModule
} from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import {
  IfhmsFeedlotFrontEndSharedDomainStateReferenceDataModule
} from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { IfhmsFeedlotFrontEndWebDomainStateFeedlotsModule } from '@ifhms/feedlot/front-end/web/domain/state/feedlots';
import { EffectsModule } from '@ngrx/effects';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import {
  FeedlotWebDomainRootStateEffects,
  FeedlotWebDomainRootStateFacade,
  feedlotWebRootMeta
} from './+state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: [ feedlotWebRootMeta ],
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true
        }
      }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // DONT COMMIT ME
      name: 'TFT - Feedlot Web',
      logOnly: false
      , connectInZone: true }),
    IfhmsFeedlotFrontEndWebDomainStateFeedlotsModule,
    IfhmsFeedlotFrontEndSharedDomainStateFeedlotModule,
    IfhmsFeedlotFrontEndSharedDomainStateReferenceDataModule,
    EffectsModule.forRoot([FeedlotWebDomainRootStateEffects])
  ],
  providers: [FeedlotWebDomainRootStateEffects, FeedlotWebDomainRootStateFacade]
})
export class IfhmsFeedlotFrontEndWebDomainStateRootModule {
}
