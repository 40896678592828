<ng-container *transloco="let t; read: translateScope">

  <p-messages
    showTransitionOptions="1000ms"
    hideTransitionOptions="1000ms"
    [(value)]="inlineErrMsg"
    [enableService]="false"
    [closable]="false"
    [escape]="false"
  />

  <p-fileUpload
    #fileUpload
    name="verify-animals"
    accept=".csv"
    chooseIcon="pi pi-upload"
    data-ele="select-file-btn"
    [auto]="true"
    [customUpload]="true"
    chooseStyleClass="p-button-rounded"
    [fileLimit]="1"
    [maxFileSize]="50000000"
    [chooseLabel]="t('select-file')"
    (uploadHandler)="onUpload($event)"
  >
    <ng-template pTemplate="file" />

    <ng-template
      pTemplate="content"
      let-files
      let-uploadedFiles="uploadedFiles"
      let-removeFileCallback="removeFileCallback"
      let-removeUploadedFileCallback="removeUploadedFileCallback"
    >

      <ng-container *ngIf="files?.length">
        <div class="upload-container">
          <sersi-pulse-loader [loadingMsg]="'Uploading ' + files[0].name"/>
        </div>
      </ng-container>

      <div *ngIf="uploadedFiles?.length > 0">
        <h5>Completed</h5>
        <div class="flex flex-wrap p-0 sm:p-5 gap-5">
          <div *ngFor="let file of uploadedFiles; let i = index" class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
            <span class="font-semibold">{{ file.name }}</span>
            <p-button icon="pi pi-times" (onClick)="removeUploadedFileCallback(i)" [outlined]="true" [rounded]="true" severity="danger" />
          </div>
        </div>
      </div>

    </ng-template>

    <ng-template pTemplate="empty">
      <div class="upload-container flex-column">
        <i class="pi pi-cloud-upload border-2 border-circle p-3 text-6xl text-400 border-400"></i>
        <p class="mt-4 mb-0">{{ t('drag-drop') }}</p>
      </div>
    </ng-template>

  </p-fileUpload>

</ng-container>
