import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ShipmentWorkOrderDto,
  ShipmentWorkOrderUpdateDto,
  WorkOrderShipmentListDto,
  WorkOrderShipmentListRequestDto,
  WorkOrderShipmentStatus
} from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';

@Injectable()
export class ShipmentWorkOrderService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {
  }

  getList(feedlotId: string, request: WorkOrderShipmentListRequestDto): Observable<WorkOrderShipmentListDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<WorkOrderShipmentListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Shipment/WorkOrder`, {
      params
    });
  }

  getShipmentWorkOrder(feedlotId: string, workOrderId: string): Observable<ShipmentWorkOrderDto> {
    return this.http.get<ShipmentWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Shipment/WorkOrder/${workOrderId}`);
  }

  update(feedlotId: string, shipmentWorkOrderUpdate: ShipmentWorkOrderUpdateDto): Observable<ShipmentWorkOrderDto> {
    return this.http.put<ShipmentWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Shipment/WorkOrder`, shipmentWorkOrderUpdate);
  }

  schedule(feedlotId: string, workOrderId: string): Observable<ShipmentWorkOrderDto> {
    return this.http.put<ShipmentWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Shipment/WorkOrder/${workOrderId}/Schedule`, null);
  }

  requestApproval(feedlotId: string, workOrderId: string, workEventDate: string): Observable<ShipmentWorkOrderDto> {
    return this.http.put<ShipmentWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Shipment/WorkOrder/${workOrderId}/RequestApproval`, { workEventDate });
  }

  generateReport(feedlotId: string, workOrderId: string): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            levelId: feedlotId ?? '',
            workOrderId: workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/Reports/ShipmentWorkOrder`, options);
  }

  finalize(feedlotId: string, workOrderId: string): Observable<ShipmentWorkOrderDto> {
    return this.http.put<ShipmentWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Shipment/WorkOrder/${workOrderId}/Finalize`, null);
  }

  deleteWorkOrder(feedlotId: string, workOrderId: string): Observable<ShipmentWorkOrderDto> {
    return this.http.delete<ShipmentWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Shipment/WorkOrder/${workOrderId}`);
  }

  private getRequestStatusName(status: WorkOrderShipmentStatus): string {
    switch (status) {
      case WorkOrderShipmentStatus.Scheduled:
        return 'Schedule';
      case WorkOrderShipmentStatus.WaitingForApproval:
        return 'RequestApproval';
      case WorkOrderShipmentStatus.Finalized:
        return 'Finalize';
      default:
        return 'Unknown';
    }
  }
}
