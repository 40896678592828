export enum FeedlotPermissionsWorkOrder {
  WorkOrdersArrivalRead = 'workOrders.arrival.read',
  WorkOrdersArrivalWrite = 'workOrders.arrival.write',
  WorkOrdersArrivalFinalize = 'workOrders.arrival.finalize',
  WorkOrdersLotTransferRead = 'workOrders.lotTransfer.read',
  WorkOrdersLotTransferWrite = 'workOrders.lotTransfer.write',
  WorkOrdersLotTransferFinalize = 'workOrders.lotTransfer.finalize',
  WorkOrdersMovementRead = 'workOrders.movement.read',
  WorkOrdersMovementWrite = 'workOrders.movement.write',
  WorkOrdersMovementFinalize = 'workOrders.movement.finalize',
  WorkOrdersRailRead = 'workOrders.rail.read',
  WorkOrdersRailWrite = 'workOrders.rail.write',
  WorkOrdersRailFinalize = 'workOrders.rail.finalize',
  WorkOrdersReHandlingRead = 'workOrders.reHandling.read',
  WorkOrdersReHandlingWrite = 'workOrders.reHandling.write',
  WorkOrdersReHandlingFinalize = 'workOrders.reHandling.finalize',
  WorkOrdersShipmentRead = 'workOrders.shipment.read',
  WorkOrdersShipmentWrite = 'workOrders.shipment.write',
  WorkOrdersShipmentFinalize = 'workOrders.shipment.finalize'
}
