export enum FeedlotPermissionsEvent {
  EventsArrivalRead = 'events.arrival.read',
  EventsArrivalWrite = 'events.arrival.write',
  EventsPostMortemRead = 'events.postMortem.read',
  EventsPostMortemWrite = 'events.postMortem.write',
  EventsPostMortemComplete = 'events.postMortem.complete',
  EventsRailRead = 'events.rail.read',
  EventsRailWrite = 'events.rail.write',
  EventsReHandlingRead = 'events.reHandling.read',
  EventsReHandlingWrite = 'events.reHandling.write',
  EventsTreatmentRead = 'events.treatment.read',
  EventsTreatmentWrite = 'events.treatment.write',
  EventsTreatmentComplete = 'events.treatment.complete'
}
