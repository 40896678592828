import { createReducer, on } from '@ngrx/store';
import * as _ from 'lodash-es';
import { UpcDosingGunsActions } from './upc-dosing-guns.actions';
import { DeviceConnectionStatusEnum, DeviceStatusDto, DosingGunItem } from '@ifhms/models/feedlot';

export interface State {
  loading: boolean;
  loaded: boolean;
  dosingGunsBridge: DeviceStatusDto | null;
  dosingGunItems: DosingGunItem[] | null;
  oneTimeDose: boolean;
  errMsg?: string | null;
}

const initialState: State = {
  loading: false,
  loaded: false,
  dosingGunsBridge: null,
  dosingGunItems: null,
  oneTimeDose: false,
  errMsg: null
};

export const reducer = createReducer(
  initialState,

  on(UpcDosingGunsActions.getDosingGunsStatusFromUpc, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(UpcDosingGunsActions.getDosingGunsStatusFromUpcSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    dosingGunsBridge: action.dosingGunsSettings

  })),
  on(UpcDosingGunsActions.getAvailableDosingGunsFromUpc, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(UpcDosingGunsActions.getAvailableDosingGunsFromUpcSuccess, (state, action) => {
    const newState = _.cloneDeep(state);

    if (newState.dosingGunsBridge &&
        newState.dosingGunsBridge.settings &&
        newState.dosingGunsBridge.settings.dosingGunSettings) {
      newState.dosingGunsBridge.settings.dosingGunSettings.dosingGuns = action.availableDosingGuns;
    }

    return {
      ...newState,
      loading: false,
      loaded: true
    };
  }),

  on(UpcDosingGunsActions.getDosingGunsSettingsFromApi, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(UpcDosingGunsActions.getDosingGunsSettingsFromApiSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    dosingGunItems: action.dosingGuns
  })),
  on(UpcDosingGunsActions.getDosingGunsListFromApi, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(UpcDosingGunsActions.getDosingGunsListFromApiSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    dosingGunItems: action.dosingGunsDto.dosingGuns!
  })),
  on(UpcDosingGunsActions.updateDosingGunsSettings, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(UpcDosingGunsActions.updateDosingGunsSettingsSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    dosingGunItems: action.dosingGunsDto.dosingGuns!
  })),

  on(UpcDosingGunsActions.assignDosingGunToProduct, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(UpcDosingGunsActions.assignDosingGunToProductSuccess,  (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    dosingGunItems: action.dosingGunsDto.dosingGuns!
  })),
  on(UpcDosingGunsActions.unassignDosingGunFromProduct, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(UpcDosingGunsActions.unassignDosingGunFromProductSuccess,  (state, action) => {
    if (state.dosingGunItems) {
      return {
        ...state,
        loading: false,
        loaded: true,
        dosingGunItems: state.dosingGunItems.map(dosingGun =>
          dosingGun.id === action.dosingGun.id ? action.dosingGun : dosingGun
        )
      };
    } else {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
  }),

  on(UpcDosingGunsActions.updateConnectionStatus, (state, action) => {
    if (state.dosingGunsBridge) {
      return {
        ...state,
        dosingGunsBridge: {
          ...state.dosingGunsBridge,
          status: action.connectionStatus
        }
      };
    } else {
      return state;
    }
  }),
  on(UpcDosingGunsActions.setDose, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(UpcDosingGunsActions.setDoseSuccess, (state) => ({
    ...state,
    loading: false,
    loaded: true
  })),
  on(UpcDosingGunsActions.setOneTimeDose, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(UpcDosingGunsActions.setOneTimeDoseSuccess, (state) => ({
    ...state,
    loading: false,
    loaded: true,
    oneTimeDose: true
  })),
  on(UpcDosingGunsActions.resetOneTimeDose, (state) => ({
    ...state,
    oneTimeDose: false
  })),
  on(UpcDosingGunsActions.validateGun, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(UpcDosingGunsActions.validateGunSuccess, (state, action) => {
    if (state.dosingGunItems) {
      return {
        ...state,
        loading: false,
        loaded: true,
        dosingGunItems: state.dosingGunItems.map(dosingGun =>
          dosingGun.id === action.dosingGun.id ? action.dosingGun : dosingGun
        )
      };
    } else {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
  }),

  on(UpcDosingGunsActions.error, (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    errMsg: action.errMsg
  })),
  on(UpcDosingGunsActions.resetError, (state) => ({
    ...state,
    errMsg: null
  }))
);

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;

export const getError = (state: State): string | undefined | null => state.errMsg;

export const getDosingGunsSettings = (state: State): DeviceStatusDto | null => state.dosingGunsBridge;

export const getAvailableDosingGuns = (state: State): DosingGunItem[] | null => state.dosingGunItems;

export const getDosingGunsConnectionStatus = (state: State): DeviceConnectionStatusEnum => {
  if(state.dosingGunsBridge) return state.dosingGunsBridge.status as DeviceConnectionStatusEnum;
  return DeviceConnectionStatusEnum.Disconnected;
};

export const getDosingGunsMacAddresses = (state: State): string[] | null => {
  if (state.dosingGunsBridge &&
      state.dosingGunsBridge.settings &&
      state.dosingGunsBridge.settings.dosingGunSettings &&
      state.dosingGunsBridge.settings.dosingGunSettings.dosingGuns) {
    return state.dosingGunsBridge.settings.dosingGunSettings.dosingGuns;
  }
  return null;
};

export const getOneTimeDose = (state: State): boolean => state.oneTimeDose;

