import { GRID_KEY, GridData } from '../interfaces';

export function modelDataToGrid<T>(modelData: T[] | null): GridData<T> {
  const gridData = modelData?.length ? structuredClone(modelData) : [];
  return { [GRID_KEY]: gridData };
}

export function gridToModelData<T>(gridData?: GridData<T>): T[] {
  const modelData = gridData?.[GRID_KEY];
  return modelData?.length ? structuredClone(modelData) : [];
}
