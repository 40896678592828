import { Injectable } from '@angular/core';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import * as reducer from './lot-sub-groups.reducer';
import { LotSubGroupsSelectors } from './lot-sub-groups.selectors';
import { LotSubGroupsActions } from './lot-sub-groups.actions';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class LotSubGroupsFacade {

  loaded$ = this.store.select(LotSubGroupsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(LotSubGroupsSelectors.selectItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(LotSubGroupsActions.get());
    return this.items$;
  }

  getByLotId(lotId: string): Observable<SersiSelectListItem[]> {
    return this.items$.pipe(
      map((subGroups: SersiSelectListItem[]) =>
        subGroups.filter((subGroup: SersiSelectListItem) => {
          const attr = subGroup?.attributes;
          return attr ? attr['lotId'] === lotId : subGroup.code
        })
      )
    );
  }

  reset(): void {
    this.store.dispatch(LotSubGroupsActions.reset());
  }
}
