import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'sersi-grid-delete-button-renderer',
  templateUrl: './delete-button-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GridDeleteButtonRendererComponent {

  @Input() disabled: boolean;
  @Output() deleteRow = new EventEmitter<void>();

}
