import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { HashMap } from '@jsverse/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { SortGroupProductItemComponent } from '@ifhms/feedlot/shared/domain/product-item';
import { FormlyTypesEnum, GRID_KEY, GridColumnDef, GridRendererCellType } from '@sersi/angular/formly/core';

import { AbstractProductSelectorComponent } from '../abstract-product-selector.component';

@Component({
  selector: 'ifhms-work-order-product-selector-product-selector',
  templateUrl: './sort-group-product-selector.component.html',
  styleUrls: ['./sort-group-product-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortGroupProductSelectorComponent extends AbstractProductSelectorComponent {
  translateScope = 'product-selector';

  protected getInitialModel(): HashMap {
    return {
      id: null,
      productTypeId: null,
      productId: null,
      routeId: null
    };
  }

  protected setGridField(): FormlyFieldConfig {
    return {
      className: 'sort-group-product-selector',
      type: FormlyTypesEnum.REPEATING_GRID_TYPE,
      key: GRID_KEY,
      props: {
        initialModel: this.getInitialModel(),
        noDataMessage$: this.getParentTranslation$('empty-product'),
        footerAddButtonLabel$: this.getParentTranslation$('product'),
        isFooterAddButtonEnabled: !this.fieldProps['disableAdd'],
        gridOptions: this.getGridOptions()
      },
      fieldArray: {
        fieldGroup: [
          this.setGridRow()
        ]
      }
    };
  }

  protected setGridRow(): FormlyFieldConfig {
    return {
      type: SortGroupProductItemComponent,
      props: {
        contextKey: this.fieldKey,
        gridOptions: this.getGridOptions(),
        translateFn: this.getParentTranslation.bind(this)
      }
    }
  }

  protected getColumnDefs(): GridColumnDef[] {
    return [
      {
        headerName$: this.getParentTranslation$('type'),
        fieldKey: 'productTypeId',
        width: '30%'
      },
      {
        headerName$: this.getParentTranslation$('product'),
        fieldKey: 'productId',
        width: '30%'
      },
      {
        headerName$: this.getParentTranslation$('route'),
        fieldKey: 'routeDetailId',
        width: '30%'
      },
      {
        fieldKey: 'delete-btn',
        width: '48px',
        cellRendererParams: {
          type: GridRendererCellType.Delete
        }
      }
    ];
  }

}
