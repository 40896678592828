import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from '@sentry/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { HotkeyModule } from 'angular2-hotkeys';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';

import { AUTH_CONFIG_API_URLS, AUTH_CONFIG_SERVER_URL, CommonAngularAuthModule } from '@common/angular/auth';
import {
  APP_CONFIG,
  getApiUrl,
  getAuthServerUrls,
  getConfig,
  getWindow,
  SHOW_DEV_FEATURE,
  WINDOW
} from '@common/angular/config';
import { AH_USER_API_URL } from '@common/angular/core/models';
import { IfhmsFeedlotFrontEndWebCoreErrorHandlingModule } from '@ifhms/feedlot/shared/core/error-handling';
import { CommonUserProfileModule } from '@common/angular/features/user-profile';
import { HeapModule } from '@common/angular/integrations/heap';
import { CommonAngularTranslationLoaderModule } from '@common/angular/translation';
import { CommonAngularUrlRouterModule } from '@common/angular/url-router';
import {
  CommonAngularUtilsModule,
  EmptyDateTransformInterceptor,
  TimezoneHttpInterceptor
} from '@common/angular/utils';
import { AH_SIGNALR_URL, IfhmsCommonAngularApiMessageHubModule } from '@ifhms/common/angular/api/message-hub';
import { IfhmsCommonAngularDataAccessDosrtApiModule } from '@ifhms/common/angular/data-access/dosrt-api';
import {
  AH_FMS_FEEDLOT_API_CONFIG,
  AH_FMS_FEEDLOT_DOMAIN_API,
  AH_FMS_SORT_GROUP_API,
  AhFmsFeedlotApiConfig,
  IfhmsCommonAngularDataAccessFeedlotApiModule
} from '@ifhms/common/angular/data-access/feedlot-api';
import { AH_FMS_REPORT_API_URL, AH_FMS_REPORT_SELECT_LIST_API_URL } from '@ifhms/common/angular/data-access/report-api';
import { IfhmsCommonAngularDataAccessUpcApiModule } from '@ifhms/common/angular/data-access/upc-api';
import { IfhmsCommonAngularUpcSharedModule } from '@ifhms/common/angular/upc/shared';
import { IfhmsFeedlotFrontEndWebCoreFormlyModule } from '@ifhms/feedlot/shared/core/formly';
import { IfhmsFeedlotDomainNavigationModule } from '@ifhms/feedlot/shared/domain/navigation';
import { IfhmsFeedlotFrontEndWebDomainStateRootModule } from '@ifhms/feedlot/web/domain/state/root';
import { AuthAppLevelRoles, ContextLevel } from '@ifhms/models/shared';

import { AppConfigService } from '../services';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const getShowDevFeature = (appConfig: AppConfigService): boolean => {
  return appConfig.getConfig().showDevFeature;
};

const getFeedlotApiConfig = (
  appConfig: AppConfigService
): AhFmsFeedlotApiConfig => {
  return {
    apiUrl: appConfig.getFeedlotApiUrl()
  };
};

const getFeedlotApiUrl = (appConfig: AppConfigService): string => appConfig.getFeedlotApiUrl();

const getFeedlotReportApiUrl = (
  appConfig: AppConfigService
): string | undefined => {
  return `${appConfig.getFeedlotApiUrl()}/Reports`;
};

const getFeedlotReportSelectListApiUrl = (
  appConfig: AppConfigService
): string | undefined => {
  return `${appConfig.getFeedlotApiUrl()}/ReportSelectLists`;
};

const getSignalRUrl = (appConfig: AppConfigService): string | undefined => {
  return appConfig.getConfig().signalRUrl;
};

const getAuthApiUrls = (appConfig: AppConfigService): string[] => {
  return [
    getFeedlotApiUrl(appConfig),
    getApiUrl(appConfig) || ''
  ].filter((url) => !!url);
};

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonAngularTranslationLoaderModule,
    AppRoutingModule,
    IfhmsFeedlotFrontEndWebDomainStateRootModule,
    IfhmsFeedlotFrontEndWebCoreFormlyModule,
    CommonUserProfileModule,
    ToastModule,
    ConfirmDialogModule,
    HotkeyModule.forRoot(),
    NgSelectModule,
    IfhmsFeedlotDomainNavigationModule,
    IfhmsCommonAngularApiMessageHubModule,
    CommonAngularUrlRouterModule,
    CommonAngularAuthModule.forRoot({
      clientId: 'feedlotweb',
      scope:
        'openid profile AnimalHealth.Fms.Feedlot.Api.All AnimalHealth.Fms.Admin.App.All offline_access',
      forceUserLogin: true,
      restrictedRoleAccess: {
        levelDataKey: 'contextLevel',
        accessDeniedPath: '/auth/access-denied',
        levelsMap: [
          {
            level: ContextLevel.Feedlot,
            path: '/feedlot',
            requiredRoles: [
              AuthAppLevelRoles.Company,
              AuthAppLevelRoles.Operation
            ]
          }
        ]
      }
    }),
    IfhmsCommonAngularDataAccessFeedlotApiModule,
    IfhmsCommonAngularUpcSharedModule,
    IfhmsCommonAngularDataAccessUpcApiModule,
    CommonAngularUtilsModule,
    IfhmsCommonAngularDataAccessDosrtApiModule,
    IfhmsFeedlotFrontEndWebCoreErrorHandlingModule,
    HeapModule,
    NgxMapboxGLModule.withConfig({
      accessToken:
        'pk.eyJ1Ijoiam9zaGx5bm4iLCJhIjoiY2xkMmw5dnQ5MDd5ejNxcjR0eDY3OGQ5cSJ9.4X9vhjP_HI4M68CQ--yXXw'
    })
  ],
  providers: [
    AppConfigService,
    MessageService,
    ConfirmationService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_CONFIG,
      useFactory: getConfig,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AUTH_CONFIG_SERVER_URL,
      useFactory: getAuthServerUrls,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AUTH_CONFIG_API_URLS,
      useFactory: getAuthApiUrls,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: 'apiUrl',
      useFactory: getApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AH_USER_API_URL,
      useFactory: getFeedlotApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AH_FMS_FEEDLOT_API_CONFIG,
      useFactory: getFeedlotApiConfig,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AH_FMS_FEEDLOT_DOMAIN_API,
      useFactory: getFeedlotApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AH_FMS_SORT_GROUP_API,
      useFactory: getApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AH_FMS_REPORT_API_URL,
      useFactory: getFeedlotReportApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AH_FMS_REPORT_SELECT_LIST_API_URL,
      useFactory: getFeedlotReportSelectListApiUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EmptyDateTransformInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimezoneHttpInterceptor,
      multi: true
    },
    {
      provide: SHOW_DEV_FEATURE,
      useFactory: getShowDevFeature,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: AH_SIGNALR_URL,
      useFactory: getSignalRUrl,
      multi: false,
      deps: [AppConfigService]
    },
    {
      provide: WINDOW,
      useFactory: getWindow,
      multi: false
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}
