import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation
} from '@angular/core';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { FEATURE_NAME } from '../../+state';
import { scopeLoader } from '@common/angular/translation';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'ifhms-cattle-validation-error-panel',
  templateUrl: './cattle-selector-validation-panel.component.html',
  styleUrls: ['./cattle-selector-validation-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: FEATURE_NAME,
        alias: FEATURE_NAME,
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class CattleSelectorValidationPanelComponent {
  readonly translateScope = `${FEATURE_NAME}.components.cattle-selector-validation-panel`;

  @HostBinding('class.cattle-selector-validation-panel') hostClass = true;

  messages: string[];

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef
  ) {
    this.messages = this.dialogConfig.data.messages;
  }

  onCancelClick(): void {
    this.dialogRef.close('cancel');
  }

  onOkClick(): void {
    this.dialogRef.close('ok');
  }
}
