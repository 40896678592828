import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PricingMethodsActions } from './pricing-methods.actions';

export interface State extends EntityState<SelectListItemWithTranslationDto> {
  loading: boolean;
  loaded: boolean;
}

const adapter: EntityAdapter<SelectListItemWithTranslationDto> =
  createEntityAdapter<SelectListItemWithTranslationDto>({
    sortComparer: false
  });

const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: true
});

export const reducer = createReducer(
  initialState,

  on(PricingMethodsActions.getSuccess, (state, { items }) =>
    adapter.setAll(items, {
      ...state,
      loading: false,
      loaded: true
    })
  ),

  on(PricingMethodsActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),

  on(PricingMethodsActions.reset, () => initialState)
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const getEntities = selectEntities;

export const getAll = selectAll;

export const getLoading = (state: State): boolean => state.loading;

export const getLoaded = (state: State): boolean => state.loaded;

