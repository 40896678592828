import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ErrorHandlingService } from '@common/angular/error-handling';
import { FeedlotApiErrorType } from '@ifhms/feedlot/shared/core/interfaces';
import { AnimalAvailabilityDto, AnimalSearchNotAvailableDto } from '@ifhms/models/feedlot';
import { AnimalAvailabilityDialogComponent } from '@ifhms/feedlot/front-end/shared/components/animal-availability';

@Injectable()
export class FeedlotErrorHandlingService extends ErrorHandlingService {

  protected override processUnhandledApiError(resp: HttpErrorResponse): void {
    switch (resp.status) {
      case FeedlotApiErrorType.ApiErrorAnimalNotAvailableException:
        return this.showAnimalNotAvailableErrorMessage(resp.error);
    }
  }

  showAnimalNotAvailableErrorMessage(animalSearchError: AnimalSearchNotAvailableDto): void {
    const animalAvailabilityDetails: AnimalAvailabilityDto = {
      animalId: animalSearchError.AnimalId,
      isAvailable: animalSearchError.IsAvailable,
      animalUnavailabilityReason: animalSearchError.AnimalUnavailabilityReason,
      animalUnavailabilityEventId: animalSearchError.AnimalUnavailabilityEventId,
      animalUnavailabilityWorkOrderId: animalSearchError.AnimalUnavailabilityWorkOrderId
    }
    this.dialogService.openModal(AnimalAvailabilityDialogComponent, {
      header: this.getTranslation('availability-error.title'),
      data: { animalAvailabilityDetails }
    });
  }

}
