import { Injectable } from '@angular/core';

import {
  FeedlotDto,
  FeedlotSettingsGlobalDto,
  FeedlotSettingsIntegrationDto,
  FeedlotSettingsTagsDto
} from '@ifhms/models/feedlot';
import { FeedlotPermissionsDto } from '@ifhms/models/shared';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { FeedlotActions } from './feedlot.actions';
import { feedlotSelectors } from './feedlot.selectors';

@Injectable()
export class FeedlotAppFacade {
  state$ = this.store.select(feedlotSelectors.selectState);

  feedlot$: Observable<FeedlotDto | null> = this.store.select(
    feedlotSelectors.selectFeedlot
  );

  feedlotId$: Observable<string> = this.store.select(
    feedlotSelectors.selectFeedlotId
  );

  feedlotSlug$: Observable<string> = this.store.select(
    feedlotSelectors.selectFeedlotSlug
  );

  feedlotName$: Observable<string> = this.store.select(
    feedlotSelectors.selectFeedlotName
  );

  isLoading$: Observable<boolean> = this.store.select(
    feedlotSelectors.selectLoading
  );

  isLoaded$: Observable<boolean> = this.store.select(
    feedlotSelectors.selectLoaded
  );

  isActive$: Observable<boolean> = this.store.select(
    feedlotSelectors.selectActive
  );

  feedlotPermissions$: Observable<FeedlotPermissionsDto | null> = this.store.select(
    feedlotSelectors.selectFeedlotPermissions
  );

  tagSettings$: Observable<FeedlotSettingsTagsDto | null> = this.store.select(
    feedlotSelectors.selectFeedlotTagSettings
  );

  globalSettings$: Observable<FeedlotSettingsGlobalDto | null> = this.store.select(
    feedlotSelectors.selectFeedlotGlobalSettings
  );

  integrationSettings$: Observable<FeedlotSettingsIntegrationDto | null> = this.store.select(
    feedlotSelectors.selectIntegrationSettings
  );

  constructor(protected readonly store: Store) {
  }

  get(id: string): void {
    this.store.dispatch(FeedlotActions.get({ id }));
  }

  getBySlug(slug: string): void {
    this.store.dispatch(FeedlotActions.getBySlug({ slug }));
  }

  reset(): void {
    this.store.dispatch(FeedlotActions.reset());
  }

}
