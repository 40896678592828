<sersi-spin [spin]="loading$ | async">
<ng-container *transloco="let t; read: scopedNamespace">
    <formly-form
            [fields]="fields"
            [form]="form"
            [model]="model"
    >
    </formly-form>
    <div class="container-footer-buttons">
        <ng-container *ngIf="!isDosingGunAssigned()">
            <button
                    pButton
                    data-ele="cancel-btn"
                    [label]="t('cancel-button')"
                    class="p-button-secondary"
                    (click)="cancel()"
                    [rounded]="true"
                    [text]="true"
                >
            </button>
        </ng-container>
        <ng-container *ngIf="isDosingGunAssigned()">
        <button
                pButton
                data-ele="unassign-btn"
                [label]="t('unassign-button')"
                class="p-button-raised p-button-help"
                (click)="unAssignDosingGun()"
                icon="pi pi-times-circle"
                iconPos="left"
                [rounded]="true">
        </button>
        </ng-container>
        <ng-container *ngIf="this.model.validated">
            <button
                    pButton
                    data-ele="validate-btn"
                    [label]="t('validated-button')"
                    class="p-button-raised validate-button"
                    [disabled]="true"
                    (click)="onValidate()"
                    icon="pi pi-check-circle"
                    iconPos="left"
                    [rounded]="true">
            </button>
        </ng-container>
        <ng-container *ngIf="!this.model.validated">
            <button
                    pButton
                    data-ele="validate-btn"
                    [label]="t('validate-button')"
                    class="p-button-raised validate-button"
                    [disabled]="isValidationDisabled()"
                    (click)="onValidate()"
                    iconPos="left"
                    [rounded]="true">
            </button>
        </ng-container>
        <button
                pButton
                data-ele="save-btn"
                [label]="t('save-button')"
                class="p-button-raised save-button"
                [disabled]="isSaveDisabled()"
                (click)="onSave()"
                icon="pi pi-save"
                iconPos="left"
                [rounded]="true">
        </button>
    </div>
</ng-container>
</sersi-spin>