import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'ifhms-cattle-selector',
  templateUrl: './cattle-selector.component.html',
  styleUrls: ['./cattle-selector.component.scss']
})
export class CattleSelectorComponent extends FieldType<FieldTypeConfig> {
  override defaultOptions = {
    props: {
      data: [],
      hideRequiredMessage: false
    }
  };
}
