import { Injectable } from '@angular/core';
import { AppConfig } from '@ifhms/feedlot/web/core/config';

import { getAppConfig } from '../utils';

@Injectable()
export class AppConfigService {

  getConfig(): AppConfig {
    return getAppConfig();
  }

  getFeedlotApiUrl(): string {
    const appConfig = this.getConfig();
    return appConfig.feedlotApiUrl || 'http://host.docker.internal:5227/api';
  }

  getSignalRUrl(): string {
    const appConfig = this.getConfig();
    return appConfig.signalRUrl;
  }

}
