import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';

import { SelectListItemDto } from '@ifhms/models/shared';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { FeedlotSelectListNoTranslationType } from '@ifhms/models/feedlot';
import { SelectListsService } from '@ifhms/common/angular/data-access/feedlot-api';

import { UnitOfMeasuresActions } from './unit-of-measures.actions';

@Injectable()
export class UnitOfMeasuresEffects {

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnitOfMeasuresActions.get),
      withLatestFrom(this.feedlotFacade.feedlotId$),
      exhaustMap(([, feedlotId]) => {
        return this.selectListsService.getSelectListData(
          feedlotId,
          FeedlotSelectListNoTranslationType.UnitOfMeasure
        ).pipe(
          map((items: SelectListItemDto[]) => {
            if (!items) UnitOfMeasuresActions.error();
            return UnitOfMeasuresActions.getSuccess({ items });
          }),
          catchError(() => of(UnitOfMeasuresActions.error()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private feedlotFacade: FeedlotFacade,
    private selectListsService: SelectListsService
  ) {
  }
}
