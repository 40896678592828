import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { DeviceType, DoseTriggerResponse, GetBatteryLevelResponse, SetDoseResponse } from '@ifhms/models/feedlot';
import { UpcBaseDeviceService } from './upc-base-device.service';

@Injectable(
  { providedIn: 'root' }
)
export class UpcDosingGunsService extends UpcBaseDeviceService {
  private _dosingGunData = new Subject<DoseTriggerResponse>();
  dosingGunData$ = this._dosingGunData.asObservable();
  private dosingGunInitialized: boolean|null = null;

  constructor(injector: Injector) {
    super(DeviceType.DosingGuns, injector);
  }

  async initDevice(): Promise<boolean> {
    if (!this.dosingGunInitialized) {
      this.dosingGunInitialized = await this.initConnection();
      await this.subscribeToDosageAdministered();
    }
    return this.dosingGunInitialized;
  }

  private async subscribeToDosageAdministered(): Promise<void> {
    this.hubConnection?.on('DoseReceived', (data: any) => {
      this._dosingGunData.next(data);
    });
  }

  async getAvailableGuns(): Promise<string[] | null> {
    const isConnected = await this.ensureHubConnection();
    if (!isConnected) return null;
    try {
      const guns = await this.hubConnection?.invoke<string[]>('GetAvailableGuns') ?? null;
      return guns;
    } catch (err) {
      console.error('Error getting available guns:', err);
      return null;
    }
  }

  async setDose(gunId: string, quantity: number, timeout = 2): Promise<SetDoseResponse | null> {
    const isConnected = await this.ensureHubConnection();
    if (!isConnected) return null;
    try {
      const response = await this.hubConnection?.invoke('SetDose', gunId, quantity, timeout) ?? null;
      return response;
    } catch (err) {
      console.error('Error setting dose:', err);
      return null;
    }
  }

  async setOneTimeDose(gunId: string, quantity: number, timeout = 2): Promise<SetDoseResponse | null> {
    const isConnected = await this.ensureHubConnection();
    if (!isConnected) return null;
    try {
      const response = await this.hubConnection?.invoke('SetOneTimeDoseAndLock', gunId, quantity, timeout) ?? null;
      return response;
    } catch (err) {
      console.error('Error setting dose:', err);
      return null;
    }
  }

  async lockGun(gunId: string): Promise<void> {
    try {
      await this.hubConnection?.invoke('LockGun', gunId);
    } catch (err) {
      console.error('Error locking gun:', err);
    }
  }

  async unlockGun(gunId: string): Promise<void> {
    try {
      await this.hubConnection?.invoke('UnlockGun', gunId);
    } catch (err) {
      console.error('Error unlocking gun:', err);
    }
  }

  async getBatteryLevel(gunId: string, timeout = 5): Promise<GetBatteryLevelResponse | null> {
    try {
      const response = await this.hubConnection?.invoke('GetBatteryLevel', gunId, timeout) ?? null;
      console.log('Battery level response:', response)
      return response;
    } catch (err) {
      console.error('Error getting battery level:', err);
      return null;
    }
  }
}