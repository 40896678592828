import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  SortGroupDetailDto,
  SortGroupOutcomeDto,
  SortGroupOutcomeListItemDto, SortGroupTemplateRequestDto, SortGroupTemplateResultDto
} from '@ifhms/models/feedlot';
import { WorkOrderType } from '@ifhms/models/shared';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API, AH_FMS_SORT_GROUP_API } from '../tokens';

@Injectable()
export class SortGroupsService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    // Remove me after service migration
    @Inject(AH_FMS_SORT_GROUP_API) private readonly adminApiUrl: string,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string,
    private http: HttpClient
  ) {}

  getList(
    request: SortGroupTemplateRequestDto,
    operationId: string
  ): Observable<SortGroupTemplateResultDto> {
    return this.http.put<SortGroupTemplateResultDto>(
      `${this.adminApiUrl}/Feedlots/${operationId}/Settings/ManagementGroups/List`,
      // `${this.apiConfig.apiUrl}/Feedlot/${operationId}/SortGroups/TemplateList`,
      JSON.stringify(request),
      {
        headers: this.headers
      }
    );
  }

  applyTemplate(
    operationId: string,
    workOrderType: WorkOrderType,
    workOrderId: string,
    sortGroupId: string
  ): Observable<SortGroupDetailDto> {
    return this.http.put<SortGroupDetailDto>(
      `${this.apiUrl}/Feedlot/${operationId}/SortGroups/Group/${workOrderType}/${workOrderId}/${sortGroupId}`,
      {}
    );
  }

  getDetail(
    operationId: string,
    workOrderId: string,
    workOrderType: WorkOrderType
  ): Observable<SortGroupDetailDto> {
    return this.http.get<SortGroupDetailDto>(
      `${this.apiUrl}/Feedlot/${operationId}/SortGroups/Group/${workOrderType}/${workOrderId}`
    );
  }

  putOutcomesList(
    operationId: string,
    sortGroupId: string,
    isActionNewHomePen?: boolean
  ): Observable<SortGroupOutcomeListItemDto[]> {
    return this.http.put<SortGroupOutcomeListItemDto[]>(
      `${this.apiUrl}/Feedlot/${operationId}/SortGroups/OutcomeList/${sortGroupId}`,
      isActionNewHomePen
    );
  }

  getOutcomesDetail(
    operationId: string,
    sortGroupId: string,
    sortGroupOutcomeId: string
  ): Observable<SortGroupOutcomeDto> {
    return this.http.get<SortGroupOutcomeDto>(
      `${this.apiUrl}/Feedlot/${operationId}/SortGroups/Outcome/${sortGroupId}/${sortGroupOutcomeId}`
    );
  }

  updateOutcome(
    operationId: string,
    sortGroupId: string,
    sortGroupOutcome: SortGroupOutcomeDto
  ): Observable<SortGroupOutcomeDto> {
    return this.http.put<SortGroupOutcomeDto>(
      `${this.apiUrl}/Feedlot/${operationId}/SortGroups/Outcome/${sortGroupId}/`,
      JSON.stringify(sortGroupOutcome),
      {
        headers: this.headers
      }
    );
  }

  bulkOutcome(
    operationId: string,
    sortGroupOutcome: SortGroupOutcomeDto,
    sortGroupId: string
  ): Observable<SortGroupOutcomeListItemDto[]> {
    return this.http.put<SortGroupOutcomeListItemDto[]>(
      `${this.apiUrl}/Feedlot/${operationId}/SortGroups/Outcome/Bulk/${sortGroupId}`,
      JSON.stringify(sortGroupOutcome),
      {
        headers: this.headers
      }
    );
  }

  clearOutcome(
    operationId: string,
    sortGroupId: string,
    ids: string[]
  ): Observable<SortGroupOutcomeListItemDto[]> {
    return this.http.put<SortGroupOutcomeListItemDto[]>(
      `${this.apiUrl}/Feedlot/${operationId}/SortGroups/Outcome/Clear/${sortGroupId}`,
      ids,
      {
        headers: this.headers
      }
    );
  }

  applyDefaultOutcome(
    operationId: string,
    sortGroupId: string,
    sortGroupOutcomeId: string
  ): Observable<SortGroupOutcomeDto> {
    return this.http.put<SortGroupOutcomeDto>(
      `${this.apiUrl}/Feedlot/${operationId}/SortGroups/Outcome/ApplyDefault/${sortGroupId}/${sortGroupOutcomeId}`,
      {}
    );
  }

  setDefaultOutcome(
    operationId: string,
    sortGroupId: string,
    sortGroupOutcome: SortGroupOutcomeDto
  ): Observable<SortGroupOutcomeDto> {
    return this.http.put<SortGroupOutcomeDto>(
      `${this.apiUrl}/Feedlot/${operationId}/SortGroups/Outcome/SetDefault/${sortGroupId}`,
      JSON.stringify(sortGroupOutcome),
      {
        headers: this.headers
      }
    );
  }

  clearDefaultOutcome(
    operationId: string,
    sortGroupId: string
  ): Observable<SortGroupOutcomeDto> {
    return this.http.put<SortGroupOutcomeDto>(
      `${this.apiUrl}/Feedlot/${operationId}/SortGroups/Outcome/ClearDefault/${sortGroupId}`,
      {}
    );
  }

  copyOutcome(
    operationId: string,
    sortGroupId: string,
    sortGroupOutcomeId: string,
    ids: string[]
  ): Observable<SortGroupOutcomeListItemDto[]> {
    return this.http.put<SortGroupOutcomeListItemDto[]>(
      `${this.apiUrl}/Feedlot/${operationId}/SortGroups/Outcome/${sortGroupId}/Copy/${sortGroupOutcomeId}`,
      ids,
      {
        headers: this.headers
      }
    );
  }

  getTagColor(operationId: string, lotId: string): Observable<string> {
    return this.http.get<string>(
      `${this.apiUrl}/Feedlot/${operationId}/SortGroups/TagColor/${lotId}`);
  }

  getTagNumber(operationId: string, tagColorId: string): Observable<string> {
    return this.http.get(
      `${this.apiUrl}/Feedlot/${operationId}/SortGroups/TagNumber/${tagColorId}`,
      { responseType: 'text' }
    );
  }
}
