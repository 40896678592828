import { SortGroupDetailDto } from '@ifhms/models/feedlot';
import { createAction, props } from '@ngrx/store';

export const NAMESPACE = '[SORT GROUP - DETAIL]';

const get = createAction(
  `${NAMESPACE} Get`,
  props<{ detail: SortGroupDetailDto }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ detail: SortGroupDetailDto }>()
);

const apply = createAction(
  `${NAMESPACE} Apply`,
  props<{ sortGroupId: string }>()
);

const applySuccess = createAction(
  `${NAMESPACE} Apply Group Success`,
  props<{ detail: SortGroupDetailDto }>()
);

const error = createAction(`${NAMESPACE} Error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const SortGroupDetailActions = {
  get,
  getSuccess,
  apply,
  applySuccess,
  error,
  reset
};
