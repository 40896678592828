import { Injectable } from '@angular/core';
import {
  SortGroupOutcomeFilterBy,
  SortGroupOutcomeListItemDto,
  SortGroupOutcomeSortBy
} from '@ifhms/models/feedlot';
import { Store } from '@ngrx/store';
import { Observable, distinctUntilChanged, map } from 'rxjs';
import { SortGroupOutcomeListActions } from './sort-group-outcome-list.actions';
import { SortGroupOutcomeListSelectors } from './sort-group-outcome-list.selectors';
import { ListFacade } from '..';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

@Injectable()
export class SortGroupOutcomeListFacade implements ListFacade {
  state$: Observable<SortGroupOutcomeListItemDto[]> = this.store.select(
    SortGroupOutcomeListSelectors.selectAll
  );
  list$: Observable<SortGroupOutcomeListItemDto[]> = this.store.select(
    SortGroupOutcomeListSelectors.selectAll
  );
  filterBy$: Observable<SortGroupOutcomeFilterBy> = this.store.select(
    SortGroupOutcomeListSelectors.selectFilterBy
  );
  sortBy$: Observable<SortGroupOutcomeSortBy> = this.store.select(
    SortGroupOutcomeListSelectors.selectSortBy
  );
  filteredList$: Observable<SortGroupOutcomeListItemDto[]> = this.store.select(
    SortGroupOutcomeListSelectors.selectFiltered
  );
  filteredTotalLength$: Observable<number> = this.store.select(
    SortGroupOutcomeListSelectors.filteredTotalLength
  );
  filteredSelectedLength$: Observable<number> = this.store.select(
    SortGroupOutcomeListSelectors.filteredSelectedLength
  );
  selected$: Observable<SortGroupOutcomeListItemDto[]> = this.store.select(
    SortGroupOutcomeListSelectors.selectAllSelected
  );
  isLoading$: Observable<boolean> = this.store.select(
    SortGroupOutcomeListSelectors.selectLoading
  );
  isLoaded$: Observable<boolean> = this.store.select(
    SortGroupOutcomeListSelectors.selectLoaded
  );
  selectedLength$: Observable<number> = this.store.select(
    SortGroupOutcomeListSelectors.selectedLength
  );
  totalLength$: Observable<number> = this.store.select(
    SortGroupOutcomeListSelectors.totalLength
  );
  completedLength$: Observable<number> = this.store.select(
    SortGroupOutcomeListSelectors.completedLength
  );
  modal$: Observable<boolean> = this.store.select(
    SortGroupOutcomeListSelectors.selectModal
  );

  constructor(private readonly store: Store) {}

  get(): void {
    this.store.dispatch(SortGroupOutcomeListActions.get());
  }

  select(outcome: SortGroupOutcomeListItemDto): void {
    this.store.dispatch(
      SortGroupOutcomeListActions.select({
        outcome: { ...outcome, changes: { selected: !outcome.selected } }
      })
    );
  }

  updateSingle(outcome: SortGroupOutcomeListItemDto): void {
    this.store.dispatch(
      SortGroupOutcomeListActions.updateSingle({
        outcome: { ...outcome, changes: { status: outcome.status } }
      })
    );
  }

  selectAll(): void {
    this.store.dispatch(SortGroupOutcomeListActions.selectAll());
  }

  filter(filter: SortGroupOutcomeFilterBy): void {
    this.store.dispatch(SortGroupOutcomeListActions.filter({ filter }));
  }

  sort(sortBy: SortGroupOutcomeSortBy): void {
    this.store.dispatch(SortGroupOutcomeListActions.sortBy({ sortBy }));
  }

  clearOutput(): void {
    this.store.dispatch(SortGroupOutcomeListActions.clearOutput());
  }

  showModal(): void {
    this.store.dispatch(SortGroupOutcomeListActions.showModal());
  }

  hideModal(): void {
    this.store.dispatch(SortGroupOutcomeListActions.hideModal());
  }

  createDistinctSelector<
    T extends Extract<keyof SortGroupOutcomeListItemDto, string | number>
  >(property: T): Observable<SersiSelectListItem[]> {
    // @ts-ignore
    return this.list$.pipe(
      map((items) => {
        return Array.from(new Set(items.map((item) => item[property]).filter(x => x))).map(
          (value) => ({
            id: value,
            code: value,
            description: value
          })
        );
      }),
      distinctUntilChanged()
    );
  }
}
