import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ScaleDate } from '@ifhms/models/feedlot';

@Injectable(
  { providedIn: 'root' }
)
export class UpcWeightFormFieldUpdateService {
  private upcWeight: Subject<ScaleDate> = new Subject<ScaleDate>();
  private isUPCScaleVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isUPCScaleVisible$ = this.isUPCScaleVisible.asObservable();
  getUPCWeight$ = this.upcWeight.asObservable();

  updateUPCWeight(data: ScaleDate): void {
    this.upcWeight.next(data);
  }

  toggleDisplay(isVisible: boolean): void {
    this.isUPCScaleVisible.next(isVisible);
  }

}
