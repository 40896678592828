<ifhms-cattle-selector-input
  [formControl]="formControl"
  [disabled]="props['disabled'] || false"
  [data]="props['data']"
  [woAttributes]="props['woAttributes']"
  [bannerSeverity]="props['bannerSeverity']"
  [hideRequiredMessage]="props['hideRequiredMessage']"
  (blur)="formControl.setValue(formControl.value?.trim())"
>
</ifhms-cattle-selector-input>
