export enum LotSortBy {
  LotNumberAscending = 1,
  LotNumberDescending = 2,
  LotNameAscending = 3,
  LotNameDescending = 4,
  LotOwnerAscending = 5,
  LotOwnerDescending = 6,
  LotStatusAscending = 7,
  LotStatusDescending = 8,
  ActiveAnimalsAscending = 9,
  ActiveAnimalsDescending = 10
}
