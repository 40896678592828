<ng-container *transloco="let t; read: translateScope">
  <span
    class="sersi-input-wrapper p-fluid icon-suffix"
    [class.p-input-icon-right]="props['iconSuffix'] || props['textIconSuffix']"
    (click)="setTemperature()"
  >
    <i *ngIf="props['iconSuffix']" [className]="'pi ' + props['iconSuffix']"></i>
    <p-inputNumber
      [formControl]="formControl"
      [formlyAttributes]="field"
      [minFractionDigits]="1"
      [maxFractionDigits]="1"
      [useGrouping]="false"
      [placeholder]="t('temperature')"
      [readonly]="true"
      (keydown)="onCaptureTemperature($event)"
      class="temp-field"
      mode="decimal"
      [attr.data-ele]="'animal-temprature'"
    ></p-inputNumber>
    <span
      *ngIf="props['textIconSuffix'] && (formControl.value || !props.placeholder)"
      class="input-text-icon-suffix"
    >
      {{ props['textIconSuffix'] }}
    </span>
  </span>
</ng-container>
