import { Injectable } from '@angular/core';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import * as reducer from './unit-of-measures.reducer';
import { UnitOfMeasuresSelectors } from './unit-of-measures.selectors';
import { UnitOfMeasuresActions } from './unit-of-measures.actions';
import { UnitOfMeasureTypes } from '@ifhms/models/feedlot';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class UnitOfMeasuresFacade {

  loaded$ = this.store.select(UnitOfMeasuresSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(UnitOfMeasuresSelectors.selectItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(UnitOfMeasuresActions.get());
    return this.items$;
  }

  getByType(type: UnitOfMeasureTypes): Observable<SersiSelectListItem[]> {
    return this.items$.pipe(
      map(items => this.filterByType(items, type))
    );
  }

  private filterByType(items: SersiSelectListItem[], type: UnitOfMeasureTypes): SersiSelectListItem[] {
    return items.filter((item: SersiSelectListItem) =>
      item?.attributes ? item.attributes['unitOfMeasureType'] === type : item.code
    )
  }

  reset(): void {
    this.store.dispatch(UnitOfMeasuresActions.reset());
  }
}
