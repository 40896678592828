import { Inject, Injectable } from '@angular/core';
import { UpcService } from '@ifhms/common/angular/data-access/feedlot-api';
import { UpcDosingGunsService } from '@ifhms/common/angular/data-access/upc-api';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import {
  DeviceConnectionStatusEnum,
  DosingGunItem
} from '@ifhms/models/feedlot';
import {
  TRANSLOCO_SCOPE,
  TranslocoScope,
  TranslocoService
} from '@jsverse/transloco';
import { HubConnectionState } from '@microsoft/signalr';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import {
  catchError,
  exhaustMap,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs';
import { tap } from 'rxjs/operators';
import { UpcDosingGunsActions } from './upc-dosing-guns.actions';

@Injectable()
export class UpcDosingGunsEffects {
  translateScope = 'upcShared.state.messages';

  connectionStateEffect$ = createEffect(() =>
    this.dosingGunsService.connectionState$.pipe(
      switchMap((state) => {
        if (state === HubConnectionState.Connected) {
          return [
            UpcDosingGunsActions.updateConnectionStatus({
              connectionStatus: DeviceConnectionStatusEnum.Connected
            }),
            UpcDosingGunsActions.getDosingGunsStatusFromUpc()
          ];
        } else {
          return [
            UpcDosingGunsActions.updateConnectionStatus({
              connectionStatus: DeviceConnectionStatusEnum.Disconnected
            })
          ];
        }
      })
    )
  );

  getDosingGunsStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpcDosingGunsActions.getDosingGunsStatusFromUpc),
      switchMap(() =>
        from(this.dosingGunsService.getDeviceStatus()).pipe(
          mergeMap((dosingGunsSettings) => {
            if (
              dosingGunsSettings?.status ===
              DeviceConnectionStatusEnum.Connected
            ) {
              return from(this.dosingGunsService.getAvailableGuns()).pipe(
                mergeMap((availableGuns) => {
                  if (availableGuns) {
                    return [
                      UpcDosingGunsActions.getDosingGunsStatusFromUpcSuccess({
                        dosingGunsSettings
                      }),
                      UpcDosingGunsActions.getDosingGunsSettingsFromApi({
                        dosingGunsDto: { macAddresses: availableGuns }
                      })
                    ];
                  }
                  return [
                    UpcDosingGunsActions.getDosingGunsStatusFromUpcSuccess({
                      dosingGunsSettings
                    })
                  ];
                })
              );
            }
            return of(
              UpcDosingGunsActions.getDosingGunsStatusFromUpcSuccess({
                dosingGunsSettings
              })
            );
          }),
          catchError((error) =>
            of(UpcDosingGunsActions.error({ errMsg: error }))
          )
        )
      )
    )
  );

  getAvailableDosingGuns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpcDosingGunsActions.getAvailableDosingGunsFromUpc),
      switchMap(() =>
        from(this.dosingGunsService.getAvailableGuns()).pipe(
          map((availableDosingGuns) =>
            UpcDosingGunsActions.getAvailableDosingGunsFromUpcSuccess({
              availableDosingGuns
            })
          ),
          catchError((error) =>
            of(UpcDosingGunsActions.error({ errMsg: error }))
          )
        )
      )
    )
  );

  getDosingGunsListFromApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpcDosingGunsActions.getDosingGunsListFromApi),
      withLatestFrom(this.feedlotFacade.feedlotId$),
      switchMap(([, feedlotId]) =>
        this.upcApiService.getDosingGunsList(feedlotId).pipe(
          map((dosingGunsDto) =>
            UpcDosingGunsActions.getDosingGunsListFromApiSuccess({
              dosingGunsDto
            })
          ),
          catchError((error) =>
            of(UpcDosingGunsActions.error({ errMsg: error.message }))
          )
        )
      )
    )
  );

  updateDosingGunsSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpcDosingGunsActions.updateDosingGunsSettings),
      withLatestFrom(this.feedlotFacade.feedlotId$),
      mergeMap(([action, feedlotId]) =>
        this.upcApiService
          .updateDosingGunsSettings(feedlotId, action.dosingGunsDto)
          .pipe(
            tap(() => {
              this.showToastMessage(
                'success',
                'success',
                'dosing-guns-setting-update-success'
              );
            }),
            map((updatedSettings) =>
              UpcDosingGunsActions.updateDosingGunsSettingsSuccess({
                dosingGunsDto: updatedSettings
              })
            ),
            catchError((error) => {
              this.showToastMessage(
                'error',
                'error',
                'dosing-guns-setting-update-failed'
              );
              return of(UpcDosingGunsActions.error({ errMsg: error.message }));
            })
          )
      )
    )
  );

  getDosingGunsSettingsFromApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpcDosingGunsActions.getDosingGunsSettingsFromApi),
      withLatestFrom(this.feedlotFacade.feedlotId$),
      switchMap(([action, feedlotId]) =>
        this.upcApiService
          .getDosingGunsSettings(feedlotId, action.dosingGunsDto)
          .pipe(
            map((dosingGunsDto) => {
              let dosingGuns: DosingGunItem[] = [];
              if (dosingGunsDto.dosingGuns) {
                dosingGuns = dosingGunsDto.dosingGuns;
              }
              return UpcDosingGunsActions.getDosingGunsSettingsFromApiSuccess({
                dosingGuns
              });
            }),
            catchError((error) =>
              of(UpcDosingGunsActions.error({ errMsg: error.message }))
            )
          )
      )
    )
  );

  assignDosingGunToProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpcDosingGunsActions.assignDosingGunToProduct),
      withLatestFrom(this.feedlotFacade.feedlotId$),
      exhaustMap(([action, feedlotId]) =>
        this.upcApiService
          .assignDosingGunToProduct(feedlotId, action.dosingGun)
          .pipe(
            tap(() => {
              this.showToastMessage(
                'success',
                'success',
                'dosing-gun-assignment-success'
              );
            }),
            map((updatedGuns) => {
              return UpcDosingGunsActions.assignDosingGunToProductSuccess({
                dosingGunsDto: updatedGuns
              });
            }),
            catchError((error) => {
              this.showToastMessage(
                'error',
                'error',
                'dosing-gun-assignment-failed'
              );
              return of(UpcDosingGunsActions.error({ errMsg: error.message }));
            })
          )
      )
    )
  );

  unassignDosingGunFromProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpcDosingGunsActions.unassignDosingGunFromProduct),
      withLatestFrom(this.feedlotFacade.feedlotId$),
      exhaustMap(([action, feedlotId]) =>
        this.upcApiService
          .unassignDosingGunFromProduct(feedlotId, action.gunId)
          .pipe(
            tap(() => {
              this.showToastMessage(
                'success',
                'success',
                'dosing-gun-unassignment-success'
              );
            }),
            map((updatedGun) => {
              return UpcDosingGunsActions.unassignDosingGunFromProductSuccess({
                dosingGun: updatedGun
              });
            }),
            catchError((error) => {
              this.showToastMessage(
                'error',
                'error',
                'dosing-gun-unassignment-failed'
              );
              return of(UpcDosingGunsActions.error({ errMsg: error.message }));
            })
          )
      )
    )
  );

  setDose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpcDosingGunsActions.setDose),
      withLatestFrom(this.feedlotFacade.feedlotId$),
      mergeMap(([action]) =>
        from(
          this.dosingGunsService.setDose(action.macAddress, action.dose)
        ).pipe(
          mergeMap((response) => {
            if (response === null) {
              this.showToastMessage(
                'error',
                'dosing-gun-dose-null-response',
                'dosing-gun-check-connectivity',
                action.macAddress
              );
              return of(
                UpcDosingGunsActions.error({
                  errMsg: 'Null response from dosingGunsService.setDose'
                })
              );
            } else {
              return of(UpcDosingGunsActions.setDoseSuccess({ response }));
            }
          }),
          catchError((error) => {
            return of(UpcDosingGunsActions.error({ errMsg: error.message }));
          })
        )
      )
    )
  );

  setOneTimeDose$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpcDosingGunsActions.setOneTimeDose),
      mergeMap((action) =>
        from(
          this.dosingGunsService.setOneTimeDose(action.macAddress, action.dose)
        ).pipe(
          mergeMap((response) => {
            if (response === null) {
              this.showToastMessage(
                'error',
                'dosing-gun-dose-null-response',
                'dosing-gun-check-connectivity',
                action.gunName ?? action.macAddress
              );
              return of(
                UpcDosingGunsActions.error({
                  errMsg: 'Null response from dosingGunsService.setOneTimeDose'
                })
              );
            } else {
              this.showToastMessage(
                'success',
                'success',
                'dose-set-success',
                action.gunName ?? response.macAddress
              );
              return of(
                UpcDosingGunsActions.setOneTimeDoseSuccess({ response })
              );
            }
          }),
          catchError((error) => {
            return of(UpcDosingGunsActions.error({ errMsg: error.message }));
          })
        )
      )
    )
  );

  validateGun$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpcDosingGunsActions.validateGun),
      withLatestFrom(this.feedlotFacade.feedlotId$),
      mergeMap(([action, feedlotId]) =>
        this.upcApiService.validateGun(feedlotId, action.gunId).pipe(
          tap(() => {
            this.showToastMessage(
              'success',
              'success',
              'dosing-gun-validation-success'
            );
          }),
          map((dosingGun) =>
            UpcDosingGunsActions.validateGunSuccess({ dosingGun })
          ),
          catchError((error) => {
            this.messageService.add({
              severity: 'error',
              summary: this.getTranslation('error'),
              detail: this.getTranslation('dosing-gun-validation-failed')
            });
            return of(UpcDosingGunsActions.error({ errMsg: error.message }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private feedlotFacade: FeedlotFacade,
    private messageService: MessageService,
    private translateService: TranslocoService,
    private dosingGunsService: UpcDosingGunsService,
    private upcApiService: UpcService,
    @Inject(TRANSLOCO_SCOPE) private providerScope: TranslocoScope
  ) {}

  getTranslation(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }

  private showToastMessage(
    severity: string,
    summaryTranslationKey: string,
    detailTranslationKey: string,
    gunName?: string
  ): void {
    this.translateService
      .selectTranslate('', {}, this.providerScope)
      .pipe(take(1))
      .subscribe(() => {
        this.messageService.add({
          severity,
          summary: this.getTranslation(summaryTranslationKey),
          detail:
            this.getTranslation(detailTranslationKey) +
            (gunName ? ` '${gunName}'` : '')
        });
      });
  }
}
