<span
  class="pulse-loading-container tag-badge"
  [ngClass]="className"
  [pTooltip]="tooltipMsg || $any(null)"
>
  {{ loadingMsg }}
  <span class="dot-1">.</span>
  <span class="dot-2">.</span>
  <span class="dot-3">.</span>
</span>
