import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mathMin' })
export class MathMinPipe implements PipeTransform {

  transform(a: number, b: number): number {
    return Math.min(a, b);
  }

}
