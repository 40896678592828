import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractTranslationComponent } from '@common/angular/translation';
import {
  UpcGateControllerService,
  UpcRfidService,
  UpcScaleService,
  UpcThermometerService
} from '@ifhms/common/angular/data-access/upc-api';
import { FormAlleyFieldComponent } from '@ifhms/common/angular/upc/gate-controller';
import { FormRfidFieldComponent } from '@ifhms/common/angular/upc/rfid';
import { FormWeightFieldComponent } from '@ifhms/common/angular/upc/scale';
import { FormTemperatureFieldComponent } from '@ifhms/common/angular/upc/thermometer';
import { DeviceConnectionStatusEnum } from '@ifhms/models/feedlot';
import { HubConnectionState } from '@microsoft/signalr';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyWrappersEnum } from '@sersi/angular/formly/core';
import { map, Observable } from 'rxjs';
import {
  ConnectionStatusLabelFormFieldComponent
} from '../connection-status-label-form-field/connection-status-label-form-field.component';

@UntilDestroy()
@Component({
  selector: 'ifhms-feedlot-upc-global-settings-form',
  templateUrl: './upc-global-settings-form.component.html',
  styleUrls: ['./upc-global-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcGlobalSettingsFormComponent extends AbstractTranslationComponent {

  translationNamespace = 'components.upc-form';

  form = new FormGroup({});
  model: any;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];

  rfidConnectionStatus$: Observable<string>;
  scaleConnectionStatus$: Observable<string>;
  thermometerConnectionStatus$: Observable<string>;
  gateControllerConnectionStatus$: Observable<string>;

  constructor(
    private upcScaleService: UpcScaleService,
    private upcRfidService: UpcRfidService,
    private upcThermometerService: UpcThermometerService,
    private upcGateControllerService: UpcGateControllerService
  ) {
    super();
  }

  protected override onTranslationInit():void {
    this.getConnectionStatuses();
    this.setFields();
  }
  private getConnectionStatuses(): void {
    this.rfidConnectionStatus$ = this.getConnectionStatus(this.upcRfidService);
    this.scaleConnectionStatus$ = this.getConnectionStatus(this.upcScaleService);
    this.thermometerConnectionStatus$ = this.getConnectionStatus(this.upcThermometerService);
    this.gateControllerConnectionStatus$ = this.getConnectionStatus(this.upcGateControllerService);
  }

  private getConnectionStatus(deviceService: { connectionState$: Observable<HubConnectionState> }): Observable<DeviceConnectionStatusEnum> {
    return deviceService.connectionState$.pipe(
      map((state) => {
        switch(state) {
          case HubConnectionState.Connected:
            return DeviceConnectionStatusEnum.Connected;
          case HubConnectionState.Disconnected:
            return DeviceConnectionStatusEnum.Disconnected;
          default:
            return DeviceConnectionStatusEnum.Disconnected;
        }
      })
    );
  }

  private setFields(): void {

    this.fields = [{
      fieldGroupClassName: 'upc-form',
      fieldGroup: [
        this.setScale(),
        this.setScaleConnectionStatus(),
        this.setRfid(),
        this.setRfidConnectionStatus(),
        this.setThermometer(),
        this.setThermometerConnectionStatus(),
        this.setAlley(),
        this.setGateControllerConnectionStatus()
      ]
    }];

  }

  private setScale(): FormlyFieldConfig {
    return {
      type: FormWeightFieldComponent,
      className: 'upc-scale',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      props: {
        label$: this.getTranslation$('scale-label')
      },
      expressions: {
        'props.readScaleData': () : boolean => true
      }
    };
  }

  private setScaleConnectionStatus(): FormlyFieldConfig {
    return {
      type: ConnectionStatusLabelFormFieldComponent,
      className: 'upc-scale-connection',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      props: {
        label: '',
        connectionStatus: this.scaleConnectionStatus$
      }
    };
  }

  private setRfid(): FormlyFieldConfig {
    return {
      type: FormRfidFieldComponent,
      className: 'upc-rfid',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      props: {
        label$: this.getTranslation$('rfid-label')
      }
    };
  }

  private setRfidConnectionStatus(): FormlyFieldConfig {
    return {
      type: ConnectionStatusLabelFormFieldComponent,
      className: 'rfid-scale-connection',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      props: {
        label: '',
        connectionStatus: this.rfidConnectionStatus$
      }
    };
  }

  private setThermometer(): FormlyFieldConfig {
    return {
      type: FormTemperatureFieldComponent,
      className: 'upc-thermometer',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      props: {
        label$: this.getTranslation$('thermometer-label')
      },
      expressions: {
        'props.readTempData': () : boolean => true
      }
    };
  }

  private setThermometerConnectionStatus(): FormlyFieldConfig {
    return {
      type: ConnectionStatusLabelFormFieldComponent,
      className: 'upc-thermometer-connection',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      props: {
        label: '',
        connectionStatus: this.thermometerConnectionStatus$
      }
    };
  }

  private setAlley(): FormlyFieldConfig {
    return {
      type: FormAlleyFieldComponent,
      className: 'upc-alley',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      props: {
        label$: this.getTranslation$('alley-label'),
        placeholder$: this.getTranslation$('select-option')
      }
    };
  }

  private setGateControllerConnectionStatus(): FormlyFieldConfig {
    return {
      type: ConnectionStatusLabelFormFieldComponent,
      className: 'upc-gate-connection',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      props: {
        label: '',
        connectionStatus: this.gateControllerConnectionStatus$
      }
    };
  }

}
