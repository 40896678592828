import { HttpErrorResponse } from '@angular/common/http';

export function forceErrorForEmptyResponse(response: unknown): void {
  if (!response) {
    // Immediately throw an error for 204 response
    throw new HttpErrorResponse({
      status: 204,
      statusText: 'No Content'
    });
  }
}