import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';
import { EventType } from '@ifhms/models/feedlot';
import { GUID_EMPTY } from '@ifhms/models/shared';

@Injectable()
export class EventsAnimalProjectedWeightService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {}

  projectedWeightAnimal(
    feedlotId: string,
    animalId: string,
    eventDate: string,
    eventId: string,
    eventType: EventType
  ): Observable<number> {
    let params = new HttpParams();

    if (eventDate) {
      params = params.set('eventDate', eventDate);
      params = params.set('eventType', eventType);
    }

    if (eventId === 'draft') {
      params = params.set('eventId', GUID_EMPTY);
    } else {
      params = params.set('eventId', eventId);
    }

    return this.http.get<number>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Events/AnimalProjectedWeight/${animalId}`,
      { params }
    );
  }
}
