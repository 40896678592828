import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LotTransferWorkOrderDto, LotTransferWorkOrderUpdateDto, WorkOrderLotTransferListDto, WorkOrderLotTransferListRequestDto } from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';

@Injectable()
export class LotTransferWorkOrderService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {
  }

  getList(feedlotId: string, request: WorkOrderLotTransferListRequestDto): Observable<WorkOrderLotTransferListDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<WorkOrderLotTransferListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/LotTransfer/WorkOrder`, {
      params
    });
  }

  getLotTransferWorkOrder(feedlotId : string, workOrderId : string): Observable<LotTransferWorkOrderDto> {
    return this.http.get<LotTransferWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/LotTransfer/WorkOrder/${workOrderId}`);
  }
  
  update(feedlotId : string, lotTransferWorkOrderUpdate : LotTransferWorkOrderUpdateDto): Observable<LotTransferWorkOrderDto> {
    return this.http.put<LotTransferWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/LotTransfer/WorkOrder`, lotTransferWorkOrderUpdate);
  }

  generateReport(feedlotId : string, workOrderId : string): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            levelId: feedlotId ?? '',
            workOrderId: workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/Reports/LotTransferWorkOrder`, options);
  }

  delete(feedlotId : string, workOrderId : string): Observable<LotTransferWorkOrderDto> {
    return this.http.delete<LotTransferWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/LotTransfer/WorkOrder/${workOrderId}`);
  }

  requestApproval(feedlotId : string, workOrderId : string): Observable<LotTransferWorkOrderDto> {
    return this.http.put<LotTransferWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/LotTransfer/WorkOrder/${workOrderId}/RequestApproval`, workOrderId);
  }

  finalize(feedlotId: string, workOrderId: string, finalizedDate: string): Observable<LotTransferWorkOrderDto> {
    return this.http.put<LotTransferWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/LotTransfer/WorkOrder/${workOrderId}/Finalize`, { finalizedDate });
  }
}
