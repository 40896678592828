import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { distinctUntilChanged, filter, map, Observable, pairwise, startWith, switchMap, tap } from 'rxjs';

import { updateFieldSelectItems } from '@common/angular/utils';
import { SortGroupsService } from '@ifhms/common/angular/data-access/feedlot-api';
import { SortGroupProductSelectorComponent } from '@ifhms/feedlot/front-end/shared/domain/formly/product-selector';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { Product, SortGroupDetailDto, SortGroupOutcomeDto } from '@ifhms/models/feedlot';
import { WorkOrderType } from '@ifhms/models/shared';
import {
  FormlyTypesEnum,
  FormlyWrappersEnum,
  GRID_KEY,
  GridData,
  gridToModelData,
  modelDataToGrid
} from '@sersi/angular/formly/core';
import { SersiResizedEvent } from '@sersi/angular/ui/core';

import { FEATURE_NAME } from '../../+state';
import { SortGroupProductsTableComponent } from '../sort-group-products-table/sort-group-products-table.component';
import { AbstractTranslationComponent } from '@common/angular/translation';

interface Model extends Omit<SortGroupOutcomeDto, 'products'> {
  completed: string;
  products: GridData<Product>;
}

@UntilDestroy()
@Component({
  selector: 'ifhms-sort-group-form',
  templateUrl: './sort-group-form.component.html',
  styleUrls: ['./sort-group-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SortGroupFormComponent extends AbstractTranslationComponent implements OnInit, OnChanges {
  protected override translationNamespace = 'components.sort-group-form';
  model = <Model | null>{};
  modelSnapshot = <Model>{};
  form = new FormGroup({});
  fields: FormlyFieldConfig[];

  translateScope = `${FEATURE_NAME}.components.sort-group-form`;

  @HostBinding('class') class = 'feedlot-web-sort-group';

  @Input() detail: SortGroupOutcomeDto | null;
  @Input() groupDetail: SortGroupDetailDto | null;
  @Input() isBulkOutput: boolean;
  @Input() viewMode: boolean;
  @Input() operationId: string | null;
  @Input() creationDate?: string;
  @Input() visible: boolean;
  @Input() hasTagsInputs: boolean;

  constructor(
    private referenceDataFacade: ReferenceDataFacade,
    private sortGroupsService: SortGroupsService
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['detail']) {
      if (this.detail) {
        this.modelSnapshot = {
          ...this.detail,
          completed: this.detail.status ? 'Completed' : 'Not Completed',
          age:
            this.detail.age === '0 - 0'
              ? this.getTranslation('no-age')
              : this.detail.age,
          products: modelDataToGrid(this.detail.products?.map(
            (x) =>
              <Product>{
                id: x.id,
                productId: x.productId,
                productTypeId: x.productTypeId,
                routeDetailId: x.routeDetailId
              }
          ) || null)
        };
      } else {
        this.modelSnapshot = <Model>{
          products: modelDataToGrid(null)
        };
      }
      this.reset();
    }

    if(changes['visible'] && this.visible && this.isBulkOutput){
      this.modelSnapshot.products = modelDataToGrid(null)
    }

    if (changes['viewMode']) {
      this.setFields();
      this.reset();
    }
  }

  override onTranslationInit(): void {
    this.loadRefData();
    this.setFields();
    this.syncTagNumber();
  }

  setFields(): void {
    this.fields = [this.setBaseGroup()];
  }

  setBaseGroup(): FormlyFieldConfig {
    return {
      fieldGroupClassName: 'feedlot-web-sort-group__form-grid',
      fieldGroup: [
        this.setTemplateName(),

        this.setGroupDetails(),

        this.setBreed(),
        this.setBreedType(),
        this.setWeight(),
        this.setAge(),
        this.setAgeClass(),
        this.setTemperature(),
        this.setDentition(),
        this.setGender(),
        this.setStatus(),

        this.setOutput(),

        this.setPen(),
        this.setLot(),
        this.setSubGroup(),
        this.setAlley(),
        this.setTag(),
        this.setTagNumber(),

        this.setUserProductGridLabel(),
        this.setUserProductGrid(),
        this.setUserProductGridValidation()
      ]
    };
  }

  setTemplateName(): FormlyFieldConfig {
    return {
      key: 'templateName',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.TEXT_INPUT,
      className: 'feedlot-web-sort-group__tname',
      props: {
        disabled: true,
        label$: this.getTranslation$('name')
      },
      expressions: {
        hide: () => this.isBulkOutput
      }
    };
  }

  setGroupDetails(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      className: 'feedlot-web-sort-group__details',
      props: {
        label$: this.getTranslation$('details')
      },
      expressions: {
        hide: () => this.isBulkOutput
      }
    };
  }

  setBreed(): FormlyFieldConfig {
    return {
      key: 'breed',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.TEXT_INPUT,
      className: 'feedlot-web-sort-group__breed',
      props: {
        disabled: true,
        label$: this.getTranslation$('breed')
      },
      expressions: {
        hide: ({ model }) => this.isBulkOutput || !model.breed
      }
    };
  }

  setBreedType(): FormlyFieldConfig {
    return {
      key: 'breedType',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.TEXT_INPUT,
      className: 'feedlot-web-sort-group__breedType',
      props: {
        disabled: true,
        label$: this.getTranslation$('breed-type')
      },
      expressions: {
        hide: ({ model }) => this.isBulkOutput || !model.breedType
      }
    };
  }

  setWeight(): FormlyFieldConfig {
    return {
      key: 'weight',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.TEXT_INPUT,
      className: 'feedlot-web-sort-group__weights',
      props: {
        disabled: true,
        label$: this.getTranslation$('weight')
      },
      expressions: {
        hide: ({ model }) => this.isBulkOutput || !model.weight
      }
    };
  }

  setAge(): FormlyFieldConfig {
    return {
      key: 'age',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.TEXT_INPUT,
      className: 'feedlot-web-sort-group__age',
      props: {
        disabled: true,
        label$: this.getTranslation$('age')
      },
      expressions: {
        hide: ({ model }) => this.isBulkOutput || !model.age
      }
    };
  }

  setAgeClass(): FormlyFieldConfig {
    return {
      key: 'ageClass',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.TEXT_INPUT,
      className: 'feedlot-web-sort-group__ageClass',
      props: {
        disabled: true,
        label$: this.getTranslation$('age-class')
      },
      expressions: {
        hide: ({ model }) => this.isBulkOutput || !model.ageClass
      }
    };
  }

  setTemperature(): FormlyFieldConfig {
    return {
      key: 'temperature',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.TEXT_INPUT,
      className: 'feedlot-web-sort-group__temperature',
      props: {
        disabled: true,
        label$: this.getTranslation$('temperature')
      },
      expressions: {
        hide: ({ model }) => this.isBulkOutput || !model.temperature
      }
    };
  }

  setDentition(): FormlyFieldConfig {
    return {
      key: 'dentition',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.TEXT_INPUT,
      className: 'feedlot-web-sort-group__dentition',
      props: {
        disabled: true,
        label$: this.getTranslation$('dCode')
      },
      expressions: {
        hide: ({ model }) => this.isBulkOutput || !model.dentition
      }
    };
  }

  setGender(): FormlyFieldConfig {
    return {
      key: 'gender',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.TEXT_INPUT,
      className: 'feedlot-web-sort-group__gender',
      props: {
        disabled: true,
        label$: this.getTranslation$('gender')
      },
      expressions: {
        hide: ({ model }) => this.isBulkOutput || !model.gender
      }
    };
  }

  setOutput(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      className: 'feedlot-web-sort-group__details',
      props: {
        label$: this.getTranslation$('output')
      }
    };
  }

  setPen(): FormlyFieldConfig {
    return {
      key: 'locationId',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__location',
      props: {
        label$: this.getTranslation$('location'),
        placeholder$: this.getTranslation$('select-option'),
        items$: this.referenceDataFacade.homeLocations$,
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE'
      },
      expressions: {
        'props.required': this.isPenRequired,
        'props.disabled': () =>
          this.groupDetail?.workOrderType === WorkOrderType.ReHandling &&
          !this.groupDetail?.isActionNewHomePen,
        'props.showClear': () => !this.isPenRequired
      }
    };
  }

  setLot(): FormlyFieldConfig {
    return {
      key: 'lotId',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__lot',
      props: {
        label$: this.getTranslation$('lot'),
        placeholder$: this.getTranslation$('select-option'),
        items$: this.referenceDataFacade.lots$,
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE'
      },
      validators: {
        AtleastOne: {
          // False => Error; True: No-Error
          expression: (control: UntypedFormControl): boolean =>
            this.isPenRequired() ||
            this.model?.lotSubGroupId ||
            this.model?.alleyId ||
            this.model?.tagColorId ||
            control.value,
          message: this.getTranslation('atleast-one-error')
        }
      },
      expressions: {
        'props.required': () =>
          this.groupDetail?.workOrderType === WorkOrderType.Arrival,
        'props.showClear': () =>
          this.groupDetail?.workOrderType !== WorkOrderType.Arrival
      }
    };
  }

  setSubGroup(): FormlyFieldConfig {
    return {
      key: 'lotSubGroupId',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__subgroup',
      props: {
        label$: this.getTranslation$('sub-group'),
        placeholder$: this.getTranslation$('select-option'),
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE'
      },
      validators: {
        AtleastOne: {
          // False => Error; True: No-Error
          expression: (control: UntypedFormControl): boolean =>
            this.isPenRequired() ||
            this.model?.lotId ||
            this.model?.alleyId ||
            this.model?.tagColorId ||
            control.value,
          message: this.getTranslation('atleast-one-error')
        }
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.form
            ?.get('lotId')
            ?.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe((value: string) => {
              field.form?.get('lotSubGroupId')?.patchValue(null);
              updateFieldSelectItems(
                field,
                this.referenceDataFacade.getLotsSubGroupByLotId(value)
              );
            });
        }
      }
    };
  }

  setAlley(): FormlyFieldConfig {
    return {
      key: 'alleyId',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__alley',
      props: {
        label$: this.getTranslation$('alley'),
        placeholder$: this.getTranslation$('select-option'),
        items$: this.referenceDataFacade.alleys$,
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE'
      },
      validators: {
        AtleastOne: {
          // False => Error; True: No-Error
          expression: (control: UntypedFormControl): boolean =>
            this.isPenRequired() ||
            this.model?.lotId ||
            this.model?.lotSubGroupId ||
            this.model?.tagColorId ||
            control.value,
          message: this.getTranslation('atleast-one-error')
        }
      }
    };
  }

  setTag(): FormlyFieldConfig {
    return {
      key: 'tagColorId',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__tag',
      props: {
        label$: this.getTranslation$('tag-color'),
        placeholder$: this.getTranslation$('select-option'),
        items$: this.referenceDataFacade.tagColors$,
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE'
      },
      validators: {
        AtleastOne: {
          // False => Error; True: No-Error
          expression: (control: UntypedFormControl): boolean =>
            this.isPenRequired() ||
            this.model?.lotId ||
            this.model?.lotSubGroupId ||
            this.model?.alleyId ||
            control.value,
          message: this.getTranslation('atleast-one-error')
        }
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.form
            ?.get('lotId')
            ?.valueChanges.pipe(
              untilDestroyed(this),
              startWith(field?.form?.get('lotId')?.value),
              pairwise(),
              filter(([prev, next]) => prev !== next)
            )
            .subscribe(([, value]) => {
              if (
                !field.form?.get('lotId')?.touched && value == this.modelSnapshot.lotId
              ) {
                return;
              }
              if (this.operationId != null && value) {
                this.sortGroupsService
                  .getTagColor(this.operationId, value)
                  .subscribe((x) => {
                    //@ts-ignore
                    this.model = {
                      ...this.model,
                      tagColorId: x || null
                    };
                    this.form.updateValueAndValidity();
                  });
              }
            });
        }
      },
      expressions: {
        hide: () => !this.hasTagsInputs,
        'props.required': () =>
          this.groupDetail?.workOrderType === WorkOrderType.Arrival,
        'props.showClear': () =>
          this.groupDetail?.workOrderType !== WorkOrderType.Arrival
      }
    };
  }

  setTagNumber(): FormlyFieldConfig {
    return {
      key: 'tagNumber',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.TEXT_INPUT,
      className: 'feedlot-web-sort-group__tagNumber',
      props: {
        label$: this.getTranslation$('tag-number'),
        disabled: true
      },
      expressions: {
        hide: () => !this.hasTagsInputs
      }
    };
  }

  setStatus(): FormlyFieldConfig {
    return {
      key: 'completed',
      type: this.viewMode
        ? FormlyTypesEnum.TEXT_READONLY
        : FormlyTypesEnum.TEXT_INPUT,
      className: 'feedlot-web-sort-group__status',
      props: {
        disabled: true,
        label$: this.getTranslation$('status')
      },
      expressions: {
        hide: () => this.isBulkOutput
      }
    };
  }

  setUserProductGridLabel(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      className: 'feedlot-web-sort-group__details',
      props: {
        label$: this.getTranslation$('product-details')
      }
    };
  }

  private setUserProductGrid(): FormlyFieldConfig {
    if (this.viewMode) return this.setProductsSummary();
    return {
      key: 'products',
      type: SortGroupProductSelectorComponent,
      className: 'feedlot-web-sort-group__details',
      props: {
        translateFn: this.getTranslation.bind(this),
        translateFn$: this.getTranslation$.bind(this)
      },
      validators: {
        check: {
          expression: () => this.checkEmptyProductGrid()
        }
      }
    };
  }

  private setProductsSummary(): FormlyFieldConfig {
    return {
      type: SortGroupProductsTableComponent,
      key: `products.${GRID_KEY}`,
      className: 'w-full'
    }
  }

  setUserProductGridValidation(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      props: {
        label$: this.getTranslation$('user-product-grid-validation-message')
      },
      className: 'arrival__userProductGridValidation',
      expressions: {
        hide: (): boolean => this.checkEmptyProductGrid()
      }
    };
  }

  checkEmptyProductGrid(): boolean {
    const products = gridToModelData(this.model?.products);
    if (!products || products.length < 1) {
      return true;
    }

    for (const product of products) {
      const isRowEmpty =
        !product.productId && !product.productTypeId && !product.routeDetailId;
      const isRowIncomplete =
        !product.productId || !product.productTypeId || !product.routeDetailId;

      if (!isRowEmpty && isRowIncomplete) {
        return false;
      }
    }

    return true;
  }

  getData(): SortGroupOutcomeDto {
    const products: Product[] = [];
    gridToModelData(this.model?.products).forEach((x) => {
      if (x.productId && x.productTypeId && x.routeDetailId) {
        products.push(<Product>{
          productId: x.productId,
          productTypeId: x.productTypeId,
          routeDetailId: x.routeDetailId,
          routeId: x.routeId,
          ...(x.id && {
            id: x.id
          })
        });
      }
    });
    const outcome = <SortGroupOutcomeDto>{
      locationId: this.model?.locationId,
      lotId: this.model?.lotId,
      lotSubGroupId: this.model?.lotSubGroupId,
      alleyId: this.model?.alleyId,
      tagColorId: this.hasTagsInputs ? this.model?.tagColorId: null,
      tagNumber: this.hasTagsInputs ? this.model?.tagNumber: null,
      products
    };
    return outcome;
  }

  reset(): void {
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.model = {
      ...this.modelSnapshot,
      products: this.modelSnapshot.products || []
    };
    this.form.patchValue({ ...this.model });
    this.form.updateValueAndValidity();
  }

  onSizeChange(resize: SersiResizedEvent): void {
    if (resize.width < 600) {
      this.fields[0].fieldGroupClassName =
        'feedlot-web-sort-group__form-grid__sm';
    } else {
      this.fields[0].fieldGroupClassName = 'feedlot-web-sort-group__form-grid';
    }
  }

  modelChange(): void {
    const lotId: AbstractControl | null = this.form.get('lotId');
    lotId?.updateValueAndValidity();
    const lotSubGroupId: AbstractControl | null =
      this.form.get('lotSubGroupId');
    lotSubGroupId?.updateValueAndValidity();
    const alleyId: AbstractControl | null = this.form.get('alleyId');
    alleyId?.updateValueAndValidity();
    const tagColorId: AbstractControl | null = this.form.get('tagColorId');
    tagColorId?.updateValueAndValidity();
  }

  isPenRequired = (): boolean =>
    this.groupDetail?.workOrderType === WorkOrderType.Arrival ||
    (this.groupDetail?.workOrderType === WorkOrderType.ReHandling &&
      this.groupDetail?.isActionNewHomePen) ||
    false;

  private loadRefData(): void {
    this.referenceDataFacade.getLotSubGroups();
    this.referenceDataFacade.getAlleys();
  }

  private getTagNumberObservable(): Observable<string> {
    return this.form.valueChanges
      .pipe(
        startWith(null),
        map(val => (<SortGroupOutcomeDto>val)?.tagColorId),
        pairwise(),
        filter(([prev, next]) => prev !== next),
        filter(([, val]) => !!this.operationId && !this.viewMode && !!val),
        map(([, next]) => next!)
      )
  }

  private syncTagNumber(): void {
    this.getTagNumberObservable()
      .pipe(
        switchMap(val => this.sortGroupsService.getTagNumber(this.operationId!, val!)),
        untilDestroyed(this)
      )
      .subscribe(tagNumber => this.form?.patchValue({ tagNumber }));
  }
}
