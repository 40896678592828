import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../reducer';
import * as reducer from './procedures.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.procedures
);

const selectItems = createSelector(selectState, reducer.getAll);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

export const ProceduresSelectors = {
  selectItems,
  selectLoaded
};
