import { Inject, Injectable } from '@angular/core';
import { UpcService } from '@ifhms/common/angular/data-access/feedlot-api';
import { UpcApplicationSettingsService } from '@ifhms/common/angular/data-access/upc-api';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { TRANSLOCO_SCOPE, TranslocoScope, TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { catchError, combineLatest, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { UpcApiActions } from './upc-api.actions';
import { UpcApiFacade } from './upc-api.facade';

@Injectable()
export class UpcApiEffects {
  translateScope = 'upcShared.state.messages';

  initUpcSettings$ = createEffect(
    () =>
      combineLatest([
        this.upcAppSettingsService.upcClientId$,
        this.feedlotFacade.feedlotId$
      ]).pipe(
        tap(([clientId, feedlotId]) => {
          if (clientId && feedlotId) {
            this.upcApiFacade.getUPCSettings(clientId);
          }
        })
      ),
    { dispatch: false }
  );

  getUpcSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpcApiActions.getUPCSettings),
      withLatestFrom(this.feedlotFacade.feedlotId$),
      switchMap(([action, feedlotId]) =>
        this.upcApiService.getUPCSettings(feedlotId, action.clientId).pipe(
          map((upcSettings) =>
            UpcApiActions.getUPCSettingsSuccess({ upcSettings })
          ),
          catchError((error) => of(UpcApiActions.error({ errMsg: error.error?.text })))
        )
      )
    )
  );

  updateUpcSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpcApiActions.updateUPCSettings),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.upcApiFacade.upcSettings$
      ),
      switchMap(([action, feedlotId, upcSettings]) => {
        if (upcSettings?.clientId) {
          return this.upcApiService
            .updateUPCSettings(
              feedlotId,
              action.upcSettings,
              upcSettings.clientId
            )
            .pipe(
              tap(() => {
                this.translateService
                  .selectTranslate('', {}, this.providerScope)
                  .pipe(take(1))
                  .subscribe(() => {
                    this.messageService.add({
                      severity: 'success',
                      summary: this.getTranslation('success'),
                      detail: this.getTranslation('upc-settings-saved-detail')
                    });
                  });
                this.refDataFacade.getAlleys();
              }),
              map((upcSettings) =>
                UpcApiActions.updateUPCSettingsSuccess({ upcSettings })
              ),
              catchError((error) => of(UpcApiActions.error({ errMsg: error })))
            );
        } else {
          return of(
            UpcApiActions.error({ errMsg: 'Client ID not available.' })
          );
        }
      })
    );
  });

  resetGateController$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpcApiActions.resetGateController),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.upcApiFacade.upcSettings$
      ),
      switchMap(([action, feedlotId, upcSettings]) => {
        if (upcSettings?.clientId) {
          return this.upcApiService
            .resetGateController(feedlotId, upcSettings.clientId)
            .pipe(
              tap(() => {
                this.translateService
                  .selectTranslate('', {}, this.providerScope)
                  .pipe(take(1))
                  .subscribe(() => {
                    this.messageService.add({
                      severity: 'success',
                      summary: this.getTranslation('success'),
                      detail: this.getTranslation('upc-settings-saved-detail')
                    });
                  });
                this.refDataFacade.getAlleys();
              }),
              map((upcSettings) =>
                UpcApiActions.updateUPCSettingsSuccess({ upcSettings })
              ),
              catchError((error) => of(UpcApiActions.error({ errMsg: error })))
            );
        } else {
          return of(
            UpcApiActions.error({ errMsg: 'Client ID not available.' })
          );
        }
      })
    );
  });

  getUpcUsages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpcApiActions.getUPCUsages),
      withLatestFrom(
        this.upcApiFacade.clientId$,
        this.feedlotFacade.feedlotId$),
      switchMap(([, clientId, feedlotId]) =>
        this.upcApiService.getUpcFeedlotUsages(feedlotId,clientId).pipe(
          map(usagesDto => UpcApiActions.getUPCUsagesSuccess({ usages: usagesDto.operations })),
          catchError(error => of(UpcApiActions.error({ errMsg: error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private feedlotFacade: FeedlotFacade,
    private upcApiService: UpcService,
    private upcApiFacade: UpcApiFacade,
    private readonly store: Store,
    private messageService: MessageService,
    private translateService: TranslocoService,
    private refDataFacade: ReferenceDataFacade,
    private upcAppSettingsService: UpcApplicationSettingsService,
    @Inject(TRANSLOCO_SCOPE) private providerScope: TranslocoScope
  ) {}

  getTranslation(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }
}

