import {
  SortGroupOutcomeFilterBy,
  SortGroupOutcomeListItemDto,
  SortGroupOutcomeSortBy
} from '@ifhms/models/feedlot';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export const NAMESPACE = '[SORT GROUP OUTCOME COPY GRID - LIST]';

// Selects-DeSelects from the given id
const select = createAction(
  `${NAMESPACE} Select`,
  props<{ outcome: Update<SortGroupOutcomeListItemDto> }>()
);

const selectSuccess = createAction(
  `${NAMESPACE} Select Success`,
  props<{ outcome: Update<SortGroupOutcomeListItemDto> }>()
);

const updateSingle = createAction(
  `${NAMESPACE} Update Single`,
  props<{ outcome: Update<SortGroupOutcomeListItemDto> }>()
);

const updateSingleSuccess = createAction(
  `${NAMESPACE} Update Single Success`,
  props<{ outcome: Update<SortGroupOutcomeListItemDto> }>()
);

const selectAll = createAction(`${NAMESPACE} Select All`);

const selectAllSuccess = createAction(
  `${NAMESPACE} Select All Success`,
  props<{ updates: Update<SortGroupOutcomeListItemDto>[] }>()
);

// Filter
const filter = createAction(
  `${NAMESPACE} Filter`,
  props<{ filter: SortGroupOutcomeFilterBy }>()
);

const filterSuccess = createAction(
  `${NAMESPACE} Filter Success`,
  props<{ filter: SortGroupOutcomeFilterBy }>()
);

// Sort
const sortBy = createAction(
  `${NAMESPACE} Sort`,
  props<{ sortBy: SortGroupOutcomeSortBy }>()
);

const sortBySuccess = createAction(
  `${NAMESPACE} Sort Success`,
  props<{ sortBy: SortGroupOutcomeSortBy }>()
);

const apply = createAction(`${NAMESPACE} Apply`);

const applySuccess = createAction(
  `${NAMESPACE} Apply Success`,
  props<{ outcomes: SortGroupOutcomeListItemDto[] }>()
);

const showCopyFromModal = createAction(
  `${NAMESPACE} Show Copy From Modal`,
  props<{ copyFrom: string[] }>()
);

const showCopyFromModalSuccess = createAction(
  `${NAMESPACE} Show Copy From Modal Success`,
  props<{ copyFrom: string[]; list: SortGroupOutcomeListItemDto[] }>()
);

const showCopyFromModalFromForm = createAction(
  `${NAMESPACE} Show Copy From Modal From Form`,
  props<{ copyFrom: string[] }>()
);

const showCopyFromModalFromFormSuccess = createAction(
  `${NAMESPACE} Show Copy From Modal From Form Success`,
  props<{ copyFrom: string[]; list: SortGroupOutcomeListItemDto[] }>()
);

const showCopyToModal = createAction(
  `${NAMESPACE} Show Copy To Modal`,
  props<{ copyTo: string }>()
);

const showCopyToModalSuccess = createAction(
  `${NAMESPACE} Show Copy To Modal Success`,
  props<{ copyTo: string; list: SortGroupOutcomeListItemDto[] }>()
);

const hideModal = createAction(`${NAMESPACE} Hide Modal`);

const error = createAction(`${NAMESPACE} Error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const SortGroupOutcomeCopyGridListActions = {
  select,
  selectSuccess,
  selectAll,
  selectAllSuccess,
  updateSingle,
  updateSingleSuccess,
  filter,
  filterSuccess,
  sortBy,
  sortBySuccess,
  apply,
  applySuccess,
  showCopyFromModal,
  showCopyFromModalSuccess,
  showCopyFromModalFromForm,
  showCopyFromModalFromFormSuccess,
  showCopyToModal,
  showCopyToModalSuccess,
  hideModal,
  error,
  reset
};
