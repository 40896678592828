import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { distinctUntilChanged, Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'sersi-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit {
  @HostBinding('class') hostClassNames = 'sersi-search-container';

  model = {};
  form = new FormGroup({});
  fields: FormlyFieldConfig[];
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Input() placeholder: Observable<string>;
  @Input() width: string;
  @Input() type: string;

  @Input() set value(value: string | null) {
    // NOTE: if form value is patched with a space at the end, input will readjust and remove it unless handled
    // if value is present but form field isn't set, we need to set it's value
    if (value?.endsWith(' ') ||
      (value && (Object.keys(this.form.value).length === 0))) {
      this.searchTerm = value;
      this.form.patchValue({ search: value });
    }
  }

  private searchTerm: string | null;

  ngOnInit(): void {
    this.model = { search: this.searchTerm };
    this.fields = this.createFields();
  }

  createFields(): FormlyFieldConfig[] {
    return [
      {
        key: 'search',
        type: 'search',
        templateOptions: {
          cancel: () => this.cancel(),
          placeholder$: this.placeholder,
          width: this.width ?? '200px',
          type: this.type ?? 'text'
        },
        hooks: {
          onInit: (field: FormlyFieldConfig): void => {
            // NOTE: this valueChange won't trigger if form value is patched with a space at the end
            field?.formControl?.valueChanges
              .pipe(
                distinctUntilChanged(
                  (a, b) => JSON.stringify(a) === JSON.stringify(b)
                ),
                untilDestroyed(this)
              )
              .subscribe((filter: string) => {
                this.search.emit(filter);
              });
          }
        }
      }
    ];
  }

  cancel(): void {
    this.form.patchValue({ search: '' });
  }
}
