import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { getLoadedSelectItems } from '@common/angular/utils';

import { AnimalStatusSelectors } from './animal-status.selectors';
import { AnimalStatusActions } from './animal-status.actions';
import * as reducer from './animal-status.reducer';

@Injectable()
export class AnimalStatusFacade {

  loaded$ = this.store.select(AnimalStatusSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(AnimalStatusSelectors.selectItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(AnimalStatusActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(AnimalStatusActions.reset());
  }

}
