import { Product } from '@ifhms/models/feedlot';

// Function to filter products from the source list, excluding those in the predefined list
export function excludePredefinedProducts(
  source: Product[] | null,
  predefinedProducts: Product[] | null
): Product[] {
  if (!source?.length) return [];
  if (!predefinedProducts?.length) return source;

  return filterAndCombineProducts(predefinedProducts, source).slice(predefinedProducts.length);
}

// Function to merge user-defined products with suggested products, ensuring uniqueness
export function mergeUserAndSuggestedProducts(
  existingProducts: Product[] = [],
  suggestedProducts: Product[] = []
): Product[] {
  const userProducts = getUserDefinedProducts(existingProducts);

  if (!userProducts.length) return suggestedProducts;
  if (!suggestedProducts.length) return userProducts;

  return filterAndCombineProducts(userProducts, suggestedProducts);
}

// Function to filter out products that are not user-defined
export function getUserDefinedProducts(products: Product[]): Product[] {
  return products.filter(product => !product.sortGroupProduct && !product.isTxProtocolProduct);
}

// Filter and combine products without duplicates
function filterAndCombineProducts(
  primaryProducts: Product[],
  secondaryProducts: Product[]
): Product[] {
  const { ids, routeKeys, productIdCounts } = buildProductIdentifiers(primaryProducts);
  const result = [...primaryProducts];

  for (const product of secondaryProducts) {
    const productId = product.productId;
    const productRouteKey = getProductRouteKey(product);
    const activeRoutesCount = product.activeRoutes?.length || 0;
    const includedCount = productId != null ? (productIdCounts.get(productId) || 0) : 0;

    // Always skip if product.id exists in primaryProducts
    if (product.id && ids.has(product.id)) {
      continue;
    }

    // Skip if the productRouteKey already exists
    if (productRouteKey && routeKeys.has(productRouteKey)) {
      continue;
    }

    // Limit the number of products with the same productId to the number of active routes
    if (productId != null && includedCount >= activeRoutesCount) {
      continue;
    }

    if (productRouteKey) {
      routeKeys.add(productRouteKey);
    }
    if (productId != null) {
      productIdCounts.set(productId, includedCount + 1);
    }
    result.push(product);
  }

  return result;
}

// Helper function to generate a unique key for a product based on productId and routeDetailId
function getProductRouteKey(product: Product): string | null {
  if (product.productId && product.routeDetailId) {
    return `${product.productId}_${product.routeDetailId}`;
  }
  return null;
}

// Helper function to build identifiers from a list of products
function buildProductIdentifiers(products: Product[]): {
  ids: Set<string>;
  routeKeys: Set<string>;
  productIdCounts: Map<string, number>;
} {
  const ids = new Set<string>();
  const routeKeys = new Set<string>();
  const productIdCounts = new Map<string, number>();

  for (const product of products) {
    if (product.id) ids.add(product.id);

    const productRouteKey = getProductRouteKey(product);
    if (productRouteKey) routeKeys.add(productRouteKey);

    const productId = product.productId;
    if (productId != null) {
      productIdCounts.set(productId, (productIdCounts.get(productId) || 0) + 1);
    }
  }

  return { ids, routeKeys, productIdCounts };
}