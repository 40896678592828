import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '..';
import * as Reducer from './left.reducer';

const selectState = createSelector(
  selectFeatureState,
  (state: State) => state.left
);

const selectLoading = createSelector(selectState, Reducer.getLoading);

const selectLoaded = createSelector(selectState, Reducer.getLoaded);

const selectAll = createSelector(selectState, Reducer.getAll);

const totalLength = createSelector(selectState, Reducer.getTotal);

const selectFilter = createSelector(selectState, Reducer.getFilter);

const selectFilterApplied = createSelector(
  selectState,
  Reducer.getFilterApplied
);

const selectAllSelected = createSelector(selectAll, (animals) =>
  animals.filter((animal) => animal.selected)
);

const selectedLength = createSelector(
  selectAllSelected,
  (animals) => animals.length
);

export const availableLength = createSelector(
  selectAll,
  (entities) => entities.filter(entity => entity.isAvailable).length
);

export const CattleSelectorLeftSelectors = {
  selectState,
  selectLoading,
  selectLoaded,
  selectAll,
  totalLength,
  selectFilter,
  selectFilterApplied,
  selectAllSelected,
  selectedLength,
  availableLength
};
