import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UpcScaleService } from '@ifhms/common/angular/data-access/upc-api';
import { DeviceConnectionStatusEnum } from '@ifhms/models/feedlot';
import { HubConnectionState } from '@microsoft/signalr';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Observable, map } from 'rxjs';
import { FormWeightFieldComponent } from '../form-weight-field/form-weight-field.component';

@UntilDestroy()
@Component({
  selector: 'ifhms-upc-scale-shared-form',
  templateUrl: './upc-scale-shared-form.component.html',
  styleUrls: ['./upc-scale-shared-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcScaleSharedFormComponent implements OnInit {

  form = new FormGroup({});
  model: any;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];
  readonly DeviceConnectionStatusEnum = DeviceConnectionStatusEnum;

  scaleConnectionStatus$: Observable<string>;

  constructor(
    private upcScaleService: UpcScaleService) {
  }

  ngOnInit(): void {
    this.getConnectionStatus();
    this.setFields();
  }

  private getConnectionStatus(): void {
    this.scaleConnectionStatus$ = this.upcScaleService.connectionState$.pipe(
      map((state) => {
        switch (state) {
          case HubConnectionState.Connected:
            return DeviceConnectionStatusEnum.Connected;
          case HubConnectionState.Disconnected:
            return DeviceConnectionStatusEnum.Disconnected;
          default:
            return DeviceConnectionStatusEnum.Disconnected;
        }
      })
    );
  }

  private setFields(): void {

    this.fields = [{
      fieldGroupClassName: 'scale-wrapper',
      fieldGroup: [
        this.setScale()
      ]
    }];

  }

  private setScale(): FormlyFieldConfig {
    return {
      type: FormWeightFieldComponent,
      className: 'upc-scale',
      props: {
        readScaleData: true,
        textIconSuffix: 'lbs'
      }
    };
  }

}
