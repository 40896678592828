import { Injectable } from '@angular/core';
import { AnimalSelectorItemDto } from '@ifhms/models/feedlot';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { map, tap, withLatestFrom } from 'rxjs';
import { CattleSelectorLeftActions } from '../left/left.actions';
import { CattleSelectorRightActions } from './right.actions';
import { CattleSelectorRightFacade } from './right.facade';

@Injectable()
export class CattleSelectorRightEffects {
  translateScope = 'feedlot.state.feedlot-list-effects';

  replace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorRightActions.replace),
      map(({ animals }) =>
        CattleSelectorRightActions.replaceSuccess({ animals })
      )
    )
  );

  replaceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CattleSelectorRightActions.replaceSuccess),
        tap(() => {
          // TODO
        })
      ),
    { dispatch: false }
  );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorRightActions.add),
      withLatestFrom(this.rightFacade.entities$),
      map(([action, entities]) => {
        const list: AnimalSelectorItemDto[] = [];
        let existFlag = false;
        action.animals.forEach((animal) => {
          if (!(animal.id in entities)) {
            list.push(animal);
          } else existFlag = true;
        });
        if (existFlag) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail:
              'Some selected animals already exists in the selected section!'
          });
        }
        return CattleSelectorRightActions.addSuccess({
          animals: list
        });
      })
    )
  );
  addSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CattleSelectorRightActions.addSuccess),
        tap(() => {
          // TODO
        })
      ),
    { dispatch: false }
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorRightActions.remove),
      withLatestFrom(this.rightFacade.selected$),
      map(([, animals]) =>
        CattleSelectorRightActions.removeSuccess({ animals })
      )
    )
  );
  removeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorRightActions.removeSuccess),
      map(({ animals }) => CattleSelectorLeftActions.add({ animals }))
    )
  );

  select$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorRightActions.select),
      map(({ animal }) => CattleSelectorRightActions.selectSuccess({ animal }))
    )
  );
  selectSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CattleSelectorRightActions.selectSuccess),
        tap(() => {
          // TODO
        })
      ),
    { dispatch: false }
  );

  filter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorRightActions.filter),
      map((action) =>
        CattleSelectorRightActions.filterSuccess({ filter: action.filter })
      )
    )
  );

  filterSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CattleSelectorRightActions.filterSuccess),
        tap(() => {
          // TODO
        })
      ),
    { dispatch: false }
  );

  clearFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorRightActions.clearFilter),
      map(() => CattleSelectorRightActions.clearFilterSuccess())
    )
  );
  clearFilterSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CattleSelectorRightActions.clearFilterSuccess),
        tap(() => {
          // TODO
        })
      ),
    { dispatch: false }
  );

  selectAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorRightActions.selectAll),
      withLatestFrom(
        this.rightFacade.list$,
        this.rightFacade.selectedLength$,
        this.rightFacade.totalLength$
      ),
      map(([, list, selected, total]) => {
        const select = total ? selected != total : false;
        return CattleSelectorRightActions.selectAllSuccess({
          updates: list.map((x) => ({
            ...x,
            changes: {
              selected: select
            }
          }))
        });
      })
    )
  );
  selectAllSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CattleSelectorRightActions.addSuccess),
        tap(() => {
          // TODO
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private rightFacade: CattleSelectorRightFacade,
    public translateService: TranslocoService,
    private messageService: MessageService
  ) {}
}
