import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { FEATURE_NAME } from '../../+state';
import { SortGroupFormComponent } from '../sort-group-form/sort-group-form.component';
import { SortGroupOutcomeDetailViewFacade } from '../../+state/sort-group-outcome-detail-view/sort-group-outcome-detail-view.facade';

@UntilDestroy()
@Component({
  selector: 'ifhms-sort-group-outcome-view-modal',
  templateUrl: './sort-group-outcome-view-modal.component.html',
  styleUrls: ['./sort-group-outcome-view-modal.component.scss']
})
export class SortGroupOutcomeViewModalComponent implements OnInit {
  readonly translateScope = `${FEATURE_NAME}.components.sort-group-form-modal`;

  @Input() hasTagsInputs: boolean;

  display = false;
  loading$: Observable<boolean>;

  formlyForm: SortGroupFormComponent | null;

  constructor(
    public sortGroupOutcomeDetailViewFacade: SortGroupOutcomeDetailViewFacade,
    private cdr: ChangeDetectorRef
  ) {
    this.loading$ = this.sortGroupOutcomeDetailViewFacade.isLoading$;
  }
  ngOnInit(): void {
    this.sortGroupOutcomeDetailViewFacade.modal$
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.display = val;
        this.formlyForm?.reset();
        this.cdr.detectChanges();
      });
  }

  onClose(): void {
    this.sortGroupOutcomeDetailViewFacade.reset();
  }
}
