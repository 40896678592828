import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UpcThermometerService } from '@ifhms/common/angular/data-access/upc-api';
import { DeviceConnectionStatusEnum } from '@ifhms/models/feedlot';
import { HubConnectionState } from '@microsoft/signalr';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Observable, map } from 'rxjs';
import { FormTemperatureFieldComponent } from '../form-temperature-field/form-temperature-field.component';

@Component({
  selector: 'ifhms-upc-thermometer-shared-form',
  templateUrl: './upc-thermometer-shared-form.component.html',
  styleUrls: ['./upc-thermometer-shared-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcThermometerSharedFormComponent implements OnInit {

  readonly DeviceConnectionStatusEnum = DeviceConnectionStatusEnum;
  form = new FormGroup({});
  model: any;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];

  thermometerConnectionStatus$: Observable<string>;

  constructor(
    private upcThermometerService: UpcThermometerService) {
  }

  ngOnInit(): void {
    this.getConnectionStatus();
    this.setFields();
  }

  private getConnectionStatus(): void {
    this.thermometerConnectionStatus$ = this.upcThermometerService.connectionState$.pipe(
      map((state) => {
        switch (state) {
          case HubConnectionState.Connected:
            return DeviceConnectionStatusEnum.Connected;
          case HubConnectionState.Disconnected:
            return DeviceConnectionStatusEnum.Disconnected;
          default:
            return DeviceConnectionStatusEnum.Disconnected;
        }
      })
    );
  }

  private setFields(): void {

    this.fields = [{
      fieldGroup: [
        this.setThermometer()
      ]
    }];

  }

  private setThermometer(): FormlyFieldConfig {
    return {
      type: FormTemperatureFieldComponent,
      className: 'upc-thermometer',
      props: {
        readTempData: true,
        textIconSuffix: 'F'
      }
    };
  }

}

