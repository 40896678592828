import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  PenCreateDto,
  PenDto,
  PenListRequestDto,
  PenListResultDto,
  PenSelectListItemDto,
  PenUpdateDto
} from '@ifhms/models/feedlot';
import { map, Observable } from 'rxjs';

import { AH_FMS_FEEDLOT_DOMAIN_API } from '../tokens';

@Injectable()
export class PensService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {}

  get(feedlotId: string, penId: string): Observable<PenDto> {
    return this.http.get<PenDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Pens/${penId}`).pipe(
      map(
        (x) => {
          if (x.penDetails?.length > 0) {
            for (let i = 0; i < x.penDetails.length; i++) {
              if (x.penDetails[i].effortFromDate) {
                x.penDetails[i].effortFromDate = new Date(x.penDetails[i].effortFromDate);
              }
              if (x.penDetails[i].effortToDate) {
                x.penDetails[i].effortToDate = new Date(x.penDetails[i].effortToDate);
              }

            }
          }
          return x;
        }
      )
    );
  }

  create(feedlotId: string, dto: PenCreateDto): Observable<PenDto> {
    return this.http.post<PenDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Pens`, dto);
  }

  update(feedlotId: string, dto: PenUpdateDto): Observable<PenDto> {
    return this.http.put<PenDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Pens`, dto);
  }

  activate(feedlotId: string, penId: string): Observable<PenDto> {
    return this.http.put<PenDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Pens/${penId}/Activate`, {});
  }

  deactivate(feedlotId: string, penId: string): Observable<PenDto> {
    return this.http.put<PenDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Pens/${penId}/Deactivate`, {});
  }

  getList(feedlotId: string, dto: PenListRequestDto): Observable<PenListResultDto> {
    const options = {
      params: new HttpParams({ fromObject: <any>dto })
    };
    return this.http.get<PenListResultDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Pens`, options);
  }

  selectList(feedlotId: string): Observable<PenSelectListItemDto[]> {
    return this.http.get<PenSelectListItemDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/Pens/SelectList`);
  }

  isPenNumberValid(feedlotId: string, penNumber: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        penNumber: penNumber.toString(),
        feedlotId: feedlotId
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Feedlot/${feedlotId}/Pens/IsPenNumberValid`, options);
  }
}
