import { Injectable } from '@angular/core';
import { AnimalsService } from '@ifhms/common/angular/data-access/feedlot-api';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, withLatestFrom } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';

import { AnimalAvailabilityActions } from './animal-availability.actions';

@Injectable()
export class AnimalAvailabilityEffects {
  geAnimalAvailability$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnimalAvailabilityActions.getAnimalAvailability),
      withLatestFrom(this.feedlotFacade.feedlotId$),
      exhaustMap(([action, feedlotId]) => {
        return this.animalService
          .getAnimalAvailability(feedlotId, action.animalId)
          .pipe(
            map(animalAvailability => AnimalAvailabilityActions.getAnimalAvailabilitySuccess({ animalAvailability })),
            catchError((err) => of(AnimalAvailabilityActions.error(err.toString())))
          )
      })
    )
  );

  constructor(
    private actions$: Actions,
    private animalService: AnimalsService,
    private feedlotFacade: FeedlotFacade
  ) {
  }

}
