import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AbstractTranslationComponent, scopeLoader } from '@common/angular/translation';
import { UpcDosingGunsFacade } from '@ifhms/common/angular/upc/shared';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyTypesEnum } from '@sersi/angular/formly/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs/internal/Observable';

export interface ValidateDosingGunFormModel {
  macAddress: string | null;
}
@UntilDestroy()
@Component({
  selector: 'ifhms-validate-dosing-gun-modal',
  templateUrl: './validate-dosing-gun-form.component.html',
  styleUrls: ['./validate-dosing-gun-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'dosingguns',
        alias: 'dosingguns',
        loader: scopeLoader((lang: string, root: string) =>
          import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class ValidateDosingGunFormComponent extends AbstractTranslationComponent implements OnInit{

  readonly translationNamespace = 'components.validate-dosing-gun-form';

  //in ml
  readonly MIN_DOSE = 6;
  readonly MAX_DOSE = 15;

  form = new UntypedFormGroup({});
  fields: FormlyFieldConfig[];
  options: FormlyFormOptions = {};
  model: ValidateDosingGunFormModel;

  loading$: Observable<boolean> = this.dosingGunsFacade.loading$;
  error$: Observable<string | null | undefined> = this.dosingGunsFacade.error$;
  dose: number;

  constructor(
    private dosingGunsFacade: UpcDosingGunsFacade,
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef
  ) {
    super();
  }

  override onTranslationInit(): void {
    this.setFields();
    this.initValidation();

    this.error$.pipe(untilDestroyed(this))
      .subscribe(error => {
        if(error){
          this.dialogRef.close({ validated: false });
          this.cdr.detectChanges();
          this.dosingGunsFacade.resetError();
        }
      })
  }

  private setFields(): void {
    this.fields = [
      {
        fieldGroupClassName: 'validate-dosing-gun-form',
        fieldGroup: [
          this.setInstructionsField(),
          this.setDoseField()
        ]
      }];
  }

  private setInstructionsField(): FormlyFieldConfig {
    return {
      key: 'instructions',
      type: FormlyTypesEnum.TEXT_READONLY,
      defaultValue: this.getTranslation('validate-instructions'),
      props: {
        label: this.getTranslation('validate-instructions-label')
      }
    };
  }

  private setDoseField(): FormlyFieldConfig {
    return {
      key: 'dose',
      type: FormlyTypesEnum.NUMBER_INPUT,
      props: {
        label: this.getTranslation('dose-label'),
        placeholder: this.getTranslation('dose-placeholder'),
        required: true
      },
      validation: {
        messages: {
          doseValid: this.getTranslation('dose-validator-message')
        }
      }
    };
  }

  private initValidation(): void {
    this.dose = this.getRandomNumber(this.MIN_DOSE, this.MAX_DOSE);
    this.dosingGunsFacade.setOneTimeDose(this.config.data.macAddress, this.dose)
  }

  private getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  onSubmit():void {
    if(this.form.value.dose == this.dose){
      this.dialogRef.close({ validated: true });
      this.dosingGunsFacade.validateGun(this.config.data.gunId);
    }

    const doseControl = this.form.get('dose');
    doseControl?.setErrors({ doseValid: true });
    doseControl?.markAsTouched();
  }

  cancel():void {
    this.dialogRef.close({ validated: false });
  }

}
