<ng-select
        class="sersi-single-select ng-select-control"
        appendTo="body"
        [bindValue]="props['bindValue']"
        [virtualScroll]="props['virtualScroll']"
        [items]="activeItems$ | async"
        [attr.data-ele]="props['attrDataEle'] || key"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [bindLabel]="props['optionLabel']"
        [placeholder]="(props['placeholder$'] | async) || props.placeholder || ''"
        [clearable]="props['showClear']"
        [searchFn]="customSearchFn"
>
    <!-- Selected value template -->
    <ng-template ng-label-tmp let-item="item">
        <sersi-select-item [item]="item" [options]="allItems" [viewMode]="props['selectedItemLabel']" />
    </ng-template>

    <!-- Option item template -->
    <ng-template ng-option-tmp let-item="item">
        <sersi-select-item [disabled]="!!props.disabled" [item]="item" [viewMode]="props['optionsLabel']" />
    </ng-template>
</ng-select>