import { Injectable } from '@angular/core';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Store } from '@ngrx/store';
import { map, Observable, of } from 'rxjs';
import * as tagColorsReducer from './tag-colors.reducer';
import { TagColorsSelectors } from './tag-colors.selectors';
import { TagColorsActions } from './tag-colors.actions';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class TagColorsFacade {

  loaded$ = this.store.select(TagColorsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(TagColorsSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<tagColorsReducer.State>) {}

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(TagColorsActions.get());
    return this.items$;
  }

  getTagColorByCode(tagCode: string): Observable<string | null> {
    if (!tagCode) return of(null);
    return this.items$.pipe(
      map((items: SersiSelectListItem[]) => {
        const tagEntry = items.find(item => item.code.toLowerCase() === tagCode.toLowerCase());
        return tagEntry?.attributes?.['hexCode'] as string || null;
      })
    )
  }

  reset(): void {
    this.store.dispatch(TagColorsActions.reset());
  }

}
