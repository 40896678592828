export interface PenDto {
  id: string;
  penNumber: string;
  altPenId?: string;
  cciaPremiseId?: string;
  cciaPremiseName?: string;
  description?: string;
  capacity?: number;
  size?: number;
  sortOrder?: number;
  bunkSpace?: number;
  address?: string;
  sizeUnitOfMeasureId?: string;
  sizeUnitOfMeasureUnitCode?: string;
  sizeUnitOfMeasureDescription?: string;
  bunkSpaceUnitOfMeasureId?: string;
  bunkSpaceUnitOfMeasureUnitCode?: string;
  bunkSpaceUnitOfMeasureDescription?: string;
  numberOfAnimals?: string;
  status?: PenStatus;
  hasActiveAnimals: boolean;
  isSelectedOnWorkOrder: boolean;
  isSelectedOnEvent: boolean;
  isOnlyHospitalPen: boolean;
  penDetails: PenDetails[];
}

export enum PenStatus {
  Active = 101,
  Inactive = 102
}

export interface Facility {
  id: string;
  code: string;
  description?: string;
  comment?: string;
}

export interface PensType {
  id: string,
  code: string;
  description: string;
}

export interface PenDetails {
  effortFromDate: Date;
  effortToDate: Date;
  penType: PensType;
  facility: Facility[];
}