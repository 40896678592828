import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ifhms-cattle-selector-checkbox',
  templateUrl: './cattle-selector-checkbox.component.html',
  styleUrls: ['./cattle-selector-checkbox.component.scss']
})
export class CattleSelectorCheckboxComponent {
  @Input() checked: boolean | null;
  @Input() disabled: boolean;
  @Output() check: EventEmitter<any> = new EventEmitter();

}
