import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SersiSelectListItem } from '@sersi/angular/formly/core';

import * as PenTypesReducer from './pen-types.reducer';
import { PenTypesSelectors as PenTypesSelectors } from './pen-types.selectors';
import { PenTypesActions } from './pen-types.actions';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class PenTypesFacade {

  loaded$ = this.store.select(PenTypesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(PenTypesSelectors.selectPenTypes), this.loaded$);

  constructor(private store: Store<PenTypesReducer.State>) {}

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(PenTypesActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(PenTypesActions.reset());
  }
}
