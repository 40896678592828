import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { UpcGlobalSettingsComponent } from './containers/upc-global-settings';
import { UpcScaleSettingsComponent } from './containers/upc-scale-settings/upc-scale-settings.component';
import { UpcDosingGunSettingsComponent } from './containers/upc-dosing-gun-settings/upc-dosing-gun-settings.component';
import { UpcRfidSettingsComponent } from './containers/upc-rfid-settings/upc-rfid-settings.component';
import {
  UpcThermometerSettingsComponent
} from './containers/upc-thermometer-settings/upc-thermometer-settings.component';
import {
  UpcGateControllerSettingsComponent
} from './containers/upc-gate-controller-settings/upc-gate-controller-settings.component';

export const ROUTES: Route[] = [
  {
    path: '',
    component: UpcGlobalSettingsComponent
  },
  {
    path: 'global-settings',
    component: UpcGlobalSettingsComponent
  },
  {
    path: 'scale-settings',
    component: UpcScaleSettingsComponent
  },
  {
    path: 'rfid-settings',
    component: UpcRfidSettingsComponent
  },
  {
    path: 'thermometer-settings',
    component: UpcThermometerSettingsComponent
  },
  {
    path: 'gate-settings',
    component: UpcGateControllerSettingsComponent
  },
  {
    path: 'dosing-guns-settings',
    component: UpcDosingGunSettingsComponent
  }

];

@NgModule({
  imports: [
    RouterModule.forChild(ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class FeedlotSettingsUpcRoutingModule {

}