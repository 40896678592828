import { ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { filter } from 'rxjs';

import { AbstractTranslationComponent, scopeLoader } from '@common/angular/translation';
import { AnimalAvailabilityDto, AnimalUnavailabilityReason } from '@ifhms/models/feedlot';
import { getFeedlotData } from '@ifhms/feedlot/shared/core/utils';

import { AnimalAvailabilityFacade } from '../../+state';
import { AnimalAvailabilityDisplayModel } from '../../interfaces';

@UntilDestroy()
@Component({
  selector: 'ifhms-animal-availability-dialog',
  templateUrl: './animal-availability-dialog.component.html',
  styleUrls: ['./animal-availability-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'animal-availability',
        alias: 'animal-availability',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class AnimalAvailabilityDialogComponent extends AbstractTranslationComponent implements OnDestroy {
  protected override translationNamespace = 'components.animal-availability-dialog';

  isLoading$ = this.animalAvailabilityFacade.isLoading$;
  animalAvailabilityDisplayModel: AnimalAvailabilityDisplayModel | null = null;

  constructor(
    private dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private router: Router,
    private animalAvailabilityFacade: AnimalAvailabilityFacade
  ) {
    super();
  }

  override onTranslationInit(): void {
    const props = this.config.data;

    if (!props.animalAvailabilityDetails) {
      this.fetchAnimalAvailabilityDetails();
      return;
    }

    this.animalAvailabilityDisplayModel = this.getAnimalAvailabilityDisplayModelFromDto(props.animalAvailabilityDetails);
  }

  override ngOnDestroy(): void {
    this.animalAvailabilityFacade.reset();
  }

  private getAnimalAvailabilityDisplayModelFromDto(dto: AnimalAvailabilityDto): AnimalAvailabilityDisplayModel {
    if (dto.isAvailable) {
      return <AnimalAvailabilityDisplayModel>{
        message: this.getTranslation('available'),
        link: ''
      };
    }

    const slug = getFeedlotData(this.router)['slug'];
    let link = '';
    let type = '';

    switch (dto.animalUnavailabilityReason) {
      case AnimalUnavailabilityReason.PostMortemEvent:
        type = 'events-post-mortem';
        link = `/events/postmortem/${dto.animalUnavailabilityEventId}`;
        break;

      case AnimalUnavailabilityReason.TreatmentEvent:
        type = 'events-treatment';
        link = `/events/treatment/${dto.animalUnavailabilityEventId}`;
        break;

      case AnimalUnavailabilityReason.ArrivalEvent:
        type = 'events-arrival';
        link = `/events/arrival/${dto.animalUnavailabilityEventId}/${dto.animalUnavailabilityWorkOrderId}`;
        break;

      case AnimalUnavailabilityReason.RehandlingWorkOrder:
        type = 'work-orders-rehandling';
        link = `/work-orders/rehandling/${dto.animalUnavailabilityWorkOrderId}`;
        break;

      case AnimalUnavailabilityReason.RehandlingEvent:
        type = 'events-rehandling';
        link = `/events/rehandling/${dto.animalUnavailabilityEventId}/${dto.animalUnavailabilityWorkOrderId}`;
        break;

      case AnimalUnavailabilityReason.RailWorkOrder:
        type = 'work-orders-rail';
        link = `/work-orders/rail/${dto.animalUnavailabilityWorkOrderId}`;
        break;

      case AnimalUnavailabilityReason.RailEvent:
        type = 'events-rail';
        link = `/events/rail/${dto.animalUnavailabilityEventId}/${dto.animalUnavailabilityWorkOrderId}`;
        break;

      case AnimalUnavailabilityReason.ShipmentWorkOrder:
        type = 'work-orders-shipment';
        link = `/work-orders/shipment/${dto.animalUnavailabilityWorkOrderId}`;
        break;

      case AnimalUnavailabilityReason.MovementWorkOrder:
        type = 'work-orders-movement';
        link = `/work-orders/movement/${dto.animalUnavailabilityWorkOrderId}`;
        break;

      case AnimalUnavailabilityReason.LotTransferWorkOrder :
        type = 'work-orders-lot-transfer';
        link = `/work-orders/lottransfer/${dto.animalUnavailabilityWorkOrderId}`;
        break;
      case AnimalUnavailabilityReason.Died:
        type = 'died';
        break;
      case AnimalUnavailabilityReason.Railed:
        type = 'railed';
        break;
      case AnimalUnavailabilityReason.Shipped:
        type = 'shipped';
        break;
    }

    return <AnimalAvailabilityDisplayModel>{
      message: this.getTranslation('animal-message-base'),
      type: this.getTranslation(type),
      link: link ? `feedlot/${slug}${link}` : null
    };
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  private fetchAnimalAvailabilityDetails(): void {
    const animalId = this.config.data.animalId;

    this.animalAvailabilityFacade.getAnimalAvailability(animalId);
    this.animalAvailabilityFacade.animalAvailability$.pipe(
      filter(x => !!x),
      untilDestroyed(this)
    ).subscribe((animalAvailability) => {
      this.animalAvailabilityDisplayModel = this.getAnimalAvailabilityDisplayModelFromDto(animalAvailability);
      this.cdr.markForCheck();
    });
  }
}
