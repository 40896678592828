import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import * as reducer from './risk-categories.reducer';
import { RiskCategoriesSelectors } from './risk-categories.selectors';
import { RiskCategoriesActions } from './risk-categories.actions';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class RiskCategoriesFacade {

  loaded$ = this.store.select(RiskCategoriesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(RiskCategoriesSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(RiskCategoriesActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(RiskCategoriesActions.reset());
  }
}
