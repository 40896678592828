import { FeedlotFeaturePermission } from '../interfaces';

export const WorkOrdersPermissionList = [
  FeedlotFeaturePermission.WorkOrdersArrivalRead,FeedlotFeaturePermission.WorkOrdersLotTransferRead,
  FeedlotFeaturePermission.WorkOrdersMovementRead,FeedlotFeaturePermission.WorkOrdersRailRead,
  FeedlotFeaturePermission.WorkOrdersReHandlingRead,FeedlotFeaturePermission.WorkOrdersShipmentRead
];
  
export const EventsPermissionList = [
  FeedlotFeaturePermission.EventsArrivalRead,FeedlotFeaturePermission.EventsPostMortemRead,
  FeedlotFeaturePermission.EventsRailRead,FeedlotFeaturePermission.EventsReHandlingRead,
  FeedlotFeaturePermission.EventsTreatmentRead
];
  
export const AnimalsPermissionList = [
  FeedlotFeaturePermission.AnimalsRead
];
  
export const LotsPermissionList = [
  FeedlotFeaturePermission.LotsRead
];
  
export const ReportsPermissionList = [
  FeedlotFeaturePermission.ReportsEventDetails,
  FeedlotFeaturePermission.ReportsHospitalOccupancy,
  FeedlotFeaturePermission.ReportsPenAndLotInventory,
  FeedlotFeaturePermission.ReportsWithdrawal,
  FeedlotFeaturePermission.ReportsArrivalWorkOrder,
  FeedlotFeaturePermission.ReportsLotTransferWorkOrder,
  FeedlotFeaturePermission.ReportsMovementWorkOrder,
  FeedlotFeaturePermission.ReportsRailWorkOrder,
  FeedlotFeaturePermission.ReportsRehandlingWorkOrder,
  FeedlotFeaturePermission.ReportsShipmentWorkOrder
];
  
export const PensPermissionList = [
  FeedlotFeaturePermission.PensRead
];