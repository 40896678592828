import { Component, ViewEncapsulation } from '@angular/core';
import { DeviceConnectionStatusEnum } from '@ifhms/models/feedlot';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ifhms-feedlot-upc-form-connection-receipt-status-label-form-field',
  templateUrl: './connection-status-label-form-field.component.html',
  styleUrls: ['./connection-status-label-form-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConnectionStatusLabelFormFieldComponent extends FieldType<FieldTypeConfig> {
  translateScope = 'connection-status'
  protected readonly DeviceConnectionStatusEnum = DeviceConnectionStatusEnum;

  formatConnectString(str: any): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
