import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UpcBaseDeviceService } from './upc-base-device.service';
import { DeviceType } from '@ifhms/models/feedlot';
import { HubConnectionState } from '@microsoft/signalr';

@Injectable()
export class UpcThermometerService extends UpcBaseDeviceService {
  private _thermometerData = new BehaviorSubject<any>(null);
  thermometerData$ = this._thermometerData.asObservable();
  private thermometerInitialized: boolean|null = null;

  constructor(injector: Injector) {
    super(DeviceType.Thermometer,injector);
  }

  async initDevice(): Promise<boolean> {
    if(!this.thermometerInitialized){
      this.thermometerInitialized = await this.initConnection();
      if(this.thermometerInitialized) await this.subscribeToTemperatureChange();
    }
    return this.thermometerInitialized;
  }

  private async subscribeToTemperatureChange(): Promise<void> {
    if (this.hubConnection?.state !== HubConnectionState.Connected) {
      return;
    }
    await this.getCalculatedTemp();

    this.hubConnection?.on('TemperatureChanged', (data: number) => {
      this._thermometerData.next(data);
    });
  }

  async getCalculatedTemp(): Promise<number | null> {
    const isConnected = await this.ensureHubConnection();
    if (!isConnected) return null;
    try {
      const temperature =  await this.hubConnection?.invoke<number | null>('GetCalculatedTemp')
        .catch((err) => console.log(err))
          ?? null;
      this._thermometerData.next(temperature);
      return temperature;
    } catch (err) {
      console.error('Error getting temperature:', err);
      return null;
    }
  }
}