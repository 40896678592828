import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { SearchInputTypeComponent } from '@sersi/angular/formly/types/search-input';
import { SearchComponent } from './components/search/search.component';

@NgModule({
  imports: [
    CommonModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'search',
          component: SearchInputTypeComponent
        }
      ]
    })
  ],
  declarations: [SearchComponent],
  exports: [SearchComponent]
})
export class SersiAngularUiSearchModule {}
