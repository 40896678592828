import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { QuickSearchRequestDto, QuickSearchResultDto } from '@ifhms/models/feedlot';

import { AH_FMS_FEEDLOT_DOMAIN_API } from '../tokens';

@Injectable()
export class QuickSearchService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {}

  get(feedlotId: string, request: QuickSearchRequestDto): Observable<QuickSearchResultDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<QuickSearchResultDto>(`${this.apiUrl}/Feedlot/${feedlotId}/QuickSearch`, {
      params
    });
  }

}
