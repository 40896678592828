import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import * as reducer from './coat-colour.reducer';
import { CoatColoursActions } from './coat-colour.actions';
import { CoatColoursSelectors } from './coat-colour.selectors';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class CoatColourFacade {

  loaded$ = this.store.select(CoatColoursSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(CoatColoursSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(CoatColoursActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(CoatColoursActions.reset());
  }
}
