import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { scopeLoader } from '@common/angular/translation';

import { updateFieldSelectItems } from '@common/angular/utils';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { AnimalSelectorRequestDto } from '@ifhms/models/feedlot';
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyTypesEnum } from '@sersi/angular/formly/core';
import { MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable } from 'rxjs';

import { Facade, FEATURE_NAME } from '../../+state';
import { CattleSelectorWOAttributes } from '../../interfaces';

@UntilDestroy()
@Component({
  selector: 'ifhms-cattle-selector-table-search',
  templateUrl: './cattle-selector-table-search.component.html',
  styleUrls: ['./cattle-selector-table-search.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: FEATURE_NAME,
        alias: FEATURE_NAME,
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class CattleSelectorTableSearchComponent implements OnInit, OnChanges {
  translateScope = `${FEATURE_NAME}.components.cattle-selector-table-search`;

  items: MenuItem[];
  @ViewChild('search') search: ElementRef;
  @ViewChild('menu') menu: OverlayPanel;

  form = new UntypedFormGroup({});
  fields: FormlyFieldConfig[] = [];
  model = <AnimalSelectorRequestDto>{};
  modelSnapshot = <AnimalSelectorRequestDto>{};

  @Input() filter: AnimalSelectorRequestDto | null;
  @Input() facade: Facade;
  @Input() woAttributes: CattleSelectorWOAttributes;

  constructor(
    private referenceDataFacade: ReferenceDataFacade,
    private translateService: TranslocoService
  ) {
  }

  ngOnInit(): void {
    this.fields = [
      {
        fieldGroupClassName: 'locations-select-list',
        props: { label: 'Search Settings' },
        fieldGroup: [
          this.setCurrentPensSelectList(),
          this.setHomePensSelectList(),
          this.setLotsSelectList(),
          this.setLotsSubGroupSelectList(),
          this.setNationalId(),
          this.setNormalizedTagNumber()
        ]
      }
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filter'] && this.filter) {
      this.modelSnapshot = {
        ...this.filter
      };
      this.reset();
    }
  }

  toggleSearch(event: Event): void {
    this.menu.show(event, this.search.nativeElement);
    event.stopPropagation();
  }

  onApply(): void {
    this.facade.filter({
      ...this.model,
      ...this.woAttributes,
      workOrderId: this.woAttributes.isDraft ? null : this.woAttributes?.workOrderId
    });
    this.menu.hide();
  }

  getTranslation(key: string): Observable<string> {
    return this.translateService.selectTranslate(
      `${this.translateScope}.${key}`
    );
  }

  clear(): void {
    this.facade.filter(<AnimalSelectorRequestDto>{});
  }

  reset = (): void => {
    this.model = {
      ...this.modelSnapshot
    };
    this.form.markAsPristine();
    this.form.markAsUntouched();
  };

  private setCurrentPensSelectList(): FormlyFieldConfig {
    return {
      key: 'currentPenId',
      type: FormlyTypesEnum.SINGLE_SELECT,
      expressions: {
        'props.label': this.getTranslation('current-pen')
      },
      props: {
        items$: this.facade.selectedAnimalsCurrentPen$,
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE',
        preventOverlayClickEvent: true
      }
    };
  }

  private setHomePensSelectList(): FormlyFieldConfig {
    return {
      key: 'homePenId',
      type: FormlyTypesEnum.SINGLE_SELECT,
      expressions: {
        'props.label': this.getTranslation('home-pen')
      },
      props: {
        items$: this.facade.selectedAnimalsLocationTypes$,
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE',
        preventOverlayClickEvent: true
      }
    };
  }

  private setLotsSelectList(): FormlyFieldConfig {
    return {
      key: 'lotId',
      type: FormlyTypesEnum.SINGLE_SELECT,
      expressions: {
        'props.label': this.getTranslation('lot')
      },
      props: {
        items$: this.facade.selectedAnimalsLots$,
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE',
        preventOverlayClickEvent: true
      }
    };
  }

  private setLotsSubGroupSelectList(): FormlyFieldConfig {
    return {
      key: 'subGroupId',
      type: FormlyTypesEnum.SINGLE_SELECT,
      expressions: {
        'props.label': this.getTranslation('sub-group'),
        'props.disabled': (): boolean => !this.model.lotId
      },
      props: {
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE',
        preventOverlayClickEvent: true
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.form
            ?.get('lotId')
            ?.valueChanges.pipe(untilDestroyed(this))
            .subscribe((value: string) => {
              updateFieldSelectItems(field, this.referenceDataFacade.getLotsSubGroupByLotId(value));
            });
        }
      }
    };
  }

  private setNationalId(): FormlyFieldConfig {
    return {
      key: 'nationalId',
      type: FormlyTypesEnum.NUMBER_INPUT,
      expressions: {
        'props.label': this.getTranslation('national-id')
      },
      props: {
        maxFractionDigits: 0,
        min: 0,
        max: Infinity
      }
    };
  }

  private setNormalizedTagNumber(): FormlyFieldConfig {
    return {
      key: 'normalizedTagNumber',
      type: FormlyTypesEnum.TEXT_INPUT,
      expressions: {
        'props.label': this.getTranslation('normalized-tag-number')
      },
      props: {
        maxFractionDigits: 0
      }
    };
  }
}
