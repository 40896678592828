import { FeedlotListDto } from '@ifhms/models/feedlot';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[FEEDLOTS - LIST]';

const get = createAction(
  `${NAMESPACE} Get`,
  props<{ forceReload: boolean }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ feedlots: FeedlotListDto }>()
);

const getSuccessCached = createAction(`${NAMESPACE} Get Success Cached`);

const filter = createAction(`${NAMESPACE} Filter`, props<{ filter: any }>());

const error = createAction(`${NAMESPACE} Error`);

const resetFilters = createAction(`${NAMESPACE} Reset Filters`);

const reset = createAction(`${NAMESPACE} Reset`);

export const feedlotsActions = {
  get,
  getSuccess,
  getSuccessCached,
  error,
  reset,
  resetFilters,
  filter
};
