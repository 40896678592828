import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToString'
})
export class DateToStringPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(date: Date | null | undefined, format = 'yyyy-MM-dd'): string | null {
    return (date && date instanceof Date && !isNaN(date.getDate())) 
      ? this.datePipe.transform(date, format) as string 
      : null ;
  }
}
