<input
        [formControl]='formControl'
        [formlyAttributes]='field'
        (keydown.enter)="searchAnimal()"
        type="text"
        pInputText
        class="animal-tag"
        [attr.data-ele]='"animal-tag"'
        [ngClass]="{  'idle': rfidCaptureState === 'idle',
                 'captured': rfidCaptureState === 'captured'
              }"
/>