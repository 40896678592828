import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as reducer from './event-product-metadata.reducer';

export const FEATURE_NAME = 'event-product-metadata';

export const selectState = createFeatureSelector<reducer.State>(FEATURE_NAME);

const selectLoading = createSelector(selectState, reducer.getLoading);
const selectLoaded = createSelector(selectState, reducer.getLoaded);
const selectMetaData = createSelector(selectState, reducer.getMetadataMap);

export const eventProductMetadataSelectors = {
  selectLoading,
  selectLoaded,
  selectMetaData
};

