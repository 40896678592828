import { roundNumber } from '@common/angular/utils';
import { Product } from '@ifhms/models/feedlot';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

export function getProductWithQuantity(
  product: Product,
  routeDetail: SersiSelectListItem | null,
  averageWeight?: number
): Product {
  if (!routeDetail) return product;

  const calcReqQty = getRecommendedProductQtyByRoute(
    routeDetail,
    averageWeight
  );
  // check for dosing gun connection and set to null
  return {
    ...product,
    qty: product.associatedDosingGunMacAddress ? product.qty : calcReqQty,
    unit: <string>routeDetail?.attributes?.['Unit'] || null,
    recQty: calcReqQty || product.recQty
  };
}

export function getTotalQty(
  qty: number | null,
  numberOfHeads?: number
): number | null {
  if (!numberOfHeads) return null;
  const totalQty = roundNumber(qty! * numberOfHeads, 2);
  return totalQty || null;
}

export function getRecommendedProductQtyByRoute(
  routeDetail: SersiSelectListItem,
  averageWeight?: number
): number | null {
  const weight = averageWeight ?? 0;
  const routeAtts = routeDetail?.attributes;
  const dosageUnit = (<string>routeAtts?.['DosageUnit'])?.toLowerCase();
  const dosage = routeAtts?.['Dosage'] as number;
  const roundTo = routeAtts?.['RoundTo'] as number;
  const maxQty = routeAtts?.['MaxQuantity'] as number | undefined;

  let recQty = 0;

  switch (dosageUnit) {
    case 'cwt':
      recQty = Math.ceil(((weight / 100) * dosage) / roundTo) * roundTo;
      break;
    case 'head':
      recQty = Math.ceil(dosage / roundTo) * roundTo;
      break;
  }

  if (!recQty) return null;

  recQty = roundNumber(recQty, 1);

  if (maxQty && recQty > maxQty) {
    recQty = maxQty;
  }

  return recQty;
}
