export enum FeedlotSelectListWithTranslationType {
  AgeClass = 1,
  Breed = 2,
  BreedType = 3,
  Dentition = 4,
  Gender = 5,
  AcquisitionType = 6,
  BreedClass = 10,
  CoatColor = 11,
  DoseUnit = 13,
  DxGroup = 14,
  FeedingPlan = 15,
  IdType = 16,
  InjectionLocation = 17,
  MarketingPlan = 18,
  PricingMethod = 19,
  ProductType = 20,
  Purchaser = 21,
  RailDestination = 22,
  Region = 23,
  RiskCategory = 24,
  Route = 25,
  Source = 26,
  TagColor = 27,
  TagLocation = 28,
  UnitType = 29,
  Facility = 30,
  Diagnosis = 31,
  ActiveProductTypes = 32,
  PenType = 33,
  Vendor = 34,
  LotOwners = 35,
  SortType = 36
}
