<p-button
  [attr.data-ele]="'more-menu'"
  *transloco="let t; read: translateScope"
  [label]="t('more')"
  (click)="menu.toggle($event)"
  iconPos="right"
  class="p-button-primary h-full"
  outlined="true"
  [rounded]="true"
  icon="pi pi-chevron-down"
  type="submit"
></p-button>
<p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
