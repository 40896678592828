import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import * as reducer from './custom-fields.reducer';
import { CustomFieldsSelectors } from './custom-fields.selectors';
import { CustomFieldsActions } from './custom-fields.actions';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class CustomFieldsFacade {

  loaded$ = this.store.select(CustomFieldsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(CustomFieldsSelectors.selectItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(CustomFieldsActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(CustomFieldsActions.reset());
  }
}
