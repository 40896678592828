import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UpcDosingGunsActions } from './upc-dosing-guns.actions';
import { UpcDosingGunsSelectors } from './upc-dosing-guns.selectors';
import * as reducer from './upc-dosing-guns.reducer';
import { DeviceConnectionStatusEnum, DeviceStatusDto, DosingGunItem, DosingGunsListDto } from '@ifhms/models/feedlot';
import { UpcDosingGunsService } from '@ifhms/common/angular/data-access/upc-api';

@Injectable()
export class UpcDosingGunsFacade {

  loading$: Observable<boolean> = this.store.select(UpcDosingGunsSelectors.selectLoading);
  error$: Observable<string | null | undefined> = this.store.select(UpcDosingGunsSelectors.selectError);
  state$: Observable<reducer.State> = this.store.select(UpcDosingGunsSelectors.selectState);
  dosingGunsSettings$: Observable<DeviceStatusDto | null> = this.store.select(UpcDosingGunsSelectors.selectDosingGunsSettings);
  dosingGunsConnectionStatus$: Observable<DeviceConnectionStatusEnum> = this.store.select(UpcDosingGunsSelectors.selectDosingGunsConnectionStatus);
  oneTimeDose$: Observable<boolean> = this.store.select(UpcDosingGunsSelectors.selectOneTimeDose);
  dosingGunItems$: Observable<DosingGunItem[] | null> = this.store.select(UpcDosingGunsSelectors.selectAvailableDosingGuns);
  dosingGunEvents$: Observable<any> = this.upcDosingGunsService.dosingGunData$;

  constructor(private readonly store: Store, private upcDosingGunsService: UpcDosingGunsService) {
  }

  getDosingGunsStatusFromUpc(): void {
    this.store.dispatch(UpcDosingGunsActions.getDosingGunsStatusFromUpc());
  }

  getDosingGunsListFromApi(): void {
    this.store.dispatch(UpcDosingGunsActions.getDosingGunsListFromApi());
  }

  getAvailableDosingGuns(): void {
    this.store.dispatch(UpcDosingGunsActions.getAvailableDosingGunsFromUpc());
  }

  updateDosingGunsSettings(dosingGuns: DosingGunsListDto): void {
    this.store.dispatch(UpcDosingGunsActions.updateDosingGunsSettings({ dosingGunsDto: dosingGuns }));
  }

  assignDosingGunToProduct(dosingGun: DosingGunItem): void {
    this.store.dispatch(UpcDosingGunsActions.assignDosingGunToProduct({ dosingGun }));
  }

  setDose(macAddress: string, dose: number): void {
    this.store.dispatch(UpcDosingGunsActions.setDose({ macAddress, dose }));
  }

  setOneTimeDose(macAddress: string, dose: number, gunName?: string | null): void {
    this.store.dispatch(UpcDosingGunsActions.setOneTimeDose({ macAddress, dose, gunName }));
  }

  resetOneTimeDose(): void {
    this.store.dispatch(UpcDosingGunsActions.resetOneTimeDose());
  }

  validateGun(gunId: string): void {
    this.store.dispatch(UpcDosingGunsActions.validateGun({ gunId }));
  }

  UnassignDosingGunFromProduct(gunId: string): void {
    this.store.dispatch(UpcDosingGunsActions.unassignDosingGunFromProduct({ gunId }));
  }

  resetError(): void {
    this.store.dispatch(UpcDosingGunsActions.resetError());
  }

}
