import { createAction, props } from '@ngrx/store';
import { DeviceStatusDto } from '@ifhms/models/feedlot';

const NAMESPACE = '[UPC HUB]';

const getScaleStatusFromUpc = createAction(`${NAMESPACE} Get Scale Status`);

const getScaleStatusFromUpcSuccess = createAction(`${NAMESPACE} Get Scale Status Success`, props<{ scaleSettings: DeviceStatusDto | null }>());

const captureWeight = createAction(`${NAMESPACE} Capture Weight`);

const captureWeightSuccess = createAction(`${NAMESPACE} Capture Weight Success`, props<{ weight: number | null }>());

const resetWeightCapture = createAction(`${NAMESPACE} Reset Weight Capture`);

const error = createAction(
  `${NAMESPACE} Error`,
  props<{ errMsg: string }>()
);
const resetError = createAction(`${NAMESPACE} Reset Error`);

export const UpcScaleActions = {
  error,
  resetError,
  getScaleStatusFromUpc,
  getScaleStatusFromUpcSuccess,
  captureWeight,
  captureWeightSuccess,
  resetWeightCapture
};
