export enum SortGroupOutcomeSortBy {
  None = 0,
  BreedAscending = 1,
  BreedDescending = 2,
  BreedTypeAscending = 3,
  BreedTypeDescending = 4,
  WeightAscending = 5,
  WeightDescending = 6,
  AgeAscending = 7,
  AgeDescending = 8,
  AgeClassAscending = 9,
  AgeClassDescending = 10,
  TemperatureAscending = 11,
  TemperatureDescending = 12,
  DCodeAscending = 13,
  DCodeDescending = 14,
  GenderAscending = 15,
  GenderDescending = 16,
  OutputGroupAscending = 17,
  OutputGroupDescending = 18
}
