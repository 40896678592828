import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { scopeLoader } from '@common/angular/translation';
import { UpcDosingGunsFacade } from '@ifhms/common/angular/upc/shared';
import { DeviceConnectionStatusEnum } from '@ifhms/models/feedlot';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifhms-dosing-gun-status-indicator',
  templateUrl: './dosing-gun-status-indicator.component.html',
  styleUrls: ['./dosing-gun-status-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'dosingguns',
        alias: 'dosingguns',
        loader: scopeLoader((lang: string, root: string) =>
          import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class DosingGunStatusIndicatorComponent implements OnInit{

  connectionStatus: DeviceConnectionStatusEnum = DeviceConnectionStatusEnum.Disconnected;
  protected readonly DeviceConnectionStatusEnum = DeviceConnectionStatusEnum;

  constructor(private dosingGunsFacade: UpcDosingGunsFacade) {
  }

  ngOnInit(): void {
    this.dosingGunsFacade.dosingGunsConnectionStatus$
      .pipe(untilDestroyed(this))
      .subscribe(status => {
        this.connectionStatus = status;
      });
  }

  isConnected(): boolean {
    return this.connectionStatus === DeviceConnectionStatusEnum.Connected;
  }
}
