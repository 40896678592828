import { ChangeDetectionStrategy, Component } from '@angular/core';
import { scopeLoader } from '@common/angular/translation';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AbstractFormFieldConfigComponent, FormlyTypesEnum } from '@sersi/angular/formly/core';

@Component({
  selector: 'ifhms-dosing-gun-grid-item',
  templateUrl: './dosing-gun-grid-item.component.html',
  styleUrls: ['./dosing-gun-grid-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'dosingguns',
        alias: 'dosingguns',
        loader: scopeLoader((lang: string, root: string) =>
          import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class DosingGunGridItemComponent extends AbstractFormFieldConfigComponent {

  override scopedNamespace = 'dosingguns.components.dosing-gun-grid-item';
  protected override readonly fieldClassName = 'dosing-gun-item';

  constructor(protected referenceDataFacade: ReferenceDataFacade) {
    super();
  }

  protected getFieldGroupConfig(): FormlyFieldConfig[] {
    return [
      this.setGunIdField(),
      this.setDosingGunMacAddress(),
      this.setDosingGunName(),
      this.setDosingGunDescription(),
      this.setWorkOrderType(),
      this.setWorkOrder(),
      this.setDosingGunProduct(),
      this.setDosingGunProductType(),
      this.setIsValidated()
    ]
  }

  protected override onFieldInit(field: FormlyFieldConfig): void {
    super.onFieldInit(field);
  }

  public setGunIdField(): FormlyFieldConfig {
    return {
      key: 'id'
    }
  }

  private setDosingGunName(): FormlyFieldConfig {
    return {
      key: 'name',
      type: FormlyTypesEnum.TEXT_INPUT
    };
  }

  private setDosingGunDescription(): FormlyFieldConfig {
    return {
      key: 'description',
      type: FormlyTypesEnum.TEXT_INPUT,
      modelOptions: {
        updateOn: 'blur'
      }
    };
  }

  private setDosingGunMacAddress(): FormlyFieldConfig {
    return {
      key: 'macAddress',
      type: FormlyTypesEnum.TEXT_READONLY,
      className: 'dosingGuns__macAddress text-break-word',
      modelOptions: {
        updateOn: 'blur'
      }
    }
  }

  private setDosingGunProduct(): FormlyFieldConfig {
    return {
      key: 'productId',
      type: FormlyTypesEnum.TEXT_READONLY,
      className: 'dosingGuns__productId',
      props: {
        items$: this.referenceDataFacade.productList$,
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE'
      }
    }
  }

  private setDosingGunProductType(): FormlyFieldConfig {
    return {
      key: 'productTypeId',
      type: FormlyTypesEnum.TEXT_READONLY,
      className: 'dosingGuns__productType',
      props: {
        items$: this.referenceDataFacade.productTypes$,
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE'
      }
    }
  }

  private setWorkOrderType(): FormlyFieldConfig {
    return {
      key: 'dosingGunState',
      type: FormlyTypesEnum.TEXT_READONLY,
      className: 'dosingGuns__workOrderType',
      props: {
        placeholder: ''
      }
    }
  }

  private setWorkOrder(): FormlyFieldConfig {
    return {
      key: 'workOrderNumber',
      type: FormlyTypesEnum.TEXT_READONLY,
      className: 'dosingGuns__workOrderNumber'
    }
  }

  private setIsValidated(): FormlyFieldConfig {
    return {
      key: 'validated',
      type: FormlyTypesEnum.CHECKBOX,
      className: 'dosingGuns__isValidated',
      props: {
        disabled: true
      }
    }
  }

}
