<ng-container *ngFor="let cell of cells">
  <ng-container *ngIf="cell.type || cell.props?.['cellRendererParams']?.type as cellType">
    <ng-container *ngIf="$any(cell.key || cell.props?.['fieldKey']) as cellKey">
      <ng-container *ngIf="columnsConfigMap[cellKey] as cellConfig">
        <div
          class="grid-cell flex-none"
          *ngIf="!cellConfig.hideCell"
          [style.width]="cellConfig.width || null"
          [ngClass]="cellConfig.className || ''"
          [style.min-width.px]="cellConfig.minWidth"
        >
          <ng-container [ngSwitch]="cellType">
            <sersi-grid-delete-button-renderer
              [disabled]="!!disabled"
              (deleteRow)="onRowDelete()"
              *ngSwitchCase="GridRendererCellType.Delete"
            />
            <sersi-grid-dosing-gun-button-renderer
              [disabled]="!!cell.props?.['disabled']"
              [gridCell]="cell"
              (assignDosingGun)="onRowAssignDosingGun(cell)"
              *ngSwitchCase="GridRendererCellType.DosingGun"
            />
            <sersi-grid-info-button-renderer
              *ngSwitchCase="GridRendererCellType.Info"
              [disabled]="!!cell.props?.['actionDisabled']"
              (rowInfoClicked)="onRowInfo(cell)"
            />
            <ng-container *ngSwitchDefault>
              <formly-field [field]="cell" />
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
