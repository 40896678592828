<ng-container *transloco="let t; read: translateScope">
  <p-button
    [rounded]="true"
    outlined="true"
    severity="secondary"
    #search
    icon="pi pi-search"
    class="search"
    iconPos="left"
    data-ele="table-search-btn"
    [label]="t('search')"
    (click)="menu.toggle($event)"
  ></p-button>
  <p-overlayPanel #menu>
    <ng-template pTemplate>
      <form
        sersiClickStopPropagation
        class="overlay-form"
        data-ele="search-form"
        [formGroup]="form"
      >
        <formly-form
          [fields]="fields"
          [form]="form"
          [model]="model"
        />
      </form>
      <div class="flex gap-7 cattle-selector">
        <p-button
          [rounded]="true"
          severity="secondary"
          type="submit"          
          data-ele="apply-btn"
          [label]="t('apply')"
          [disabled]="form.pristine || !form.valid"
          (click)="onApply()"
        ></p-button>
        <p-button
          [rounded]="true"
          severity="secondary"
          outlined="true"
          class="flex-grow-1 search mr-0"
          data-ele="clear-btn"
          [label]="t('clear')"
          [disabled]="!((facade.filterApplied$ | async) || !form.pristine)"
          (click)="clear()"
        ></p-button>
        </div
    ></ng-template>
  </p-overlayPanel>
</ng-container>
