import { Component, HostListener, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

import { HubConnectionState } from '@microsoft/signalr';
import { withLatestFrom } from 'rxjs';

import { MAX_NUMBER_INPUT } from '@sersi/angular/formly/core';

import { HashMap } from '@common/angular/interfaces';
import { scopeLoader } from '@common/angular/translation';
import { AhCommonDialogService } from '@common/angular/utils';
import { UpcDeviceManagerService, UpcScaleService } from '@ifhms/common/angular/data-access/upc-api';
import { UpcWeightFormFieldUpdateService } from '@ifhms/common/angular/upc/shared';
import { DeviceStatusDto, ScaleDate } from '@ifhms/models/feedlot';

@UntilDestroy()
@Component({
  selector: 'ifhms-feedlot-upc-form-weight-field',
  templateUrl: './form-weight-field.component.html',
  styleUrls: ['./form-weight-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'scale',
        alias: 'scale',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class FormWeightFieldComponent extends FieldType<FieldTypeConfig> implements OnInit {
  protected readonly translateScope = 'scale.components.weight-field-form';

  readonly MAX_NUMBER_INPUT = MAX_NUMBER_INPUT;
  readonly HOTKEY_CAPTURE_WEIGHT = 'F8';

  private scaleConnectionStatus: HubConnectionState | null = null;

  private currentWeight: number | null;
  private scaleMode?: 'continuous' | 'lockAndSend';
  loadingWeight$ = this.upcScaleService.loadingWeight$;

  protected dialogService = inject(AhCommonDialogService);

  constructor(
    private upcScaleService: UpcScaleService,
    private upcDeviceManager: UpcDeviceManagerService,
    private translateService: TranslocoService,
    private upcWeightFormFieldUpdateService: UpcWeightFormFieldUpdateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.upcDeviceManager.registerDevice(this.upcScaleService).then(() => {
      this.upcScaleService.getWeight();
      this.subscribeToScaleConnectionStatus();
      this.subscribeToScaleData();
    });
  }

  @HostListener('window:keydown', ['$event'])
  async onGlobalCaptureWeight(event: KeyboardEvent): Promise<void> {
    this.onCaptureWeight(event);
  }

  onCaptureWeight(event: KeyboardEvent): void {
    const hotkey = event.key === this.HOTKEY_CAPTURE_WEIGHT;

    if (hotkey && this.scaleConnectionStatus === HubConnectionState.Connected) {
      this.upcScaleService.getWeight().then(() => this.emitUPCWeight())
    }
  }

  emitUPCWeight(scaleEventType?: 'WeightChanged' | 'GetWeight'): void {
    if (this.currentWeight !== null ) {
      this.upcWeightFormFieldUpdateService.updateUPCWeight({
        weight: this.currentWeight,
        scaleEventType: scaleEventType,
        scaleMode: this.scaleMode
      });
    }
  }

  private subscribeToScaleData(): void {
    this.upcScaleService.scaleData$
      .pipe(
        withLatestFrom(this.upcScaleService.deviceSettings$),
        untilDestroyed(this))
      .subscribe(([val, deviceSettings]: [ScaleDate, DeviceStatusDto]) => {
        if(val) {
          const scaleMode : string = deviceSettings?.settings?.scaleSettings?.mode ?? '';
          this.scaleMode = scaleMode as 'continuous' | 'lockAndSend';
          this.currentWeight = val.weight;
          this.formControl.patchValue(this.currentWeight);

          if(scaleMode === 'lockAndSend' && val.scaleEventType === 'WeightChanged') {
            this.emitUPCWeight(val.scaleEventType);
          }
        }
      });
  }

  private subscribeToScaleConnectionStatus(): void {
    this.upcScaleService.connectionState$
      .pipe(untilDestroyed(this))
      .subscribe((status) => {
        this.scaleConnectionStatus = status;
      });
  }

  private getTranslation(key: string, params?: HashMap ): string {
    return this.translateService.translate(`${this.translateScope}.${key}`, params);
  }

}
