import { Injectable } from '@angular/core';
import { SortGroupsService } from '@ifhms/common/angular/data-access/feedlot-api';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { SortGroupDetailDto } from '@ifhms/models/feedlot';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  of,
  withLatestFrom
} from 'rxjs';
import { FEATURE_NAME } from '..';
import { SortGroupListActions } from '../sort-group-list/sort-group-list.actions';
import { SortGroupOutcomeListActions } from '../sort-group-outcome-list/sort-group-outcome-list.actions';
import { SortGroupDetailActions } from './sort-group-detail.actions';
import { SortGroupDetailFacade } from './sort-group-detail.facade';

@Injectable()
export class SortGroupDetailEffects {
  translateScope = `${FEATURE_NAME}.state.effects`;

  constructor(
    private actions$: Actions,
    private sortGroupsService: SortGroupsService,
    private sortGroupDetailFacade: SortGroupDetailFacade,
    private feedlotFacade: FeedlotFacade,
    private messageService: MessageService,
    private translateService: TranslocoService
  ) {}

  apply$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupDetailActions.apply),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.sortGroupDetailFacade.detail$
      ),
      filter(([, , detail]) => !!detail),
      exhaustMap(([action, feedlotId, detail]) => {
        const { sortGroupId } = action;
        if (!sortGroupId || !detail) {
          throw new Error();
        }
        const { workOrderId, workOrderType, isActionNewHomePen, creationDate } =
          detail;
        return this.sortGroupsService
          .applyTemplate(feedlotId, workOrderType, workOrderId, sortGroupId)
          .pipe(
            map((result: SortGroupDetailDto) => {
              if (!result) throw new Error();
              result.isActionNewHomePen = isActionNewHomePen;
              result.creationDate = creationDate;
              return SortGroupDetailActions.applySuccess({
                detail: result
              });
            })
          );
      }),
      catchError(() => {
        this.messageService.add({
          severity: 'error',
          summary: this.getTranslationString('fail'),
          detail: this.getTranslationString('apply-template-fail')
        });
        return of(SortGroupDetailActions.error());
      })
    )
  );

  applySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupDetailActions.applySuccess),
      mergeMap(() => {
        this.messageService.add({
          severity: 'success',
          summary: this.getTranslationString('success'),
          detail: this.getTranslationString('apply-template-success')
        });
        return [
          SortGroupListActions.hideModal(),
          SortGroupOutcomeListActions.showModal(),
          SortGroupOutcomeListActions.get()
        ];
      })
    )
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupDetailActions.get),
      withLatestFrom(this.feedlotFacade.feedlotId$),
      exhaustMap(([action, feedlotId]) => {
        const { workOrderId, workOrderType, isActionNewHomePen, creationDate } =
          action.detail;
        return this.sortGroupsService
          .getDetail(feedlotId, workOrderId, workOrderType)
          .pipe(
            map((result: SortGroupDetailDto) => {
              if (!result) throw new Error();
              result.isActionNewHomePen = isActionNewHomePen;
              result.creationDate = creationDate;
              return SortGroupDetailActions.getSuccess({
                detail: result
              });
            }),
            catchError(() => of(SortGroupDetailActions.error()))
          );
      })
    )
  );

  private getTranslationString(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }
}
