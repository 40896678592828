import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventType, ProductMetadataDto } from '@ifhms/models/feedlot';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';
import { GUID_EMPTY } from '@ifhms/models/shared';

@Injectable()
export class EventAnimalProductMetaDataService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {}

  getProductMetadata(feedlotId: string, facilityId: string, productId: string, eventId: string, eventType: EventType): Observable<ProductMetadataDto> {

    if(eventId == 'draft') eventId = GUID_EMPTY;
    const params = new HttpParams()
      .set('eventId', eventId)
      .set('eventType', eventType);
    
    return this.http.get<ProductMetadataDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Events/ProductMetadata/${facilityId}/${productId}`, { params });
  }

}
