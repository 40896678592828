import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FeedlotAppFacade } from './state/feedlot.facade';
import { FEEDLOT_FEATURE_NAME, feedlotReducer } from './state/feedlot.reducer';
import { FeedlotEffects } from './state/feedlot.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEEDLOT_FEATURE_NAME, feedlotReducer),
    EffectsModule.forFeature([FeedlotEffects])
  ],
  providers: [
    FeedlotEffects,
    FeedlotAppFacade
  ]
})
export class IfhmsFeedlotFrontEndSharedDomainStateFeedlotModule {}