import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AbstractTranslationComponent } from '@common/angular/translation';
import { Observable } from 'rxjs';
import { UpcDosingGunsService } from '@ifhms/common/angular/data-access/upc-api';

@Component({
  selector: 'ifhms-upc-dosing-gun-settings',
  templateUrl: './upc-dosing-gun-settings.component.html',
  styleUrls: ['./upc-dosing-gun-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcDosingGunSettingsComponent extends AbstractTranslationComponent{

  translationNamespace = 'containers.upc-dosing-guns-settings';
  connectionStatus$: Observable<string>;

  constructor(private dosingGunService: UpcDosingGunsService) {
    super();
  }

  override onTranslationInit():void {
    this.connectionStatus$ = this.dosingGunService.connectionState$;
  }
}
