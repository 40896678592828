import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  LotCreateDto,
  LotDto,
  LotListRequestDto,
  LotListResultDto,
  LotUpdateDto,
  TreatmentProtocolLotSettingsDto
} from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../tokens';

@Injectable()
export class LotsService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {
  }

  get(feedlotId: string, lotId: string): Observable<LotDto> {
    return this.http.get<LotDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Lots/${lotId}`
    );
  }

  getTreatmentProtocols(feedlotId: string, txGroupId: string): Observable<TreatmentProtocolLotSettingsDto> {
    return this.http.get<TreatmentProtocolLotSettingsDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Lots/TxLevels/${txGroupId}`);
  }

  getTreatmentProtocolsByLotId(feedlotId: string, lotId: string, txGroupId: string): Observable<TreatmentProtocolLotSettingsDto> {
    return this.http.get<TreatmentProtocolLotSettingsDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Lots/${lotId}/TxLevels/${txGroupId}`);
  }

  create(feedlotId: string, dto: LotCreateDto): Observable<LotDto> {
    return this.http.post<LotDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Lots`, dto);
  }

  update(feedlotId: string, dto: LotUpdateDto): Observable<LotDto> {
    return this.http.put<LotDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Lots`, dto);
  }

  getList(feedlotId: string, request: LotListRequestDto): Observable<LotListResultDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });

    return this.http.get<LotListResultDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Lots`, { params });
  }

  isLotNumberValid(feedlotId: string, lotNumber: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        lotNumber: lotNumber.toString()
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Feedlot/${feedlotId}/Lots/IsLotNumberValid`, options);
  }

  isSubGroupNameValid(feedlotId: string, lotId: string, subGroupName: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        subGroupName: subGroupName.toString(),
        lotId: lotId
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Feedlot/${feedlotId}/Lots/IsSubGroupNameValid`, options);
  }

}
