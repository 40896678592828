import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SortGroupOutcomeDetailViewSelectors } from './sort-group-outcome-detail-view.selectors';
import { Store } from '@ngrx/store';
import { SortGroupOutcomeDetailViewActions } from './sort-group-outcome-detail-view.actions';
import { SortGroupOutcomeDto } from '@ifhms/models/feedlot';

@Injectable()
export class SortGroupOutcomeDetailViewFacade {
  isLoading$: Observable<boolean> = this.store.select(
    SortGroupOutcomeDetailViewSelectors.selectLoading
  );

  isLoaded$: Observable<boolean> = this.store.select(
    SortGroupOutcomeDetailViewSelectors.selectLoaded
  );

  detail$: Observable<SortGroupOutcomeDto | null> = this.store.select(
    SortGroupOutcomeDetailViewSelectors.selectDetail
  );

  modal$: Observable<boolean> = this.store.select(
    SortGroupOutcomeDetailViewSelectors.selectModal
  );

  constructor(private readonly store: Store) {}

  get(id: string): void {
    this.store.dispatch(SortGroupOutcomeDetailViewActions.get({ id }));
  }

  reset(): void {
    this.store.dispatch(SortGroupOutcomeDetailViewActions.reset());
  }
}
