import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, withLatestFrom } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { EventAnimalProductMetaDataService } from '@ifhms/common/angular/data-access/feedlot-api';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';

import { eventProductMetadataActions } from './event-product-metadata.actions';

@Injectable()
export class EventProductMetadataEffects {
  getProductMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventProductMetadataActions.getProductMetadata),
      withLatestFrom(this.feedlotFacade.feedlotId$),
      exhaustMap(([action, feedlotId]) => {
        return this.eventAnimalProductMetaDataService
          .getProductMetadata(feedlotId, action.facilityId, action.productId, action.eventId, action.eventType)
          .pipe(
            map(metaData => eventProductMetadataActions.getProductMetadataSuccess({ metaData })),
            catchError((err) => of(eventProductMetadataActions.error(err.toString())))
          )
      })
    )
  );

  constructor(
    private actions$: Actions,
    private eventAnimalProductMetaDataService: EventAnimalProductMetaDataService,
    private feedlotFacade: FeedlotFacade
  ) {}

}
