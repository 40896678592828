import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FeedlotsEffects } from './state/list/feedlots.effects';
import { FeedlotsFacade } from './state/list/feedlots.facade';
import { reducer } from './state';

@NgModule({
  imports: [CommonModule,
    StoreModule.forFeature('feedlots', reducer),
    EffectsModule.forFeature([FeedlotsEffects])
  ],
  providers: [
    FeedlotsEffects,
    FeedlotsFacade
  ]
})
export class IfhmsFeedlotFrontEndWebDomainStateFeedlotsModule {
}
