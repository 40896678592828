export const AnimalSelectorItemDtoType = 'AnimalSelectorDto';

export interface AnimalSelectorItemDto {
  type: 'AnimalSelectorDto';
  id: string;
  tagId: string;
  homePenId: string;
  homePenName: string;
  currentPenId: string;
  currentPenName: string;
  lotId: string;
  lotNumber: string;
  subGroup: string;
  subGroupId: string;
  isAvailable: boolean;
  inDateWarning?: string;
  selected?: boolean;
}
