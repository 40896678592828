import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { scopeLoader } from '@common/angular/translation';
import {
  SortGroupDetailDto,
  SortGroupOutcomeListItemDto
} from '@ifhms/models/feedlot';
import { WorkOrderType } from '@ifhms/models/shared';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { FEATURE_NAME } from '../../+state';
import { SortGroupDetailFacade } from '../../+state/sort-group-detail/sort-group-detail.facade';
import { SortGroupListFacade } from '../../+state/sort-group-list/sort-group-list.facade';
import { SortGroupOutcomeDetailViewFacade } from '../../+state/sort-group-outcome-detail-view/sort-group-outcome-detail-view.facade';
import { SortGroupOutcomeListFacade } from '../../+state/sort-group-outcome-list/sort-group-outcome-list.facade';
import { SortGroupFormModalComponent } from '../../components/sort-group-form-modal/sort-group-form-modal.component';

@UntilDestroy()
@Component({
  selector: 'ifhms-sort-group-input',
  templateUrl: './sort-group-input.component.html',
  styleUrls: ['./sort-group-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SortGroupInputComponent),
      multi: true
    },
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: FEATURE_NAME,
        alias: FEATURE_NAME,
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class SortGroupInputComponent
implements ControlValueAccessor, OnChanges, OnDestroy
{
  translateScope = `${FEATURE_NAME}.containers.sort-group-input`;

  _onChange: (_: any) => void;
  _onTouched: (_: any) => void;
  @Input() disabled: boolean;
  @Input() workOrderType: WorkOrderType;
  @Input() workOrderId: string;
  @Input() creationDate?: string;
  @Input() isActionNewHomePen?: boolean;
  @Input() hasTagsInputs: boolean;
  @Input() required: boolean;
  @Input() formControl?: any;
  @Input() onModelClosed: any;

  groupDetail: Observable<SortGroupDetailDto | null>;

  @HostBinding('class') class = 'sort-group custom-ctrl-container ctrl-wrapper';

  @ViewChild(SortGroupFormModalComponent, { static: false })
    formlyForm: SortGroupFormModalComponent | null;

  constructor(
    public sortGroupDetailFacade: SortGroupDetailFacade,
    public sortGroupOutcomeListFacade: SortGroupOutcomeListFacade,
    public sortGroupOutcomeDetailViewFacade: SortGroupOutcomeDetailViewFacade,
    private sortGroupListFacade: SortGroupListFacade
  ) {
    this.groupDetail = this.sortGroupDetailFacade.detail$;
  }

  ngOnDestroy(): void {
    this.sortGroupDetailFacade.reset();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isActionNewHomePen']) {
      this.getSortGroup();
    }
  }

  writeValue(): void | null {
    if (!this.workOrderId || !this.workOrderType) {

      return null;
    }
    this.getSortGroup();
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  getSortGroup(): void {
    this.sortGroupDetailFacade.get(<SortGroupDetailDto>{
      workOrderId: this.workOrderId,
      workOrderType: this.workOrderType,
      isActionNewHomePen: this.isActionNewHomePen,
      creationDate: this.creationDate
    });
  }

  showSearchModal(): void {
    this.sortGroupListFacade.showModal();
  }

  showOucomesModal(): void {
    this.sortGroupOutcomeListFacade.showModal();
  }

  onViewClick(outcome: SortGroupOutcomeListItemDto): void {
    this.sortGroupOutcomeDetailViewFacade.get(outcome.id);
  }

  onCreateOutput(outcome?: SortGroupOutcomeListItemDto): void {
    this.formlyForm?.createOutput(outcome);
  }
}
