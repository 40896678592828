import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, inject, Input, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

@Component({
  selector: 'sersi-table-sort',
  templateUrl: './table-sort.component.html',
  styleUrls: ['./table-sort.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TableSortComponent {
  protected renderer = inject(Renderer2);
  protected document = inject(DOCUMENT);
  @HostBinding('class') class = 'sersi-table-sort-container';

  @Input() sortLabel: string;
  @Input() value: number | null;
  @Input() items: SersiSelectListItem[];
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() className = 'table-sort-control';
  @Input() dropdownClassName = '';
  @Output() sortChanged = new EventEmitter<SersiSelectListItem>();

  protected get panelElement(): HTMLElement | null {
    return this.document.querySelector('.ng-dropdown-panel');
  }

  onDropdownOpen(): void {
    this.renderer.addClass(this.document.body, 'dropdown-panel-open');
    this.handleCustomClassInDropdown();
  }

  onDropdownClose(): void {
    this.renderer.removeClass(this.document.body, 'dropdown-panel-open');
  }

  private handleCustomClassInDropdown(): void {
    const dropdownClassName = this.dropdownClassName;
    if (!dropdownClassName) return;
    setTimeout(() => this.renderer.addClass(this.panelElement, dropdownClassName));
  }
}
