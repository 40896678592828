import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { HashMap } from '@common/angular/interfaces';
import { AbstractFormFieldConfigComponent } from '@sersi/angular/formly/core';
import { GridColumnDef, GridOptions } from '@sersi/angular/formly/core';

@Component({ template: '' })
export abstract class AbstractProductSelectorComponent extends AbstractFormFieldConfigComponent {

  override hostClassNames = 'product-selector';

  protected gridOptions: GridOptions;

  protected get fieldKey(): string {
    return this.fieldConfig?.['key'] as string || '';
  }

  protected abstract getInitialModel(): HashMap;
  protected abstract setGridField(): FormlyFieldConfig;
  protected abstract setGridRow(): FormlyFieldConfig;
  protected abstract getColumnDefs(): GridColumnDef[];

  protected getFieldGroupConfig(): FormlyFieldConfig[] {
    return [
      this.setGridField()
    ]
  }

  protected getGridOptions(): GridOptions {
    this.gridOptions ||= {
      context: this.fieldKey,
      columns: this.getColumnDefs(),
      addRow: new Subject<void>(),
      deleteRow: new Subject<number>()
    };
    return this.gridOptions;
  }

}
