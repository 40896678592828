import { createSelector } from '@ngrx/store';

import { selectFeatureState, State } from '../reducer';
import * as reducer from './animal-status.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.animalStatus
);

const selectItems = createSelector(selectState, reducer.getAll);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

export const AnimalStatusSelectors = {
  selectState,
  selectLoaded,
  selectItems
};
