import { AnimalDetailElectronicTagDto, AnimalDetailTagDto, AnimalElectronicTagStatus } from '@ifhms/models/feedlot';

export function getAnimalTagName(tags: AnimalDetailTagDto[]): string | null {
  return tags.find(tag => tag.isActive)?.tagName ?? null;
}

export function getAnimalNationalId(electronicTags: AnimalDetailElectronicTagDto[]): string | null {
  const primaryTag = electronicTags.find(tag => tag.isPrimary && tag.status === AnimalElectronicTagStatus.Active);
  return primaryTag?.tagNumber ?? null
}
