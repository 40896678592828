import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, of, tap, withLatestFrom } from 'rxjs';

import { SelectListsService } from '@ifhms/common/angular/data-access/feedlot-api';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';

import { ProductsActions } from './products.actions';
import { ProductsFacade } from './products.facade';

@Injectable()
export class ProductsEffects {

  private loading: boolean;

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.get),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.productsFacade.loaded$
      ),
      filter(([action,, loaded]) => {
        const hasNoPendingRequests = !this.loading && !loaded;
        return hasNoPendingRequests || !!action.force;
      }),
      tap(() => this.loading = true),
      exhaustMap(([action, feedlotId]) =>
        this.selectListsService
          .getProductsSelectList(feedlotId, action.workOrderCreatedDate)
          .pipe(
            tap(() => this.loading = false),
            map((items: SelectListItemWithTranslationDto[]) => {
              return ProductsActions.getSuccess({ items });
            }),
            catchError(() => of(ProductsActions.error()))
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private feedlotFacade: FeedlotFacade,
    private productsFacade: ProductsFacade,
    private selectListsService: SelectListsService
  ) {
  }
}
