import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { SersiResizedEvent } from './sersi-resized-event';

@Directive({
  selector: '[sersiResize]'
})
export class SersiResizeDirective implements OnInit, OnDestroy {
  @Output()
  public readonly sersiResize;
  private observer: ResizeObserver;

  public constructor(
    private readonly element: ElementRef,
    private readonly zone: NgZone
  ) {
    this.sersiResize = new EventEmitter<SersiResizedEvent>();
    this.observer = new ResizeObserver((entries) =>
      this.zone.run(() => this.observe(entries))
    );
  }

  public ngOnInit(): void {
    this.observer.observe(this.element.nativeElement);
  }

  public ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private observe(entries: ResizeObserverEntry[]): void {
    const domSize = entries[0];
    const resizedEvent = new SersiResizedEvent(domSize.contentRect);
    this.sersiResize.emit(resizedEvent);
  }
}
