import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../index';
import * as reducer from './feedlots.reducer';

const selectState = createSelector(
  selectFeatureState,
  (state: State) => state.list
);

const selectLoading = createSelector(selectState, reducer.getLoading);

const selectLoaded = createSelector(selectState, reducer.getLoaded);

const selectList = createSelector(selectState, reducer.getAll);

const selectFilter = createSelector(selectState, reducer.getFilter);

const selectFilteredFeedlots = createSelector(
  selectList,
  selectFilter,
  (feedlots, filter) => {
    if (!filter) {
      return feedlots;
    }

    return feedlots.filter((feedlot) => feedlot.name.toLowerCase().includes(filter.toLowerCase()));
  }
);

export const FeedlotListSelectors = {
  selectLoading,
  selectLoaded,
  selectList,
  selectState,
  selectFilteredFeedlots,
  selectFilter
};
