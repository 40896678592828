import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { IfhmsFeedlotTagColorPipe } from './directives/feedlot-tag-color.pipe';

@NgModule({
  imports: [
    CommonModule,
    ButtonDirective
  ],
  declarations: [
    IfhmsFeedlotTagColorPipe
  ],
  exports: [
    IfhmsFeedlotTagColorPipe
  ]
})
export class IfhmsFeedlotFrontEndWebCoreUtilsModule {
}
