import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';

import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { SelectListsService } from '@ifhms/common/angular/data-access/feedlot-api';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { FeedlotSelectListWithTranslationType } from '@ifhms/models/feedlot';

import { DentitionsActions } from './dentitions.actions';

@Injectable()
export class DentitionsEffects {

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentitionsActions.get),
      withLatestFrom(this.feedlotFacade.feedlotId$),
      exhaustMap(([, feedlotId]) => {
        return this.selectListsService.getSelectListDataTranslated(
          feedlotId,
          FeedlotSelectListWithTranslationType.Dentition
        ).pipe(
          map((items: SelectListItemWithTranslationDto[]) => {
            if (!items) DentitionsActions.error();
            return DentitionsActions.getSuccess({ items });
          }),
          catchError(() => of(DentitionsActions.error()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private feedlotFacade: FeedlotFacade,
    private selectListsService: SelectListsService
  ) {
  }
}
