<ng-container *transloco="let t; read: scopedNamespace">
    <div class="table-header"></div>
    <div class="forms-container">
        <div class="upc-settings-form-container">
            <h2>{{t('upc-rfid-settings-header')}}</h2>
            <ifhms-upc-rfid-settings-form></ifhms-upc-rfid-settings-form>

        </div>
    </div>
</ng-container>
