export enum FeedlotPermissionsReport {
  ReportsArrivalWorkOrder = 'reports.hasArrivalWorkOrder',
  ReportsEventDetails = 'reports.hasEventDetails',
  ReportsHospitalOccupancy = 'reports.hasHospitalOccupancy',
  ReportsLotTransferWorkOrder = 'reports.hasLotTransferWorkOrder',
  ReportsMovementWorkOrder = 'reports.hasMovementWorkOrder',
  ReportsPenAndLotInventory = 'reports.hasPenInventory',
  ReportsRailWorkOrder = 'reports.hasRailWorkOrder',
  ReportsRehandlingWorkOrder = 'reports.hasRehandlingWorkOrder',
  ReportsShipmentWorkOrder = 'reports.hasShipmentWorkOrder',
  ReportsWithdrawal = 'reports.hasWithdrawal'
}
