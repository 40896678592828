import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  SortGroupOutcomeFilterBy,
  SortGroupOutcomeListItemDto,
  SortGroupOutcomeSortBy
} from '@ifhms/models/feedlot';
import { SortGroupOutcomeListActions } from './sort-group-outcome-list.actions';
import { SortGroupOutcomeCopyGridListActions } from '../sort-group-outcome-copy-grid-list/sort-group-outcome-copy-grid-list.actions';

export interface State extends EntityState<SortGroupOutcomeListItemDto> {
  loading: boolean;
  loaded: boolean;
  modal: boolean;
  sortBy: SortGroupOutcomeSortBy;
  filterBy: SortGroupOutcomeFilterBy;
}

const adapter: EntityAdapter<SortGroupOutcomeListItemDto> =
  createEntityAdapter<SortGroupOutcomeListItemDto>({
    selectId: (entity: SortGroupOutcomeListItemDto) => entity.id
  });

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  modal: false,
  sortBy: SortGroupOutcomeSortBy.None,
  filterBy: <SortGroupOutcomeFilterBy>{}
});

export const reducer = createReducer(
  initialState,

  on(
    SortGroupOutcomeListActions.get,
    SortGroupOutcomeListActions.select,
    SortGroupOutcomeListActions.selectAll,
    SortGroupOutcomeListActions.updateSingle,
    SortGroupOutcomeListActions.filter,
    SortGroupOutcomeListActions.sortBy,
    SortGroupOutcomeListActions.clearOutput,
    (state) => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),

  on(
    SortGroupOutcomeListActions.getSuccess,
    SortGroupOutcomeListActions.clearOutputSuccess,
    SortGroupOutcomeCopyGridListActions.applySuccess,
    (state, { outcomes }) => {
      return adapter.setAll(outcomes, {
        ...state,
        loading: false,
        loaded: true
      });
    }
  ),

  on(
    SortGroupOutcomeListActions.selectSuccess,
    SortGroupOutcomeListActions.updateSingleSuccess,
    (state, { outcome }) =>
      adapter.updateOne(outcome, {
        ...state,
        loading: false,
        loaded: true
      })
  ),

  on(SortGroupOutcomeListActions.selectAllSuccess, (state, { updates }) =>
    adapter.updateMany(updates, {
      ...state,
      loading: false,
      loaded: true
    })
  ),

  on(SortGroupOutcomeListActions.filterSuccess, (state, { filter }) => ({
    ...state,
    filterBy: filter,
    loading: false,
    loaded: true
  })),

  on(SortGroupOutcomeListActions.sortBySuccess, (state, { sortBy }) => ({
    ...state,
    sortBy,
    loading: false,
    loaded: true
  })),

  on(SortGroupOutcomeListActions.showModal, (state) => ({
    ...state,
    modal: true
  })),

  on(SortGroupOutcomeListActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),

  on(
    SortGroupOutcomeListActions.hideModal,
    SortGroupOutcomeListActions.reset,
    () => initialState
  )
);

const { selectAll, selectTotal } = adapter.getSelectors();

export const getAll = selectAll;

export const getTotal = selectTotal;

export const getLoading = (state: State): boolean => state.loading;

export const getLoaded = (state: State): boolean => state.loaded;

export const getModal = (state: State): boolean => state.modal;

export const getFilterBy = (state: State): SortGroupOutcomeFilterBy =>
  state.filterBy;

export const getSortBy = (state: State): SortGroupOutcomeSortBy => state.sortBy;
