import { Component } from '@angular/core';
import { AbstractTranslationComponent } from '@common/angular/translation';
import { UpcApiFacade } from '@ifhms/common/angular/upc/shared';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';

@Component({
  selector: 'ifhms-upc-global-settings',
  templateUrl: './upc-global-settings.component.html',
  styleUrls: ['./upc-global-settings.component.scss']
})
export class UpcGlobalSettingsComponent extends AbstractTranslationComponent{

  translationNamespace = 'containers.upc-overview';

  usages$ = this.upcApiFacade.usages$;

  constructor(
    private upcApiFacade: UpcApiFacade,
    private refDataFacade: ReferenceDataFacade
  ) {
    super();
  }

  protected override onTranslationInit():void {
    this.loadRefData();
    this.upcApiFacade.getUPCUsages();
  }

  private loadRefData(): void {
    this.refDataFacade.getAlleys();
  }

}
