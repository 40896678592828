import { Injectable } from '@angular/core';
import { SortGroupDetailDto } from '@ifhms/models/feedlot';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SortGroupDetailActions } from './sort-group-detail.actions';
import { SortGroupDetailSelectors } from './sort-group-detail.selectors';

@Injectable()
export class SortGroupDetailFacade {
  isLoading$: Observable<boolean> = this.store.select(
    SortGroupDetailSelectors.selectLoading
  );

  isLoaded$: Observable<boolean> = this.store.select(
    SortGroupDetailSelectors.selectLoaded
  );

  detail$: Observable<SortGroupDetailDto | null> = this.store.select(
    SortGroupDetailSelectors.selectDetail
  );

  constructor(private readonly store: Store) {}

  get(detail: SortGroupDetailDto): void {
    this.store.dispatch(
      SortGroupDetailActions.get({
        detail
      })
    );
  }

  apply(sortGroupId: string): void {
    this.store.dispatch(SortGroupDetailActions.apply({ sortGroupId }));
  }

  reset(): void {
    this.store.dispatch(SortGroupDetailActions.reset());
  }
}
