import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { getLoadedStateData } from '@common/angular/utils';
import { EventType, ProductMetadataDto } from '@ifhms/models/feedlot';

import * as reducer from './event-product-metadata.reducer';
import { eventProductMetadataSelectors } from './event-product-metadata.selectors';
import { eventProductMetadataActions } from './event-product-metadata.actions';

@Injectable({ providedIn: 'root' })
export class EventProductMetadataFacade {

  isLoading$ = this.store.select(eventProductMetadataSelectors.selectLoading);
  isLoaded$ = this.store.select(eventProductMetadataSelectors.selectLoaded);

  metaData$ = getLoadedStateData<ProductMetadataDto>(
    this.store.select(eventProductMetadataSelectors.selectMetaData) as Observable<ProductMetadataDto>,
    this.isLoaded$
  );

  constructor(private store: Store<reducer.State>) {}

  getProductMetadata(productId: string, facilityId: string, eventId: string, eventType: EventType): void {
    this.store.dispatch(eventProductMetadataActions.getProductMetadata({ productId, facilityId, eventId, eventType }));
  }

}
