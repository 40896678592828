<p-table
  [value]="data"
  [scrollable]="true"
  scrollHeight="'60vh'"
  [virtualScrollItemSize]="10"
  [virtualScroll]="true"
  class="t-table"
  *transloco="let t; read: translateScope"
  styleClass="p-datatable-lg p-datatable-striped"
  [ngClass]="{'empty-table': data.length === 0}"
>
  <ng-template pTemplate="header">
    <tr>
      <th class="text-center">{{ t('tag') }}</th>
      <th class="text-center">{{ t('current-pen') }}</th>
      <th class="text-center">{{ t('home-pen') }}</th>
      <th class="text-center">{{ t('lot') }}</th>
      <th class="text-center">{{ t('sub-group') }}</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5" class="justify-content-center border-bottom-none empty">
        <p class="text-center" *ngIf="!disabled; else elseBlock">
          <b [attr.data-ele]="'click-here'" class="click" (click)="showDialog()"
            ><i class="pi pi-plus-circle"></i>&nbsp;
            <u>{{ t('click-here') }}</u></b
          >
          {{ t('to-add-animals') }}
        </p>
        <ng-template #elseBlock
          ><p class="text-center">
            {{ t('no-animals-selected') }}
          </p></ng-template
        >
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-animal>
    <tr>
      <td class="text-center">{{ animal.tagId }}</td>
      <td class="text-center">{{ animal.currentPenName }}</td>
      <td class="text-center">{{ animal.homePenName }}</td>
      <td class="text-center">{{ animal.lotNumber }}</td>
      <td class="text-center">{{ animal.subGroup }}</td>
    </tr>
  </ng-template>
</p-table>
