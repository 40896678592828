import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractTranslationComponent } from '@common/angular/translation';
import { UpcRfidService } from '@ifhms/common/angular/data-access/upc-api';
import { FormRfidFieldComponent } from '@ifhms/common/angular/upc/rfid';
import { DeviceConnectionStatusEnum, DeviceStatusDto } from '@ifhms/models/feedlot';
import { HubConnectionState } from '@microsoft/signalr';
import { untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyTypesEnum, FormlyWrappersEnum } from '@sersi/angular/formly/core';
import { Observable, map } from 'rxjs';
import {
  ConnectionStatusLabelFormFieldComponent
} from '../connection-status-label-form-field/connection-status-label-form-field.component';

@Component({
  selector: 'ifhms-upc-rfid-settings-form',
  templateUrl: './upc-rfid-settings-form.component.html',
  styleUrls: ['./upc-rfid-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcRfidSettingsFormComponent extends AbstractTranslationComponent {

  translationNamespace = 'components.upc-rfid-settings-form';

  form = new FormGroup({});
  model: DeviceStatusDto;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];

  rfidConnectionStatus$: Observable<string>;

  constructor(private upcRfidService: UpcRfidService) {
    super();
  }

  protected override onTranslationInit():void {
    this.getConnectionStatus();
    this.getRfidSettings();
    this.setFields();
  }

  private getConnectionStatus(): void {
    this.rfidConnectionStatus$ = this.upcRfidService.connectionState$.pipe(
      map((state) => {
        switch(state) {
          case HubConnectionState.Connected:
            return DeviceConnectionStatusEnum.Connected;
          case HubConnectionState.Disconnected:
            return DeviceConnectionStatusEnum.Disconnected;
          default:
            return DeviceConnectionStatusEnum.Disconnected;
        }
      })
    );
  }

  private setFields(): void {

    this.fields = [{
      fieldGroupClassName: 'upc-rfid-settings-form',
      fieldGroup: [
        {
          fieldGroupClassName: 'rfid-wrapper',
          fieldGroup: [
            this.setRfid(),
            this.setRfidConnectionStatus()
          ]
        },
        this.setSettingsFields()
      ]
    }];

  }

  private getRfidSettings(): void {
    this.upcRfidService.deviceSettings$.pipe(
      untilDestroyed(this)
    ).subscribe(settings => {
      this.model = structuredClone(settings);
    });
  }

  private setRfid(): FormlyFieldConfig {
    return {
      type: FormRfidFieldComponent,
      className: 'upc-rfid',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      templateOptions: {
        label$: this.getTranslation$('rfid-label')
      }
    };
  }

  private setRfidConnectionStatus(): FormlyFieldConfig {
    return {
      type: ConnectionStatusLabelFormFieldComponent,
      className: 'upc-rfid-connection',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      templateOptions: {
        label: '',
        connectionStatus: this.rfidConnectionStatus$
      }
    };
  }

  private setSettingsFields(): FormlyFieldConfig {
    return {
      fieldGroupClassName: 'rfid-settings-fields',
      fieldGroup: [
        {
          key: 'settings.name',
          className: 'rfid-name',
          type: FormlyTypesEnum.TEXT_READONLY,
          templateOptions: {
            label$: this.getTranslation$('rfid-name')
          }
        }

      ]
    };
  }

}

