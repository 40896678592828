import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UpcDosingGunsService } from '@ifhms/common/angular/data-access/upc-api';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { SersiAngularUiSpinModule } from '@sersi/angular/ui/spin';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import {
  AssignDosingGunFormComponent,
  DosingGunIconComponent,
  DosingGunsGridFormComponent,
  DosingGunStatusIndicatorComponent,
  ValidateDosingGunFormComponent
} from './components';

import { scopeLoader } from '@common/angular/translation';
import { FormlyModule } from '@ngx-formly/core';
import { SersiAngularFormlyTypesGridModule } from '@sersi/angular/formly/types/grid';
import { InputNumberModule } from 'primeng/inputnumber';
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { DosingGunGridItemComponent } from './components/dosing-gun-grid-item/dosing-gun-grid-item.component';
import { DosingGunsGridComponent } from './components/dosing-guns-grid/dosing-guns-grid.component';
import { EventMenuSetDoseFormComponent } from './components/event-menu-set-dose-form/event-menu-set-dose-form.component';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    ProgressBarModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    SersiAngularUiSpinModule,
    TranslocoModule,
    RippleModule,
    InputNumberModule,
    FormlyModule,
    TooltipModule,
    SersiAngularFormlyTypesGridModule,
    MenuModule
  ],
  declarations: [
    DosingGunsGridFormComponent,
    ValidateDosingGunFormComponent,
    AssignDosingGunFormComponent,
    DosingGunStatusIndicatorComponent,
    DosingGunsGridComponent,
    DosingGunGridItemComponent,
    DosingGunIconComponent,
    EventMenuSetDoseFormComponent
  ],
  providers: [
    UpcDosingGunsService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'dosingguns',
        alias: 'dosingguns',
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        )
      }
    }
  ],
  exports: [
    DosingGunsGridFormComponent,
    DosingGunStatusIndicatorComponent,
    DosingGunsGridComponent,
    DosingGunIconComponent,
    ValidateDosingGunFormComponent,
    AssignDosingGunFormComponent,
    DosingGunGridItemComponent,
    EventMenuSetDoseFormComponent
  ]
})
export class IfhmsCommonAngularUpcDosingGunsModule {}
