import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, filter, finalize, first, map, switchMap, tap } from 'rxjs';

import { getCurrentRouteDataFromSnapshot } from '@common/angular/utils';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { FeedlotPermissionsDto } from '@ifhms/models/shared';

import { FeedlotPermissionsService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class FeedlotPermissionGuard {

  private loading: boolean;

  constructor(
    private feedlotFacade: FeedlotFacade,
    private readonly feedlotPermissionsService: FeedlotPermissionsService,
    private readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const routeData = getCurrentRouteDataFromSnapshot(state);
    return this.getFeedlotPermissions(routeData['feedlotName']).pipe(
      switchMap(() => this.feedlotPermissionsService.hasAnyPermission(route.data?.['permissionList'])
        .pipe(
          map(hasPermission => {
            if (hasPermission) return true;
            return this.router.parseUrl('/auth/access-denied');
          })
        )
      )
    );
  }

  private getFeedlotPermissions(feedlotName: string): Observable<FeedlotPermissionsDto | null> {
    return this.feedlotFacade.state$.pipe(
      tap((feedlotState) => {
        if (!this.loading && !feedlotState.loaded && feedlotName) {
          this.loading = true;
          this.feedlotFacade.getBySlug(feedlotName);
        }
      }),
      filter((state) => state.loaded),
      first(),
      map(state => state.feedlot?.permissions || null),
      finalize(() => (this.loading = false))
    );
  }

}
