export enum AnimalSortBy {
  TagNumberAscending = 1,
  TagNumberDescending = 2,
  NationalIdAscending = 3,
  NationalIdDescending = 4,
  LotNumberAscending = 5,
  LotNumberDescending = 6,
  LotSubGroupNumberAscending = 7,
  LotSubGroupNumberDescending = 8,
  HomePenNumberAscending = 9,
  HomePenNumberDescending = 10,
  CurrentPenNumberAscending = 11,
  CurrentPenNumberDescending = 12
}
