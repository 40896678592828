import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AnimalAvailabilityActions } from './animal-availability.actions';

import * as reducer from './animal-availability.reducer';
import { AnimalAvailabilitySelectors } from './animal-availability.selectors';

@Injectable({ providedIn: 'root' })
export class AnimalAvailabilityFacade {

  isLoading$ = this.store.select(AnimalAvailabilitySelectors.selectLoading);
  isLoaded$ = this.store.select(AnimalAvailabilitySelectors.selectLoaded);

  animalAvailability$ = this.store.select(AnimalAvailabilitySelectors.selectAnimalAvailability);

  constructor(private store: Store<reducer.State>) {
  }

  getAnimalAvailability(animalId: string): void {
    this.store.dispatch(AnimalAvailabilityActions.getAnimalAvailability({ animalId }));
  }

  reset(): void {
    this.store.dispatch(AnimalAvailabilityActions.reset());
  }
}
