import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { distinctUntilChanged, filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SersiFormlyFormComponent } from '@sersi/angular/formly/core';
import { FEATURE_NAME, ListFacade } from '../../+state';
import { TableFilterComponent, TableFilterService } from '@sersi/angular/ui/table';

@UntilDestroy()
@Component({
  selector: 'ifhms-sort-group-outcome-table-filter',
  templateUrl: './sort-group-outcome-table-filter.component.html',
  styleUrls: ['./sort-group-outcome-table-filter.component.scss'],
  providers: [ TableFilterService ]
})
export class SortGroupOutcomeTableFilterComponent implements AfterViewInit {
  translateScope = `${FEATURE_NAME}.components.sort-group-outcome-table-filter`;

  @ViewChild('filterForm') filterForm: SersiFormlyFormComponent;
  @ViewChild('tableFilter') tableFilter: TableFilterComponent;

  @Input() facade: ListFacade;

  constructor(private tableFilterService: TableFilterService) {}

  ngAfterViewInit(): void {
    this.facade.modal$
      .pipe(
        distinctUntilChanged(),
        filter((x) => !x),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.tableFilterService.appliedFilters = {};
        this.tableFilter.clearFilters();
      });
  }

  applyFilter(): void {
    this.facade.filter(this.filterForm.form.value);
  }
}
