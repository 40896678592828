import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { CheckboxModule } from 'primeng/checkbox';

import { SersiAngularUiCoreModule } from '@sersi/angular/ui/core';
import { SersiAngularFormlyCoreModule } from '@sersi/angular/formly/core';
import { SersiAngularUiTypographyModule } from '@sersi/angular/ui/typography';

import { SingleSelectTypeComponent } from './single-select-type/single-select-type.component';
import { SelectItemComponent } from './select-item/select-item.component';
import { MultiSelectTypeComponent } from './multi-select-type/multi-select-type.component';
import { NgLetModule } from 'ng-let';

@NgModule({
  imports: [
    CommonModule,
    SersiAngularFormlyCoreModule,
    FormlySelectModule,
    ReactiveFormsModule,
    FormlyModule,
    SersiAngularUiCoreModule,
    SersiAngularUiTypographyModule,
    NgSelectModule,
    CheckboxModule,
    FormsModule,
    TooltipModule,
    NgLetModule
  ],
  declarations: [
    SelectItemComponent,
    SingleSelectTypeComponent,
    MultiSelectTypeComponent
  ],
  exports: [
    SingleSelectTypeComponent,
    MultiSelectTypeComponent,
    SelectItemComponent
  ]
})
export class SersiAngularFormlyTypesSelectModule {
}
