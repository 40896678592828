import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as SortGroupOutcomeList from './sort-group-outcome-list';
import * as SortGroupOutcomeDetail from './sort-group-outcome-detail';
import * as SortGroupList from './sort-group-list';
import * as SortGroupDetail from './sort-group-detail';
import * as SortGroupOutcomeDetailView from './sort-group-outcome-detail-view';
import * as SortCopyGridList from './sort-group-outcome-copy-grid-list';
import { Observable } from 'rxjs';
import { SortGroupOutcomeFilterBy, SortGroupOutcomeListItemDto, SortGroupOutcomeSortBy } from '@ifhms/models/feedlot';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

export const FEATURE_NAME = 'SORT GROUP OUTCOME';

export const selectFeatureState = createFeatureSelector<State>(FEATURE_NAME);

export const reducer: ActionReducerMap<State> = {
  outcomeList: SortGroupOutcomeList.reducer,
  outcomeDetail: SortGroupOutcomeDetail.reducer,
  outcomeDetailView: SortGroupOutcomeDetailView.reducer,
  groupList: SortGroupList.reducer,
  groupDetail: SortGroupDetail.reducer,
  copyGrid: SortCopyGridList.reducer
};

export interface State {
  outcomeList: SortGroupOutcomeList.State;
  outcomeDetail: SortGroupOutcomeDetail.State;
  outcomeDetailView: SortGroupOutcomeDetailView.State;
  groupList: SortGroupList.State;
  groupDetail: SortGroupDetail.State;
  copyGrid: SortCopyGridList.State;
}

export interface ListFacade {
  list$: Observable<SortGroupOutcomeListItemDto[]>;
  filteredList$: Observable<SortGroupOutcomeListItemDto[]>;
  selectedLength$: Observable<number>;
  totalLength$: Observable<number>;
  modal$: Observable<boolean>;
  filterBy$: Observable<SortGroupOutcomeFilterBy>;
  sortBy$: Observable<SortGroupOutcomeSortBy>;
  select(outcome: SortGroupOutcomeListItemDto): void;
  selectAll(): void;
  filter(filter: SortGroupOutcomeFilterBy): void;
  sort(sort: SortGroupOutcomeSortBy): void;
  createDistinctSelector<
    T extends Extract<keyof SortGroupOutcomeListItemDto, string | number>
  >(property: T): Observable<SersiSelectListItem[]>;
}
