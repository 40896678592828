import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable(
  { providedIn: 'root' }
)
export class UpcTemperatureFormFieldUpdateService {

  private upcTemperature: Subject<number> = new Subject<number>();
  private isUPCThermometerVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isUPCThermometerVisible$ = this.isUPCThermometerVisible.asObservable();

  updateUPCTemperature(data: number): void {
    this.upcTemperature.next(data);
  }

  getUPCTemperature(): Observable<number> {
    return this.upcTemperature.asObservable();
  }

  toggleDisplay(isVisbile: boolean): void {
    this.isUPCThermometerVisible.next(isVisbile);
  }

}