import { AiCategoryTypeEnum, Product, ProductSource } from '@ifhms/models/feedlot';

export function getProductsWithSourceData(products: Product[] | null | undefined, source: ProductSource): Product[] {
  if (!products) return [];

  return products.map((product) => ({
    ...product,
    productSource: source,
    colorCode: getProductColorCode(product, source)
  }));
}

export function getProductColorCode(product: Product, source: ProductSource): string {
  switch (source) {
    case ProductSource.User:
      return '#FFFFFF';
    case ProductSource.SortGroup:
      return '#F2D066';
    case ProductSource.Protocol:
      return getProtocolProductColorCode(product)
    default:
      return '';
  }
}

export function getProtocolProductColorCode(product: Product): string {
  const aiCategoryCode = <keyof typeof AiCategoryTypeEnum>product.aiCategoryCode;
  return AiCategoryTypeEnum[aiCategoryCode] || '';
}

