import { Injectable } from '@angular/core';
import { SortGroupsService } from '@ifhms/common/angular/data-access/feedlot-api';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import {
  SortGroupOutcomeDto,
  SortGroupOutcomeListItemDto
} from '@ifhms/models/feedlot';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  of,
  tap,
  withLatestFrom
} from 'rxjs';
import { FEATURE_NAME } from '..';
import { SortGroupDetailFacade } from '../sort-group-detail/sort-group-detail.facade';
import { SortGroupOutcomeCopyGridListActions } from '../sort-group-outcome-copy-grid-list/sort-group-outcome-copy-grid-list.actions';
import { SortGroupOutcomeCopyGridListFacade } from '../sort-group-outcome-copy-grid-list/sort-group-outcome-copy-grid-list.facade';
import { SortGroupOutcomeListActions } from '../sort-group-outcome-list/sort-group-outcome-list.actions';
import { SortGroupOutcomeListFacade } from '../sort-group-outcome-list/sort-group-outcome-list.facade';
import { SortGroupOutcomeDetailActions } from './sort-group-outcome-detail.actions';
import { SortGroupOutcomeDetailFacade } from './sort-group-outcome-detail.facade';

@Injectable()
export class SortGroupOutcomeDetailEffects {
  translateScope = `${FEATURE_NAME}.state.effects`;

  constructor(
    private actions$: Actions,
    private sortGroupsService: SortGroupsService,
    private sortGroupOutcomeListFacade: SortGroupOutcomeListFacade,
    private sortGroupOutcomeDetailFacade: SortGroupOutcomeDetailFacade,
    private sortGroupOutcomeCopyGridListFacade: SortGroupOutcomeCopyGridListFacade,
    private sortGroupDetailFacade: SortGroupDetailFacade,
    private messageService: MessageService,
    private feedlotFacade: FeedlotFacade,
    private translateService: TranslocoService
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeDetailActions.get),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.sortGroupDetailFacade.detail$
      ),
      exhaustMap(([{ id }, feedlotId, detail]) => {
        if (!detail) throw new Error();
        return this.sortGroupsService
          .getOutcomesDetail(feedlotId, detail.sortGroupId, id)
          .pipe(
            map((result: SortGroupOutcomeDto) => {
              if (!result) throw new Error();
              return SortGroupOutcomeDetailActions.getSuccess({
                outcome: result
              });
            }),
            catchError(() => of(SortGroupOutcomeDetailActions.error()))
          );
      })
    )
  );

  updateModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeDetailActions.updateModal),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.sortGroupDetailFacade.detail$,
        this.sortGroupOutcomeDetailFacade.detail$,
        this.sortGroupOutcomeCopyGridListFacade.selected$
      ),
      exhaustMap(([, feedlotId, detail, outcomeDetail, list]) => {
        if (!detail) throw new Error();
        return this.sortGroupsService
          .getOutcomesDetail(feedlotId, detail.sortGroupId, list[0].id)
          .pipe(
            map((result: SortGroupOutcomeDto) => {
              if (!result) throw new Error();
              const outcome = { ...result };
              outcome.id = outcomeDetail?.id || '';
              outcome.outcomeNumber = outcomeDetail?.outcomeNumber || '';

              outcome.breed = outcomeDetail?.breed || '';
              outcome.breedType = outcomeDetail?.breedType || '';
              outcome.weight = outcomeDetail?.weight || '';
              outcome.age = outcomeDetail?.age || '';
              outcome.ageClass = outcomeDetail?.ageClass || '';
              outcome.temperature = outcomeDetail?.temperature || '';
              outcome.dentition = outcomeDetail?.dentition || '';
              outcome.gender = outcomeDetail?.gender || '';

              return SortGroupOutcomeDetailActions.updateModalSuccess({
                outcome
              });
            }),
            catchError(() => of(SortGroupOutcomeDetailActions.error()))
          );
      })
    )
  );
  updateModalSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeDetailActions.updateModalSuccess),
      map(() => SortGroupOutcomeCopyGridListActions.hideModal())
    )
  );

  applyDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeDetailActions.applyDefault),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.sortGroupDetailFacade.detail$,
        this.sortGroupOutcomeDetailFacade.detail$
      ),
      exhaustMap(([, feedlotId, detail, outcomeDetail]) => {
        if (!detail || !outcomeDetail) throw new Error();
        return this.sortGroupsService
          .applyDefaultOutcome(
            feedlotId,
            detail?.sortGroupId,
            outcomeDetail?.id
          )
          .pipe(
            map((result: SortGroupOutcomeDto) => {
              if (!result) throw new Error();
              return SortGroupOutcomeDetailActions.applyDefaultSuccess({
                outcome: result
              });
            }),
            catchError(() => {
              this.messageService.add({
                severity: 'error',
                summary: this.getTranslationString('fail'),
                detail: this.getTranslationString('apply-default-fail')
              });
              return of(SortGroupOutcomeDetailActions.error());
            })
          );
      })
    )
  );
  applyDefaultSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeDetailActions.applyDefaultSuccess),
      map(({ outcome }) => {
        this.messageService.add({
          severity: 'success',
          summary: this.getTranslationString('success'),
          detail: this.getTranslationString('apply-default-success')
        });
        return SortGroupOutcomeListActions.updateSingle({
          outcome: { ...outcome, changes: { ...outcome } }
        });
      })
    )
  );

  setDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeDetailActions.setDefault),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.sortGroupDetailFacade.detail$,
        this.sortGroupOutcomeDetailFacade.detail$
      ),
      exhaustMap(([{ outcome }, feedlotId, detail, outcomeDetail]) => {
        if (!detail || !outcomeDetail) throw new Error();
        const newOutcome = { ...outcome, id: outcomeDetail?.id };
        return this.sortGroupsService
          .setDefaultOutcome(feedlotId, detail?.sortGroupId, newOutcome)
          .pipe(
            map((result: SortGroupOutcomeDto) => {
              if (!result) throw new Error();
              return SortGroupOutcomeDetailActions.setDefaultSuccess({
                outcome: result
              });
            }),
            catchError(() => {
              this.messageService.add({
                severity: 'error',
                summary: this.getTranslationString('fail'),
                detail: this.getTranslationString('set-default-fail')
              });
              return of(SortGroupOutcomeDetailActions.error());
            })
          );
      })
    )
  );
  setDefaultSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SortGroupOutcomeDetailActions.setDefaultSuccess),
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: this.getTranslationString('success'),
            detail: this.getTranslationString('set-default-success')
          });
        })
      ),
    { dispatch: false }
  );

  clearDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeDetailActions.clearDefault),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.sortGroupDetailFacade.detail$,
        this.sortGroupOutcomeDetailFacade.detail$
      ),
      exhaustMap(([, feedlotId, detail, outcomeDetail]) => {
        if (!detail || !outcomeDetail) throw new Error();
        return this.sortGroupsService
          .clearDefaultOutcome(feedlotId, detail?.sortGroupId)
          .pipe(
            map((result: SortGroupOutcomeDto) => {
              if (!result) throw new Error();
              return SortGroupOutcomeDetailActions.clearDefaultSuccess();
            }),
            catchError(() => {
              this.messageService.add({
                severity: 'error',
                summary: this.getTranslationString('fail'),
                detail: this.getTranslationString('clear-default-fail')
              });
              return of(SortGroupOutcomeDetailActions.error());
            })
          );
      })
    )
  );
  clearDefaultSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SortGroupOutcomeDetailActions.clearDefaultSuccess),
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: this.getTranslationString('success'),
            detail: this.getTranslationString('clear-default-success')
          });
        })
      ),
    { dispatch: false }
  );

  next$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeDetailActions.next),
      withLatestFrom(
        this.sortGroupOutcomeDetailFacade.detail$,
        this.sortGroupDetailFacade.detail$,
        this.feedlotFacade.feedlotId$
      ),
      exhaustMap(
        ([{ outcome, showCopyTo }, detail, groupDetail, feedlotId]) => {
          if (!detail || !groupDetail) throw new Error();
          const newOutcome = { ...outcome, id: detail?.id };
          const newOb = this.sortGroupsService.updateOutcome(
            feedlotId,
            groupDetail?.sortGroupId,
            newOutcome
          );
          return newOb.pipe(
            map((result: SortGroupOutcomeDto) => {
              if (!result) throw new Error();
              return SortGroupOutcomeDetailActions.nextSuccess({
                outcome: result,
                showCopyTo
              });
            }),
            catchError(() => of(SortGroupOutcomeDetailActions.error()))
          );
        }
      )
    )
  );
  nextSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeDetailActions.nextSuccess),
      withLatestFrom(this.sortGroupOutcomeListFacade.filteredList$),
      mergeMap(([{ outcome, showCopyTo }, list]) => {
        const index = list.findIndex((x) => x.id === outcome.id);
        const arr = [];
        if (index !== list.length - 1 && !showCopyTo) {
          arr.push(
            SortGroupOutcomeDetailActions.get({ id: list[index + 1].id })
          );
        } else {
          arr.push(SortGroupOutcomeDetailActions.hideModal());
        }
        arr.push(
          SortGroupOutcomeListActions.updateSingle({
            outcome: { ...outcome, changes: { ...outcome } }
          })
        );
        if (showCopyTo) {
          arr.push(
            SortGroupOutcomeCopyGridListActions.showCopyToModal({
              copyTo: outcome.id
            })
          );
        }
        return arr;
      })
    )
  );

  back$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeDetailActions.back),
      map(() => {
        return SortGroupOutcomeDetailActions.backSuccess();
      }),
      catchError(() => of(SortGroupOutcomeDetailActions.error()))
    )
  );
  backSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeDetailActions.backSuccess),
      withLatestFrom(
        this.sortGroupOutcomeListFacade.filteredList$,
        this.sortGroupOutcomeDetailFacade.detail$
      ),
      map(([, list, outcomeDetail]) => {
        if (!outcomeDetail) throw new Error();
        const index = list.findIndex((x) => x.id === outcomeDetail.id);
        if (index > 0) {
          return SortGroupOutcomeDetailActions.get({ id: list[index - 1].id });
        } else {
          return SortGroupOutcomeDetailActions.hideModal();
        }
      })
    )
  );

  bulk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeDetailActions.bulk),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.sortGroupOutcomeListFacade.selected$,
        this.sortGroupDetailFacade.detail$
      ),
      exhaustMap(
        ([{ outcome, showCopyTo }, feedlotId, selectedList, detail]) => {
          if (!detail) throw new Error();
          const newOutcome = { ...outcome, ids: selectedList.map((x) => x.id) };
          return this.sortGroupsService
            .bulkOutcome(feedlotId, newOutcome, detail?.sortGroupId)
            .pipe(
              map((outcomes: SortGroupOutcomeListItemDto[]) => {
                if (!outcomes) throw new Error();
                return SortGroupOutcomeDetailActions.bulkSuccess({
                  outcomes,
                  copyToId: showCopyTo ? newOutcome.ids[0] : undefined
                });
              }),
              catchError(() => {
                this.messageService.add({
                  severity: 'error',
                  summary: this.getTranslationString('fail'),
                  detail: this.getTranslationString('bulk-output-fail')
                });
                return of(SortGroupOutcomeDetailActions.error());
              })
            );
        }
      )
    )
  );
  bulkSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeDetailActions.bulkSuccess),
      mergeMap(({ outcomes, copyToId }) => {
        const arr = [];
        arr.push(SortGroupOutcomeDetailActions.hideModal());
        arr.push(SortGroupOutcomeListActions.getSuccess({ outcomes }));
        copyToId &&
          arr.push(
            SortGroupOutcomeCopyGridListActions.showCopyToModal({
              copyTo: copyToId
            })
          );
        this.messageService.add({
          severity: 'success',
          summary: this.getTranslationString('success'),
          detail: this.getTranslationString('bulk-output-success')
        });
        return arr;
      })
    )
  );

  private getTranslationString(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }
}
