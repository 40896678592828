<ng-container *transloco="let t; read: translateScope">
  <div *ngIf="facade?.filteredList$ | async as data">
    <p-table
      *transloco="let t; read: translateScope"
      [tableStyle]="{ 'min-width': '50rem' }"
      [value]="data || []"
      [scrollable]="false"
      [virtualScrollItemSize]="50"
      styleClass="p-datatable-lg p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr>
          <th class="w-5rem">
            <ifhms-sort-group-checkbox
              [attr.data-ele]="'select-all-chk-box'"
              [checked]="status | async"
              (check)="selectAll()"
              [disabled]="
                !!(facade.totalLength$ | async) === false || !!disableSelectAll
              "
            ></ifhms-sort-group-checkbox>
          </th>

          <th *ngIf="data?.[0]?.['breed']">
            {{ t('breed') }}
          </th>
          <th *ngIf="data?.[0]?.['breedType']">
            {{ t('breed-type') }}
          </th>
          <th *ngIf="data?.[0]?.['weight']">
            {{ t('weight') }}
          </th>
          <th *ngIf="data?.[0]?.['ageClass']">
            {{ t('age-class') }}
          </th>
          <th *ngIf="data?.[0]?.['age']">
            {{ t('age') }}
          </th>
          <th *ngIf="data?.[0]?.['gender']">
            {{ t('gender') }}
          </th>
          <th *ngIf="data?.[0]?.['dentition']">
            {{ t('dCode') }}
          </th>
          <th *ngIf="data?.[0]?.['temperature']">
            {{ t('temperature') }}
          </th>
          <th>
            {{ t('output-group') }}
          </th>
          <th>
            {{ t('status') }}
          </th>
          <th class="w-5rem"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <div class="w-30rem">
            {{ t('empty') }}
          </div>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-outcome>
        <tr
          [attr.data-outcome-id-ele]="outcome.id"
          tooltipPosition="bottom"
          sersiCursorHover
          [ngClass]="outcome.status ? 'isCompleted' : ''"
        >
          <td (click)="check(outcome)" class="w-5rem">
            <ifhms-sort-group-checkbox
              [checked]="outcome.selected ? true : null"
              (check)="check(outcome)"
            ></ifhms-sort-group-checkbox>
          </td>

          <td *ngIf="outcome.breed" (click)="onRowClick(outcome)">
            {{ outcome.breed }}
          </td>
          <td *ngIf="outcome.breedType" (click)="onRowClick(outcome)">
            {{ outcome.breedType }}
          </td>
          <td *ngIf="outcome.weight" (click)="onRowClick(outcome)">
            {{ outcome.weight }}
          </td>
          <td *ngIf="outcome.ageClass" (click)="onRowClick(outcome)">
            {{ outcome.ageClass }}
          </td>
          <td *ngIf="outcome.age" (click)="onRowClick(outcome)">
            {{ outcome.age !== '0 - 0' ? outcome.age : t('no-age') }}
          </td>
          <td *ngIf="outcome.gender" (click)="onRowClick(outcome)">
            {{ outcome.gender }}
          </td>
          <td *ngIf="outcome.dentition" (click)="onRowClick(outcome)">
            {{ outcome.dentition }}
          </td>
          <td *ngIf="outcome.temperature" (click)="onRowClick(outcome)">
            {{ outcome.temperature }}
          </td>
          <td (click)="onRowClick(outcome)">
            {{ outcome.groupNumber }}
          </td>
          <td (click)="onRowClick(outcome)">
            {{ outcome.status ? t('completed') : '' }}
          </td>
          <td class="w-5rem">
            <i
              *ngIf="outcome.status"
              class="pi pi-eye"
              style="font-size: 1.5rem"
              (click)="view(outcome)"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-container>
