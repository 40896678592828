import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { scopeLoader } from '@common/angular/translation';
import { ImportAnimalsFileService } from '@ifhms/common/angular/data-access/feedlot-api';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FormlyModule } from '@ngx-formly/core';
import { SersiAngularFormlyCoreModule } from '@sersi/angular/formly/core';
import { SersiAngularUiCoreModule } from '@sersi/angular/ui/core';
import { SersiAngularUiIconModule } from '@sersi/angular/ui/icon';
import { SersiAngularUiPulseLoaderModule } from '@sersi/angular/ui/pulse-loader';
import { SersiAngularUiSpinModule } from '@sersi/angular/ui/spin';
import { NgLetModule } from 'ng-let';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { Ripple } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { FEATURE_NAME, reducer } from './+state';
import { CattleSelectorLeftEffects } from './+state/left/left.effects';
import { CattleSelectorLeftFacade } from './+state/left/left.facade';
import { CattleSelectorRightEffects } from './+state/right/right.effects';
import { CattleSelectorRightFacade } from './+state/right/right.facade';
import {
  CattleSelectorCheckboxComponent,
  CattleSelectorDataTableComponent,
  CattleSelectorModalComponent,
  CattleSelectorTableComponent,
  CattleSelectorTableSearchComponent,
  CattleSelectorUploadFormComponent,
  CattleSelectorValidationPanelComponent
} from './components';
import { CattleSelectorInputComponent } from './containers';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,

    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([
      CattleSelectorLeftEffects,
      CattleSelectorRightEffects
    ]),

    SersiAngularUiIconModule.forRoot(),
    SersiAngularFormlyCoreModule,
    SersiAngularUiCoreModule,
    SersiAngularUiSpinModule,

    FormsModule,
    FormlyModule,
    ReactiveFormsModule,

    TableModule,
    ButtonModule,
    CardModule,
    TriStateCheckboxModule,
    TooltipModule,
    OverlayPanelModule,
    DialogModule,
    MessagesModule,
    FileUploadModule,
    SersiAngularUiPulseLoaderModule,
    NgLetModule,
    PanelModule,
    Ripple,
    MenuModule
  ],
  declarations: [
    CattleSelectorModalComponent,
    CattleSelectorTableComponent,
    CattleSelectorTableSearchComponent,
    CattleSelectorCheckboxComponent,
    CattleSelectorDataTableComponent,
    CattleSelectorInputComponent,
    CattleSelectorUploadFormComponent,
    CattleSelectorValidationPanelComponent
  ],
  exports: [CattleSelectorInputComponent],
  providers: [
    // Effects and Facade
    CattleSelectorLeftEffects,
    CattleSelectorLeftFacade,
    CattleSelectorRightEffects,
    CattleSelectorRightFacade,
    ImportAnimalsFileService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: FEATURE_NAME,
        alias: FEATURE_NAME,
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class IfhmsFeedlotFrontEndSharedDomainCattleSelectorModule {
}
