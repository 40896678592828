import { Injectable } from '@angular/core';
import { getLoadedSelectItems } from '@common/angular/utils';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import { LotsActions } from './lots.actions';
import * as reducer from './lots.reducer';
import { LotsSelectors } from './lots.selectors';

@Injectable()
export class LotsFacade {

  loaded$ = this.store.select(LotsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(LotsSelectors.selectItems), this.loaded$);

  itemsWithCloseoutInProgressIncluded$ = getLoadedSelectItems(this.store.select(LotsSelectors.selectWithCloseoutInProgress), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(LotsActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(LotsActions.reset());
  }
}
