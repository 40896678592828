import { createSelector } from '@ngrx/store';
import * as SortGroupListReducer from './sort-group-list.reducer';
import { selectFeatureState, State } from '..';

const selectState = createSelector(
  selectFeatureState,
  (state: State) => state.groupList
);

const selectLoading = createSelector(
  selectState,
  SortGroupListReducer.getLoading
);

const selectLoaded = createSelector(
  selectState,
  SortGroupListReducer.getLoaded
);

const selectModal = createSelector(
  selectState,
  SortGroupListReducer.getModal
);

const selectTable = createSelector(selectState, SortGroupListReducer.getTable);

const selectSearch = createSelector(selectState, SortGroupListReducer.getSearch);

export const SortGroupListSelectors = {
  selectState,
  selectLoaded,
  selectLoading,
  selectModal,
  selectTable,
  selectSearch
};
