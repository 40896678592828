import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../reducer';
import * as reducer from './other-fees.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.otherFees
);

const selectItems = createSelector(selectState, reducer.getAll);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

export const OtherFeesSelectors = {
  selectItems,
  selectLoaded
};
