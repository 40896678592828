import { Component, Input } from '@angular/core';
import { SortGroupTemplateResultDto } from '@ifhms/models/feedlot';
import { SortGroupTemplateAttributes } from '@ifhms/models/shared';
import { FEATURE_NAME } from '../../+state';

@Component({
  selector: 'ifhms-sort-group-search-table',
  templateUrl: './sort-group-search-table.component.html',
  styleUrls: ['./sort-group-search-table.component.scss']
})
export class SortGroupSearchTableComponent {
  translateScope = FEATURE_NAME + '.components.sort-group-table';

  SortGroupTemplateAttributes = SortGroupTemplateAttributes;

  selectedId: string | null = null;

  @Input() data: SortGroupTemplateResultDto | null;

  onRowClick(id: string): void {
    this.selectedId = id;
  }
}
