import { Injectable } from '@angular/core';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import * as reducer from './labour-charges.reducer';
import { LabourChargesSelectors } from './labour-charges.selectors';
import { LabourChargesActions } from './labour-charges.actions';
import { LabourChargesTypes } from '@ifhms/models/feedlot';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class LabourChargesFacade {

  loaded$ = this.store.select(LabourChargesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(LabourChargesSelectors.selectItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(LabourChargesActions.get());
    return this.items$;
  }

  getByType(type: LabourChargesTypes): Observable<SersiSelectListItem[]> {
    return this.items$.pipe(
      map((labourCharges: SersiSelectListItem[]) =>
        labourCharges.filter((item: SersiSelectListItem) => {
          const attr = item?.attributes;
          return attr ? attr['labourChargeType'] === type : item.code
        })
      )
    );
  }

  reset(): void {
    this.store.dispatch(LabourChargesActions.reset());
  }
}
