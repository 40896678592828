import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import * as reducer from './tag-locations.reducer';
import { TagLocationsSelectors } from './tag-locations.selectors';
import { TagLocationsActions } from './tag-locations.actions';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class TagLocationsFacade {

  loaded$ = this.store.select(TagLocationsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(TagLocationsSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(TagLocationsActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(TagLocationsActions.reset());
  }
}
