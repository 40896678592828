import * as feedlotListReducer from './list/feedlots.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export const selectFeatureState = createFeatureSelector<State>('feedlots');

export const reducer: ActionReducerMap<State> = {
  list: feedlotListReducer.reducer
};

export interface State {
  list: feedlotListReducer.State;
}
