import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../reducer';
import * as reducer from './alleys.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.alleys
);

const selectItems = createSelector(selectState, reducer.getAll);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

// const selectTranslatedItems = createSelector(
//   selectItems,
//   CommonUserProfileSelectors.selectLanguage,
//   (items: SelectListItemDto[], lang: string) => convertSelectListItemDtoToSelectListItem(items, lang));

export const AlleysSelectors = {
  selectItems,
  selectLoaded
  //selectTranslatedItems
};
