import { createReducer, on } from '@ngrx/store';
import { UpcApiActions } from './upc-api.actions';
import { DeviceConnectionStatusEnum, UpcSettingsDto, UpcUsageItemDto } from '@ifhms/models/feedlot';

export interface State {
  loading: boolean;
  loaded: boolean;
  upcSettings: UpcSettingsDto | null;
  connectionStatus: DeviceConnectionStatusEnum;
  usages: UpcUsageItemDto[];
}

const initialState: State = {
  loading: false,
  loaded: false,
  upcSettings: null,
  connectionStatus: DeviceConnectionStatusEnum.Disconnected,
  usages: []
};

export const reducer = createReducer(
  initialState,

  on(UpcApiActions.getUPCSettings, (state) => ({
    ...state,
    loading: true,
    loaded: false,
    connectionStatus: DeviceConnectionStatusEnum.Connected
  })),
  on(UpcApiActions.getUPCSettingsSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    upcSettings: action.upcSettings
  })),

  on(UpcApiActions.updateUPCSettings,
    UpcApiActions.resetGateController,  (state) => ({
      ...state,
      loading: true,
      loaded: false
    })),
  on(UpcApiActions.updateUPCSettingsSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    upcSettings: action.upcSettings
  })),
  on(UpcApiActions.connectToUPC, (state) => ({
    ...state,
    loading: true,
    loaded: false,
    connectionStatus: DeviceConnectionStatusEnum.Disconnected
  })),
  on(UpcApiActions.connectToUPCSuccess, (state) => ({
    ...state,
    loading: false,
    loaded: true,
    connectionStatus: DeviceConnectionStatusEnum.Connected
  })),
  on(UpcApiActions.connectToUPCFailure, (state) => ({
    ...state,
    loading: false,
    loaded: true,
    connectionStatus: DeviceConnectionStatusEnum.Disconnected
  })),
  on(UpcApiActions.getUPCUsagesSuccess, (state, action) => ({
    ...state,
    usages: action.usages
  })),
  on(UpcApiActions.error, (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    errMsg: action.errMsg
  }))
);

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getUpcSettings = (state: State): UpcSettingsDto | null => state.upcSettings;
export const getUpcConnectionStatus = (state: State): string => state.connectionStatus;
export const getClientId = (state: State): string => state.upcSettings?.clientId ?? '';
export const getUsages = (state: State): UpcUsageItemDto[] => state.usages;

