import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable, map } from 'rxjs';
import { ProductRoutesActions } from './product-routes.actions';
import * as reducer from './product-routes.reducer';
import { ProductRoutesSelectors } from './product-routes.selectors';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class ProductRoutesFacade {

  loading$ = this.store.select(ProductRoutesSelectors.selectLoading);
  loaded$ = this.store.select(ProductRoutesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(ProductRoutesSelectors.selectTranslatedItems), this.loaded$);
  
  constructor(private store: Store<reducer.State>) { }

  get(workOrderCreatedDate?: string, force?: boolean): void{
    this.store.dispatch(ProductRoutesActions.get({ workOrderCreatedDate, force }));
  }

  getRouteById(id: string): Observable<SersiSelectListItem | undefined> {
    return this.items$.pipe(
      map(routes => routes.find(route => route.id === id))
    );
  }

  getRoutesForProducts(productId: string): Observable<SersiSelectListItem[]> {
    return this.items$.pipe(
      map((productRoutes: SersiSelectListItem[]) => {
        const productRoutesArr = productRoutes.filter((route: SersiSelectListItem) =>
          route?.attributes ? route?.attributes['ProductId'] === productId : false
        );

        const productUniqueRoutes = productRoutesArr.reduce(
          (memo, iter) => {
            // check if we need to replace with the latest one
            if (memo[iter.code] ) {
              const iterAttrDate = this.getProductEffectiveDate(iter, 'EffectiveFrom');
              const memoAttrDate = this.getProductEffectiveDate(memo[iter.code], 'EffectiveFrom');
              if (iterAttrDate && memoAttrDate && iterAttrDate > memoAttrDate) {
                memo[iter.code] = iter;
              }
            }
            memo[iter.code] ||= iter;
            return memo;
          }, {} as { [key: string]: SersiSelectListItem }
        );
        return Object.values(productUniqueRoutes);
      })
    );
  }

  reset(): void {
    this.store.dispatch(ProductRoutesActions.reset());
  }

  private getProductEffectiveDate(product: SersiSelectListItem, dateKey: string): Date | null {
    const dateStrVal = product?.attributes?.[dateKey] as string;
    return dateStrVal ? new Date(dateStrVal) : null;
  }
}
