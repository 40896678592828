import { Injectable } from '@angular/core';
import { getLoadedSelectItems } from '@common/angular/utils';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { map, Observable } from 'rxjs';
import { FeedlotUsersActions } from './feedlot-users.actions';
import * as reducer from './feedlot-users.reducer';
import { FeedlotUsersSelectors } from './feedlot-users.selectors';

@Injectable()
export class FeedlotUsersFacade {

  loaded$ = this.store.select(FeedlotUsersSelectors.selectLoaded);

  items$ = getLoadedSelectItems(this.store.select(FeedlotUsersSelectors.selectItems), this.loaded$);

  feedlotUsers$ = this.items$.pipe(
    map(items => items.filter(item => item.attributes && item.attributes['IsFeedlotUser'] === true)));

  dvms$ = this.items$.pipe(
    map(items => {
      // Immutable so we need to clone the array
      const itemsCopy = structuredClone(items);

      return itemsCopy.map(item => {
        item.isActive = item.attributes && item.isActive && item.attributes['IsDvm'] === true;
        return item;
      })
    })
  );

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(FeedlotUsersActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(FeedlotUsersActions.reset());
  }
}
