<ng-container *ngIf="{ val: appliedFiltersCount$ | async } as filtersCount">
  <p-button
    [attr.data-ele]="'table-filter-btn'"
    (onClick)="openModal()"
    [badge]="filtersCount.val ? filtersCount.val.toString() : ''"
    [label]="btnLabel"
    icon="pi pi-filter"
    styleClass="p-button-filter p-button-sm"
    [rounded]="roundedBtn"
  >
  </p-button>
</ng-container>
<p-dialog
  (onHide)="closeModal()"
  [(visible)]="isDialogOpen"
  [header]="modalTitle"
  [modal]="true"
  [styleClass]="modalClassName"
  [draggable]="false"
  [style]="{ width: modalViewWidth, height: modalViewHeight }"
>
  <div class="flex flex-column">
    <ng-content></ng-content>
    <hr>
    <div class="flex mt-4 justify-content-between">
      <button
        (click)="clearFilters()"
        [label]="btnClearLabel"
        class="p-button-outlined p-button-raised p-button-secondary p-button-rounded mr-4"
        pButton
      ></button>
      <div class="flex flex-row-reverse">
        <button
          [attr.data-ele]="'apply-filter-btn'"
          (click)="onSubmit()"
          *ngIf="hasFilterChange"
          [disabled]="btnApplyDisabled"
          [label]="btnApplyLabel"
          class="ml-4"
          pButton
          [rounded]="true"
        ></button>
        <button (click)="closeModal()" [label]="btnCloseLabel" class="p-button-text p-button-rounded p-button-secondary" pButton></button>
      </div>
    </div>
  </div>
</p-dialog>
