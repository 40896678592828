import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AbstractTranslationComponent } from '@common/angular/translation';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, Observable } from 'rxjs';
import { HubConnectionState } from '@microsoft/signalr';
import { FormWeightFieldComponent } from '@ifhms/common/angular/upc/scale';
import { FormlyTypesEnum, FormlyWrappersEnum } from '@sersi/angular/formly/core';
import {
  ConnectionStatusLabelFormFieldComponent
} from '../connection-status-label-form-field/connection-status-label-form-field.component';
import { DeviceConnectionStatusEnum, DeviceStatusDto } from '@ifhms/models/feedlot';
import { UpcScaleService } from '@ifhms/common/angular/data-access/upc-api';

@UntilDestroy()
@Component({
  selector: 'ifhms-upc-scale-settings-form',
  templateUrl: './upc-scale-settings-form.component.html',
  styleUrls: ['./upc-scale-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcScaleSettingsFormComponent extends AbstractTranslationComponent {

  translationNamespace = 'components.upc-scale-settings-form';

  form = new FormGroup({});
  model: DeviceStatusDto;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];

  scaleConnectionStatus$: Observable<string>;

  constructor(private upcScaleService: UpcScaleService) {
    super();
  }

  protected override onTranslationInit():void {
    this.getConnectionStatus();
    this.getScaleSettings();
    this.setFields();
  }

  private getConnectionStatus(): void {
    this.scaleConnectionStatus$ = this.upcScaleService.connectionState$.pipe(
      map((state) => {
        switch(state) {
          case HubConnectionState.Connected:
            return DeviceConnectionStatusEnum.Connected;
          case HubConnectionState.Disconnected:
            return DeviceConnectionStatusEnum.Disconnected;
          default:
            return DeviceConnectionStatusEnum.Disconnected;
        }
      })
    );
  }

  private getScaleSettings(): void {
    this.upcScaleService.deviceSettings$.pipe(
      untilDestroyed(this)
    ).subscribe(settings => {
      this.model = structuredClone(settings);
    });
  }

  private setFields(): void {

    this.fields = [{
      fieldGroupClassName: 'upc-scale-settings-form',
      fieldGroup: [
        {
          fieldGroupClassName: 'scale-wrapper',
          fieldGroup: [
            this.setScale(),
            this.setScaleConnectionStatus()
          ]
        },
        this.setSettingsFields()
      ]
    }];

  }

  private setScale(): FormlyFieldConfig {
    return {
      type: FormWeightFieldComponent,
      className: 'upc-scale',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      templateOptions: {
        label$: this.getTranslation$('scale-label')
      },
      expressionProperties: {
        'props.readScaleData': () : boolean => true
      }
    };
  }

  private setScaleConnectionStatus(): FormlyFieldConfig {
    return {
      type: ConnectionStatusLabelFormFieldComponent,
      className: 'upc-scale-connection',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      templateOptions: {
        label: '',
        connectionStatus: this.scaleConnectionStatus$
      }
    };
  }

  private setSettingsFields(): FormlyFieldConfig {
    return {
      fieldGroupClassName: 'scale-settings-fields',
      fieldGroup: [
        {
          key: 'settings.name',
          className: 'scale-name',
          type: FormlyTypesEnum.TEXT_READONLY,
          templateOptions: {
            label$: this.getTranslation$('scale-name')
          }
        },
        {
          key: 'settings.scaleSettings.units',
          className: 'scale-units',
          type: FormlyTypesEnum.TEXT_READONLY,
          templateOptions: {
            label$: this.getTranslation$('scale-units')
          }
        },
        {
          key: 'settings.scaleSettings.mode',
          className: 'scale-mode',
          type: FormlyTypesEnum.TEXT_READONLY,
          templateOptions: {
            label$: this.getTranslation$('scale-mode')
          }
        },
        {
          key: 'settings.scaleSettings.algorithm',
          className: 'scale-algorithm',
          type: FormlyTypesEnum.TEXT_READONLY,
          templateOptions: {
            label$: this.getTranslation$('scale-algorithm')
          }
        }

      ]
    };
  }

}
