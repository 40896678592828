import { SortGroupOutcomeFilterBy, SortGroupOutcomeListItemDto, SortGroupOutcomeSortBy } from '@ifhms/models/feedlot';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export const NAMESPACE = '[SORT GROUP OUTCOME - LIST]';

const get = createAction(`${NAMESPACE} Get`);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ outcomes: SortGroupOutcomeListItemDto[] }>()
);

// Selects-DeSelects from the given id
const select = createAction(
  `${NAMESPACE} Select`,
  props<{ outcome: Update<SortGroupOutcomeListItemDto> }>()
);

const selectSuccess = createAction(
  `${NAMESPACE} Select Success`,
  props<{ outcome: Update<SortGroupOutcomeListItemDto> }>()
);

// Update Single Record with the given id
const updateSingle = createAction(
  `${NAMESPACE} Update Single`,
  props<{ outcome: Update<SortGroupOutcomeListItemDto> }>()
);

const updateSingleSuccess = createAction(
  `${NAMESPACE} Update Single Success`,
  props<{ outcome: Update<SortGroupOutcomeListItemDto> }>()
);

// Selects-DeSelects All
const selectAll = createAction(`${NAMESPACE} Select All`);

const selectAllSuccess = createAction(
  `${NAMESPACE} Select All Success`,
  props<{ updates: Update<SortGroupOutcomeListItemDto>[] }>()
);

// Filter
const filter = createAction(
  `${NAMESPACE} Filter`,
  props<{ filter: SortGroupOutcomeFilterBy }>()
);

const filterSuccess = createAction(
  `${NAMESPACE} Filter Success`,
  props<{ filter: SortGroupOutcomeFilterBy }>()
);

// Sort
const sortBy = createAction(
  `${NAMESPACE} Sort`,
  props<{ sortBy: SortGroupOutcomeSortBy }>()
);

const sortBySuccess = createAction(
  `${NAMESPACE} Sort Success`,
  props<{ sortBy: SortGroupOutcomeSortBy }>()
);

// Clear Output
const clearOutput = createAction(`${NAMESPACE} Clear Output`);

const clearOutputSuccess = createAction(
  `${NAMESPACE} Clear Output Success`,
  props<{ outcomes: SortGroupOutcomeListItemDto[] }>()
);

const showModal = createAction(`${NAMESPACE} Show Modal`);

const hideModal = createAction(`${NAMESPACE} Hide Modal`);

const error = createAction(`${NAMESPACE} Error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const SortGroupOutcomeListActions = {
  get,
  getSuccess,
  select,
  selectSuccess,
  selectAll,
  selectAllSuccess,
  updateSingle,
  updateSingleSuccess,
  filter,
  filterSuccess,
  sortBy,
  sortBySuccess,
  clearOutput,
  clearOutputSuccess,
  showModal,
  hideModal,
  error,
  reset
};
