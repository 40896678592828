import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import * as reducer from './genders.reducer';
import { GendersSelectors } from './genders.selectors';
import { GendersActions } from './genders.actions';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class GendersFacade {

  loaded$ = this.store.select(GendersSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(GendersSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(GendersActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(GendersActions.reset());
  }
}
