import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FEATURE_NAME, ListFacade } from '../../+state';
import { Observable, combineLatest } from 'rxjs';
import { SortGroupOutcomeListItemDto } from '@ifhms/models/feedlot';

@Component({
  selector: 'ifhms-sort-group-outcome-table',
  templateUrl: './sort-group-outcome-table.component.html',
  styleUrls: ['./sort-group-outcome-table.component.scss']
})
export class SortGroupTableComponent implements OnInit {
  translateScope = FEATURE_NAME + '.components.sort-group-table';

  status: Observable<boolean | null>;
  data: Observable<SortGroupOutcomeListItemDto[] | null>;

  @Input() facade: ListFacade;
  @Input() disableSelectAll? : boolean;
  @Output() rowClick = new EventEmitter<SortGroupOutcomeListItemDto>();
  @Output() viewClick = new EventEmitter<SortGroupOutcomeListItemDto>();

  ngOnInit(): void {
    this.status = combineLatest(
      [this.facade.totalLength$, this.facade.selectedLength$],
      (total, selected) => {
        if (selected == 0) return null;
        else if (selected == total) return true;
        return false;
      }
    );
  }

  check(outcome: SortGroupOutcomeListItemDto): void {
    this.facade.select(outcome);
  }

  onRowClick(outcome: SortGroupOutcomeListItemDto): void {
    this.rowClick.emit(outcome);
  }

  view(outcome: SortGroupOutcomeListItemDto): void {
    this.viewClick.emit(outcome);
  }

  selectAll(): void {
    this.facade.selectAll();
  }
}
