import { Injectable } from '@angular/core';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { AnimalSelectorItemDto, AnimalSelectorRequestDto } from '@ifhms/models/feedlot';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import { Facade } from '..';
import { CattleSelectorLeftActions } from './left.actions';
import { State } from './left.reducer';
import { CattleSelectorLeftSelectors } from './left.selectors';

@Injectable()
export class CattleSelectorLeftFacade implements Facade {
  availableLength$: Observable<number> = this.store.select(
    CattleSelectorLeftSelectors.availableLength
  );
  list$: Observable<AnimalSelectorItemDto[]> = this.store.select(
    CattleSelectorLeftSelectors.selectAll
  );
  selected$: Observable<AnimalSelectorItemDto[]> = this.store.select(
    CattleSelectorLeftSelectors.selectAllSelected
  );
  isLoading$: Observable<boolean> = this.store.select(
    CattleSelectorLeftSelectors.selectLoading
  );
  isLoaded$: Observable<boolean> = this.store.select(
    CattleSelectorLeftSelectors.selectLoaded
  );
  selectedLength$: Observable<number> = this.store.select(
    CattleSelectorLeftSelectors.selectedLength
  );
  totalLength$: Observable<number> = this.store.select(
    CattleSelectorLeftSelectors.totalLength
  );
  filter$: Observable<AnimalSelectorRequestDto> = this.store.select(
    CattleSelectorLeftSelectors.selectFilter
  );
  filterApplied$: Observable<boolean> = this.store.select(
    CattleSelectorLeftSelectors.selectFilterApplied
  );

  selectedAnimalsLocationTypes$: Observable<SersiSelectListItem[]> =
    this.referenceDataFacade.homeLocations$;
  selectedAnimalsLots$: Observable<SersiSelectListItem[]> =
    this.referenceDataFacade.lots$;
  selectedAnimalsCurrentPen$: Observable<SersiSelectListItem[]> =
    this.referenceDataFacade.locations$;

  constructor(
    private store: Store<State>,
    private referenceDataFacade: ReferenceDataFacade
  ) {
  }

  replace(): void {
    this.store.dispatch(CattleSelectorLeftActions.replace());
  }

  add(animals: AnimalSelectorItemDto[]): void {
    this.store.dispatch(CattleSelectorLeftActions.add({ animals }));
  }

  select(animal: AnimalSelectorItemDto): void {
    this.store.dispatch(
      CattleSelectorLeftActions.select({
        animal: { ...animal, changes: { selected: !animal.selected } }
      })
    );
  }

  selectMultiple(animals: AnimalSelectorItemDto[]): void {
    this.store.dispatch(
      CattleSelectorLeftActions.selectMultiple({
        animals: animals.map((animal) => {
          return { ...animal, changes: { selected: true } };
        })
      })
    );
  }

  selectAll(): void {
    this.store.dispatch(CattleSelectorLeftActions.selectAll());
  }

  filter(filter: AnimalSelectorRequestDto): void {
    this.store.dispatch(CattleSelectorLeftActions.filter({ filter }));
  }

  clearFilter(): void {
    this.store.dispatch(CattleSelectorLeftActions.clearFilter());
  }

  remove(): void {
    this.store.dispatch(CattleSelectorLeftActions.remove());
  }

  reset(): void {
    this.store.dispatch(CattleSelectorLeftActions.reset());
  }
}
