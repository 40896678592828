import { CommonUserProfileSelectors } from '@common/angular/features/user-profile';
import { convertSelectListItemDtoToSelectListItem } from '@common/angular/translation';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../reducer';
import * as reducer from './sort-types.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.sortTypes
);

const selectItems = createSelector(selectState, reducer.getAll);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

const selectTranslatedItems = createSelector(
  selectItems,
  CommonUserProfileSelectors.selectLanguage,
  (items: SelectListItemWithTranslationDto[], lang: string) => convertSelectListItemDtoToSelectListItem(items, lang));

export const SortTypesSelectors = {
  selectItems,
  selectLoaded,
  selectTranslatedItems
};
