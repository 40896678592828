import { Injectable } from '@angular/core';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as reducer from './other-fees.reducer';
import { OtherFeesSelectors } from './other-fees.selectors';
import { OtherFeesActions } from './other-fees.actions';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class OtherFeesFacade {

  loaded$ = this.store.select(OtherFeesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(OtherFeesSelectors.selectItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(OtherFeesActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(OtherFeesActions.reset());
  }
}
