import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { map, Observable } from 'rxjs';
import { ProductsActions } from './products.actions';
import * as reducer from './products.reducer';
import { ProductsSelectors } from './products.selectors';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class ProductsFacade {
  
  loaded$ = this.store.select(ProductsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(ProductsSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}

  get(workOrderCreatedDate?: string, force?: boolean): Observable<SersiSelectListItem[]> {
    this.store.dispatch(ProductsActions.get({ workOrderCreatedDate, force }));
    return this.items$;
  }

  getProductWithRoutes(): Observable<SersiSelectListItem[]> {
    return this.items$.pipe(
      map((items: SersiSelectListItem[]) =>
        items.filter((product: SersiSelectListItem) =>
          product?.attributes ? !!product?.attributes['hasRoutes'] : false
        )
      )
    );
  }

  getProductById(productId: string): Observable<SersiSelectListItem | undefined> {
    return this.items$.pipe(
      map((productList: SersiSelectListItem[]) =>
        productList.find((product => product.id === productId))
      )
    );
  }

  getProductsForProductTypes(
    productTypeId: string
  ): Observable<SersiSelectListItem[]> {
    return this.items$.pipe(
      map((productList: SersiSelectListItem[]) =>
        productList.filter((product: SersiSelectListItem) => {
          const attrs = product?.attributes;
          const isMatchingProduct =
            attrs &&
            attrs['ProductTypeId'] === productTypeId &&
            !!attrs['hasRoutes'];

          return isMatchingProduct;
        })
      )
    );
  }

  reset(): void {
    this.store.dispatch(ProductsActions.reset());
  }
}
