import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  FeedlotSelectListNoTranslationType,
  FeedlotSelectListWithTranslationType
} from '@ifhms/models/feedlot';
import { SelectListItemDto, SelectListItemWithTranslationDto } from '@ifhms/models/shared';

import { AH_FMS_FEEDLOT_DOMAIN_API } from '../tokens';

@Injectable()
export class SelectListsService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {}

  getSelectListData(
    feedlotId: string,
    noTranslationType: FeedlotSelectListNoTranslationType
  ): Observable<SelectListItemDto[]> {
    const params = new HttpParams({
      fromObject: { noTranslationType }
    });
    return this.http.get<SelectListItemDto[]>(
      `${this.apiUrl}/Feedlot/${feedlotId}/SelectLists/SelectListData`,
      { params }
    );
  }

  getSelectListDataTranslated(
    feedlotId: string,
    withTranslationType: FeedlotSelectListWithTranslationType
  ): Observable<SelectListItemWithTranslationDto[]> {
    const params = new HttpParams({
      fromObject: { withTranslationType }
    });
    return this.http.get<SelectListItemWithTranslationDto[]>(
      `${this.apiUrl}/Feedlot/${feedlotId}/SelectLists/SelectListDataTranslated`,
      { params }
    );
  }

  getProductsSelectList(
    feedlotId: string,
    workOrderCreatedDate?: string
  ): Observable<SelectListItemWithTranslationDto[]> {
    if (workOrderCreatedDate) {
      const params = new HttpParams({
        fromObject: { workOrderCreatedDate }
      });
      return this.http.get<SelectListItemWithTranslationDto[]>(
        `${this.apiUrl}/Feedlot/${feedlotId}/SelectLists/SelectListProducts`,
        { params }
      );
    }

    return this.http.get<SelectListItemWithTranslationDto[]>(
      `${this.apiUrl}/Feedlot/${feedlotId}/SelectLists/SelectListProducts`
    );
  }

  getProductRoutesSelectList(
    feedlotId: string,
    workOrderCreatedDate?: string
  ): Observable<SelectListItemWithTranslationDto[]> {
    if (workOrderCreatedDate) {
      const params = new HttpParams({
        fromObject: { workOrderCreatedDate }
      });
      return this.http.get<SelectListItemWithTranslationDto[]>(
        `${this.apiUrl}/Feedlot/${feedlotId}/SelectLists/SelectListProductRoutes`,
        { params }
      );
    }

    return this.http.get<SelectListItemWithTranslationDto[]>(
      `${this.apiUrl}/Feedlot/${feedlotId}/SelectLists/SelectListProductRoutes`
    );
  }
}
