import { Injectable } from '@angular/core';
import { getLoadedSelectItems } from '@common/angular/utils';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { map, Observable } from 'rxjs';
import { ProductTypesActions } from './product-types.actions';
import * as reducer from './product-types.reducer';
import { ProductTypesSelectors } from './product-types.selectors';

@Injectable()
export class ProductTypesFacade {

  loaded$ = this.store.select(ProductTypesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(ProductTypesSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(ProductTypesActions.get());
    return this.items$;
  }

  getWithActiveProducts(): Observable<SersiSelectListItem[]> {
    return this.items$.pipe(
      map((items: SersiSelectListItem[]) =>
        items.map(product => ({
          ...product,
          isActive: product.isActive && !!product?.attributes?.['hasActiveProducts']
        }))
      ),
      map((items: SersiSelectListItem[]) => {
        const sortedList = [...items].sort((a, b) => a.code.localeCompare(b.code))
        return sortedList;
      })
    );
  }

  reset(): void {
    this.store.dispatch(ProductTypesActions.reset());
  }
}
