import { SelectListItemDto } from '@ifhms/models/shared';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Reference - TX Groups] - ';

const get = createAction(`${NAMESPACE} Get`);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ items: SelectListItemDto[] }>()
);

const reset = createAction(`${NAMESPACE} Reset`);

const error = createAction(`${NAMESPACE} error`);

export const TxGroupsActions = {
  error,
  get,
  getSuccess,
  reset
};