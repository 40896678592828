import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  GoToEventDto,
  RailWorkOrderDto,
  RailWorkOrderUpdateDto,
  WorkOrderRailListDto,
  WorkOrderRailListRequestDto,
  WorkOrderRailStatus
} from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';

@Injectable()
export class RailWorkOrderService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {
  }

  getList(feedlotId: string, request: WorkOrderRailListRequestDto): Observable<WorkOrderRailListDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<WorkOrderRailListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder`, {
      params
    });
  }

  getActiveEventId(feedlotId: string, workOrderId: string): Observable<GoToEventDto> {
    return this.http.get<GoToEventDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder/${workOrderId}/GoToEvent`);
  }

  createRail(feedlotId: string, dto: unknown): Observable<RailWorkOrderDto> {
    return this.http.post<RailWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail`, dto);
  }

  getRailWorkOrder(feedlotId: string, workOrderId: string): Observable<RailWorkOrderDto> {
    return this.http.get<RailWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder/${workOrderId}`);
  }

  update(feedlotId: string, railWorkOrderUpdate: RailWorkOrderUpdateDto): Observable<RailWorkOrderDto> {
    return this.http.put<RailWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder`, railWorkOrderUpdate);
  }

  updateStatus(feedlotId: string, workOrderId: string, status: WorkOrderRailStatus): Observable<RailWorkOrderDto> {
    const requestStatus = status === WorkOrderRailStatus.Scheduled ? 'Schedule' : status === WorkOrderRailStatus.ProcessingComplete ? 'RequestApproval' : 'Finalize';
    return this.http.put<RailWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder/${workOrderId}/${requestStatus}`, null);
  }

  generateReport(feedlotId: string, workOrderId: string): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: feedlotId ?? '',
            workOrderId: workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/Reports/RailWorkOrder`, options);
  }

  finalize(feedlotId: string, workOrderId: string): Observable<RailWorkOrderDto> {
    return this.http.put<RailWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder/${workOrderId}/Finalize`, null);
  }

  completeWorkOrder(feedlotId: string, workOrderId: string): Observable<RailWorkOrderDto> {
    return this.http.put<RailWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder/${workOrderId}/Complete`, null);
  }

  deleteWorkOrder(feedlotId: string, workOrderId: string): Observable<RailWorkOrderDto> {
    return this.http.delete<RailWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder/${workOrderId}`);
  }
}
