import { createAction, props } from '@ngrx/store';
import { UpcSettingsDto, UpcUsageItemDto } from '@ifhms/models/feedlot';

const NAMESPACE = '[UPC]';

const getUPCSettings = createAction(`${NAMESPACE} Get UPC Settings`, props<{ clientId: string }>());

const getUPCSettingsSuccess = createAction(`${NAMESPACE} Get UPC Settings Success`, props<{ upcSettings: UpcSettingsDto }>());

const updateUPCSettings = createAction(`${NAMESPACE} Update UPC Settings`, props<{ upcSettings: Partial<UpcSettingsDto> }>());

const updateUPCSettingsSuccess = createAction(`${NAMESPACE} Update UPC Settings Success`, props<{ upcSettings: UpcSettingsDto }>());

const getUPCUsages = createAction(`${NAMESPACE} Get UPC Usages`);

const getUPCUsagesSuccess = createAction(`${NAMESPACE} Get UPC Usages Success`, props<{ usages: UpcUsageItemDto[] }>());

const connectToUPC = createAction(`${NAMESPACE} Connect to Upc`, props<{ url?: string }>());

const connectToUPCSuccess = createAction(`${NAMESPACE} Connection to UPC Success`);

const connectToUPCFailure = createAction(`${NAMESPACE} Connection to UPC Failure`);

const resetGateController = createAction(`${NAMESPACE} Reset Gate Controller`);

const error = createAction(
  `${NAMESPACE} Error`,
  props<{ errMsg: string }>()
);

export const UpcApiActions = {
  error,
  updateUPCSettings,
  updateUPCSettingsSuccess,
  getUPCSettings,
  getUPCSettingsSuccess,
  connectToUPCSuccess,
  connectToUPCFailure,
  connectToUPC,
  resetGateController,
  getUPCUsages,
  getUPCUsagesSuccess
};
