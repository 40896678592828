import { Injectable } from '@angular/core';
import { getLoadedSelectItems } from '@common/angular/utils';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import { LotOwnersActions } from './lot-owners.actions';
import * as reducer from './lot-owners.reducer';
import { LotOwnersSelectors } from './lot-owners.selectors';

@Injectable()
export class LotOwnersFacade {

  loaded$ = this.store.select(LotOwnersSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(LotOwnersSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(LotOwnersActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(LotOwnersActions.reset());
  }
}
