<sersi-spin [spin]="loading$ | async">
    <ng-container *transloco="let t; read: scopedNamespace">
        <formly-form
                [fields]="fields"
                [form]="form"
                [model]="model"
        >
        </formly-form>
        <div class="container-footer-buttons">
            <ng-container>
                <button
                        pButton
                        data-ele="cancel-btn"
                        [label]="t('cancel-button')"
                        class="p-button-secondary"
                        (click)="cancel()"
                        [text]="true"
                        [rounded]="true">
                </button>
            </ng-container>
            <ng-container>
                <button
                        pButton
                        data-ele="validate-btn"
                        [label]="t('set-dose-button')"
                        class="p-button-raised validate-button"
                        [disabled]="!form.valid"
                        (click)="onSetDose()"
                        icon="pi pi-check"
                        iconPos="left"
                        [rounded]="true">
                </button>
            </ng-container>
        </div>
    </ng-container>
</sersi-spin>