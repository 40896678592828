import { Injectable } from '@angular/core';

import { Product } from '@ifhms/models/feedlot';
import { toPromise } from '@common/angular/utils';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { SelectListItemDto } from '@ifhms/models/shared';

import { getProductWithQuantity, getTotalQty } from '../utils';

@Injectable({ providedIn: 'root' })
export class ProductItemService {

  constructor(private referenceDataFacade: ReferenceDataFacade) { }

  async transformProducts(products: Product[] | null, weight?: number, numberOfHeads?: number, forceQtyUpdate = false): Promise<Product[] | null> {
    if (!products?.length) return products;

    const transformedProducts: Product[] = [];

    // Get product quantities by selected route
    for (const item of products) {
      const autoSelectedProduct = await this.getProductWithAutoSelectedId(item, products);
      const product = await this.getProductWithTransformedRoutes(autoSelectedProduct);
      const hasSelectedRoute = !!product.routeDetailId;
      const isMissingQty = product.qty === null || forceQtyUpdate;
      let transformedProduct = product;
      if (hasSelectedRoute && isMissingQty) {
        const routeDetail = await toPromise(this.referenceDataFacade.getRouteById(product.routeDetailId!)) || null;

        const productWithQty = getProductWithQuantity(product, routeDetail, weight);
        transformedProduct = {
          ...productWithQty,
          totalQty: getTotalQty(productWithQty.qty, numberOfHeads)
        };
      }
      transformedProducts.push(transformedProduct);
    }

    return transformedProducts;
  }

  private async getProductWithTransformedRoutes(product: Product): Promise<Product> {
    const { productId } = product;

    if (!productId) return product;

    const productRoutes = await toPromise(this.referenceDataFacade.getRoutesForProducts(productId));
    const activeRoutes = productRoutes.filter(route => route.isActive) as SelectListItemDto[];

    product.activeRoutes = activeRoutes;

    if (product.routeDetailId) return product;

    // auto select single route option
    if (activeRoutes.length === 1) {
      product.routeDetailId = <string>activeRoutes[0].id;
    }

    return product;
  }

  private async getProductWithAutoSelectedId(product: Product, existingProducts: Product[]): Promise<Product> {
    const { productId, productTypeId } = product;

    if (!!productId || !productTypeId) return product;

    const productOptions$ = this.referenceDataFacade.getProductsForProductTypes(productTypeId);
    const productList = await toPromise(productOptions$)
    const activeProducts = productList.filter(option => option.isActive);

    // auto select product if there is only one option
    if (activeProducts.length === 1) {
      const [singleProduct] = activeProducts;
      const singleProductId = <string>singleProduct.id;

      // check if proposed product is not already used on other products
      const existingProduct = existingProducts.find(product => product.id === singleProductId);

      product.productId = existingProduct ? null : singleProductId;
    }

    return product;
  }

}
