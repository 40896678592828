import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { scopeLoader } from '@common/angular/translation';
import { IfhmsCommonAngularUpcDosingGunsModule } from '@ifhms/common/angular/upc/dosing-guns';
import { IfhmsCommonAngularUpcGateControllerModule } from '@ifhms/common/angular/upc/gate-controller';
import { IfhmsCommonAngularUpcRfidModule } from '@ifhms/common/angular/upc/rfid';
import { IfhmsCommonAngularUpcScaleModule } from '@ifhms/common/angular/upc/scale';
import { UpcApiFacade } from '@ifhms/common/angular/upc/shared';
import { IfhmsCommonAngularUpcThermometerModule } from '@ifhms/common/angular/upc/thermometer';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { FormlyModule } from '@ngx-formly/core';
import { ButtonModule } from 'primeng/button';
import {
  ConnectionStatusLabelFormFieldComponent,
  UpcDosingGunsSettingsFormComponent,
  UpcGlobalSettingsFormComponent
} from './components';
import { UpcGateSettingsFormComponent } from './components/upc-gate-settings-form/upc-gate-settings-form.component';
import { UpcRfidSettingsFormComponent } from './components/upc-rfid-settings-form/upc-rfid-settings-form.component';
import { UpcScaleSettingsFormComponent } from './components/upc-scale-settings-form/upc-scale-settings-form.component';
import {
  UpcThermometerSettingsFormComponent
} from './components/upc-thermometer-settings-form/upc-thermometer-settings-form.component';
import { UpcDosingGunSettingsComponent } from './containers/upc-dosing-gun-settings/upc-dosing-gun-settings.component';
import {
  UpcGateControllerSettingsComponent
} from './containers/upc-gate-controller-settings/upc-gate-controller-settings.component';
import { UpcGlobalSettingsComponent } from './containers/upc-global-settings';
import { UpcRfidSettingsComponent } from './containers/upc-rfid-settings/upc-rfid-settings.component';
import { UpcScaleSettingsComponent } from './containers/upc-scale-settings/upc-scale-settings.component';
import {
  UpcThermometerSettingsComponent
} from './containers/upc-thermometer-settings/upc-thermometer-settings.component';
import { FeedlotSettingsUpcRoutingModule } from './feedlot-settings-upc-routing.module';
import { CommonAngularUtilsModule } from '@common/angular/utils';
import { TableModule } from 'primeng/table';

@NgModule({
  imports: [
    CommonModule,
    FormlyModule,
    ReactiveFormsModule,
    ButtonModule,
    TranslocoModule,
    IfhmsCommonAngularUpcScaleModule,
    IfhmsCommonAngularUpcRfidModule,
    IfhmsCommonAngularUpcThermometerModule,
    IfhmsCommonAngularUpcGateControllerModule,
    FeedlotSettingsUpcRoutingModule,
    IfhmsCommonAngularUpcDosingGunsModule,
    CommonAngularUtilsModule,
    TableModule
  ],
  declarations: [
    UpcGlobalSettingsComponent,
    UpcGlobalSettingsFormComponent,
    ConnectionStatusLabelFormFieldComponent,
    UpcScaleSettingsComponent,
    UpcRfidSettingsComponent,
    UpcThermometerSettingsComponent,
    UpcGateControllerSettingsComponent,
    UpcDosingGunSettingsComponent,
    UpcScaleSettingsFormComponent,
    UpcRfidSettingsFormComponent,
    UpcThermometerSettingsFormComponent,
    UpcGateSettingsFormComponent,
    UpcDosingGunsSettingsFormComponent
  ],
  providers: [
    UpcApiFacade,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'upcGlobalSettings',
        alias: 'upcGlobalSettings',
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class IfhmsFeedlotSharedFeaturesFeedlotSettingsUpcModule {}
