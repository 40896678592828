import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';

import { CommonPermissionsService } from '@common/angular/permissions';
import { FeedlotDto } from '@ifhms/models/feedlot';
import { FeedlotService } from '@ifhms/common/angular/data-access/feedlot-api';
import { transformPermission } from '@common/angular/utils';

import { FeedlotActions } from './feedlot.actions';

@Injectable({
  providedIn: 'root'
})
export class FeedlotEffects {

  constructor(
    protected actions$: Actions,
    private feedlotService: FeedlotService,
    private commonPermissionService: CommonPermissionsService
  ) {
  }

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedlotActions.get),
      exhaustMap((action) => {
        return this.feedlotService.get(action.id).pipe(
          tap((feedlot: FeedlotDto) => {
            this.commonPermissionService.setPermissions(transformPermission(feedlot.permissions))
          }),
          map((feedlot: FeedlotDto) => {
            if (!feedlot) throw new Error();
            return FeedlotActions.getSuccess({ feedlot });
          }),
          catchError(() => of(FeedlotActions.error()))
        );
      })
    )
  );

  getBySlug$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedlotActions.getBySlug),
      exhaustMap((action) => {
        return this.feedlotService.getBySlug(action.slug).pipe(
          tap((feedlot: FeedlotDto) => {
            this.commonPermissionService.setPermissions(transformPermission(feedlot.permissions))
          }),
          map((feedlot: FeedlotDto) => {
            if (!feedlot) throw new Error();
            return FeedlotActions.getSuccess({ feedlot });
          }),
          catchError(() => of(FeedlotActions.error()))
        );
      })
    )
  );
}
