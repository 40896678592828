export enum AnimalUnavailabilityReason {
  TreatmentEvent = 1,
  PostMortemEvent = 2,
  ArrivalEvent = 3,
  RehandlingWorkOrder = 4,
  RehandlingEvent = 5,
  RailWorkOrder = 6,
  RailEvent = 7,
  ShipmentWorkOrder = 8,
  MovementWorkOrder = 9,
  LotTransferWorkOrder = 10,
  Died = 11,
  Shipped = 12,
  Railed = 13
}