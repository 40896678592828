import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';
import { AhCommonDialogService } from '@common/angular/utils';
import { SortGroupOutcomeListItemDto } from '@ifhms/models/feedlot';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, take } from 'rxjs';
import { FEATURE_NAME } from '../../+state';
import { SortGroupDetailFacade } from '../../+state/sort-group-detail/sort-group-detail.facade';
import { SortGroupOutcomeCopyGridListFacade } from '../../+state/sort-group-outcome-copy-grid-list/sort-group-outcome-copy-grid-list.facade';
import { SortGroupOutcomeDetailFacade } from '../../+state/sort-group-outcome-detail/sort-group-outcome-detail.facade';
import { SortGroupOutcomeListFacade } from '../../+state/sort-group-outcome-list/sort-group-outcome-list.facade';

@UntilDestroy()
@Component({
  selector: 'ifhms-sort-group-outcome-grid-modal',
  templateUrl: './sort-group-outcome-grid-modal.component.html',
  styleUrls: ['./sort-group-outcome-grid-modal.component.scss']
})
export class SortGroupOutcomeGridModalComponent implements OnInit {
  translateScope = `${FEATURE_NAME}.components.sort-group-outcome-modal`;

  display = false;
  loading$: Observable<boolean>;
  table: Observable<SortGroupOutcomeListItemDto[]>;

  @Output() viewClick = new EventEmitter<SortGroupOutcomeListItemDto>();

  constructor(
    public sortGroupOutcomeListFacade: SortGroupOutcomeListFacade,
    public sortGroupDetailFacade: SortGroupDetailFacade,
    public sortGroupOutcomeDetailFacade: SortGroupOutcomeDetailFacade,
    public sortGroupOutcomeCopyGridListFacade: SortGroupOutcomeCopyGridListFacade,
    private cdr: ChangeDetectorRef,
    private dialogService: AhCommonDialogService,
    private translocoService: TranslocoService
  ) {
    this.loading$ = this.sortGroupOutcomeListFacade.isLoading$;
    this.table = this.sortGroupOutcomeListFacade.filteredList$;
  }

  ngOnInit(): void {
    this.sortGroupOutcomeCopyGridListFacade.modal$
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.display = val;
        this.cdr.detectChanges();
        if (!val) this.sortGroupOutcomeCopyGridListFacade.reset();
      });
  }

  onClose(): void {
    this.sortGroupOutcomeCopyGridListFacade.hideModal();
    this.sortGroupOutcomeCopyGridListFacade.reset();
  }

  onApply(): void {
    this.sortGroupOutcomeCopyGridListFacade.state$
      .pipe(untilDestroyed(this), take(1))
      .subscribe(({ fromForm }) => {
        if (fromForm) {
          this.sortGroupOutcomeDetailFacade.updateModal();
        } else {
          this.dialogService.openConfirmationModal({
            message: this.getTranslation('message'),
            header: this.getTranslation('overwrite'),
            acceptIcon: 'delete',
            acceptLabel: this.getTranslation('apply-overwrite'),
            accept: () => {
              this.sortGroupOutcomeCopyGridListFacade.apply();
            }
          });
        }
      });
  }

  onCheck(outcome: SortGroupOutcomeListItemDto): void {
    this.sortGroupOutcomeCopyGridListFacade.select(outcome);
  }

  onCheckAll(): void {
    this.sortGroupOutcomeCopyGridListFacade.selectAll();
  }

  getTranslation(key: string): string {
    return this.translocoService.translate(`${this.translateScope}.${key}`);
  }
}
