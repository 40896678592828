import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { SortGroupOutcomeListItemDto } from '@ifhms/models/feedlot';
import { TranslocoService } from '@jsverse/transloco';
import { MenuItem } from 'primeng/api';
import { FEATURE_NAME } from '../../+state';
import { SortGroupOutcomeCopyGridListFacade } from '../../+state/sort-group-outcome-copy-grid-list/sort-group-outcome-copy-grid-list.facade';
import { SortGroupOutcomeListFacade } from '../../+state/sort-group-outcome-list/sort-group-outcome-list.facade';

@Component({
  selector: 'ifhms-sort-group-outcome-table-menu',
  templateUrl: './sort-group-outcome-table-menu.component.html',
  styleUrls: ['./sort-group-outcome-table-menu.component.scss']
})
export class SortGroupOutcomeTableMenuComponent implements OnChanges {
  translateScope = `${FEATURE_NAME}.components.sort-group-outcome-table-menu`;
  items: MenuItem[];

  @Input() selected: SortGroupOutcomeListItemDto[];
  @Output() createOutput = new EventEmitter();

  constructor(
    private sortGroupOutcomeCopyGridListFacade: SortGroupOutcomeCopyGridListFacade,
    private sortGroupOutcomeListFacade: SortGroupOutcomeListFacade,
    private translateService: TranslocoService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selected'] && this.selected) {
      this.updateMenuItems();
    }
  }

  clearOutput(): void {
    this.sortGroupOutcomeListFacade.clearOutput();
  }

  onCopyFrom(): void {
    this.sortGroupOutcomeCopyGridListFacade.showCopyFromModal(
      this.selected.map((x) => x.id)
    );
  }

  onCopyTo(): void {
    const id = this.selected[0].id;
    this.sortGroupOutcomeCopyGridListFacade.showCopyToModal(id);
  }

  private updateMenuItems(): void {
    const newItems: MenuItem[] = [];
    const len = this.selected.length;
    const atleastOneCompleted =
      this.selected.filter((x) => x.status).length > 0;
    if (len > 0) {
      newItems.push({
        label: this.getTranslation('copy-from'),
        command: (): void => {
          this.onCopyFrom();
        }
      });
    }
    if (len == 1 && atleastOneCompleted) {
      newItems.push({
        label: this.getTranslation('copy-to'),
        command: (): void => {
          this.onCopyTo();
        }
      });
    }
    if (len > 0 && atleastOneCompleted) {
      newItems.push({
        label: this.getTranslation('clear'),
        command: (): void => {
          this.clearOutput();
        }
      });
    }
    this.items = newItems;
  }

  getTranslation(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }
}
