import { Component, Input } from '@angular/core';

@Component({
  selector: 'sersi-pulse-loader',
  templateUrl: './pulse-loader.component.html',
  styleUrls: ['./pulse-loader.component.scss']
})
export class PulseLoaderComponent {

  @Input() loadingMsg: string;
  @Input() tooltipMsg?: string;
  @Input() className = '';

}
