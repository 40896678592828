import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AnimalTagValidationCode } from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';

@Injectable()
export class AnimalTagService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API)  private readonly apiUrl: string
  ) {}

  getAnimalTagValidationState(feedlotId: string, animalTag: string): Observable<AnimalTagValidationCode> {
    const params = new HttpParams()
      .set('animalTag', animalTag ?? '');

    return this.http.get<AnimalTagValidationCode>(`${this.apiUrl}/Feedlot/${feedlotId}/Animals/TagValidation`, { params });
  }

  IsAnimalTagUsed(feedlotId: string, animalTag: string): Observable<AnimalTagValidationCode> {
    const params = new HttpParams()
      .set('animalTag', animalTag ?? '');

    return this.http.get<AnimalTagValidationCode>(`${this.apiUrl}/Feedlot/${feedlotId}/Animals/IsAnimalTagUsed`, { params });
  }
}
