import { createReducer, on } from '@ngrx/store';
import { SortGroupDetailDto } from '@ifhms/models/feedlot';
import { SortGroupDetailActions } from './sort-group-detail.actions';
import { SortGroupOutcomeDetailActions } from '../sort-group-outcome-detail/sort-group-outcome-detail.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  detail: SortGroupDetailDto | null;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  detail: null
};

export const reducer = createReducer(
  initialState,

  on(SortGroupDetailActions.get, SortGroupDetailActions.apply, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(
    SortGroupDetailActions.getSuccess,
    SortGroupDetailActions.applySuccess,
    (state, action) => ({
      ...state,
      detail: action.detail,
      loaded: true,
      loading: false
    })
  ),

  on(SortGroupOutcomeDetailActions.setDefaultSuccess, (state) => {
    if (!state.detail) return { ...state };

    return {
      ...state,
      detail: {
        ...state.detail,
        hasDefaultOutcome: true
      }
    };
  }),

  on(SortGroupOutcomeDetailActions.clearDefaultSuccess, (state) => {
    if (!state.detail) return { ...state };

    return {
      ...state,
      detail: {
        ...state.detail,
        hasDefaultOutcome: false
      }
    };
  }),

  on(SortGroupDetailActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),

  on(SortGroupDetailActions.reset, () => initialState)
);

export const getLoading = (state: State): boolean => state.loading;

export const getLoaded = (state: State): boolean => state.loaded;

export const getDetail = (state: State): SortGroupDetailDto | null =>
  state.detail;

export const getWorkOrderId = (detail: SortGroupDetailDto | null): string =>
  detail?.workOrderId || '';
