import { Injectable, Injector } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { DeviceConnectionStatusEnum, DeviceStatusDto, DeviceType } from '@ifhms/models/feedlot';
import { AH_FMS_UPC_CONFIG, AhFmsUPCConfig } from '../tokens';
import { UpcApplicationSettingsService } from './upc-application-settings.service';
import { UpcDeviceManagerService } from './upc-device-manager.service';

@Injectable()
export abstract class UpcBaseDeviceService {

  public hubConnection: HubConnection | null;
  protected _connectionState = new BehaviorSubject<HubConnectionState>(HubConnectionState.Disconnected);
  protected _deviceSettings = new BehaviorSubject<any>(null);
  connectionState$ = this._connectionState.asObservable();
  deviceSettings$ = this._deviceSettings.asObservable();

  upcSignalRConfig: AhFmsUPCConfig;
  upcAppSettings: UpcApplicationSettingsService;
  deviceManagerService: UpcDeviceManagerService;

  deviceType: DeviceType;

  protected constructor(deviceType: DeviceType, private injector: Injector) {
    this.deviceType = deviceType;
    this.upcSignalRConfig = this.injector.get(AH_FMS_UPC_CONFIG);
    this.upcAppSettings = this.injector.get(UpcApplicationSettingsService);
    this.deviceManagerService = this.injector.get(UpcDeviceManagerService);
  }

  async initConnection(): Promise<boolean> {
    this.hubConnection = this.createConnection();
    await this.startConnection();
    await this.subscribeToConnectionStatus();
    await this.subscribeToEvents();
    return true;
  }

  protected createConnection(url?: string): HubConnection {
    const connectionUrl: string = url || this.upcSignalRConfig.upcSignalRUrl;

    return new HubConnectionBuilder()
      .withUrl(`${connectionUrl}/${this.deviceType}`)
      .configureLogging(LogLevel.None)
      .build();
  }

  protected async startConnection(): Promise<void> {
    this._connectionState.next(HubConnectionState.Connecting);
    try {
      await this.hubConnection?.start();
      this._connectionState.next(HubConnectionState.Connected);
    } catch (error) {
      // For now, we'll just silently fail without logging to the console
    }
    this.hubConnection?.onclose((error) => {
      console.log('Connection closed', error)
      this._connectionState.next(HubConnectionState.Disconnected);
    });
  }

  protected async subscribeToConnectionStatus(): Promise<void> {
    const initialConnectionStatus = await this.getDeviceStatus();
    if(!initialConnectionStatus) return;

    this._connectionState.next(initialConnectionStatus.status === DeviceConnectionStatusEnum.Connected ?
      HubConnectionState.Connected :
      HubConnectionState.Disconnected);

    this.hubConnection?.on('StatusChanged', (data: DeviceStatusDto) => {
      if (data.status === DeviceConnectionStatusEnum.Connected) {
        this._deviceSettings.next(data);
        this._connectionState.next(HubConnectionState.Connected);
      } else if (data.status === DeviceConnectionStatusEnum.Disconnected) {
        this._deviceSettings.next(null);
        this._connectionState.next(HubConnectionState.Disconnected);
      }
    });
  }

  async getDeviceStatus(): Promise<DeviceStatusDto | null> {
    if (!this.hubConnection) {
      // console.log('Getting device status: No hub connection, registering device.');
      const upcAvailable = await this.deviceManagerService.registerDevice(this);
      if (!upcAvailable) return null;
    }

    if (!this.hubConnection) return null;
    try {
      const deviceStatus = await this.hubConnection.invoke<DeviceStatusDto>('GetStatus');
      this._deviceSettings.next(deviceStatus);
      return deviceStatus;
    } catch (err) {
      //console.error(`Error getting ${this.deviceType} connection status:`, err);
      return null;
    }
  }

  private async subscribeToEvents(): Promise<void> {
    try {
      await this.hubConnection?.invoke('Subscribe');
    } catch (err) {
      //console.error(`Error subscribing to ${this.deviceType} events:`, err);
    }
  }

  protected async ensureHubConnection(): Promise<boolean> {
    if (!this.hubConnection) {
      return await this.initDevice();
    }
    return true;
  }

  abstract initDevice(): Promise<boolean>;

}