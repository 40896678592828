<button
        pButton
        pRipple
        type="button"
        class="p-button-rounded p-button-text"
        [disabled]="isButtonDisabled"
        [pTooltip]="tooltipMessage"
        tooltipPosition="top"
>
    <ng-container *ngIf="dosingGunIcon">
        <img [src]="dosingGunIcon" alt="Dosing Gun Status" width="20" height="20"/>
    </ng-container>
</button>