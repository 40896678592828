import { Injectable } from '@angular/core';
import { AbstractStaticListFacade } from '@common/angular/utils';
import { ProcessMethod } from '@ifhms/models/shared';

@Injectable()
export class ProcessMethodsFacade extends AbstractStaticListFacade {

  protected selectList = [
    {
      id: ProcessMethod.Group,
      code: 'Group',
      description: ''
    },
    {
      id: ProcessMethod.IndvM,
      code: 'Indv-M',
      description: ''
    },
    {
      id: ProcessMethod.IndvS,
      code: 'Indv-S',
      description: '',
      attributes: {
        isDefault: 'true'
      }
    }
  ];
  protected selectListType = 'process-methods';

}

