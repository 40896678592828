import { createSelector } from '@ngrx/store';
import * as Reducer from './sort-group-detail.reducer';
import { selectFeatureState, State } from '..';

const selectState = createSelector(
  selectFeatureState,
  (state: State) => state.groupDetail
);

const selectLoading = createSelector(selectState, Reducer.getLoading);

const selectLoaded = createSelector(selectState, Reducer.getLoaded);

const selectDetail = createSelector(selectState, Reducer.getDetail);

const selectWorkOrderId = createSelector(selectDetail, Reducer.getWorkOrderId);

export const SortGroupDetailSelectors = {
  selectState,
  selectLoading,
  selectLoaded,
  selectDetail,
  selectWorkOrderId
};
