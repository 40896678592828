import {
  SortGroupOutcomeDto,
  SortGroupOutcomeListItemDto
} from '@ifhms/models/feedlot';
import { createAction, props } from '@ngrx/store';

export const NAMESPACE = '[SORT GROUP OUTCOME - DETAIL]';

const get = createAction(`${NAMESPACE} Get`, props<{ id: string }>());

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ outcome: SortGroupOutcomeDto }>()
);

const updateModal = createAction(`${NAMESPACE} Update Modal`);

const updateModalSuccess = createAction(
  `${NAMESPACE} Update Modal Success`,
  props<{ outcome: SortGroupOutcomeDto }>()
);

const next = createAction(
  `${NAMESPACE} Next`,
  props<{ outcome: SortGroupOutcomeDto; showCopyTo: boolean  }>()
);

const nextSuccess = createAction(
  `${NAMESPACE} Next Success`,
  props<{ outcome: SortGroupOutcomeDto; showCopyTo: boolean }>()
);

const back = createAction(`${NAMESPACE} Back`);

const backSuccess = createAction(`${NAMESPACE} Back Success`);

const bulk = createAction(
  `${NAMESPACE} Bulk`,
  props<{ outcome: SortGroupOutcomeDto; showCopyTo: boolean }>()
);

const bulkSuccess = createAction(
  `${NAMESPACE} Bulk Success`,
  props<{ outcomes: SortGroupOutcomeListItemDto[]; copyToId?: string }>()
);

const applyDefault = createAction(`${NAMESPACE} Apply Default`);

const applyDefaultSuccess = createAction(
  `${NAMESPACE} Apply Default Success`,
  props<{ outcome: SortGroupOutcomeDto }>()
);

const setDefault = createAction(
  `${NAMESPACE} Set Default`,
  props<{ outcome: SortGroupOutcomeDto }>()
);

const setDefaultSuccess = createAction(
  `${NAMESPACE} Set Default Success`,
  props<{ outcome: SortGroupOutcomeDto }>()
);

const clearDefault = createAction(`${NAMESPACE} Clear Default`);

const clearDefaultSuccess = createAction(`${NAMESPACE} Clear Default Success`);

const showModal = createAction(`${NAMESPACE} Show Modal`);

const hideModal = createAction(`${NAMESPACE} Hide Modal`);

const error = createAction(`${NAMESPACE} Error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const SortGroupOutcomeDetailActions = {
  get,
  getSuccess,
  updateModal,
  updateModalSuccess,
  next,
  nextSuccess,
  back,
  backSuccess,
  bulk,
  bulkSuccess,
  applyDefault,
  applyDefaultSuccess,
  setDefault,
  setDefaultSuccess,
  clearDefault,
  clearDefaultSuccess,
  showModal,
  hideModal,
  error,
  reset
};
