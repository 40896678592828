import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { AbstractTranslationComponent } from '@common/angular/translation';
import { FormGroup } from '@angular/forms';
import { DeviceStatusDto } from '@ifhms/models/feedlot';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Observable, filter } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyTypesEnum, FormlyWrappersEnum } from '@sersi/angular/formly/core';
import {
  ConnectionStatusLabelFormFieldComponent
} from '../connection-status-label-form-field/connection-status-label-form-field.component';
import { UpcDosingGunsFacade } from '@ifhms/common/angular/upc/shared';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';

@UntilDestroy()
@Component({
  selector: 'ifhms-upc-dosing-guns-settings-form',
  templateUrl: './upc-dosing-guns-settings-form.component.html',
  styleUrls: ['./upc-dosing-guns-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcDosingGunsSettingsFormComponent extends AbstractTranslationComponent implements OnDestroy {

  translationNamespace = 'components.upc-dosing-guns-settings-form';

  form = new FormGroup({});
  model: DeviceStatusDto;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];

  dosingGunConnectionStatus$: Observable<string>;

  constructor(
    private referenceDataFacade: ReferenceDataFacade,
    private upcDosingGunsFacade: UpcDosingGunsFacade
  ) {
    super();
  }

  protected override onTranslationInit():void {
    this.getConnectionStatus();
    this.getDosingGunSettings();
    this.setFields();
  }

  private getConnectionStatus(): void {
    this.dosingGunConnectionStatus$ = this.upcDosingGunsFacade.dosingGunsConnectionStatus$;
  }

  private getDosingGunSettings(): void {
    this.upcDosingGunsFacade.dosingGunsSettings$.pipe(
      filter(x => !!x),
      untilDestroyed(this)
    ).subscribe(settings => {
      this.model = structuredClone(settings!);
    });
  }

  private setFields(): void {
    this.fields = [{
      fieldGroupClassName: 'dosing-gun-settings-form',
      fieldGroup: [
        this.setDosingGunConnectionStatus(),
        this.setSettingsFields()
      ]
    }];
  }

  private setDosingGunConnectionStatus(): FormlyFieldConfig {
    return {
      type: ConnectionStatusLabelFormFieldComponent,
      className: 'dosing-gun-connection',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      templateOptions: {
        label: '',
        connectionStatus: this.dosingGunConnectionStatus$
      }
    };
  }

  private setSettingsFields(): FormlyFieldConfig {
    return {
      fieldGroupClassName: 'dosing-gun-settings-fields',
      fieldGroup: [
        {
          key: 'settings.name',
          className: 'dosing-gun-name',
          type: FormlyTypesEnum.TEXT_READONLY,
          templateOptions: {
            label$: this.getTranslation$('dosing-gun-bridge-name')
          }
        },
        {
          key: 'settings.dosingGunSettings.connectionName',
          className: 'dosing-gun-connection-name',
          type: FormlyTypesEnum.TEXT_READONLY,
          templateOptions: {
            label$: this.getTranslation$('dosing-gun-connection-name')
          }
        },
        {
          key: 'settings.dosingGunSettings.hostName',
          className: 'dosing-gun-host-name',
          type: FormlyTypesEnum.TEXT_READONLY,
          templateOptions: {
            label$: this.getTranslation$('dosing-gun-host-name')
          }
        }
      ]
    };
  }
  
  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.referenceDataFacade.reset();
  }
}
