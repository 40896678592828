import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonAngularErrorHandlingModule, ErrorHandlingService } from '@common/angular/error-handling';

import { FeedlotErrorHandlingService } from './services';

@NgModule({
  imports: [
    CommonModule,
    CommonAngularErrorHandlingModule
  ],
  providers: [
    {
      provide: ErrorHandlingService,
      useClass: FeedlotErrorHandlingService
    },
    FeedlotErrorHandlingService
  ]
})
export class IfhmsFeedlotFrontEndWebCoreErrorHandlingModule {}
