import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';

import { MessageService } from 'primeng/api';
import { exhaustMap, map, catchError, of, withLatestFrom } from 'rxjs';
import { SortGroupListActions } from './sort-group-list.actions';
import { SortGroupTemplateRequestDto,  SortGroupTemplateResultDto } from '@ifhms/models/feedlot';
import { SortOrder, SortGroupTemplateSortBy } from '@ifhms/models/shared';
import { SortGroupsService } from '@ifhms/common/angular/data-access/feedlot-api';

@Injectable({
  providedIn: 'root'
})
export class SortGroupListEffects {
  constructor(
    private actions$: Actions,
    private sortGroupsService: SortGroupsService,
    private feedlotFacade: FeedlotFacade,
    public messageService: MessageService
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupListActions.get),
      withLatestFrom(this.feedlotFacade.feedlotId$),
      exhaustMap(([action, feedlotId]) => {
        const request: SortGroupTemplateRequestDto = {
          sortOrder: SortOrder.Ascending,
          sortBy: SortGroupTemplateSortBy.Code,
          pageIndex: 0,
          pageSize: 10000,
          attributes: action.attributes
        };
        return this.sortGroupsService.getList(request, feedlotId).pipe(
          map((result: SortGroupTemplateResultDto) => {
            if (!result) throw new Error();
            return SortGroupListActions.getSuccess({ result });
          }),
          catchError(() => of(SortGroupListActions.error()))
        );
      })
    )
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupListActions.search),
      map(({ search }) => SortGroupListActions.searchSuccess({ search }))
    )
  );
}
