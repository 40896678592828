import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { CommonAngularPermissionsModule } from '@common/angular/permissions';

import { FeedlotHasPermissionDirective } from './directives';
import { FeedlotPermissionGuard } from './guards';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    CommonAngularPermissionsModule
  ],
  declarations: [
    FeedlotHasPermissionDirective
  ],
  providers: [
    FeedlotPermissionGuard
  ],
  exports: [
    FeedlotHasPermissionDirective
  ]
})
export class IfhmsFeedlotDomainPermissionModule {}
