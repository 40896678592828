import { createSelector } from '@ngrx/store';
import * as Reducer from './sort-group-outcome-detail.reducer';
import { selectFeatureState, State } from '..';

const selectState = createSelector(
  selectFeatureState,
  (state: State) => state.outcomeDetail
);

const selectLoading = createSelector(selectState, Reducer.getLoading);

const selectLoaded = createSelector(selectState, Reducer.getLoaded);

const selectModal = createSelector(selectState, Reducer.getModal);

const selectDetail = createSelector(selectState, Reducer.getDetail);

export const SortGroupOutcomeDetailSelectors = {
  selectState,
  selectLoading,
  selectLoaded,
  selectModal,
  selectDetail
};
