import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { scopeLoader } from '@common/angular/translation';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyTypesEnum, GridColumnDef } from '@sersi/angular/formly/core';
import { AbstractDosingGunGridComponent } from './abstract-dosing-gun-grid-component';

@Component({
  selector: 'ifhms-dosing-guns-grid',
  templateUrl: './dosing-guns-grid.component.html',
  styleUrls: ['./dosing-guns-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'dosingguns',
        alias: 'dosingguns',
        loader: scopeLoader((lang: string, root: string) =>
          import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class DosingGunsGridComponent extends AbstractDosingGunGridComponent{

  protected setGridField(): FormlyFieldConfig {
    return {
      key: this.fieldKey,
      className: 'work-order-procedure-selector',
      type: FormlyTypesEnum.REPEATING_GRID_TYPE,
      props: {
        initialModel: {
          macAddress: null,
          name: null, 
          description: null,
          workOrderId: null,
          dosingGunState: null,
          productId: null,
          productTypeId: null,
          validated: false
        },
        gridOptions: this.getGridOptions()
      },
      fieldArray: {
        fieldGroup: [
          this.setGridRow()
        ]
      }
    };
  }

  protected getColumnDefs(): GridColumnDef[] {
    return [
      {
        headerName: this.getParentTranslation('macAddress-label'),
        fieldKey: 'macAddress',
        width: '15%'
      },
      {
        headerName: this.getParentTranslation('name-label'),
        fieldKey: 'name',
        width: '13%'
      },
      {
        headerName: this.getParentTranslation('description-label'),
        fieldKey: 'description',
        width: '13%'
      },
      {
        headerName: this.getParentTranslation('workOrderType-label'),
        fieldKey: 'dosingGunState',
        width: '11%'
      },
      {
        headerName: this.getParentTranslation('workOrder-label'),
        fieldKey: 'workOrderNumber',
        width: '11%'
      },
      {
        headerName: this.getParentTranslation('product-label'),
        fieldKey: 'productId',
        width: '11%'
      },
      {
        headerName: this.getParentTranslation('productType-label'),
        fieldKey: 'productTypeId',
        width: '11%'
      },
      {
        headerName: this.getParentTranslation('validated-label'),
        fieldKey: 'validated',
        width: '11%'
      }
    ]
  }

}
