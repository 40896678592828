import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../reducer'; // TODO : add the reducer to State in +state/reducer.ts
import * as reducer from './locations.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.locations
);

const selectItems = createSelector(selectState, reducer.getAll);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

export const LocationsSelectors = {
  selectItems,
  selectLoaded
};
