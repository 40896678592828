import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AbstractTranslationComponent, scopeLoader } from '@common/angular/translation';
import { UpcDosingGunsFacade } from '@ifhms/common/angular/upc/shared';
import { DosingGunItem } from '@ifhms/models/feedlot';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyTypesEnum } from '@sersi/angular/formly/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs/internal/Observable';

@UntilDestroy()
@Component({
  selector: 'ifhms-dosing-gun-event-menu',
  templateUrl: './event-menu-set-dose-form.component.html',
  styleUrls: ['./event-menu-set-dose-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'dosingguns',
        alias: 'dosingguns',
        loader: scopeLoader((lang: string, root: string) =>
          import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class EventMenuSetDoseFormComponent extends AbstractTranslationComponent implements OnInit {

  readonly translationNamespace = 'components.set-dose-form';

  form = new UntypedFormGroup({});
  fields: FormlyFieldConfig[];
  options: FormlyFormOptions = {};
  model:any;

  dosingGun: DosingGunItem | null;

  constructor(
    private dosingGunsFacade: UpcDosingGunsFacade,
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef)
  {
    super();
  }
  override onTranslationInit():void {
    this.dosingGun = this.config.data.gun;
    this.setFields();

  }

  loading$: Observable<boolean> = this.dosingGunsFacade.loading$;

  private setFields(): void {
    this.fields = [
      {
        fieldGroupClassName: 'set-dose-form',
        fieldGroup: [
          this.setDoseQuantityField()
        ]
      }];
  }

  public setDoseQuantityField(): FormlyFieldConfig {
    return {
      key: 'quantity',
      type: FormlyTypesEnum.NUMBER_INPUT,
      props: {
        label: this.getTranslation('quantity-label'),
        required: true,
        maxFractionDigits: 1,
        min: 0
      }
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  onSetDose():void {
    this.dosingGunsFacade.setOneTimeDose(this.config.data.gun.macAddress, this.form.value.quantity,this.config.data.gun.name);
    this.cancel();
  }
}
