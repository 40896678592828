import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import * as reducer from './breed-types.reducer';
import { BreedTypesSelectors } from './breed-types.selectors';
import { BreedTypesActions } from './breed-types.actions';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class BreedTypesFacade {

  loaded$ = this.store.select(BreedTypesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(BreedTypesSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(BreedTypesActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(BreedTypesActions.reset());
  }
}
