import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';
import { AbstractTranslationComponent } from '@common/angular/translation';

@Component({
  selector: 'ifhms-upc-scale-settings',
  templateUrl: './upc-scale-settings.component.html',
  styleUrls: ['./upc-scale-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcScaleSettingsComponent extends AbstractTranslationComponent {

  translationNamespace = 'containers.upc-scale-settings';
}
