import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, map } from 'rxjs';
import { SortGroupTemplateResultDto } from '@ifhms/models/feedlot';
import { SortGroupTemplateAttributes } from '@ifhms/models/shared';
import { SortGroupListSelectors } from './sort-group-list.selectors';
import { SortGroupListActions } from './sort-group-list.actions';

@Injectable()
export class SortGroupListFacade {
  table$: Observable<SortGroupTemplateResultDto | null> = this.store.select(
    SortGroupListSelectors.selectTable
  );

  search$: Observable<string> = this.store.select(
    SortGroupListSelectors.selectSearch
  );

  filteredList$: Observable<SortGroupTemplateResultDto | null>;

  isLoading$: Observable<boolean> = this.store.select(
    SortGroupListSelectors.selectLoading
  );

  isLoaded$: Observable<boolean> = this.store.select(
    SortGroupListSelectors.selectLoaded
  );

  modal$: Observable<boolean> = this.store.select(
    SortGroupListSelectors.selectModal
  );

  constructor(private readonly store: Store) {
    //@ts-ignore
    this.filteredList$ = combineLatest([this.table$, this.search$]).pipe(
      map(([table, search]) => {
        {
          const filteredTable = { ...table };
          if (filteredTable?.list) {
            filteredTable.list = filteredTable?.list.filter(
              (x) =>
                x.isActive &&
                x.description.toLowerCase().includes(search.toLowerCase())
            );
          }
          return filteredTable;
        }
      })
    );
  }

  get(attributes?: SortGroupTemplateAttributes[]): void {
    this.store.dispatch(
      SortGroupListActions.get({ attributes: attributes || [] })
    );
  }

  reset(): void {
    this.store.dispatch(SortGroupListActions.reset());
  }

  showModal(): void {
    this.store.dispatch(SortGroupListActions.showModal());
  }

  hideModal(): void {
    this.store.dispatch(SortGroupListActions.hideModal());
  }

  search(search: string): void {
    this.store.dispatch(SortGroupListActions.search({ search }));
  }
}
