import { createReducer, on } from '@ngrx/store';
import { SortGroupOutcomeDto } from '@ifhms/models/feedlot';
import { SortGroupOutcomeDetailViewActions } from './sort-group-outcome-detail-view.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  modal: boolean;
  detail: SortGroupOutcomeDto | null;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  modal: false,
  detail: null
};

export const reducer = createReducer(
  initialState,

  on(SortGroupOutcomeDetailViewActions.get, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(SortGroupOutcomeDetailViewActions.getSuccess, (state, action) => ({
    ...state,
    detail: action.outcome,
    modal: true,
    loaded: true,
    loading: false
  })),

  on(SortGroupOutcomeDetailViewActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),

  on(SortGroupOutcomeDetailViewActions.reset, () => initialState)
);

export const getLoading = (state: State): boolean => state.loading;

export const getLoaded = (state: State): boolean => state.loaded;

export const getOutcome = (state: State): SortGroupOutcomeDto | null =>
  state.detail;

export const getOutcomeId = (state: State): string | undefined =>
  state.detail?.id;

export const getModal = (state: State): boolean => state.modal;

export const getDetail = (state: State): SortGroupOutcomeDto | null =>
  state.detail;
