import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { scopeLoader } from '@common/angular/translation';
import { UpcService } from '@ifhms/common/angular/data-access/feedlot-api';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  UpcApiEffects,
  UpcApiFacade,
  UpcDosingGunsEffects,
  UpcDosingGunsFacade,
  upcReducer,
  UpcScaleEffects,
  UpcScaleFacade
} from './+state';
import { UpcTemperatureFormFieldUpdateService, UpcWeightFormFieldUpdateService } from './services';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('upc', upcReducer),
    EffectsModule.forFeature([UpcDosingGunsEffects, UpcApiEffects, UpcScaleEffects])
  ],
  providers: [
    UpcDosingGunsFacade,
    UpcApiFacade,
    UpcScaleFacade,
    UpcService,
    UpcTemperatureFormFieldUpdateService,
    UpcWeightFormFieldUpdateService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'upcShared',
        alias: 'upcShared',
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class IfhmsCommonAngularUpcSharedModule {}
