import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { UpcBaseDeviceService } from './upc-base-device.service';
import { DeviceType, ScaleDate } from '@ifhms/models/feedlot';
import { HubConnectionState } from '@microsoft/signalr';

@Injectable({ providedIn: 'root' })
export class UpcScaleService extends UpcBaseDeviceService {
  private _scaleData = new Subject<ScaleDate>();
  private _loading = new Subject<boolean>();
  scaleData$ = this._scaleData.asObservable();
  loadingWeight$ = this._loading.asObservable();
  private scaleInitialized: boolean|null = null;

  constructor(injector: Injector) {
    super(DeviceType.Scale, injector);
  }

  async initDevice(): Promise<boolean> {
    if (!this.scaleInitialized) {
      this.scaleInitialized = await this.initConnection();
      if (this.scaleInitialized) this.subscribeToWeightChange();
    }
    return this.scaleInitialized;
  }

  private subscribeToWeightChange(): void {
    if (this.hubConnection?.state !== HubConnectionState.Connected) {
      return;
    }
    this.hubConnection?.on('WeightChanged', (data: number) => {
      this._scaleData.next({
        weight: data,
        scaleEventType: 'WeightChanged'
      });
    });
  }

  async getWeight(): Promise<number | null> {
    const isConnected = this.scaleInitialized;
    if (!isConnected) return null;
    try {
      this._loading.next(true);
      const weight = await this.hubConnection?.invoke<number | null>('GetWeight', null) ?? null;
      if(weight !== null) {
        this._scaleData.next({
          weight: weight,
          scaleEventType: 'GetWeight'
        });
        this._loading.next(false);
        return weight;
      }
      this._loading.next(false);
      return null;
    } catch (err) {
      console.error('Error getting weight:', err);
      return null;
    }
  }
}