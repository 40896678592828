import { Inject, Injectable } from '@angular/core';
import { UpcScaleService } from '@ifhms/common/angular/data-access/upc-api';
import { TRANSLOCO_SCOPE, TranslocoScope, TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { catchError, from, mergeMap, of, switchMap } from 'rxjs';
import { UpcScaleActions } from './upc-scale.actions';

@Injectable()
export class UpcScaleEffects {

  translateScope = 'upcShared.state.messages';

  getScaleStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpcScaleActions.getScaleStatusFromUpc),
      switchMap(() => {
        return from(this.scaleService.getDeviceStatus()).pipe(
          mergeMap((scaleSettings) => {
            return [
              UpcScaleActions.getScaleStatusFromUpcSuccess({ scaleSettings })
            ];
          }),
          catchError((err) => {
            return from([
              UpcScaleActions.error({ errMsg: err.message })
            ]);
          })
        );
      })));

  captureWeight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpcScaleActions.captureWeight),
      switchMap(() =>
        from(this.scaleService.getWeight()).pipe(
          mergeMap((weight) => {
            return of(UpcScaleActions.captureWeightSuccess({ weight }));
          }),
          catchError((err) => {
            return from([
              UpcScaleActions.error({ errMsg: err.message })
            ]);
          })
        )
      )
    ));

  constructor(
    private actions$: Actions,
    private messageService: MessageService,
    private translateService: TranslocoService,
    private scaleService: UpcScaleService,
    @Inject(TRANSLOCO_SCOPE) private providerScope: TranslocoScope
  ) {
  }

  getTranslation(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }

}
