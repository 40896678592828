import { Injectable, Injector } from '@angular/core';

import { Subject } from 'rxjs';
import { UpcBaseDeviceService } from './upc-base-device.service';
import { DeviceType } from '@ifhms/models/feedlot';

@Injectable()
export class UpcRfidService extends UpcBaseDeviceService {
  private _rfidData = new Subject<any>();
  rfidData$ = this._rfidData.asObservable();
  private rfidInitialized: boolean|null = null;

  constructor(injector: Injector) {
    super(DeviceType.Rfid,injector);
    // todo: remove me before production ( Backdoor for testing )
    (<any>window).setRfId = (tagNumber: number): void => this._rfidData.next({ tagNumber });
  }

  async initDevice(): Promise<boolean> {
    if(!this.rfidInitialized) {
      this.rfidInitialized = await this.initConnection();
      if (this.rfidInitialized) await this.subscribeToTagScan();
    }
    return this.rfidInitialized;
  }

  triggerScanValue(tagNumber: number): void {
    this._rfidData.next({ tagNumber });
  }

  private async subscribeToTagScan(): Promise<void> {

    this.hubConnection?.on('TagScanned', (data: string) => {
      console.log(data);
      this._rfidData.next(data);
    });

  }
}