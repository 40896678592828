import { Injectable } from '@angular/core';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as facilitiesReducer from './facilities.reducer';
import { FacilitiesSelectors } from './facilities.selectors';
import { FacilitiesActions } from './facilities.actions';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class FacilitiesFacade {
  
  loaded$ = this.store.select(FacilitiesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(FacilitiesSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<facilitiesReducer.State>) {}

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(FacilitiesActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(FacilitiesActions.reset());
  }
}
