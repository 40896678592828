import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AnimalAvailabilityDto,
  AnimalListItemRequestDto,
  AnimalListItemResultDto,
  AnimalSelectorRequestDto,
  AnimalSelectorResultDto
} from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../tokens';

@Injectable()
export class AnimalsService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {
  }

  getList(operationId: string, request: AnimalListItemRequestDto): Observable<AnimalListItemResultDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<AnimalListItemResultDto>(`${this.apiUrl}/Feedlot/${operationId}/Animals`, {
      params
    });
  }

  selectorSearch(
    feedlotId: string,
    filter: AnimalSelectorRequestDto
  ): Observable<AnimalSelectorResultDto> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put<AnimalSelectorResultDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Animals/Selector/Search`,
      JSON.stringify(filter),
      {
        headers: headers
      }
    );
  }

  getDSortApiId(operationId: string, animalId: string, eventId: string): Observable<string | null> {
    return this.http.get<string | null>(`${this.apiUrl}/Feedlot/${operationId}/Animals/${animalId}/DSortApiId?eventId=${eventId}`);
  }

  getAnimalAvailability(feedlotId: string, animalId: string): Observable<AnimalAvailabilityDto> {
    return this.http.get<AnimalAvailabilityDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Animal/${animalId}/AnimalAvailability`);
  }
}
