import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ArrivalCreateWorkOrderTemplateDto,
  ArrivalWorkOrderDto,
  ArrivalWorkOrderUpdateDto,
  GoToEventDto,
  WorkOrderArrivalListDto,
  WorkOrderArrivalListRequestDto
} from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';
import { SelectListItemDto } from '@ifhms/models/shared';

@Injectable()
export class ArrivalWorkOrderService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {
  }

  getList(feedlotId: string, request: WorkOrderArrivalListRequestDto): Observable<WorkOrderArrivalListDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<WorkOrderArrivalListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder`, {
      params
    });
  }

  getArrivalWorkOrder(feedlotId: string, workOrderId: string): Observable<ArrivalWorkOrderDto> {
    return this.http.get<ArrivalWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder/${workOrderId}`);
  }

  getActiveEventId(feedlotId: string, workOrderId: string): Observable<GoToEventDto> {
    return this.http.get<GoToEventDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder/${workOrderId}/GoToEvent`);
  }

  update(feedlotId: string, arrivalWorkOrderUpdate: ArrivalWorkOrderUpdateDto): Observable<ArrivalWorkOrderDto> {
    return this.http.put<ArrivalWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder/${arrivalWorkOrderUpdate.id}`, arrivalWorkOrderUpdate);
  }

  generateWorkSheetReport(feedlotId: string, workOrderId: string): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            levelId: feedlotId ?? '',
            workOrderId: workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/Reports/ArrivalWorkOrderWorkSheet`, options);
  }

  generateSummaryReport(feedlotId: string, workOrderId: string): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            levelId: feedlotId ?? '',
            workOrderId: workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/Reports/ArrivalWorkOrder`, options);
  }

  deleteWorkOrder(feedlotId: string, workOrderId: string): Observable<ArrivalWorkOrderDto> {
    return this.http.delete<ArrivalWorkOrderDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder/${workOrderId}`);
  }

  complete(feedlotId: string, workOrderId: string): Observable<string> {
    return this.http.put<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder/${workOrderId}/Complete`, null);
  }

  finalize(feedlotId: string, workOrderId: string): Observable<string> {
    return this.http.put<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder/${workOrderId}/Finalize`, null);
  }

  schedule(feedlotId: string, workOrderId: string): Observable<string> {
    return this.http.put<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder/${workOrderId}/Schedule`, null);
  }

  startGroupProcessing(feedlotId: string, workOrderId: string, workEventDate: string): Observable<string> {
    return this.http.put<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder/${workOrderId}/Process`, { workEventDate });
  }

  cancelGroupProcessing(feedlotId: string, workOrderId: string): Observable<string> {
    return this.http.put<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder/${workOrderId}/CancelGroupProcess`, null);
  }

  createWorkOrderFromTemplate(feedlotId: string, workOrderId: string): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder/${workOrderId}/CreateWOFromTemplate`, null);
  }

  createTemplate(feedlotId: string, arrivalWorkOrder: ArrivalCreateWorkOrderTemplateDto): Observable<string> {
    return this.http.put<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder/CreateTemplate`, arrivalWorkOrder);
  }

  isTemplateNameValid(feedlotId: string, templateName: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        templateName
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };
    return this.http.get<boolean>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder/IsTemplateNameValid`, options);
  }

  saveWorkOrderAsTemplate(feedlotId: string, templateDto: ArrivalCreateWorkOrderTemplateDto): Observable<string> {
    return this.http.put<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder/SaveAsTemplate`, templateDto);
  }

  loadFromTemplate(feedlotId: string, workOrderId: string, templateId: string): Observable<string> {
    return this.http.put<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder/${workOrderId}/LoadFromTemplate/${templateId}`, null);
  }

  getTemplatesList(feedlotId: string, searchTerm: string | undefined): Observable<SelectListItemDto[]> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        ...(searchTerm ? { searchTerm } : {})
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<SelectListItemDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder/GetTemplatesList`, options);
  }
}
