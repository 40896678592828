import { createSelector } from '@ngrx/store';
import * as reducer from './upc-dosing-guns.reducer';

import { selectFeatureState } from '../upc.reducer';

export const selectState = createSelector(selectFeatureState, (state) => state.dosingGuns);

const selectLoading = createSelector(selectState, reducer.getLoading);
const selectLoaded = createSelector(selectState, reducer.getLoaded);
const selectError = createSelector(selectState, reducer.getError);
const selectDosingGunsSettings = createSelector(selectState, reducer.getDosingGunsSettings);
const selectDosingGunsConnectionStatus = createSelector(selectState, reducer.getDosingGunsConnectionStatus);
const selectDosingGunsMacAddresses = createSelector(selectState, reducer.getDosingGunsMacAddresses);
const selectAvailableDosingGuns = createSelector(selectState, reducer.getAvailableDosingGuns);
const selectOneTimeDose = createSelector(selectState, reducer.getOneTimeDose);

export const UpcDosingGunsSelectors = {
  selectLoading,
  selectLoaded,
  selectError,
  selectState,
  selectDosingGunsSettings,
  selectDosingGunsConnectionStatus,
  selectDosingGunsMacAddresses,
  selectAvailableDosingGuns,
  selectOneTimeDose
}
