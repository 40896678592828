import { Injectable } from '@angular/core';
import { AnimalsService } from '@ifhms/common/angular/data-access/feedlot-api';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { AnimalSelectorResultDto } from '@ifhms/models/feedlot';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, of, tap, withLatestFrom } from 'rxjs';
import { CattleSelectorRightActions } from '../right/right.actions';
import { CattleSelectorLeftActions } from './left.actions';
import { CattleSelectorLeftFacade } from './left.facade';

@Injectable()
export class CattleSelectorLeftEffects {
  translateScope = 'feedlot.state.feedlot-list-effects';

  replace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorLeftActions.replace),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.leftFacade.filterApplied$,
        this.leftFacade.filter$
      ),
      exhaustMap(([, feedlotId, filterApplied, filter]) =>
        !filterApplied
          ? of(true).pipe(
            map(() =>
              CattleSelectorLeftActions.replaceSuccess({ animals: [] })
            )
          )
          : this.animalsService.selectorSearch(feedlotId, filter).pipe(
            map((animals: AnimalSelectorResultDto) =>
              CattleSelectorLeftActions.replaceSuccess({
                animals: animals.animals
              })
            )
          )
      )
    )
  );

  replaceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CattleSelectorLeftActions.replaceSuccess),
        tap(() => {
          // TODO
        })
      ),
    { dispatch: false }
  );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorLeftActions.add),
      map(({ animals }) => CattleSelectorLeftActions.addSuccess({ animals }))
    )
  );
  addSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CattleSelectorLeftActions.addSuccess),
        tap(() => {
          // TODO
        })
      ),
    { dispatch: false }
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorLeftActions.remove),
      withLatestFrom(this.leftFacade.selected$),
      map(([, animals]) => CattleSelectorLeftActions.removeSuccess({ animals }))
    )
  );
  removeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorLeftActions.removeSuccess),
      map(({ animals }) => CattleSelectorRightActions.add({ animals }))
    )
  );

  select$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorLeftActions.select),
      map(({ animal }) => CattleSelectorLeftActions.selectSuccess({ animal }))
    )
  );
  selectSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CattleSelectorLeftActions.selectSuccess),
        tap(() => {
          // TODO
        })
      ),
    { dispatch: false }
  );

  filter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorLeftActions.filter),
      map((action) =>
        CattleSelectorLeftActions.filterSuccess({ filter: action.filter })
      )
    )
  );
  filterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorLeftActions.filterSuccess),
      map(() => CattleSelectorLeftActions.replace())
    )
  );

  clearFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorLeftActions.clearFilter),
      map(() => CattleSelectorLeftActions.clearFilterSuccess())
    )
  );
  clearFilterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorLeftActions.clearFilterSuccess),
      map(() => CattleSelectorLeftActions.replace())
    )
  );

  selectAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CattleSelectorLeftActions.selectAll),
      withLatestFrom(
        this.leftFacade.selectedLength$,
        this.leftFacade.availableLength$
      ),
      map(([, selected, total]) =>
        CattleSelectorLeftActions.selectAllSuccess({
          selected: !!total && selected != total
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private leftFacade: CattleSelectorLeftFacade,
    private feedlotFacade: FeedlotFacade,
    public translateService: TranslocoService,
    private animalsService: AnimalsService
  ) {
  }
}
