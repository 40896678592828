import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as FeedlotReducer from './feedlot.reducer';

export const selectFeatureState = createFeatureSelector<FeedlotReducer.State>(
  FeedlotReducer.FEEDLOT_FEATURE_NAME
);

export const selectState = createSelector(
  selectFeatureState,
  (state: FeedlotReducer.State) => state
);

export const selectFeedlot = createSelector(selectState, FeedlotReducer.getFeedlot);

const selectFeedlotId = createSelector(
  selectState,
  FeedlotReducer.getFeedlotId
);

const selectFeedlotSlug = createSelector(
  selectState,
  FeedlotReducer.getFeedlotSlug
);

const selectFeedlotName = createSelector(
  selectState,
  FeedlotReducer.getFeedlotName
);

const selectFeedlotPermissions = createSelector(
  selectState,
  FeedlotReducer.getFeedlotPermissions
);

const selectFeedlotTagSettings = createSelector(
  selectState,
  FeedlotReducer.getTagSettings
);
const selectIntegrationSettings = createSelector(
  selectState,
  FeedlotReducer.getIntegrationSettings
);

const selectFeedlotGlobalSettings = createSelector(
  selectState,
  FeedlotReducer.getGlobalSettings
);

const selectLoading = createSelector(selectState, FeedlotReducer.getLoading);

const selectLoaded = createSelector(selectState, FeedlotReducer.getLoaded);

const selectActive = createSelector(selectState, FeedlotReducer.getActive);

export const feedlotSelectors = {
  selectActive,
  selectFeedlot,
  selectFeedlotGlobalSettings,
  selectFeedlotId,
  selectFeedlotName,
  selectFeedlotPermissions,
  selectFeedlotSlug,
  selectFeedlotTagSettings,
  selectLoaded,
  selectLoading,
  selectState,
  selectIntegrationSettings
};
