import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SortGroupTemplateAttributes } from '@ifhms/models/shared';
import { TranslocoService } from '@jsverse/transloco';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import {
  FormlyTypesEnum,
  SersiFormlyFormComponent
} from '@sersi/angular/formly/core';
import { of } from 'rxjs';
import { FEATURE_NAME } from '../../+state';
import { SortGroupOutcomeListFacade } from '../../+state/sort-group-outcome-list/sort-group-outcome-list.facade';
import { AbstractTranslationComponent } from '@common/angular/translation';

interface Model {
  sort_group_search_breed: string;
  sort_group_search_breedType: string;
  sort_group_search_weight: string;
  sort_group_search_age: string;
  sort_group_search_ageClass: string;
  sort_group_search_temperature: string;
  sort_group_search_dentition: string;
  sort_group_search_gender: string;
}

@Component({
  selector: 'ifhms-sort-group-search-table-filter-form',
  templateUrl: './sort-group-search-table-filter-form.component.html',
  styleUrls: ['./sort-group-search-table-filter-form.component.scss']
})
export class SortGroupSearchTableFilterFormComponent extends AbstractTranslationComponent
  implements OnInit, SersiFormlyFormComponent {
  protected override translationNamespace = 'components.sort-group-form';
  translateScope = `${FEATURE_NAME}.components.sort-group-form`;
  form = new FormGroup({});
  fields: FormlyFieldConfig[];
  options: FormlyFormOptions;
  model = <Model>{};
  modelSnapshot = <Model>{};

  constructor(
    public facade: SortGroupOutcomeListFacade
  ) { super() }

  override onTranslationInit(): void {
    this.setFields();
  }

  reset(): void {
    this.model = { ...this.modelSnapshot };
    this.setFields();
  }

  setFields(): void {
    this.fields = [
      {
        fieldGroupClassName: 'feedlot-web-sort-group__form-grid__sm',
        fieldGroup: [
          this.setBreed(),
          this.setBreedType(),
          this.setWeight(),
          this.setAge(),
          this.setAgeClass(),
          this.setTemperature(),
          this.setDentition(),
          this.setGender()
        ]
      }
    ];
  }

  setBreed(): FormlyFieldConfig {
    return {
      key: 'sort_group_search_breed',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__breed',
      props: {
        items$: of([
          {
            id: 'true',
            code: this.getTranslation('true')
          },
          {
            id: 'false',
            code: this.getTranslation('false')
          }
        ]),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: '',
        label$: this.getTranslation$('breed'),
        placeholder$: this.getTranslation$('select-option')
      }
    };
  }

  setBreedType(): FormlyFieldConfig {
    return {
      key: 'sort_group_search_breedType',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__breedType',
      props: {
        items$: of([
          {
            id: 'true',
            code: this.getTranslation('true')
          },
          {
            id: 'false',
            code: this.getTranslation('false')
          }
        ]),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: '',
        label$: this.getTranslation$('breed-type'),
        placeholder$: this.getTranslation$('select-option')
      }
    };
  }

  setWeight(): FormlyFieldConfig {
    return {
      key: 'sort_group_search_weight',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__weights',
      props: {
        items$: of([
          {
            id: 'true',
            code: this.getTranslation('true')
          },
          {
            id: 'false',
            code: this.getTranslation('false')
          }
        ]),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: '',
        label$: this.getTranslation$('weight'),
        placeholder$: this.getTranslation$('select-option')
      }
    };
  }

  setAge(): FormlyFieldConfig {
    return {
      key: 'sort_group_search_age',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__age',
      props: {
        items$: of([
          {
            id: 'true',
            code: this.getTranslation('true')
          },
          {
            id: 'false',
            code: this.getTranslation('false')
          }
        ]),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: '',
        label$: this.getTranslation$('age'),
        placeholder$: this.getTranslation$('select-option')
      }
    };
  }

  setAgeClass(): FormlyFieldConfig {
    return {
      key: 'sort_group_search_ageClass',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__ageClass',
      props: {
        items$: of([
          {
            id: 'true',
            code: this.getTranslation('true')
          },
          {
            id: 'false',
            code: this.getTranslation('false')
          }
        ]),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: '',
        label$: this.getTranslation$('age-class'),
        placeholder$: this.getTranslation$('select-option')
      }
    };
  }

  setTemperature(): FormlyFieldConfig {
    return {
      key: 'sort_group_search_temperature',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__temperature',
      props: {
        items$: of([
          {
            id: 'true',
            code: this.getTranslation('true')
          },
          {
            id: 'false',
            code: this.getTranslation('false')
          }
        ]),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: '',
        label$: this.getTranslation$('temperature'),
        placeholder$: this.getTranslation$('select-option')
      }
    };
  }

  setDentition(): FormlyFieldConfig {
    return {
      key: 'sort_group_search_dentition',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__dentition',
      props: {
        items$: of([
          {
            id: 'true',
            code: this.getTranslation('true')
          },
          {
            id: 'false',
            code: this.getTranslation('false')
          }
        ]),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: '',
        label$: this.getTranslation$('dCode'),
        placeholder$: this.getTranslation$('select-option')
      }
    };
  }

  setGender(): FormlyFieldConfig {
    return {
      key: 'sort_group_search_gender',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__gender',
      props: {
        items$: of([
          {
            id: 'true',
            code: this.getTranslation('true')
          },
          {
            id: 'false',
            code: this.getTranslation('false')
          }
        ]),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: '',
        label$: this.getTranslation$('gender'),
        placeholder$: this.getTranslation$('select-option')
      }
    };
  }

  getData(): SortGroupTemplateAttributes[] {
    const arr: SortGroupTemplateAttributes[] = [];

    if (this.model?.['sort_group_search_breed'] == 'true') {
      arr.push(SortGroupTemplateAttributes.Breed);
    } else if (this.model?.['sort_group_search_breed'] == 'false') {
      arr.push(SortGroupTemplateAttributes.NoBreed);
    }

    if (this.model?.['sort_group_search_breedType'] == 'true') {
      arr.push(SortGroupTemplateAttributes.BreedType);
    } else if (this.model?.['sort_group_search_breedType'] == 'false') {
      arr.push(SortGroupTemplateAttributes.NoBreedType);
    }

    if (this.model?.['sort_group_search_weight'] == 'true') {
      arr.push(SortGroupTemplateAttributes.Weight);
    } else if (this.model?.['sort_group_search_weight'] == 'false') {
      arr.push(SortGroupTemplateAttributes.NoWeight);
    }

    if (this.model?.['sort_group_search_age'] == 'true') {
      arr.push(SortGroupTemplateAttributes.Age);
    } else if (this.model?.['sort_group_search_age'] == 'false') {
      arr.push(SortGroupTemplateAttributes.NoAge);
    }

    if (this.model?.['sort_group_search_ageClass'] == 'true') {
      arr.push(SortGroupTemplateAttributes.AgeClass);
    } else if (this.model?.['sort_group_search_ageClass'] == 'false') {
      arr.push(SortGroupTemplateAttributes.NoAgeClass);
    }

    if (this.model?.['sort_group_search_temperature'] == 'true') {
      arr.push(SortGroupTemplateAttributes.Temperature);
    } else if (this.model?.['sort_group_search_temperature'] == 'false') {
      arr.push(SortGroupTemplateAttributes.NoTemperature);
    }

    if (this.model?.['sort_group_search_dentition'] == 'true') {
      arr.push(SortGroupTemplateAttributes.Dentition);
    } else if (this.model?.['sort_group_search_dentition'] == 'false') {
      arr.push(SortGroupTemplateAttributes.NoDentition);
    }

    if (this.model?.['sort_group_search_gender'] == 'true') {
      arr.push(SortGroupTemplateAttributes.Gender);
    } else if (this.model?.['sort_group_search_gender'] == 'false') {
      arr.push(SortGroupTemplateAttributes.NoGender);
    }

    this.modelSnapshot = { ...this.model };

    return arr;
  }
}
