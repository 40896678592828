<sersi-spin [spin]="isLoading$ | async">
  <ng-container *transloco="let t; read: scopedNamespace">
    <ng-container *ngIf="productMetadata$ | async as metadata">
      <ifhms-product-metadata-form
          [metadata]="metadata"
          [productData]="config.data.product"
          [disabled]="config.data.disabled"
      />
      <div class="container-footer-buttons" *ngIf="!config.data.disabled">
        <p-button
          type="button"
          severity="secondary"
          [text]="true" 
          [rounded]="true"
          [label]="t('cancel-button-label')"
          (click)="cancel()"
        ></p-button>
        <p-button
          type="button"
          data-ele="apply-btn"
          icon="pi pi-check"
          iconPos="left"
          class="p-button-primary"
          raised="true"
          [label]="t('confirm-button-label')"
          (click)="apply()"
          [rounded]="true"
        ></p-button>
      </div>
    </ng-container>
  </ng-container>
</sersi-spin>
