import { Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { SersiFormlyFieldProps } from '@sersi/angular/formly/core';
import { isEmpty } from 'lodash-es';
import { distinctUntilChanged, filter } from 'rxjs';
import { UpcDeviceManagerService, UpcRfidService } from '@ifhms/common/angular/data-access/upc-api';
import { DataCaptureState } from '@ifhms/models/feedlot';

@UntilDestroy()
@Component({
  selector: 'ifhms-feedlot-event-form-rfid-field',
  templateUrl: './form-rfid-field.component.html',
  styleUrls: ['./form-rfid-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormRfidFieldComponent extends FieldType<FieldTypeConfig> implements OnInit{

  @HostBinding('class') class = 'national-id-field';

  private fieldConfig: FormlyFieldConfig;
  rfidCaptureState: DataCaptureState  = DataCaptureState.Idle;

  get fieldProps(): SersiFormlyFieldProps {
    if (!isEmpty(this?.fieldConfig?.props)) {
      return this?.fieldConfig?.props;
    }
    if (this.props) {
      return this.props
    }
    return {} as SersiFormlyFieldProps;
  }

  constructor(private upcRfidService: UpcRfidService, private upcDeviceManager: UpcDeviceManagerService) {
    super();
  }

  ngOnInit(): void {

    this.upcDeviceManager.registerDevice(this.upcRfidService).then(() => {
      this.subscribeToRFIDData();
    });

    this.emitDebouncedChange();
  }

  subscribeToRFIDData():void {
    this.upcRfidService.rfidData$
      .pipe(
        filter(() => this.fieldProps['readRFIDData'] !== false),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        if (data) {
          this.formControl.patchValue(data.tagNumber);
          this.rfidCaptureState = DataCaptureState.Captured;
        }
      });
  }

  searchAnimal(): void {
    const animalId = this.formControl.value;
    if (animalId) {
      this.fieldProps['onSearch']?.(animalId);
    }
  }

  private emitDebouncedChange(): void {
    this.formControl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(() =>{
      this.rfidCaptureState = DataCaptureState.Idle;
      this.searchAnimal()
    });
  }

}
