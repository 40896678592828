import { AnimalSelectorItemDto, AnimalSelectorRequestDto } from '@ifhms/models/feedlot';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CattleSelectorLeftActions } from './left.actions';

export interface State extends EntityState<AnimalSelectorItemDto> {
  loading: boolean;
  loaded: boolean;
  filter: AnimalSelectorRequestDto;
}

const adapter: EntityAdapter<AnimalSelectorItemDto> =
  createEntityAdapter<AnimalSelectorItemDto>({
    selectId: (entity: AnimalSelectorItemDto) => entity.id
  });

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  filter: <AnimalSelectorRequestDto>{}
});

export const reducer = createReducer(
  initialState,

  on(
    CattleSelectorLeftActions.replace,
    CattleSelectorLeftActions.add,
    CattleSelectorLeftActions.select,
    CattleSelectorLeftActions.selectAll,
    CattleSelectorLeftActions.remove,
    CattleSelectorLeftActions.filter,
    CattleSelectorLeftActions.clearFilter,
    (state) => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),

  on(CattleSelectorLeftActions.replaceSuccess, (state, { animals }) =>
    adapter.setAll(animals, {
      ...state,
      loading: false,
      loaded: true
    })
  ),

  on(CattleSelectorLeftActions.addSuccess, (state, { animals }) => {
    const list = animals.map((animal) => {
      return {
        ...animal,
        selected: false
      };
    });
    return adapter.addMany(list, {
      ...state,
      loading: false,
      loaded: true
    });
  }),

  on(CattleSelectorLeftActions.selectSuccess, (state, { animal }) =>
    adapter.updateOne(animal, {
      ...state,
      loading: false,
      loaded: true
    })
  ),

  on(CattleSelectorLeftActions.selectMultiple, (state, { animals }) =>
    adapter.updateMany(animals, {
      ...state,
      loading: false,
      loaded: true
    })
  ),

  on(CattleSelectorLeftActions.selectAllSuccess, (state, { selected }) => {
    const entityMap = function (
      entity: AnimalSelectorItemDto
    ): AnimalSelectorItemDto {
      return {
        ...entity,
        selected: selected ? (selected && entity.isAvailable) : selected
      };
    };
    return adapter.map(entityMap, {
      ...state,
      loading: false,
      loaded: true
    });
  }),

  on(CattleSelectorLeftActions.filterSuccess, (state, { filter }) => ({
    ...state,
    filter,
    loading: false,
    loaded: true
  })),

  on(CattleSelectorLeftActions.clearFilterSuccess, (state) => ({
    ...state,
    filter: <AnimalSelectorRequestDto>{},
    loading: false,
    loaded: true
  })),

  on(CattleSelectorLeftActions.removeSuccess, (state, { animals }) =>
    adapter.removeMany(
      animals.map((animal) => animal.id),
      {
        ...state,
        loading: false,
        loaded: true
      }
    )
  ),

  on(CattleSelectorLeftActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),

  on(CattleSelectorLeftActions.reset, () => initialState)
);

const { selectAll, selectTotal } = adapter.getSelectors();

export const getAll = selectAll;

export const getTotal = selectTotal;

export const getLoading = (state: State): boolean => state.loading;

export const getLoaded = (state: State): boolean => state.loaded;

export const getFilter = (state: State): AnimalSelectorRequestDto =>
  state.filter;

export const getFilterApplied = (state: State): boolean =>
  !!(
    state.filter.currentPenId ||
    state.filter.homePenId ||
    state.filter.lotId ||
    state.filter.animalIds?.length ||
    state.filter.nationalId ||
    state.filter.normalizedTagNumber
  );
