import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';

import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';

// TODO: move to ifhms common
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  DosingGunEventActionType,
  UpcDosingGunsFacade
} from '@ifhms/common/angular/upc/shared';
import {
  DeviceConnectionStatusEnum,
  DosingGunItem
} from '@ifhms/models/feedlot';
import { toPromise } from '@common/angular/utils';

@UntilDestroy()
@Component({
  selector: 'sersi-grid-dosing-gun-button-renderer',
  templateUrl: './dosing-gun-button-renderer.component.html',
  styleUrls: ['./dosing-gun-button-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DosingGunButtonRendererComponent implements OnInit {
  @ViewChild('menu') menu: Menu;
  @Input() disabled = true;
  @Input() gridCell: FormlyFieldConfig;
  @Input() showTooltip = true;
  @Output() assignDosingGun = new EventEmitter<void>();

  status$: Observable<DeviceConnectionStatusEnum> =
    this.dosingGunsFacade.dosingGunsConnectionStatus$;
  assignedDosingGuns$: Observable<DosingGunItem[] | null> =
    this.dosingGunsFacade.dosingGunItems$;

  dosingGunIcon: string;
  toolTipMsg: string | null;
  isButtonDisabled: boolean;

  workOrderNumber: number;

  eventButton = false;
  items: MenuItem[];

  constructor(
    private dosingGunsFacade: UpcDosingGunsFacade,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    combineLatest([this.status$, this.assignedDosingGuns$])
      .pipe(untilDestroyed(this))
      .subscribe(([status, dosingGuns]) => {
        this.workOrderNumber = this.gridCell.form?.root?.value.workOrderNumber;
        this.dosingGunIcon = this.getIcon(status, dosingGuns);
        this.toolTipMsg = this.getTooltipMessage(status, dosingGuns);
        this.isButtonDisabled =
          status === DeviceConnectionStatusEnum.Disconnected || this.disabled;
        this.cdr.markForCheck();
      });

    this.eventButton = this.gridCell?.props?.['eventButton'];
    if (this.eventButton) {
      this.buildMenuOptions();
    }
  }

  private getIcon(
    status: DeviceConnectionStatusEnum,
    dosingGuns: DosingGunItem[] | null
  ): string {
    if (!dosingGuns || status === DeviceConnectionStatusEnum.Disconnected) {
      return 'assets/img/dosing-guns/dosinggun_error.svg';
    }

    if (!this.gridCell.model.productId)
      return 'assets/img/dosing-guns/dosinggun_default.svg';

    const gunItem = dosingGuns.find(
      (gun) =>
        gun.productId === this.gridCell.model.productId &&
        gun.workOrderNumber === this.workOrderNumber
    );
    if (gunItem) {
      if (gunItem.validated)
        return 'assets/img/dosing-guns/dosinggun_success.svg';
      else return 'assets/img/dosing-guns/dosinggun_assigned.svg';
    } else {
      return 'assets/img/dosing-guns/dosinggun_default.svg';
    }
  }

  private getTooltipMessage(
    status: DeviceConnectionStatusEnum,
    dosingGuns: DosingGunItem[] | null
  ): string {
    if (!dosingGuns || status === DeviceConnectionStatusEnum.Disconnected) {
      return this.gridCell?.props?.['translateFn']('dosing-gun-not-connected');
    }

    const gunItem = dosingGuns.find(
      (gun) =>
        gun.productId === this.gridCell.model.productId &&
        gun.workOrderNumber === this.workOrderNumber
    );

    if (!this.gridCell.model.productId && !gunItem)
      return this.gridCell?.props?.['translateFn'](
        'dosing-gun-tooltip-available'
      );

    if (gunItem) {
      if (gunItem.validated)
        return this.gridCell?.props?.['translateFn'](
          'dosing-gun-tooltip-validated'
        );
      else
        return this.gridCell?.props?.['translateFn'](
          'dosing-gun-tooltip-assigned'
        );
    } else {
      switch (status) {
        case DeviceConnectionStatusEnum.Connected:
          return this.gridCell?.props?.['translateFn'](
            'dosing-gun-tooltip-available'
          );
        default:
          return this.gridCell?.props?.['translateFn'](
            'dosing-gun-not-connected'
          );
      }
    }
  }

  private buildMenuOptions(): void {
    this.items = [
      {
        label: this.gridCell?.props?.['translateFn']('set-one-time-dose'),
        command: (): void =>
          this.gridCell?.props?.['onAction'](
            DosingGunEventActionType.SetOneTimeDose
          )
      },
      {
        label: this.gridCell?.props?.['translateFn']('unassign-dosing-gun'),
        command: (): void =>
          this.gridCell?.props?.['onAction'](
            DosingGunEventActionType.UnassignDosingGun
          )
      },
      {
        label: this.gridCell?.props?.['translateFn']('dosing-gun-settings'),
        command: (): void =>
          this.gridCell?.props?.['onAction'](
            DosingGunEventActionType.OpenSettings
          )
      }
    ];
  }

  async onClick(event: Event): Promise<void> {
    const gun = await this.getAssignedDosingGun();
    if (gun && gun.validated && this.eventButton) {
      this.menu.toggle(event);
    } else {
      this.assignDosingGun.emit();
    }
  }

  async getAssignedDosingGun(): Promise<DosingGunItem | null> {
    const dosingGuns = await toPromise(this.dosingGunsFacade.dosingGunItems$);
    return (
      dosingGuns?.find(
        (gun) =>
          gun.productId === this.gridCell.model.productId &&
          gun.workOrderNumber === this.workOrderNumber
      ) || null
    );
  }
}
