import { createAction, props } from '@ngrx/store';
import { SortGroupTemplateResultDto } from '@ifhms/models/feedlot';
import { SortGroupTemplateAttributes } from '@ifhms/models/shared';

export const NAMESPACE = '[SORT GROUP - LIST]';

const get = createAction(
  `${NAMESPACE} Get`,
  props<{ attributes: SortGroupTemplateAttributes[] }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ result: SortGroupTemplateResultDto }>()
);

const search = createAction(
  `${NAMESPACE} Search`,
  props<{ search: string }>()
);

const searchSuccess = createAction(
  `${NAMESPACE} Search Success`,
  props<{ search: string }>()
);

const showModal = createAction(`${NAMESPACE} Show Modal`);

const hideModal = createAction(`${NAMESPACE} Hide Modal`);

const error = createAction(`${NAMESPACE} Error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const SortGroupListActions = {
  get,
  getSuccess,
  search,
  searchSuccess,
  showModal,
  hideModal,
  error,
  reset
};
