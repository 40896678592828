import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as AcquistionTypesReducer from './acquisition-types/acquisition-types.reducer';
import * as AgeClassesReducer from './age-classes/age-classess.reducer';
import * as AlleysReducer from './alleys/alleys.reducer';
import * as AnimalStatusReducer from './animal-status/animal-status.reducer';
import * as BreedTypesReducer from './breed-types/breed-types.reducer';
import * as CoatColourReducer from './coat-colours/coat-colour.reducer';
import * as BreedsReducer from './breeds/breeds.reducer';
import * as CciaPremiseIdsReducer from './ccia-premise-ids/ccia-premise-ids.reducer';
import * as CustomFieldsReducer from './custom-fields/custom-fields.reducer';
import * as DentitionsReducer from './dentitions/dentitions.reducer';
import * as DiagnosisReducer from './diagnosis/diagnosis.reducer';
import * as FacilitiesReducer from './facilities/facilities.reducer';
import * as FeedingPlansReducer from './feeding-plans/feeding-plans.reducer';
import * as FeedlotUsersReducer from './feedlot-users/feedlot-users.reducer';
import * as GendersReducer from './genders/genders.reducer';
import * as InjectionLocationsReducer from './injection-locations/injection-locations.reducer';
import * as LabourChargesReducer from './labour-charges/labour-charges.reducer';
import * as LocationsReducer from './locations/locations.reducer';
import * as LotSubGroupsReducer from './lot-sub-groups/lot-sub-groups.reducer';
import * as LotsReducer from './lots/lots.reducer';
import * as MarketingPlansReducer from './marketing-plans/marketing-plans.reducer';
import * as OtherFeesReducer from './other-fees/other-fees.reducer';
import * as PenTypesReducer from './pen-types/pen-types.reducer';
import * as PricingMethodsReducer from './pricing-methods/pricing-methods.reducer';
import * as ProceduresReducer from './procedures/procedures.reducer';
import * as ProductRoutesReducer from './product-routes/product-routes.reducer';
import * as ProductTypesReducer from './product-types/product-types.reducer';
import * as ProductsReducer from './products/products.reducer';
import * as PurchasersReducer from './purchasers/purchasers.reducer';
import * as RailDestinationsReducer from './rail-destinations/rail-destinations.reducer';
import * as RiskCategoriesReducer from './risk-categories/risk-categories.reducer';
import * as SourcesReducer from './sources/sources.reducer';
import * as TagColorsReducer from './tag-colors/tag-colors.reducer';
import * as TagLocationsReducer from './tag-locations/tag-locations.reducer';
import * as UnitOfMeasuresReducer from './unit-of-measures/unit-of-measures.reducer';
import * as LotOwnersReducer from './lot-owners/lot-owners.reducer';
import * as SortTypesReducer from './sort-types/sort-types.reducer';
import * as TxGroupsReducer from './tx-groups/tx-groups.reducer';

export const FEATURE_NAME = 'reference-data';

export const selectFeatureState = createFeatureSelector<State>(FEATURE_NAME);

export const reducer: ActionReducerMap<State> = {
  acquisitionTypes: AcquistionTypesReducer.reducer,
  ageClasses: AgeClassesReducer.reducer,
  alleys: AlleysReducer.reducer,
  animalStatus: AnimalStatusReducer.reducer,
  breeds: BreedsReducer.reducer,
  breedTypes: BreedTypesReducer.reducer,
  coatColours: CoatColourReducer.reducer,
  cciaPremiseIds: CciaPremiseIdsReducer.reducer,
  customFields: CustomFieldsReducer.reducer,
  dentitions: DentitionsReducer.reducer,
  diagnosis: DiagnosisReducer.reducer,
  facilities: FacilitiesReducer.reducer,
  feedingPlans: FeedingPlansReducer.reducer,
  feedlotUsers: FeedlotUsersReducer.reducer,
  genders: GendersReducer.reducer,
  injectionLocations: InjectionLocationsReducer.reducer,
  labourCharges: LabourChargesReducer.reducer,
  locations: LocationsReducer.reducer,
  lots: LotsReducer.reducer,
  lotSubGroups: LotSubGroupsReducer.reducer,
  marketingPlans: MarketingPlansReducer.reducer,
  otherFees: OtherFeesReducer.reducer,
  penTypes: PenTypesReducer.reducer,
  pricingMethods: PricingMethodsReducer.reducer,
  procedures: ProceduresReducer.reducer,
  products: ProductsReducer.reducer,
  productTypes: ProductTypesReducer.reducer,
  productRoutes: ProductRoutesReducer.reducer,
  purchasers: PurchasersReducer.reducer,
  railDestinations: RailDestinationsReducer.reducer,
  riskCategories: RiskCategoriesReducer.reducer,
  sources: SourcesReducer.reducer,
  tagColors: TagColorsReducer.reducer,
  tagLocations: TagLocationsReducer.reducer,
  txGroups: TxGroupsReducer.reducer,
  unitOfMeasures: UnitOfMeasuresReducer.reducer,
  lotOwners: LotOwnersReducer.reducer,
  sortTypes: SortTypesReducer.reducer
};

export interface State {
  acquisitionTypes: AcquistionTypesReducer.State,
  ageClasses: AgeClassesReducer.State,
  alleys: AlleysReducer.State,
  animalStatus: AnimalStatusReducer.State,
  breeds: BreedsReducer.State,
  breedTypes: BreedTypesReducer.State,
  coatColours: CoatColourReducer.State,
  cciaPremiseIds: CciaPremiseIdsReducer.State;
  customFields: CustomFieldsReducer.State,
  dentitions: DentitionsReducer.State,
  diagnosis: DiagnosisReducer.State,
  facilities: FacilitiesReducer.State;
  feedingPlans: FeedingPlansReducer.State,
  feedlotUsers: FeedlotUsersReducer.State,
  genders: GendersReducer.State,
  injectionLocations: InjectionLocationsReducer.State;
  labourCharges: LabourChargesReducer.State;
  locations: LocationsReducer.State;
  lots: LotsReducer.State;
  lotSubGroups: LotSubGroupsReducer.State;
  marketingPlans: MarketingPlansReducer.State,
  otherFees: OtherFeesReducer.State;
  penTypes: PenTypesReducer.State;
  pricingMethods: PricingMethodsReducer.State,
  procedures: ProceduresReducer.State,
  products: ProductsReducer.State
  productTypes: ProductTypesReducer.State,
  productRoutes: ProductRoutesReducer.State,
  purchasers: PurchasersReducer.State,
  railDestinations: RailDestinationsReducer.State;
  riskCategories: RiskCategoriesReducer.State,
  sources: SourcesReducer.State,
  tagColors: TagColorsReducer.State;
  tagLocations: TagLocationsReducer.State,
  txGroups: TxGroupsReducer.State;
  unitOfMeasures: UnitOfMeasuresReducer.State;
  lotOwners: LotOwnersReducer.State;
  sortTypes: SortTypesReducer.State;
}
