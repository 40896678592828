import { Injectable } from '@angular/core';
import { getLoadedStateData } from '@common/angular/utils';

import { FeedlotListDto } from '@ifhms/models/feedlot';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { feedlotsActions } from './feedlots.actions';
import * as feedlotListReducer from './feedlots.reducer';
import { FeedlotListSelectors } from './feedlots.selectors';

@Injectable()
export class FeedlotsFacade {

  state$: Observable<feedlotListReducer.State> = this.store.select(FeedlotListSelectors.selectState);
  feedlots$: Observable<FeedlotListDto> = this.store.select(FeedlotListSelectors.selectList);
  filteredFeedlots$: Observable<FeedlotListDto> = this.store.select(FeedlotListSelectors.selectFilteredFeedlots);
  loading$: Observable<boolean> = this.store.select(FeedlotListSelectors.selectLoading);
  loaded$: Observable<boolean> = this.store.select(FeedlotListSelectors.selectLoaded);
  filter$: Observable<string | null> = this.store.select(FeedlotListSelectors.selectFilter);

  loadedFeedlots$: Observable<FeedlotListDto> = getLoadedStateData<FeedlotListDto>(this.feedlots$, this.loaded$);

  constructor(private store: Store) {
  }

  get(forceReload = true): void {
    this.store.dispatch(feedlotsActions.get({ forceReload }));
  }

  filter(filter: string): void {
    this.store.dispatch(feedlotsActions.filter({ filter }));
  }

  resetFilters(): void {
    this.store.dispatch(feedlotsActions.resetFilters());
  }
}
