import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HashMap } from '@common/angular/interfaces';
import {
  SortGroupOutcomeFilterBy,
  SortGroupOutcomeListItemDto
} from '@ifhms/models/feedlot';
import { TranslocoService } from '@jsverse/transloco';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import {
  FormlyTypesEnum,
  SersiFormlyFormComponent
} from '@sersi/angular/formly/core';
import { map, of } from 'rxjs';
import { FEATURE_NAME, ListFacade } from '../../+state';
import { AbstractTranslationComponent } from '@common/angular/translation';

@Component({
  selector: 'ifhms-sort-group-outcome-table-filter-form',
  templateUrl: './sort-group-outcome-table-filter-form.component.html',
  styleUrls: ['./sort-group-outcome-table-filter-form.component.scss']
})
export class SortGroupOutcomeTableFilterFormComponent extends AbstractTranslationComponent
  implements OnInit, SersiFormlyFormComponent, OnChanges {
  protected override translationNamespace = 'components.sort-group-form';
  translateScope = `${FEATURE_NAME}.components.sort-group-form`;
  form = new FormGroup({});
  fields: FormlyFieldConfig[];
  options: FormlyFormOptions;
  model = {};
  modelSnapshot = {};

  @Input() singleRecord: SortGroupOutcomeListItemDto | undefined;
  @Input() detail: SortGroupOutcomeFilterBy | null;
  @Input() facade: ListFacade;

  constructor() {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['singleRecord'] || changes['detail']) {
      this.modelSnapshot = {
        ...this.detail
      };
      this.reset();
    }
  }

  override onTranslationInit(): void {
    this.setFields();
  }

  reset(): void {
    this.model = {
      ...this.modelSnapshot
    };
    this.setFields();
  }

  setFields(): void {
    this.fields = [
      {
        fieldGroupClassName: 'feedlot-web-sort-group__form-grid__sm',
        fieldGroup: [
          this.setBreed(),
          this.setBreedType(),
          this.setWeight(),
          this.setAge(),
          this.setAgeClass(),
          this.setTemperature(),
          this.setDentition(),
          this.setGender(),
          this.setOutputGroup(),
          this.setStatus()
        ]
      }
    ];
  }

  getData = (): HashMap => {
    return { ...this.model };
  };

  setBreed(): FormlyFieldConfig {
    return {
      key: 'breed',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__breed',
      props: {
        label$: this.getTranslation$('breed'),
        placeholder$: this.getTranslation$('select-option'),
        items$: this.facade.createDistinctSelector('breed'),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: null,
        hide: true
      },
      expressions: {
        hide: () => !this.singleRecord?.breed
      }
    };
  }

  setBreedType(): FormlyFieldConfig {
    return {
      key: 'breedType',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__breedType',
      props: {
        label$: this.getTranslation$('breed-type'),
        placeholder$: this.getTranslation$('select-option'),
        items$: this.facade.createDistinctSelector('breedType'),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: null,
        hide: true
      },
      expressions: {
        hide: () => !this.singleRecord?.breedType
      }
    };
  }

  setWeight(): FormlyFieldConfig {
    return {
      key: 'weight',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__weights',
      props: {
        label$: this.getTranslation$('weight'),
        placeholder$: this.getTranslation$('select-option'),
        items$: this.facade.createDistinctSelector('weight'),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: null,
        hide: true
      },
      expressions: {
        hide: () => !this.singleRecord?.weight
      }
    };
  }

  setAge(): FormlyFieldConfig {
    return {
      key: 'age',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__age',
      props: {
        label$: this.getTranslation$('age'),
        placeholder$: this.getTranslation$('select-option'),
        items$: this.facade.createDistinctSelector('age').pipe(
          map((value) => {
            return value.map((x) => {
              if (x.code === '0 - 0') {
                x.code = this.getTranslation('no-age');
              }
              return {
                ...x
              };
            });
          })
        ),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: null,
        hide: true
      },
      expressions: {
        hide: () => !this.singleRecord?.age
      }
    };
  }

  setAgeClass(): FormlyFieldConfig {
    return {
      key: 'ageClass',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__ageClass',
      props: {
        label$: this.getTranslation$('age-class'),
        placeholder$: this.getTranslation$('select-option'),
        items$: this.facade.createDistinctSelector('ageClass'),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: null,
        hide: true
      },
      expressions: {
        hide: () => !this.singleRecord?.ageClass
      }
    };
  }

  setTemperature(): FormlyFieldConfig {
    return {
      key: 'temperature',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__temperature',
      props: {
        label$: this.getTranslation$('temperature'),
        placeholder$: this.getTranslation$('select-option'),
        items$: this.facade.createDistinctSelector('temperature'),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: null,
        hide: true
      },
      expressions: {
        hide: () => !this.singleRecord?.temperature
      }
    };
  }

  setDentition(): FormlyFieldConfig {
    return {
      key: 'dentition',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__dentition',
      props: {
        label$: this.getTranslation$('dCode'),
        placeholder$: this.getTranslation$('select-option'),
        items$: this.facade.createDistinctSelector('dentition'),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: null,
        hide: true
      },
      expressions: {
        hide: () => !this.singleRecord?.dentition
      }
    };
  }

  setGender(): FormlyFieldConfig {
    return {
      key: 'gender',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__gender',
      props: {
        label$: this.getTranslation$('gender'),
        placeholder$: this.getTranslation$('select-option'),
        items$: this.facade.createDistinctSelector('gender'),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: null,
        hide: true
      },
      expressions: {
        hide: () => !this.singleRecord?.gender
      }
    };
  }

  setOutputGroup(): FormlyFieldConfig {
    return {
      key: 'groupNumber',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__gender',
      props: {
        label$: this.getTranslation$('outputGroup'),
        placeholder$: this.getTranslation$('select-option'),
        items$: this.facade.createDistinctSelector('groupNumber'),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        appendTo: null,
        hide: true
      },
      expressions: {}
    };
  }

  setStatus(): FormlyFieldConfig {
    return {
      key: 'status',
      type: FormlyTypesEnum.SINGLE_SELECT,
      className: 'feedlot-web-sort-group__gender',
      props: {
        label$: this.getTranslation$('status'),
        placeholder$: this.getTranslation$('select-option'),
        appendTo: null,
        hide: true,
        items$: of([
          {
            id: 'true',
            code: this.getTranslation('completed')
          },
          {
            id: 'false',
            code: this.getTranslation('not-completed')
          }
        ]),
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE'
      }
    };
  }
}
