import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectListItemToCodePipe } from '@common/angular/utils';
import {
  IfhmsFeedlotFrontEndSharedComponentsEventProductMetadataModule
} from '@ifhms/feedlot/front-end/shared/components/event-product-metadata'
import { FormlyModule } from '@ngx-formly/core';

import { SersiAngularFormlyTypesGridModule } from '@sersi/angular/formly/types/grid';

import { EventProductItemComponent, SortGroupProductItemComponent, WorkOrderProductItemComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    FormlyModule,
    ReactiveFormsModule,
    SersiAngularFormlyTypesGridModule,
    IfhmsFeedlotFrontEndSharedComponentsEventProductMetadataModule
  ],
  declarations: [
    EventProductItemComponent,
    SortGroupProductItemComponent,
    WorkOrderProductItemComponent
  ],
  exports: [
    EventProductItemComponent,
    SortGroupProductItemComponent,
    WorkOrderProductItemComponent
  ],
  providers: [
    SelectListItemToCodePipe
  ]
})
export class IfhmsFeedlotFrontEndSharedDomainProductItemModule {
}
