import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../reducer';
import * as reducer from './labour-charges.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.labourCharges
);

const selectItems = createSelector(selectState, reducer.getAll);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

export const LabourChargesSelectors = {
  selectState,
  selectLoaded,
  selectItems
};
