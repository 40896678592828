import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FeedlotDto, FeedlotListDto } from '@ifhms/models/feedlot';

import { AH_FMS_FEEDLOT_DOMAIN_API } from '../tokens';

@Injectable()
export class FeedlotService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {
  }

  get(feedlotId: string): Observable<FeedlotDto> {
    return this.http.get<FeedlotDto>(`${this.apiUrl}/Feedlots/${feedlotId}`);
  }

  getAll(): Observable<FeedlotListDto> {
    return this.http.get<FeedlotListDto>(`${this.apiUrl}/Feedlots`);
  }

  getBySlug(slug: string): Observable<FeedlotDto> {
    return this.http.get<FeedlotDto>(`${this.apiUrl}/Feedlots/Slug/${slug}`);
  }
}
