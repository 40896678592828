// @ts-nocheck
import { Injectable } from '@angular/core';
import { SortGroupsService } from '@ifhms/common/angular/data-access/feedlot-api';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { SortGroupOutcomeListItemDto } from '@ifhms/models/feedlot';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { catchError, exhaustMap, map, of, tap, withLatestFrom } from 'rxjs';
import { FEATURE_NAME } from '..';
import { SortGroupDetailFacade } from '../sort-group-detail/sort-group-detail.facade';
import { SortGroupOutcomeListActions } from './sort-group-outcome-list.actions';
import { SortGroupOutcomeListFacade } from './sort-group-outcome-list.facade';

@Injectable()
export class SortGroupOutcomeListEffects {
  translateScope = `${FEATURE_NAME}.state.effects`;

  constructor(
    private actions$: Actions,
    private feedlotFacade: FeedlotFacade,
    private facade: SortGroupOutcomeListFacade,
    private sortGroupDetailFacade: SortGroupDetailFacade,
    private sortGroupOutcomeListFacade: SortGroupOutcomeListFacade,
    private messageService: MessageService,
    private sortGroupsService: SortGroupsService,
    private translateService: TranslocoService
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeListActions.get),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.sortGroupDetailFacade.detail$
      ),
      exhaustMap(([, feedlotId, group]) => {
        if (!group) throw new Error('Invalid Sort Group Detail');
        return this.sortGroupsService
          .putOutcomesList(
            feedlotId,
            group.sortGroupId,
            group.isActionNewHomePen
          )
          .pipe(
            map((result: SortGroupOutcomeListItemDto[]) => {
              return SortGroupOutcomeListActions.getSuccess({
                outcomes: result
              });
            })
          );
      })
    )
  );

  filterBy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeListActions.filter),
      map(({ filter }) => SortGroupOutcomeListActions.filterSuccess({ filter }))
    )
  );
  filterBySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SortGroupOutcomeListActions.filterSuccess),
        tap(() =>
        {
          this.messageService.add({
            severity: 'success',
            summary: this.getTranslationString('success'),
            detail: this.getTranslationString('apply-filter-success')
          });
        }
        )
      ),
    { dispatch: false }
  );

  sortBy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeListActions.sortBy),
      map(({ sortBy }) => SortGroupOutcomeListActions.sortBySuccess({ sortBy }))
    )
  );

  showModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeListActions.showModal),
      map(() => SortGroupOutcomeListActions.get())
    )
  );

  select$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeListActions.select),
      map(({ outcome }) =>
        SortGroupOutcomeListActions.selectSuccess({ outcome })
      )
    )
  );

  updateSingle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeListActions.updateSingle),
      map(({ outcome }) =>
        SortGroupOutcomeListActions.updateSingleSuccess({ outcome })
      )
    )
  );

  clearOutput$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeListActions.clearOutput),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.sortGroupOutcomeListFacade.selected$,
        this.sortGroupDetailFacade.detail$
      ),
      exhaustMap(([, feedlotId, selectedList, detail]) => {
        if (!detail) throw new Error();
        const ids = selectedList.map((x) => x.id);
        return this.sortGroupsService
          .clearOutcome(feedlotId, detail?.sortGroupId, ids)
          .pipe(
            map((result) =>
              SortGroupOutcomeListActions.clearOutputSuccess({
                outcomes: result
              })
            ),
            catchError(() => {
              this.messageService.add({
                severity: 'error',
                summary: this.getTranslationString('fail'),
                detail: this.getTranslationString('clear-output-fail')
              });
              return of(SortGroupOutcomeListActions.error());
            })
          );
      })
    )
  );
  clearOutputSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeListActions.clearOutputSuccess),
      map(({ outcomes }) => {
        this.messageService.add({
          severity: 'success',
          summary: this.getTranslationString('success'),
          detail: this.getTranslationString('clear-output-success')
        });
        return SortGroupOutcomeListActions.getSuccess({ outcomes });
      })
    )
  );

  selectAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeListActions.selectAll),
      withLatestFrom(
        this.facade.filteredList$,
        this.facade.filteredSelectedLength$,
        this.facade.filteredTotalLength$
      ),
      map(([, list, selectedLength, total]) => {
        const select = !!total && selectedLength != total;
        return SortGroupOutcomeListActions.selectAllSuccess({
          updates: list.map((x) => ({
            ...x,
            changes: {
              selected: select
            }
          }))
        });
      })
    )
  );

  private getTranslationString(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }
}
