import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { SortGroupListFacade } from '../../+state/sort-group-list/sort-group-list.facade';
import { FEATURE_NAME } from '../../+state';
import { SortGroupSearchTableFilterFormComponent } from '../sort-group-search-table-filter-form/sort-group-search-table-filter-form.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TableFilterComponent, TableFilterService } from '@sersi/angular/ui/table';
import { distinctUntilChanged } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ifhms-sort-group-search-table-filter',
  templateUrl: './sort-group-search-table-filter.component.html',
  styleUrls: ['./sort-group-search-table-filter.component.scss'],
  providers: [ TableFilterService ]
})
export class SortGroupSearchTableFilterComponent implements AfterViewInit {
  translateScope = `${FEATURE_NAME}.components.sort-group-outcome-table-filter`;

  @ViewChild('filterForm') filterForm: SortGroupSearchTableFilterFormComponent;
  @ViewChild('tableFilter') tableFilter: TableFilterComponent;

  constructor(
    private facade: SortGroupListFacade,
    private tableFilterService: TableFilterService
  ) {}

  ngAfterViewInit(): void {
    this.facade.modal$
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((x) => {
        if (!x) {
          this.tableFilterService.appliedFilters = {};
          this.tableFilter.clearFilters();
        }
      });
  }

  applyFilter(): void {
    this.facade.get(this.filterForm.getData());
  }
}
