import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AuthSecuredRouteGuard } from '@common/angular/auth';
import { CommonAuthUserProfileResolver } from '@common/angular/features/user-profile';

const ROUTES: Route[] = [
  {
    path: 'health',
    loadChildren: () => import('@common/angular/health').then(m => m.CommonAngularHealthModule),
    canMatch: [AuthSecuredRouteGuard]
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@common/angular/features/auth').then(m => m.CommonAngularFeatureAuthModule)
  },
  {
    path: '',
    loadChildren: () =>
      import('@ifhms/feedlot/web/features/main').then(m => m.IfhmsFeedlotFeaturesMainModule),
    canMatch: [AuthSecuredRouteGuard],
    resolve: [CommonAuthUserProfileResolver]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
