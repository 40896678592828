import { createSelector } from '@ngrx/store';
import * as reducer from './upc-scale.reducer';

import { selectFeatureState } from '../upc.reducer';

export const selectState = createSelector(selectFeatureState, (state) => state.scale);

const selectLoading = createSelector(selectState, reducer.getLoading);
const selectLoaded = createSelector(selectState, reducer.getLoaded);
const selectError = createSelector(selectState, reducer.getError);
const selectWeightCaptured = createSelector(selectState, reducer.getWeightCaptured);
const selectWeight = createSelector(selectState, reducer.getWeight);

export const UpcScaleSelectors = {
  selectLoading,
  selectLoaded,
  selectError,
  selectState,
  selectWeightCaptured,
  selectWeight
}
