import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

import { PulseLoaderComponent } from './components/pulse-loader/pulse-loader.component';

@NgModule({
  imports: [
    CommonModule,
    TooltipModule
  ],
  declarations: [ PulseLoaderComponent ],
  exports: [ PulseLoaderComponent ]
})
export class SersiAngularUiPulseLoaderModule {}
