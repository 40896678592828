import { SortGroupOutcomeDto } from '@ifhms/models/feedlot';
import { createAction, props } from '@ngrx/store';

export const NAMESPACE = '[SORT GROUP OUTCOME - DETAIL VIEW]';

const get = createAction(`${NAMESPACE} Get`, props<{ id: string }>());

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ outcome: SortGroupOutcomeDto }>()
);

const error = createAction(`${NAMESPACE} Error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const SortGroupOutcomeDetailViewActions = {
  get,
  getSuccess,
  error,
  reset
};
