import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { HashMap } from '@jsverse/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { WorkOrderProductItemComponent } from '@ifhms/feedlot/shared/domain/product-item';
import { FormlyTypesEnum, GRID_KEY, GridColumnDef, GridRendererCellType } from '@sersi/angular/formly/core';

import { UpcDosingGunsFacade } from '@ifhms/common/angular/upc/shared';
import { AbstractProductSelectorComponent } from '../abstract-product-selector.component';

@Component({
  selector: 'ifhms-work-order-product-selector',
  templateUrl: './work-order-product-selector.component.html',
  styleUrls: ['./work-order-product-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkOrderProductSelectorComponent extends AbstractProductSelectorComponent {
  translateScope = 'product-selector';

  constructor(private dosingGunsFacade: UpcDosingGunsFacade) {
    super();
    this.dosingGunsFacade.getDosingGunsStatusFromUpc();
  }

  protected getInitialModel(): HashMap {
    return {
      id: null,
      categoryColor: '',
      productTypeId: null,
      productId: null,
      routeId: null,
      recQty: null,
      qty: null,
      totalQty: null,
      unit: null,
      flat: !this.fieldConfig.form?.value.isRecordWeight,
      routeDetailId: null,
      adminLocationId: null
    };
  }

  protected setGridField(): FormlyFieldConfig {
    return {
      className: 'work-order-product-selector',
      type: FormlyTypesEnum.REPEATING_GRID_TYPE,
      key: GRID_KEY,
      props: {
        initialModel: () => this.getInitialModel(),
        noDataMessage$: this.getParentTranslation$('no-products-label'),
        footerAddButtonLabel$: this.getParentTranslation$('user-product-label'),
        isFooterAddButtonEnabled: !this.fieldProps['disableAdd'],
        gridOptions: this.getGridOptions()
      },
      fieldArray: {
        fieldGroup: [
          this.setGridRow()
        ]
      }
    };
  }

  protected setGridRow(): FormlyFieldConfig {
    return {
      type: WorkOrderProductItemComponent,
      props: {
        contextKey: this.fieldKey,
        gridOptions: this.getGridOptions(),
        relatedProductsKeys: this.fieldProps['relatedProductsKeys'],
        disableProductTypeEdit: this.fieldProps['disableProductTypeEdit'],
        translateFn: this.getParentTranslation.bind(this),
        checkTemplate: this.fieldProps['checkTemplate'],
        checkTemplateSource: this.fieldProps['checkTemplateSource']
      },
      expressions: {
        'props.numberOfHeads': () => this.fieldProps['numberOfHeads']
      }
    };
  }

  protected getColumnDefs(): GridColumnDef[] {
    return [
      {
        fieldKey: 'aiCategoryCode',
        width: '55px',
        hideCell: !this.fieldProps['enableCategory']
      },
      {
        headerName$: this.getParentTranslation$('type-label'),
        fieldKey: 'productTypeId',
        width: '12%',
        required: true
      },
      {
        headerName$: this.getParentTranslation$('product-label'),
        fieldKey: 'productId',
        width: '12%',
        required: true
      },
      {
        headerName$: this.getParentTranslation$('route-label'),
        fieldKey: 'routeDetailId',
        width: '12%',
        required: true
      },
      {
        headerName$: this.getParentTranslation$('recommended-qty-label'),
        fieldKey: 'recQty',
        width: '8%'
      },
      {
        headerName$: this.getParentTranslation$('qty-label'),
        fieldKey: 'qty',
        width: '8%'
      },
      {
        headerName$: this.getParentTranslation$('total-qty-label'),
        fieldKey: 'totalQty',
        width: '8%'
      },
      {
        headerName$: this.getParentTranslation$('unit-label'),
        fieldKey: 'unit',
        width: '8%'
      },
      {
        headerName$: this.getParentTranslation$('flat-label'),
        fieldKey: 'flat',
        width: '8%'
      },
      {
        headerName$: this.getParentTranslation$('dosing-gun-label'),
        fieldKey: 'dosing-gun-btn',
        width: '8%',
        cellRendererParams: {
          type: GridRendererCellType.DosingGun
        }
      },
      {
        fieldKey: 'delete-btn',
        width: '48px',
        cellRendererParams: {
          type: GridRendererCellType.Delete
        }
      }
    ];
  }

}
