import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sersi-grid-info-button-renderer',
  templateUrl: './info-button-renderer.component.html',
  styleUrls: ['./info-button-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GridInfoButtonRendererComponent {

  @Input() disabled: boolean;
  @Output() rowInfoClicked = new EventEmitter<void>();

}
