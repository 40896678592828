<ng-container *transloco="let t; read: scopedNamespace">
    <formly-form
            [fields]="fields"
            [form]="form"
            [model]="model"
    >
    </formly-form>
    <br/>
    <p-button
            type="submit"
            [disabled]="form.pristine"
            (click)="onUpdateDosingGuns()"
            [rounded]="true"
            [raised]="true"
    > {{ t('save-button') }}
    </p-button>
</ng-container>