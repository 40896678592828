import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { scopeLoader, TRANSLOCO_NAV_SCOPE_TOKEN } from '@common/angular/translation';
import { TranslocoModule } from '@jsverse/transloco';
import { PreventUnsavedChangesGuard } from './guards/prevent-unsaved-changes.guard';
import { FeedlotNavigationService } from './services/feedlot-navigation';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule
  ],
  providers: [
    PreventUnsavedChangesGuard,
    {
      provide: TRANSLOCO_NAV_SCOPE_TOKEN,
      useValue: {
        scope: 'navigation',
        alias: 'navigation',
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        )
      }
    },
    FeedlotNavigationService
  ]

})
export class IfhmsFeedlotDomainNavigationModule {}
