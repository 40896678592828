import { isDate } from 'lodash-es';

import { getLocalDateFromUTCIsoString } from '@common/angular/utils';

export function calculateDaysOnFeedlot(inDateStr: string, outDateStr: string): number | null {
  const inDate = getLocalDateFromUTCIsoString(inDateStr, true);
  const outDate = getLocalDateFromUTCIsoString(outDateStr, true);

  if (!isDate(inDate) || !isDate(outDate)) return null;

  const diffInMs = outDate.getTime() - inDate.getTime();
  // Round to the nearest whole number to handle Winter-Summer time changes
  const diffInDays = Math.round(diffInMs / (1000 * 3600 * 24));
  return Math.max(diffInDays, 0);
}
