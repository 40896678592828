import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';
import { AnimalVerificationDto } from '@ifhms/models/feedlot';

@Injectable()
export class ImportAnimalsFileService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {}

  verifyImportFile(
    feedlotId: string,
    formattedData: NonNullable<unknown>
  ): Observable<AnimalVerificationDto[]> {
    return this.http.put<AnimalVerificationDto[]>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Animals/VerifyAnimalIds`,
      formattedData
    );
  }
}
