import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonDialogSize } from '@common/angular/interfaces';
import { scopeLoader } from '@common/angular/translation';
import { AhCommonDialogService } from '@common/angular/utils';
import { ImportAnimalsFileService } from '@ifhms/common/angular/data-access/feedlot-api';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { AnimalSelectorItemDto, AnimalVerificationDto } from '@ifhms/models/feedlot';
import { HashMap, TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { parse } from 'papaparse';
import { Message, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload, FileUploadHandlerEvent } from 'primeng/fileupload';
import { filter, first } from 'rxjs';
import { FEATURE_NAME } from '../../+state';
import { CattleSelectorLeftFacade } from '../../+state/left/left.facade';
import { formatDataForValidation } from '../../utils';
import { CattleSelectorValidationPanelComponent } from '../cattle-selector-validation-panel';
import CSV_IMPORT_HEADERS from './cattle-selector-upload-form.constants';

@Component({
  selector: 'ifhms-cattle-selector-upload-form',
  templateUrl: './cattle-selector-upload-form.component.html',
  styleUrls: ['./cattle-selector-upload-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: FEATURE_NAME,
        alias: FEATURE_NAME,
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class CattleSelectorUploadFormComponent implements OnInit {
  readonly translateScope = `${FEATURE_NAME}.components.cattle-selector-upload-form`;
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly DIALOG_HEIGHT = 'fit-content';

  @HostBinding('class.cattle-selector-upload-form') hostClass = true;
  @ViewChild('fileUpload') fileUpload: FileUpload | undefined;

  feedlotId: string;
  validationErrors: any[];
  selectedFile: File | null;
  inlineErrMsg: Message[] = [];

  constructor(
    private importAnimalsService: ImportAnimalsFileService,
    private feedlotFacade: FeedlotFacade,
    private translateService: TranslocoService,
    private cdr: ChangeDetectorRef,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private messageService: MessageService,
    private leftFacade: CattleSelectorLeftFacade,
    private dialogService: AhCommonDialogService
  ) {
  }

  ngOnInit(): void {
    this.setFeedlot();
  }

  setFeedlot(): void {
    this.feedlotFacade.feedlotId$
      .pipe(first())
      .subscribe((feedlotId: string) => {
        this.feedlotId = feedlotId;
      });
  }

  onUpload(event: FileUploadHandlerEvent): void {
    this.inlineErrMsg = [];
    this.validationErrors = [];
    this.selectedFile = event.files[0];
    this.cdr.markForCheck();
    this.processSelectedFile();
  }

  private processSelectedFile(): void {
    parse(this.selectedFile!, {
      complete: (results: any): void => {
        const hasValidColumns = this.hasValidColumns(results.data[0]);

        if (!hasValidColumns) {
          this.onFileParseError();
          return;
        }
        else {
          const updatedColumnHeadersData = this.changeColumnHeaders(
            results.data
          );
          const data = formatDataForValidation(updatedColumnHeadersData);

          this.uploadSelectedFile(data);
        }
      }
    });
  }

  private hasValidColumns(headers: string[]): boolean {
    return CSV_IMPORT_HEADERS.every((col) => headers.includes(col));
  }

  private changeColumnHeaders(data: any[]): any[] {
    const newColumnHeaders = ['AnimalTags', 'NationalIds'];
    data[0] = newColumnHeaders;
    return data;
  }

  private uploadSelectedFile(formattedData: NonNullable<unknown>): void {
    this.leftFacade.clearFilter();

    this.importAnimalsService
      .verifyImportFile(this.feedlotId, formattedData)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data: any) => this.onUploadSuccess(data),
        error: (): void => this.onUploadError()
      });
    this.cdr.markForCheck();
  }

  private onUploadSuccess(data: AnimalVerificationDto[]): void {
    const verifiedData: AnimalVerificationDto[] =
      this.getVerifiedUniqueAnimals(data);

    const unverifiedData: AnimalVerificationDto[] =
      this.getUnverifiedData(data);

    if (unverifiedData.length > 0) {
      this.getUnverifiedDataError(unverifiedData, verifiedData);
    }
    else {
      this.setAnimalsAndReset(verifiedData);
    }
  }

  private setAnimalsSelected(verifiedData: AnimalVerificationDto[]): void {
    this.leftFacade.list$
      .pipe(
        filter((items: AnimalSelectorItemDto[]) => items.length > 0),
        first()
      )
      .subscribe((items: AnimalSelectorItemDto[]) => {
        items.forEach((item: AnimalSelectorItemDto) => {
          verifiedData.forEach((data: AnimalVerificationDto) => {
            if (data.animalId === item.id) {
              this.leftFacade.select(item);
            }
          });
        });
      });

    this.messageService.add({
      severity: 'success',
      summary: this.getTranslation('upload-complete')
    });
  }

  private getUnverifiedDataError(
    unverifiedData: AnimalVerificationDto[],
    verifiedData: AnimalVerificationDto[]
  ): void {
    const validationMessages: string[] = [];

    for (const item of unverifiedData.map((value, index) => ({
      index,
      value
    }))) {
      if (item.value.message === 'Not Verified - Invalid NationalId') {
        if (
          item.value.electronicTag !== '' &&
          item.value.electronicTag !== null
        ) {
          validationMessages.push(
            `${this.getTranslation('electronic-tag', {
              electronicTag: item.value?.electronicTag
            })}`
          );
        }
      }
      else if (item.value.message === 'Not Verified') {
        if (item.value.animalTag !== '' && item.value.animalTag !== null) {
          validationMessages.push(
            `${this.getTranslation('animal-tag', {
              animalTag: item.value?.animalTag
            })}`
          );
        }
      }
    }

    if (validationMessages.length > 0) {
      this.showValidationPanel(validationMessages, verifiedData);
    }
    else {
      this.setAnimalsAndReset(verifiedData);
    }
  }

  private setAnimalsAndReset(verifiedData: AnimalVerificationDto[]): void {
    this.searchAnimals(verifiedData);
    this.setAnimalsSelected(verifiedData);
    this.resetForm();
  }

  private showValidationPanel(
    validationMessages: string[],
    verifiedData: AnimalVerificationDto[]
  ): void {
    const dialogRef = this.dialogService.openModal(
      CattleSelectorValidationPanelComponent,
      {
        height: this.DIALOG_HEIGHT,
        data: {
          messages: validationMessages
        }
      },
      CommonDialogSize.MD
    );

    dialogRef.onClose
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result: 'ok' | 'cancel') => {
        if (result === 'ok') {
          this.searchAnimals(verifiedData);
          this.setAnimalsSelected(verifiedData);
        }
        else {
          this.leftFacade.clearFilter();
        }
        this.resetForm();
        this.cdr.markForCheck();
      });
  }

  private searchAnimals(verifiedData: AnimalVerificationDto[]): void {
    this.leftFacade.filter({
      currentPenId: null,
      homePenId: null,
      lotId: null,
      subGroupId: null,
      ...this.dialogConfig.data.woAttributes,
      nationalId: 0,
      normalizedTagNumber: '',
      animalIds: [
        ...new Set(
          verifiedData.map((item: AnimalVerificationDto) => item.animalId)
        )
      ]
    });
  }

  private onFileParseError(): void {
    this.showInlineValidationError(this.getTranslation('invalid-columns'));
    this.fileUpload?.clear();
    this.cdr.markForCheck();
  }

  private onUploadError(): void {
    this.resetUploadInput();
  }

  private resetUploadInput(): void {
    this.fileUpload?.clear();
    this.dialogRef.close();
  }

  private showInlineValidationError(errMsg: string): void {
    this.inlineErrMsg = [
      {
        severity: 'error',
        detail: errMsg
      }
    ];
  }

  private resetForm(): void {
    this.validationErrors = [];
    this.inlineErrMsg = [];
    this.selectedFile = null;
    this.cdr.markForCheck();
    this.resetUploadInput();
  }

  private getUniqueAnimalIds(
    data: AnimalVerificationDto[]
  ): AnimalVerificationDto[] {
    return [
      ...new Map(
        data.map((item: AnimalVerificationDto) => [item['animalId'], item])
      ).values()
    ];
  }

  private getVerifiedUniqueAnimals(
    data: AnimalVerificationDto[]
  ): AnimalVerificationDto[] {
    return this.getUniqueAnimalIds(data).filter(
      (item: AnimalVerificationDto) => item.message === 'Verified'
    );
  }

  private getUnverifiedData(
    data: AnimalVerificationDto[]
  ): AnimalVerificationDto[] {
    return data.filter((item: AnimalVerificationDto) =>
      item.message.includes('Not Verified')
    );
  }

  private getTranslation(key: string, params?: HashMap): string {
    return this.translateService.translate(
      `${this.translateScope}.${key}`,
      params
    );
  }
}
