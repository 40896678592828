import { InjectionToken } from '@angular/core';

export interface AhFmsFeedlotApiConfig {
  apiUrl: string;
}

// TODO: Remove AH_FMS_FEEDLOT_API_CONFIG AS ITS LEGARY
export const AH_FMS_FEEDLOT_API_CONFIG = new InjectionToken<AhFmsFeedlotApiConfig>('AH_FMS_FEEDLOT_API_CONFIG');
export const AH_FMS_FEEDLOT_DOMAIN_API = new InjectionToken<AhFmsFeedlotApiConfig>('AH_FMS_FEEDLOT_DOMAIN_API');
// TODO: Remove me after Sort Group List is added to feedlot API
export const AH_FMS_SORT_GROUP_API = new InjectionToken<AhFmsFeedlotApiConfig>('AH_FMS_SORT_GROUP_API');
