import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  SortGroupOutcomeFilterBy,
  SortGroupOutcomeListItemDto,
  SortGroupOutcomeSortBy
} from '@ifhms/models/feedlot';
import { SortGroupOutcomeCopyGridListActions } from './sort-group-outcome-copy-grid-list.actions';

export interface State extends EntityState<SortGroupOutcomeListItemDto> {
  copyFrom: string[];
  copyTo: string;
  fromForm: boolean;
  loading: boolean;
  loaded: boolean;
  modal: boolean;
  sortBy: SortGroupOutcomeSortBy;
  filterBy: SortGroupOutcomeFilterBy;
}

const adapter: EntityAdapter<SortGroupOutcomeListItemDto> =
  createEntityAdapter<SortGroupOutcomeListItemDto>({
    selectId: (entity: SortGroupOutcomeListItemDto) => entity.id
  });

export const initialState: State = adapter.getInitialState({
  copyFrom: [],
  copyTo: '',
  fromForm: false,
  loading: false,
  loaded: false,
  modal: false,
  sortBy: SortGroupOutcomeSortBy.None,
  filterBy: <SortGroupOutcomeFilterBy>{}
});

export const reducer = createReducer(
  initialState,

  on(
    SortGroupOutcomeCopyGridListActions.selectAll,
    SortGroupOutcomeCopyGridListActions.updateSingle,
    SortGroupOutcomeCopyGridListActions.showCopyFromModal,
    SortGroupOutcomeCopyGridListActions.showCopyFromModalFromForm,
    SortGroupOutcomeCopyGridListActions.showCopyToModal,
    SortGroupOutcomeCopyGridListActions.updateSingle,
    SortGroupOutcomeCopyGridListActions.filter,
    SortGroupOutcomeCopyGridListActions.sortBy,
    SortGroupOutcomeCopyGridListActions.apply,
    (state) => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),

  on(SortGroupOutcomeCopyGridListActions.select, (state) => {
    if (!state.copyTo) {
      const entityMap = function (
        entity: SortGroupOutcomeListItemDto
      ): SortGroupOutcomeListItemDto {
        return {
          ...entity,
          selected: false
        };
      };
      return adapter.map(entityMap, {
        ...state,
        loading: true,
        loaded: false
      });
    }
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),

  on(
    SortGroupOutcomeCopyGridListActions.selectSuccess,
    SortGroupOutcomeCopyGridListActions.updateSingleSuccess,
    (state, { outcome }) =>
      adapter.updateOne(outcome, {
        ...state,
        loading: false,
        loaded: true
      })
  ),

  on(
    SortGroupOutcomeCopyGridListActions.showCopyFromModalSuccess,
    (state, { copyFrom, list }) =>
      adapter.setAll(list, {
        ...state,
        copyFrom,
        copyTo: '',
        modal: true,
        loading: false,
        loaded: true
      })
  ),

  on(
    SortGroupOutcomeCopyGridListActions.showCopyFromModalFromFormSuccess,
    (state, { copyFrom, list }) =>
      adapter.setAll(list, {
        ...state,
        copyFrom,
        fromForm: true,
        copyTo: '',
        modal: true,
        loading: false,
        loaded: true
      })
  ),

  on(
    SortGroupOutcomeCopyGridListActions.showCopyToModalSuccess,
    (state, { copyTo, list }) =>
      adapter.setAll(list, {
        ...state,
        copyTo,
        copyFrom: [],
        modal: true,
        loading: false,
        loaded: true
      })
  ),

  on(
    SortGroupOutcomeCopyGridListActions.selectAllSuccess,
    (state, { updates }) =>
      adapter.updateMany(updates, {
        ...state,
        loading: false,
        loaded: true
      })
  ),

  on(SortGroupOutcomeCopyGridListActions.filterSuccess, (state, { filter }) => ({
    ...state,
    filterBy: filter,
    loading: false,
    loaded: true
  })),

  on(SortGroupOutcomeCopyGridListActions.sortBySuccess, (state, { sortBy }) => ({
    ...state,
    sortBy,
    loading: false,
    loaded: true
  })),

  on(SortGroupOutcomeCopyGridListActions.applySuccess, (state) => ({
    ...state,
    modal: false,
    loading: false,
    loaded: true
  })),

  on(SortGroupOutcomeCopyGridListActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),

  on(
    SortGroupOutcomeCopyGridListActions.hideModal,
    SortGroupOutcomeCopyGridListActions.reset,
    () => initialState
  )
);

const { selectAll, selectTotal } = adapter.getSelectors();

export const getAll = selectAll;

export const getTotal = selectTotal;

export const getLoading = (state: State): boolean => state.loading;

export const getLoaded = (state: State): boolean => state.loaded;

export const getCopyTo = (state: State): string => state.copyTo;
export const getCopyFrom = (state: State): string[] => state.copyFrom;

export const getModal = (state: State): boolean => state.modal;

export const getFilterBy = (state: State): SortGroupOutcomeFilterBy =>
  state.filterBy;

export const getSortBy = (state: State): SortGroupOutcomeSortBy => state.sortBy;