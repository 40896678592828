import { Injectable } from '@angular/core';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as reducer from './ccia-premise-ids.reducer';
import { CciaPremiseIdsSelectors } from './ccia-premise-ids.selectors';
import { CciaPremiseIdsActions } from './ccia-premise-ids.actions';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class CciaPremiseIdsFacade {

  loaded$ = this.store.select(CciaPremiseIdsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(CciaPremiseIdsSelectors.selectItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(CciaPremiseIdsActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(CciaPremiseIdsActions.reset());
  }
}
