import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import * as reducer from './procedures.reducer';
import { ProceduresSelectors } from './procedures.selectors';
import { ProceduresActions } from './procedures.actions';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class ProceduresFacade {

  loaded$ = this.store.select(ProceduresSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(ProceduresSelectors.selectItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(ProceduresActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(ProceduresActions.reset());
  }
}
