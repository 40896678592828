import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UpcBaseDeviceService } from './upc-base-device.service';
import { DeviceType } from '@ifhms/models/feedlot';

@Injectable()
export class UpcGateControllerService extends UpcBaseDeviceService {
  private _numberOfGates = new BehaviorSubject<number>(0);
  numberOfGates$ = this._numberOfGates.asObservable();

  private gateControllerInitialized: boolean | null = null;

  constructor(injector: Injector) {
    super(DeviceType.Gate,injector);
  }

  async initDevice(): Promise<boolean> {
    if (!this.gateControllerInitialized) {
      this.gateControllerInitialized = await this.initConnection();
    }
    return this.gateControllerInitialized;
  }

  async openGate(gateNumber: number): Promise<void> {
    try {
      await this.hubConnection?.invoke('OpenGate', gateNumber);
    } catch (err) {
      console.error('Error opening gate:', err);
    }
  }
}