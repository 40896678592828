import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as reducer from './animal-availability.reducer';

export const FEATURE_NAME = 'animal-availability';

export const selectState = createFeatureSelector<reducer.State>(FEATURE_NAME);

const selectLoading = createSelector(selectState, reducer.getLoading);
const selectLoaded = createSelector(selectState, reducer.getLoaded);
const selectAnimalAvailability = createSelector(selectState, reducer.getAnimalAvailability);

export const AnimalAvailabilitySelectors = {
  selectLoading,
  selectLoaded,
  selectAnimalAvailability
};

