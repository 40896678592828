<sersi-spin [spin]="loading$ | async">
    <ng-container *transloco="let t; read: scopedNamespace">
    <formly-form
            [fields]="fields"
            [form]="form"
            [model]="model"
            (keydown.enter)="onSubmit()"
    >
    </formly-form>
    <div class="container-footer-buttons">
        <button
                pButton
                data-ele="cancel-btn"
                [label]="t('cancel-button')"
                class="p-button-secondary"
                (click)="cancel()"
                [rounded]="true"
                [text]="true">
        </button>
        <button
                pButton
                data-ele="save-btn"
                [label]="t('validate-button')"
                class="p-button-raised save-button"
                [disabled]="!form.valid || !form.dirty"
                (click)="onSubmit()"
                icon="pi pi-check"
                iconPos="left"
                [rounded]="true">
        </button>

    </div>
    </ng-container>
</sersi-spin>