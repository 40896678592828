import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { USE_DEFAULT_ERROR_HANDLER } from '@common/angular/error-handling';
import {
  ReHandlingEventListRequestDto,
  ReHandlingEventListDto,
  ReHandlingEventDto,
  ReHandlingEventUpdateDto,
  EventsListDto,
  ReHandlingAnimalSearchDto,
  ReHandlingAnimalSearchResultDto,
  EventAnimalCountDto, EventFormTriggerParamsDto, EventFormTriggerOutputDto, RehandlingWorkSheetSummary
} from '@ifhms/models/feedlot';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';

@Injectable()
export class FeedlotEventsReHandlingService {

  private context = new HttpContext().set(USE_DEFAULT_ERROR_HANDLER, false);

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API)  private readonly apiUrl: string
  ) {}

  getList(feedlotId: string, request: ReHandlingEventListRequestDto): Observable<ReHandlingEventListDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<ReHandlingEventListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/List`, {
      params
    });
  }

  getEvent(feedlotId: string, eventId: string, workOrderId: string): Observable<ReHandlingEventDto> {
    return this.http.get<ReHandlingEventDto>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/Event/${eventId}`);
  }

  createEvent(feedlotId: string, eventCreate: ReHandlingEventUpdateDto, workOrderId: string): Observable<ReHandlingEventDto> {
    return this.http.post<ReHandlingEventDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/Event`,
      eventCreate,
      { context: this.context }
    );
  }

  updateEvent(feedlotId: string, eventId: string, eventUpdate: ReHandlingEventUpdateDto, workOrderId: string): Observable<ReHandlingEventDto> {
    return this.http.put<ReHandlingEventDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/Event/${eventId}`,
      eventUpdate,
      { context: this.context }
    );
  }

  getNextEventId(feedlotId: string, eventId: string, workOrderId: string): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/Event/${eventId}/Next`);
  }
  getPreviousEventId(feedlotId: string, eventId: string, workOrderId: string): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/Event/${eventId}/Back`);
  }

  deleteEvent(feedlotId: string, eventId: string, workOrderId: string): Observable<string> {
    return this.http.delete<string>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/Event/${eventId}/Delete`);
  }

  getGoToEventList(feedlotId: string, workOrderId: string): Observable<EventsListDto> {
    return this.http.get<EventsListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/GoToEventList`);
  }

  animalSearch(feedlotId: string, searchParams: ReHandlingAnimalSearchDto): Observable<ReHandlingAnimalSearchResultDto> {
    const params = new HttpParams({ fromObject: searchParams as unknown as Record<string, any> });
    return this.http.get<ReHandlingAnimalSearchResultDto>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/AnimalSearch`, { params, context: this.context });
  }

  getEventsAnimalCount(feedlotId: string, workOrderId: string): Observable<EventAnimalCountDto[]> {
    return this.http.get<EventAnimalCountDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/AnimalCountList`);
  }

  getEventFormTrigger(feedlotId: string, workOrderId: string, params: EventFormTriggerParamsDto): Observable<EventFormTriggerOutputDto> {
    return this.http.put<EventFormTriggerOutputDto>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/EventFormTriggerOutput`, params);
  }

  getWorkSheetSummary(feedlotId: string, workOrderId: string): Observable<RehandlingWorkSheetSummary> {
    return this.http.get<RehandlingWorkSheetSummary>(`${this.apiUrl}/Feedlot/${feedlotId}/ReHandling/WorkOrder/${workOrderId}/WorkSheetSummary`);
  }

}
