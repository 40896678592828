import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';
import { SortGroupOutcomeDto } from '@ifhms/models/feedlot';
import { SortGroupsService } from '@ifhms/common/angular/data-access/feedlot-api';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { SortGroupOutcomeDetailViewActions } from './sort-group-outcome-detail-view.actions';
import { SortGroupDetailFacade } from '../sort-group-detail/sort-group-detail.facade';

@Injectable()
export class SortGroupOutcomeDetailViewEffects {
  translateScope = 'feedlot.state.feedlot-list-effects';

  constructor(
    private actions$: Actions,
    private sortGroupsService: SortGroupsService,
    private sortGroupDetailFacade: SortGroupDetailFacade,
    private feedlotFacade: FeedlotFacade
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SortGroupOutcomeDetailViewActions.get),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.sortGroupDetailFacade.detail$
      ),
      exhaustMap(([{ id }, feedlotId, detail]) => {
        if (!detail) throw new Error();
        return this.sortGroupsService
          .getOutcomesDetail(feedlotId, detail.sortGroupId, id)
          .pipe(
            map((result: SortGroupOutcomeDto) => {
              if (!result) throw new Error();
              return SortGroupOutcomeDetailViewActions.getSuccess({
                outcome: result
              });
            }),
            catchError(() => of(SortGroupOutcomeDetailViewActions.error()))
          );
      })
    )
  );
}
