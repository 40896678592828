import { FeedlotDto } from '@ifhms/models/feedlot';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[FEEDLOT]';

const get = createAction(`${NAMESPACE} Get`, props<{ id: string }>());

const getBySlug = createAction(
  `${NAMESPACE} Get By Slug`,
  props<{ slug: string }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ feedlot: FeedlotDto }>()
);

const error = createAction(`${NAMESPACE} Error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const FeedlotActions = {
  get,
  getBySlug,
  getSuccess,
  error,
  reset
};
