import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, of, tap, withLatestFrom } from 'rxjs';

import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { SelectListsService } from '@ifhms/common/angular/data-access/feedlot-api';

import { ProductRoutesActions } from './product-routes.actions';
import { ProductRoutesFacade } from './product-routes.facade';

@Injectable()
export class ProductRoutesEffects {

  private loading: boolean;

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductRoutesActions.get),
      withLatestFrom(
        this.feedlotFacade.feedlotId$,
        this.productRoutesFacade.loaded$
      ),
      filter(([action,, loaded]) => {
        const hasNoPendingRequests = !this.loading && !loaded;
        return hasNoPendingRequests || !!action.force;
      }),
      tap(() => this.loading = true),
      exhaustMap(([action, feedlotId]) =>
        this.selectListsService
          .getProductRoutesSelectList(feedlotId, action.workOrderCreatedDate)
          .pipe(
            tap(() => this.loading = false),
            map((items: SelectListItemWithTranslationDto[]) => {
              return ProductRoutesActions.getSuccess({ items });
            }),
            catchError(() => of(ProductRoutesActions.error()))
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private feedlotFacade: FeedlotFacade,
    private selectListsService: SelectListsService,
    private productRoutesFacade: ProductRoutesFacade
  ) {}
}
