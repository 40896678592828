import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AnimalDetailsService,
  AnimalTagService,
  AnimalsService,
  ArrivalWorkOrderService,
  EventAnimalProductMetaDataService,
  EventsAnimalProjectedWeightService,
  EventsAnimalSearchService,
  EventsPostMortemService,
  EventsTreatmentService,
  FeedlotEventsAnimalHistoryService,
  FeedlotEventsArrivalService,
  FeedlotEventsRailService,
  FeedlotEventsReHandlingService,
  FeedlotService,
  LotTransferWorkOrderService,
  LotsService,
  MovementWorkOrderService,
  NationalIdService,
  PensService,
  QuickSearchService,
  RailWorkOrderService,
  RehandlingWorkOrderService,
  SelectListsService,
  ShipmentWorkOrderService,
  SortGroupsService,
  WorkOrderService
} from './services';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    AnimalDetailsService,
    AnimalTagService,
    AnimalsService,
    ArrivalWorkOrderService,
    EventAnimalProductMetaDataService,
    EventsAnimalProjectedWeightService,
    EventsAnimalSearchService,
    EventsPostMortemService,
    EventsTreatmentService,
    FeedlotEventsArrivalService,
    FeedlotEventsRailService,
    FeedlotEventsReHandlingService,
    LotTransferWorkOrderService,
    MovementWorkOrderService,
    NationalIdService,
    PensService,
    QuickSearchService,
    RailWorkOrderService,
    RehandlingWorkOrderService,
    SelectListsService,
    ShipmentWorkOrderService,
    SortGroupsService,
    WorkOrderService,
    FeedlotEventsAnimalHistoryService,
    FeedlotService,
    LotsService
  ]
})
export class IfhmsCommonAngularDataAccessFeedlotApiModule {}
