import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../tokens';
import {
  DosingGunItem,
  DosingGunsListDto,
  DosingGunsSettingsRequestDto,
  UpcSettingsDto,
  UpcUsagesDto
} from '@ifhms/models/feedlot';

@Injectable()
export class UpcService {
  constructor(@Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string,
    private http: HttpClient) {}

  updateUPCSettings(feedlotId: string, upcSettingsDto: Partial<UpcSettingsDto>, clientId: string): Observable<UpcSettingsDto> {
    const dto = {
      clientId,
      ...upcSettingsDto
    }
    return this.http.put<UpcSettingsDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Upc/Settings`, dto);
  }
  resetGateController(feedlotId: string, clientId: string): Observable<UpcSettingsDto> {
    return this.http.delete<UpcSettingsDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Upc/${clientId}/ResetGateController`);
  }

  getUPCSettings(feedlotId: string, upcClientId: string): Observable<UpcSettingsDto> {
    return this.http.get<UpcSettingsDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Upc/Settings/${upcClientId}`);
  }

  updateDosingGunsSettings(feedlotId: string, dosingGunsDto: DosingGunsListDto): Observable<DosingGunsListDto> {
    return this.http.put<DosingGunsListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Upc/DosingGun`, dosingGunsDto);
  }

  getDosingGunsSettings(feedlotId: string, requestDto: DosingGunsSettingsRequestDto): Observable<DosingGunsListDto> {
    const params = new HttpParams({ fromObject: requestDto as unknown as Record<string, any> });
    return this.http.get<DosingGunsListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Upc/DosingGun/Settings`, { params });
  }

  getDosingGunsList(feedlotId: string): Observable<DosingGunsListDto> {
    return this.http.get<DosingGunsListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Upc/DosingGun/List`);
  }

  assignDosingGunToProduct(feedlotId: string, dosingGunDto: DosingGunItem): Observable<DosingGunsListDto> {
    return this.http.put<DosingGunsListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Upc/DosingGun/AssignToWorkOrderProduct`, dosingGunDto);
  }

  validateGun(feedlotId: string, dosingGunId: string): Observable<DosingGunItem> {
    return this.http.put<DosingGunItem>(`${this.apiUrl}/Feedlot/${feedlotId}/Upc/DosingGun/${dosingGunId}/Validate`, null);
  }

  unassignDosingGunFromProduct(feedlotId: string, dosingGunId: string): Observable<DosingGunItem> {
    return this.http.put<DosingGunItem>(`${this.apiUrl}/Feedlot/${feedlotId}/Upc/DosingGun/${dosingGunId}/UnAssign`, null);
  }

  getUpcFeedlotUsages(feedlotId: string, clientId: string): Observable<UpcUsagesDto> {
    if(!clientId) {
      return of({ operations: [] })
    }

    return this.http.get<UpcUsagesDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Upc/${clientId}/Usages`);
  }
}