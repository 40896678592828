export enum FeedlotSelectListNoTranslationType {
  Pen = 1,
  Lot = 2,
  LotSubGroup = 3,
  UnitOfMeasure = 5,
  LabourCharge = 6,
  CustomField = 7,
  OtherFee = 8,
  CciaAccountPremise = 9,
  ManagementGroup = 10,
  Procedure = 11,
  User = 12,
  AnimalStatus = 13,
  TxGroup = 14,
  Alley = 15
}

