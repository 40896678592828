import { EventType, ProductMetadataDto } from '@ifhms/models/feedlot';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Event Product Metadata]';

const getProductMetadata = createAction(
  `${NAMESPACE} Get`,
  props<{ productId: string, facilityId: string, eventId:string, eventType: EventType }>()
);

const getProductMetadataSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ metaData: ProductMetadataDto }>()
);

const error = createAction(
  `${NAMESPACE} Error`,
  props<{ errMsg: string }>()
);

export const eventProductMetadataActions = {
  getProductMetadata,
  getProductMetadataSuccess,
  error
};
