<div class="products-table-block my-3" *transloco="let t; read: translateScope">
  <p-table
    *ngIf="productsList"
    [value]="productsList || []"
    styleClass="p-datatable-striped p-datatable-lg"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>{{ t('type') }}</th>
        <th>{{ t('product') }}</th>
        <th>{{ t('route') }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr class="w-full">
        <td class="width-18" *ngIf="product.productTypes$ | async as productTypes">
          {{ product.productTypeId | selectListItemToCode: $any(productTypes) }}
        </td>
        <td class="width-18" *ngIf="product.products$ | async as products">
          {{ product.productId | selectListItemToCode: $any(products) }}
        </td>
        <td class="width-18" *ngIf="product.routes$ | async as routes">
          {{ product.routeDetailId | selectListItemToCode: $any(routes) }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr >
        <td [attr.colspan]="3" class="text-center">
          {{ t('no-products')}}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
