import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';

import { TABLE_DATA_DEFAULT_PAGE_SIZE } from '@common/angular/interfaces';

import { TablePaginateEvent } from '../../interfaces';
import { PaginatorState } from 'primeng/paginator';

interface PaginatorLabels {
  labelShowing?: string,
  labelTo?: string,
  labelOf?: string,
  labelRecords?: string
}

@Component({
  selector: 'sersi-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TablePaginatorComponent {

  @HostBinding('class') class = 'sersi-table-paginator';

  first = 0;

  @Input() set pageIndex(pageIndex: number) {
    this.first = pageIndex * this.rows;
  }
  @Input() rows: number;
  @Input() totalRecords: number;
  @Input() pageLinkSize = 3;
  @Input() rowsPerPageOptions = [TABLE_DATA_DEFAULT_PAGE_SIZE, 25, 50];

  @Input() labels: PaginatorLabels;
  @Output() pageChange: EventEmitter<TablePaginateEvent> = new EventEmitter<TablePaginateEvent>();

  pageChangeAction(event: PaginatorState): void {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.pageChange.emit({
      first: event.first || this.first,
      page: event.page || 0,
      pageCount: event.pageCount || TABLE_DATA_DEFAULT_PAGE_SIZE,
      rows: event.rows || this.rows
    })
  }

}
