import { createSelector } from '@ngrx/store';
import * as Reducer from './sort-group-outcome-copy-grid-list.reducer';
import { selectFeatureState, State } from '..';
import { filterAndSortOutcomeList } from '../../helpers/sort-group-filter';

const selectState = createSelector(
  selectFeatureState,
  (state: State) => state.copyGrid
);

const selectLoading = createSelector(selectState, Reducer.getLoading);

const selectLoaded = createSelector(selectState, Reducer.getLoaded);

const selectCopyTo = createSelector(selectState, Reducer.getCopyTo);
const selectCopyFrom = createSelector(selectState, Reducer.getCopyFrom);

const selectAll = createSelector(selectState, Reducer.getAll);

const selectFiltered = createSelector(selectState, (state) => {
  const list = Reducer.getAll(state);
  const filterBy = Reducer.getFilterBy(state);
  const sortBy = Reducer.getSortBy(state);

  return filterAndSortOutcomeList(list, filterBy, sortBy);
});

const selectFilterBy = createSelector(selectState, Reducer.getFilterBy);

const selectSortBy = createSelector(selectState, Reducer.getSortBy);

const totalLength = createSelector(selectState, Reducer.getTotal);

const filteredTotalLength = createSelector(
  selectFiltered,
  (outcomes) => outcomes.length
);

const selectAllSelected = createSelector(selectFiltered, (outcomes) =>
  outcomes.filter((outcome) => outcome.selected)
);

const filteredSelectedLength = createSelector(
  selectAllSelected,
  (outcomes) => outcomes.length
);

const selectedLength = createSelector(
  selectAllSelected,
  (outcomes) => outcomes.length
);

const selectModal = createSelector(selectState, Reducer.getModal);

export const SortGroupOutcomeCopyGridListSelectors = {
  selectState,
  selectLoading,
  selectLoaded,
  selectCopyTo,
  selectCopyFrom,
  selectAll,
  selectFilterBy,
  selectSortBy,
  totalLength,
  selectFiltered,
  filteredTotalLength,
  filteredSelectedLength,
  selectAllSelected,
  selectedLength,
  selectModal
};
