import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[BREEDS_LIST] - ';

const get = createAction(`${NAMESPACE} Get`);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ items: SelectListItemWithTranslationDto[] }>()
);

const error = createAction(`${NAMESPACE} error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const BreedsActions = {
  error,
  get,
  getSuccess,
  reset
};
