import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UpcDeviceManagerService, UpcGateControllerService } from '@ifhms/common/angular/data-access/upc-api';
import { UpcApiFacade } from '@ifhms/common/angular/upc/shared';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { AbstractSelectType } from '@sersi/angular/formly/types/select';
import { combineLatest, map, Observable, tap, withLatestFrom } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ifhms-feedlot-form-alley-field',
  templateUrl: './form-alley-field.component.html',
  styleUrls: ['./form-alley-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormAlleyFieldComponent
  extends AbstractSelectType
  implements OnInit {
  translateScope = 'events.shared.components.form-alley-field';

  override defaultOptions = {
    props: {
      label: 'Alley',
      showClear: true,
      optionsLabel: 'CODE_DESCRIPTION',
      selectedItemLabel: 'CODE',
      items$: this.handleAlleysData(),
      excludeItems$: null,
      virtualScroll: true,
      bindValue: 'id'
    }
  };

  upcClientId: string | null | undefined;

  constructor(
    private upcGateControllerService: UpcGateControllerService,
    private upcDeviceManager: UpcDeviceManagerService,
    private refDataFacade: ReferenceDataFacade,
    private upcApiFacade: UpcApiFacade,
    public translateService: TranslocoService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.onAlleyValueChange();
    if (!this.isFieldReadonly()) {
      this.upcDeviceManager.registerDevice(this.upcGateControllerService)
        .then(() => {
          this.handleAlleysData();
        });
    }
  }

  handleAlleysData(): Observable<SersiSelectListItem[]> {
    return combineLatest([
      this.refDataFacade.alleys$,
      this.upcApiFacade.upcSettings$
    ]).pipe(
      tap(([, upcSettings]) => {
        if (upcSettings?.clientId) {
          this.upcClientId = upcSettings?.clientId;
        }
      }),
      map(([alleys]) =>
        alleys.map((alley) => {
          return {
            ...alley,
            description: alley.description + this.getAlleyCode(alley)
          };
        })
      )
    );
  }

  onAlleyValueChange(): void {
    this.field.formControl.valueChanges
      .pipe(
        withLatestFrom(this.activeItems$, this.upcApiFacade.upcSettings$),
        untilDestroyed(this)
      )
      .subscribe(([selectedItem, activeItems, upcSettings]) => {
        const selectedListItem = activeItems.find(
          (item) => item.id === selectedItem
        );

        if (!selectedListItem) return;

        this.upcClientId = upcSettings?.clientId;

        const gateMap: GateMapItem[] = selectedListItem.attributes?.[
          'GateMap'
        ] as any[];

        if (!gateMap) return;

        const gateId = gateMap.find(
          (item) => item.clientId === this.upcClientId
        )?.gateId;

        const gateNumber = gateId ? parseInt(gateId) : null;
        if (gateNumber) {
          this.upcGateControllerService.openGate(gateNumber);
        }
      });
  }

  private getAlleyCode(alley: SersiSelectListItem): string {
    const gateMap: GateMapItem[] = alley.attributes?.['GateMap'] as any[];
    if (!gateMap) return '';

    const gateId = gateMap.find(
      (item) => item.clientId === this.upcClientId
    )?.gateId;

    return gateId ? ` (Gate ${gateId})` : '';
  }

  isFieldReadonly(): boolean {
    return this.field.props.readonly || false;
  }
}

interface GateMapItem {
  gateId: string;
  clientId: string;
}
