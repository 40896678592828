import { AnimalSelectorItemDto, AnimalSelectorRequestDto } from '@ifhms/models/feedlot';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CattleSelectorLeftActions } from '../left/left.actions';
import { CattleSelectorRightActions } from './right.actions';

export interface State extends EntityState<AnimalSelectorItemDto> {
  loading: boolean;
  loaded: boolean;
  filter: AnimalSelectorRequestDto;
}

const adapter: EntityAdapter<AnimalSelectorItemDto> =
  createEntityAdapter<AnimalSelectorItemDto>({
    selectId: (entity: AnimalSelectorItemDto) => entity.id
  });

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  filter: <AnimalSelectorRequestDto>{}
});

export const reducer = createReducer(
  initialState,

  on(
    CattleSelectorRightActions.replace,
    CattleSelectorRightActions.add,
    CattleSelectorRightActions.select,
    CattleSelectorRightActions.selectAll,
    CattleSelectorRightActions.remove,
    CattleSelectorRightActions.filter,
    CattleSelectorRightActions.clearFilter,
    (state) => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),

  on(CattleSelectorRightActions.replaceSuccess, (state, { animals }) =>
    adapter.setAll(animals, {
      ...state,
      loading: false,
      loaded: true
    })
  ),

  on(CattleSelectorRightActions.addSuccess, (state, { animals }) => {
    const list = animals.map((animal) => {
      return {
        ...animal,
        selected: false
      };
    });
    return adapter.addMany(list, {
      ...state,
      loading: false,
      loaded: true
    });
  }),

  on(CattleSelectorRightActions.selectSuccess, (state, { animal }) =>
    adapter.updateOne(animal, {
      ...state,
      loading: false,
      loaded: true
    })
  ),

  on(CattleSelectorLeftActions.selectMultiple, (state, { animals }) =>
    adapter.updateMany(animals, {
      ...state,
      loading: false,
      loaded: true
    })
  ),

  on(CattleSelectorRightActions.selectAllSuccess, (state, { updates }) =>
    adapter.updateMany(updates, {
      ...state,
      loading: false,
      loaded: true
    })
  ),

  on(CattleSelectorRightActions.filterSuccess, (state, { filter }) => ({
    ...state,
    filter,
    loading: false,
    loaded: true
  })),

  on(CattleSelectorRightActions.clearFilterSuccess, (state) => ({
    ...state,
    filter: <AnimalSelectorRequestDto>{},
    loading: false,
    loaded: true
  })),

  on(CattleSelectorRightActions.removeSuccess, (state, { animals }) =>
    adapter.removeMany(
      animals.map((animal) => animal.id),
      {
        ...state,
        loading: false,
        loaded: true
      }
    )
  ),

  on(CattleSelectorRightActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),

  on(CattleSelectorRightActions.reset, () => initialState)
);

const { selectAll, selectTotal, selectEntities } = adapter.getSelectors();

export const getAll = selectAll;

export const getTotal = selectTotal;

export const getEntities = selectEntities;

export const getLoading = (state: State): boolean => state.loading;

export const getLoaded = (state: State): boolean => state.loaded;

export const getFilter = (state: State): AnimalSelectorRequestDto =>
  state.filter;

export const getFilterApplied = (state: State): boolean =>
  !!(state.filter.currentPenId ||
    state.filter.homePenId ||
    state.filter.lotId ||
    state.filter.nationalId ||
    state.filter.normalizedTagNumber);
