import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SersiFormlyFormComponent, SLIDER_MAX_NUMBER_INPUT, SLIDER_MIN_NUMBER_INPUT } from '@sersi/angular/formly/core';
import { TableFilterService } from './table-filter.service';
import { isArray } from 'lodash-es';

@UntilDestroy()
@Component({
  selector: 'sersi-table-filter',
  templateUrl: './table-filter.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableFilterComponent implements AfterViewInit, OnDestroy {
  @ContentChild('filterForm') filterForm: SersiFormlyFormComponent;

  @Input() btnLabel: string;
  @Input() modalTitle: string;
  @Input() btnClearLabel: string;
  @Input() btnApplyLabel: string;
  @Input() btnCloseLabel: string;
  @Input() btnApplyDisabled = false;
  @Input() modalClassName = 'table-filter-modal';
  @Input() roundedBtn = false;

  @Input() modalViewHeight?: string;
  @Input() modalViewWidth?: string;

  @Output() applyFilters = new EventEmitter();

  hasFilterChange: boolean;
  isDialogOpen: boolean;
  appliedFiltersCount$ = this.tableFilterService.appliedFiltersCount$;

  constructor(
    private tableFilterService: TableFilterService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.updateSelectedFilters();
    this.subscribeDialogVisibilityChange();
    this.subscribeFormChange();
  }

  ngOnDestroy(): void {
    this.closeModal();
  }

  openModal(): void {
    this.filterForm.form.patchValue(this.tableFilterService.appliedFilters);
    this.tableFilterService.toggleModalVisibility(true);
    this.hasFilterChange = false;
  }

  clearFilters(): void {
    Object.values(this.filterForm.form.controls)
      .forEach(control => control.reset(null));
  }

  onSubmit(): void {
    const formVal = this.filterForm.getData();
    this.applyFilters.emit(formVal);
    this.updateSelectedFilters();
    this.closeModal();
  }

  closeModal(): void {
    this.tableFilterService.toggleModalVisibility(false);
  }

  private updateSelectedFilters(): void {
    const formVal = this.filterForm.getData();
    this.tableFilterService.appliedFilters = structuredClone(
      formVal
    );
  }

  private subscribeFormChange(): void {
    this.filterForm.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.hasFilterChange = true;
        this.cdr.markForCheck();
      });
  }

  private subscribeDialogVisibilityChange(): void {
    this.tableFilterService.isModalVisible$
      .pipe(untilDestroyed(this))
      .subscribe((isVisible) => {
        this.isDialogOpen = isVisible;
        this.cdr.markForCheck();
      });
  }
}
