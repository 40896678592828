import { Injectable } from '@angular/core';
import { HashMap } from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class AnimalsOverflowStateService {

  private alreadyConfirmedOverflows: HashMap<boolean> = {};

  setConfirmedOverflow(workOrderId: string, isConfirmed = true): void {
    this.alreadyConfirmedOverflows[workOrderId] = isConfirmed;
  }

  getConfirmedOverflow(workOrderId: string): boolean {
    return this.alreadyConfirmedOverflows[workOrderId];
  }

}
