import { UpcBaseDeviceService } from './upc-base-device.service';
import { Injectable } from '@angular/core';
import { UpcApplicationSettingsService } from './upc-application-settings.service';

@Injectable({ providedIn: 'root' })
export class UpcDeviceManagerService {
  devices: UpcBaseDeviceService[] = [];
  private deviceInitializationQueue: UpcBaseDeviceService[] = [];
  private readonly CONCURRENT_LIMIT = 5;
  upcAvailable = false;

  constructor(private upcAppSettingsService: UpcApplicationSettingsService) {
    if(!this.upcAvailable){
      this.upcAppSettingsService.initDevice().then((upcAvailable) => {
        this.upcAvailable = upcAvailable;
      });
    }
  }

  // todo - check for a better sol
  async registerDevice(device: UpcBaseDeviceService): Promise<boolean> {
    if(!this.upcAvailable) return false;

    this.deviceInitializationQueue.push(device);
    await this.initializeDevices();
    return this.devices.includes(device);
  }

  private async initializeDevices(): Promise<void> {
    if (this.deviceInitializationQueue.length === 0) {
      return;
    }
    const devicesToInitialize = [];

    for (let i = 0; i < this.CONCURRENT_LIMIT; i++) {
      devicesToInitialize.push(this.deviceInitializationQueue.shift());
    }

    await Promise.all(devicesToInitialize.map(async (device) => {
      if (device) {
        const deviceConnected = await device.initDevice();
        if(deviceConnected) this.devices.push(device);
      }
    }));

    if (this.deviceInitializationQueue.length > 0) {
      await this.initializeDevices();
    }
  }

}
