import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import * as reducer from './breeds.reducer';
import { BreedsSelectors } from './breeds.selectors';
import { BreedsActions } from './breeds.actions';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class BreedsFacade {

  loaded$ = this.store.select(BreedsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(BreedsSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(BreedsActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(BreedsActions.reset());
  }
}
