import { createSelector } from '@ngrx/store';
import * as reducer from './upc-api.reducer'
import { selectFeatureState, State } from '../upc.reducer'

const selectState = createSelector(
  selectFeatureState, (state: State) => state.api
);

const selectLoading = createSelector(selectState, reducer.getLoading);

const selectLoaded = createSelector(selectState, reducer.getLoaded);

const selectUpcSettings = createSelector(selectState, reducer.getUpcSettings);

const selectUpcConnectionStatus = createSelector(selectState, reducer.getUpcConnectionStatus);

const selectUpcUsages = createSelector(selectState, state => state.usages);
const selectClientId = createSelector(selectState, reducer.getClientId);

export const UpcApiSelectors = {
  selectLoading,
  selectLoaded,
  selectUpcSettings,
  selectState,
  selectUpcConnectionStatus,
  selectUpcUsages,
  selectClientId
}
