import { HashMap } from '@common/angular/interfaces';
import { Action, ActionReducer } from '@ngrx/store';
import { resetObjectProperty } from '@common/angular/utils';
import { FeedlotWebDomainRootActions } from './feedlot-web-domain-root.actions';

export function feedlotWebRootMeta(reducer: ActionReducer<HashMap>): ActionReducer<HashMap> {
  return function(state, action: Action) {
    switch(action.type) {
      case FeedlotWebDomainRootActions.error.type: {
        const stateWithLoadingReset = resetObjectProperty(state!, 'loading', false);
        return reducer({
          ...resetObjectProperty(stateWithLoadingReset!, 'searching', false)
        }, action);
      }
      default:
        return reducer(state, action);
    }
  };
}