import { createSelector } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { selectFeatureState, State } from '../reducer';
import * as reducer from './lots.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.lots
);

const selectItems = createSelector(selectState, reducer.getAll);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

const selectWithCloseoutInProgress = createSelector(
  selectItems,
  (items: SersiSelectListItem[]) => includeCloseoutInProgress(items)
);

export const LotsSelectors = {
  selectItems,
  selectLoaded,
  selectWithCloseoutInProgress
};

function includeCloseoutInProgress(items: SersiSelectListItem[]): SersiSelectListItem[] {
  const itemsCopy = structuredClone(items);

  return itemsCopy.map((selectItem: SersiSelectListItem) => {
    const attr = selectItem?.attributes;
    const isCloseoutInProgress = attr ? attr['closeoutInProgress'] === true : false;

    selectItem.isActive = selectItem.isActive || isCloseoutInProgress;

    return selectItem;
  });
}
