export const formatDataForValidation = (
  data: string[][]
): NonNullable<unknown> => {
  const properties = {};

  for (const key of data[0]) {
    // @ts-ignore
    properties[camelize(key)] = [];
  }

  Object.entries(properties).forEach(([key, value], index) => {
    const newData = [...data];
    newData.shift();

    const lastEntryInArrayForColumns = newData[newData.length - 1][index];

    if (
      lastEntryInArrayForColumns === '' ||
      lastEntryInArrayForColumns === undefined ||
      lastEntryInArrayForColumns === null
    ) {
      newData.pop();
    }

    // @ts-ignore
    properties[key] = getDataForProperty(newData, index);
  });

  return properties;
};

function getDataForProperty(data: string[], index: number): any[] {
  return data.map((item: any) => item[index]);
}

function camelize(str: string): string {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}
