import { inject, Injectable } from '@angular/core';
import { State, Store } from '@ngrx/store';

import { HashMap } from '@common/angular/interfaces';

import { FeedlotWebDomainRootActions } from './feedlot-web-domain-root.actions';

@Injectable()
export class FeedlotWebDomainRootStateFacade {

  private readonly store = inject(Store<State<HashMap>>)

  error(message?: string): void {
    this.store.dispatch(FeedlotWebDomainRootActions.error({ message: message || 'Unknown error' }));
  }
}
