import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { AbstractTranslationComponent, scopeLoader } from '@common/angular/translation';
import { convertDateStringToDateObj } from '@common/angular/utils';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { ProductMetadataDto } from '@ifhms/models/feedlot';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyTypesEnum, FormlyWrappersEnum } from '@sersi/angular/formly/core';
import { pick } from 'lodash-es';

import { ProductMetadataDialogProduct, ProductMetadataFormModelType } from '../../interfaces';

@UntilDestroy()
@Component({
  selector: 'ifhms-product-metadata-form',
  templateUrl: './product-metadata-form.component.html',
  styleUrls: ['./product-metadata-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'product-metadata-shared',
        alias: 'product-metadata-shared',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class ProductMetadataFormComponent extends AbstractTranslationComponent {
  protected override translationNamespace = 'components.product-metadata-form';

  @Input() metadata: ProductMetadataDto;
  @Input() productData: ProductMetadataDialogProduct;
  @Input() disabled: boolean;

  form = new UntypedFormGroup({});
  fields: FormlyFieldConfig[];
  model: ProductMetadataFormModelType;

  private readonly defaultSelectFieldConfig = {
    type: FormlyTypesEnum.SINGLE_SELECT,
    props: {
      className: 'text-mode',
      selectedItemLabel: 'CODE',
      placeholder: ' ',
      readonly: true
    }
  }

  constructor(private referenceDataFacade: ReferenceDataFacade) {
    super();
    this.referenceDataFacade.getFacilities();
  }

  override onTranslationInit(): void {
    const { expireDate } = this.metadata;
    this.model = {
      ...this.productData,
      ...pick(this.metadata, [
        'isCarryOver', 'productLotNumber', 'productSerialNumber'
      ]) as ProductMetadataDto,
      expireDate: expireDate ? convertDateStringToDateObj(expireDate) : null
    };

    this.setFields();
  }

  setFields(): void {
    this.fields = [
      {
        fieldGroupClassName: 'product-metadata-dialog',
        fieldGroup: [
          this.setFacility(),
          this.setProductType(),
          this.setProductCode(),
          this.setProductDescription(),
          this.setLotNumber(),
          this.setSerialNumber(),
          this.setExpireDate(),
          this.setCarryOver()
        ]
      }
    ]
  }

  private setFacility(): FormlyFieldConfig {
    return {
      ...this.defaultSelectFieldConfig,
      key: 'facilityId',
      props: {
        ...this.defaultSelectFieldConfig.props,
        label: this.getTranslation('facility'),
        items$: this.referenceDataFacade.facilities$
      }
    };
  }

  private setProductType(): FormlyFieldConfig {
    return {
      ...this.defaultSelectFieldConfig,
      key: 'productTypeId',
      props: {
        ...this.defaultSelectFieldConfig.props,
        label: this.getTranslation('type'),
        items$: this.referenceDataFacade.productTypesWithProducts$
      }
    };
  }

  private setProductCode(): FormlyFieldConfig {
    return {
      ...this.defaultSelectFieldConfig,
      key: 'productId',
      className: 'formly-field-product',
      props: {
        ...this.defaultSelectFieldConfig.props,
        label: this.getTranslation('product'),
        items$: this.referenceDataFacade.getProductsForProductTypes(this.model.productTypeId)
      }
    };
  }

  private setProductDescription(): FormlyFieldConfig {
    return {
      ...this.defaultSelectFieldConfig,
      key: 'productId',
      className: 'formly-field-product-description',
      props: {
        ...this.defaultSelectFieldConfig.props,
        label: this.getTranslation('product-description'),
        items$: this.referenceDataFacade.getProductsForProductTypes(this.model.productTypeId),
        selectedItemLabel: 'DESCRIPTION'
      }
    };
  }

  private setLotNumber(): FormlyFieldConfig {
    return {
      key: 'productLotNumber',
      type: FormlyTypesEnum.TEXT_INPUT,
      props: {
        label: this.getTranslation('product-lot-number'),
        disabled: this.disabled
      }
    };
  }

  private setSerialNumber(): FormlyFieldConfig {
    return {
      key: 'productSerialNumber',
      type: FormlyTypesEnum.TEXT_INPUT,
      props: {
        label: this.getTranslation('product-serial-number'),
        disabled: this.disabled
      }
    };
  }

  private setExpireDate(): FormlyFieldConfig {
    return {
      key: 'expireDate',
      type: FormlyTypesEnum.CALENDAR_INPUT,
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      props: {
        label: this.getTranslation('expire-date'),
        required: false,
        appendTo: 'body',
        disabled: this.disabled
      }
    };
  }

  private setCarryOver(): FormlyFieldConfig {
    return {
      key: 'isCarryOver',
      type: FormlyTypesEnum.CHECKBOX,
      wrappers: [],
      props: {
        label: this.getTranslation('carry-over'),
        disabled: this.disabled
      }
    };
  }

}
