import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[sersiCursorHover]'
})
export class CursorHoverDirective {

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2) {
  }

  @HostListener('mouseenter') mouseover(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'pointer');

  }

  @HostListener('mouseleave') mouseleave(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'inherit');

  }
}
