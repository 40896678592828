<form [formGroup]="form">
    <formly-form
            [fields]="fields"
            [form]="form"
            [model]="model"
    ></formly-form>
</form>
<div class="dosing-gun-table-container">
    <ifhms-dosing-guns-grid-form />
</div>

