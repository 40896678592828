import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormAlleyFieldComponent } from './components';
import { NgSelectModule } from '@ng-select/ng-select';
import { SersiAngularFormlyTypesSelectModule } from '@sersi/angular/formly/types/select';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AH_FMS_UPC_CONFIG, UPC_CONFIG, UpcGateControllerService } from '@ifhms/common/angular/data-access/upc-api';

@NgModule({
  imports: [CommonModule, NgSelectModule, SersiAngularFormlyTypesSelectModule, FormlyModule, ReactiveFormsModule],
  declarations: [FormAlleyFieldComponent],
  providers: [
    {
      provide: AH_FMS_UPC_CONFIG,
      useValue: UPC_CONFIG,
      multi: false
    },
    UpcGateControllerService
  ],
  exports: [FormAlleyFieldComponent]
})
export class IfhmsCommonAngularUpcGateControllerModule {}
