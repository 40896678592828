import { createAction, props } from '@ngrx/store';
import { SelectListItemDto } from '@ifhms/models/shared';

const NAMESPACE = '[OTHER_FEES_SELECT_LIST] - ';

const get = createAction(`${NAMESPACE} Get`);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ items: SelectListItemDto[] }>()
);

const error = createAction(`${NAMESPACE} error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const OtherFeesActions = {
  error,
  get,
  getSuccess,
  reset
};
