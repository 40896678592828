import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { FormlyModule } from '@ngx-formly/core';

import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';

import { AH_FMS_UPC_CONFIG, UPC_CONFIG, UpcScaleService } from '@ifhms/common/angular/data-access/upc-api';
import { UpcApiFacade } from '@ifhms/common/angular/upc/shared';

import { scopeLoader } from '@common/angular/translation';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FormWeightFieldComponent, UpcScaleSharedFormComponent } from './components';
import { SersiAngularUiSpinModule } from '@sersi/angular/ui/spin';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    InputNumberModule,
    FormlyModule,
    ReactiveFormsModule,
    ButtonModule,
    ProgressSpinnerModule,
    SersiAngularUiSpinModule
  ],
  declarations: [FormWeightFieldComponent, UpcScaleSharedFormComponent],
  providers: [
    {
      provide: AH_FMS_UPC_CONFIG,
      useValue: UPC_CONFIG,
      multi: false
    },
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'scale',
        alias: 'scale',
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        )
      }
    },
    UpcScaleService,
    UpcApiFacade
  ],
  exports: [FormWeightFieldComponent, UpcScaleSharedFormComponent]
})
export class IfhmsCommonAngularUpcScaleModule {}
