import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { reducer as upcApiReducer, State as upcApiState } from './upc-api/upc-api.reducer';
import { reducer as dosingGunReducer, State as dosingGunsState } from './dosing-guns/upc-dosing-guns.reducer';
import { reducer as scaleReducer, State as scaleState } from './scale/upc-scale.reducer';

export const FEATURE_NAME = 'upc';

export const selectFeatureState = createFeatureSelector<State>(FEATURE_NAME);

export interface State {
  api: upcApiState,
  dosingGuns: dosingGunsState,
  scale: scaleState
}

export const upcReducer: ActionReducerMap<State> = {
  api: upcApiReducer,
  dosingGuns: dosingGunReducer,
  scale: scaleReducer
}