export enum WorkOrderArrivalStatus {
  Draft = 1,
  Scheduled = 2,
  InProgress = 3,
  ProcessingComplete = 4,
  Processing= 5,
  Imported = 6,
  Template = 7,
  Finalized = 10,
  Archived = 11
}
