import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractTranslationComponent, scopeLoader } from '@common/angular/translation';
import { DeviceConnectionStatusEnum, DosingGunItem } from '@ifhms/models/feedlot';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';

@Component({
  selector: 'ifhms-dosing-gun-icon',
  templateUrl: './dosing-gun-icon.component.html',
  styleUrls: ['./dosing-gun-icon.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'dosingguns',
        alias: 'dosingguns',
        loader: scopeLoader((lang: string, root: string) =>
          import(`../../${root}/${lang}.json`)
        )
      }
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DosingGunIconComponent extends AbstractTranslationComponent implements OnInit {

  readonly translationNamespace = 'components.dosing-gun-icon';

  @Input() productId: string;
  @Input() dosingGunItems: DosingGunItem[] | null;
  @Input() dosingGunsConnectionStatus: DeviceConnectionStatusEnum | null
  @Input() workOrderNumber: number;
  @Input() disabled: boolean;

  dosingGunIcon: string | null;
  tooltipMessage: string;
  isButtonDisabled: boolean;

  override onTranslationInit(): void {
    this.dosingGunIcon = this.getIcon(this.dosingGunsConnectionStatus, this.dosingGunItems);
    this.tooltipMessage = this.getTooltipMessage(this.dosingGunsConnectionStatus, this.dosingGunItems);
    this.isButtonDisabled = this.disabled || this.dosingGunsConnectionStatus === DeviceConnectionStatusEnum.Disconnected

  }

  private getIcon(status: DeviceConnectionStatusEnum | null, dosingGuns: DosingGunItem[] | null): string | null {
    if (!dosingGuns || status === DeviceConnectionStatusEnum.Disconnected) {
      return 'assets/img/dosing-guns/dosinggun_error.svg';
    }

    if (!this.productId) return null;
    const gunItem = dosingGuns.find(gun =>
      gun.productId === this.productId && gun.workOrderNumber === this.workOrderNumber);

    if (gunItem) {
      if (gunItem.validated)
        return 'assets/img/dosing-guns/dosinggun_success.svg';
      else return 'assets/img/dosing-guns/dosinggun_assigned.svg';
    } else {
      return 'assets/img/dosing-guns/dosinggun_error.svg';
    }
  }

  private getTooltipMessage(status: DeviceConnectionStatusEnum | null, dosingGuns: DosingGunItem[] | null): string {
    if (this.getIcon(status, dosingGuns) === null) {
      return '';
    }
    if (!dosingGuns || status === DeviceConnectionStatusEnum.Disconnected) {
      return this.getTranslation('dosing-gun-tooltip-not-connected');
    }

    const gunItem = dosingGuns.find(gun => gun.productId === this.productId && gun.workOrderNumber === this.workOrderNumber);
    if (gunItem) {
      if (gunItem.validated)
        return this.getTranslation('dosing-gun-tooltip-validated');
      else return this.getTranslation('dosing-gun-tooltip-assigned');
    } else {
      switch (status) {
        case DeviceConnectionStatusEnum.Connected:
          return this.getTranslation('dosing-gun-tooltip-available');
        default:
          return 'Status unknown';
      }
    }
  }
}
