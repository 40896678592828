import { FeedlotPermissionsAnimal } from './feedlot-permissions-animal.enum';
import { FeedlotPermissionsEvent } from './feedlot-permissions-event.enum';
import { FeedlotPermissionsLot } from './feedlot-permissions-lot.enum';
import { FeedlotPermissionsPen } from './feedlot-permissions-pen.enum';
import { FeedlotPermissionsReport } from './feedlot-permissions-report.enum';
import { FeedlotPermissionsWorkOrder } from './feedlot-permissions-work-order.enum';

export type FeedlotFeaturePermission =
  FeedlotPermissionsAnimal
  | FeedlotPermissionsEvent
  | FeedlotPermissionsLot
  | FeedlotPermissionsPen
  | FeedlotPermissionsReport
  | FeedlotPermissionsWorkOrder;

export const FeedlotFeaturePermission = {
  ...FeedlotPermissionsAnimal,
  ...FeedlotPermissionsEvent,
  ...FeedlotPermissionsLot,
  ...FeedlotPermissionsPen,
  ...FeedlotPermissionsReport,
  ...FeedlotPermissionsWorkOrder
};
