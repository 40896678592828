import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ifhms-sort-group-checkbox',
  templateUrl: './sort-group-checkbox.component.html',
  styleUrls: ['./sort-group-checkbox.component.scss']
})
export class SortGroupCheckboxComponent {
  @Input() checked: boolean | null;
  @Input() disabled: boolean;
  @Output() check: EventEmitter<string> = new EventEmitter<string>();
}
