import { SortGroupOutcomeFilterBy, SortGroupOutcomeListItemDto, SortGroupOutcomeSortBy } from '@ifhms/models/feedlot';
import { Store } from '@ngrx/store';
import { Observable, distinctUntilChanged, map } from 'rxjs';
import { SortGroupOutcomeCopyGridListSelectors } from './sort-group-outcome-copy-grid-list.selectors';
import { SortGroupOutcomeCopyGridListActions } from './sort-group-outcome-copy-grid-list.actions';
import * as SortCopyGridList from './sort-group-outcome-copy-grid-list.reducer';
import { ListFacade } from '..';
import { Injectable } from '@angular/core';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

@Injectable()
export class SortGroupOutcomeCopyGridListFacade implements ListFacade {
  state$: Observable<SortCopyGridList.State> = this.store.select(
    SortGroupOutcomeCopyGridListSelectors.selectState
  );
  list$: Observable<SortGroupOutcomeListItemDto[]> = this.store.select(
    SortGroupOutcomeCopyGridListSelectors.selectAll
  );
  filterBy$: Observable<SortGroupOutcomeFilterBy> = this.store.select(
    SortGroupOutcomeCopyGridListSelectors.selectFilterBy
  );
  sortBy$: Observable<SortGroupOutcomeSortBy> = this.store.select(
    SortGroupOutcomeCopyGridListSelectors.selectSortBy
  );
  filteredList$: Observable<SortGroupOutcomeListItemDto[]> = this.store.select(
    SortGroupOutcomeCopyGridListSelectors.selectFiltered
  );
  filteredTotalLength$: Observable<number> = this.store.select(
    SortGroupOutcomeCopyGridListSelectors.filteredTotalLength
  );
  filteredSelectedLength$: Observable<number> = this.store.select(
    SortGroupOutcomeCopyGridListSelectors.filteredSelectedLength
  );
  copyTo$: Observable<string> = this.store.select(
    SortGroupOutcomeCopyGridListSelectors.selectCopyTo
  );
  copyFrom$: Observable<string[]> = this.store.select(
    SortGroupOutcomeCopyGridListSelectors.selectCopyFrom
  );
  selected$: Observable<SortGroupOutcomeListItemDto[]> = this.store.select(
    SortGroupOutcomeCopyGridListSelectors.selectAllSelected
  );
  isLoading$: Observable<boolean> = this.store.select(
    SortGroupOutcomeCopyGridListSelectors.selectLoading
  );
  isLoaded$: Observable<boolean> = this.store.select(
    SortGroupOutcomeCopyGridListSelectors.selectLoaded
  );
  selectedLength$: Observable<number> = this.store.select(
    SortGroupOutcomeCopyGridListSelectors.selectedLength
  );

  totalLength$: Observable<number> = this.store.select(
    SortGroupOutcomeCopyGridListSelectors.totalLength
  );
  modal$: Observable<boolean> = this.store.select(
    SortGroupOutcomeCopyGridListSelectors.selectModal
  );

  constructor(private readonly store: Store) {}

  select(outcome: SortGroupOutcomeListItemDto): void {
    this.store.dispatch(
      SortGroupOutcomeCopyGridListActions.select({
        outcome: { ...outcome, changes: { selected: !outcome.selected } }
      })
    );
  }

  selectAll(): void {
    this.store.dispatch(SortGroupOutcomeCopyGridListActions.selectAll());
  }

  filter(filter: SortGroupOutcomeFilterBy): void {
    this.store.dispatch(SortGroupOutcomeCopyGridListActions.filter({ filter }));
  }

  sort(sortBy: SortGroupOutcomeSortBy): void {
    this.store.dispatch(SortGroupOutcomeCopyGridListActions.sortBy({ sortBy }));
  }

  showCopyFromModal(copyFrom: string[]): void {
    this.store.dispatch(
      SortGroupOutcomeCopyGridListActions.showCopyFromModal({ copyFrom })
    );
  }

  showCopyFromModalFromForm(copyFrom: string[]): void {
    this.store.dispatch(
      SortGroupOutcomeCopyGridListActions.showCopyFromModalFromForm({
        copyFrom
      })
    );
  }

  showCopyToModal(copyTo: string): void {
    this.store.dispatch(
      SortGroupOutcomeCopyGridListActions.showCopyToModal({ copyTo })
    );
  }

  apply(): void {
    this.store.dispatch(SortGroupOutcomeCopyGridListActions.apply());
  }

  hideModal(): void {
    this.store.dispatch(SortGroupOutcomeCopyGridListActions.hideModal());
  }

  reset(): void {
    this.store.dispatch(SortGroupOutcomeCopyGridListActions.reset());
  }

  createDistinctSelector<
    T extends Extract<keyof SortGroupOutcomeListItemDto, string | number>
  >(property: T): Observable<SersiSelectListItem[]> {
    // @ts-ignore
    return this.list$.pipe(
      map((items) => {
        return Array.from(new Set(items.map((item) => item[property]).filter(x => x))).map(
          (value) => ({
            id: value,
            code: value,
            description: value
          })
        );
      }),
      distinctUntilChanged()
    );
  }
}
