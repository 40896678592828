import { Injectable } from '@angular/core';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { AnimalSelectorItemDto, AnimalSelectorRequestDto } from '@ifhms/models/feedlot';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { map, Observable, withLatestFrom } from 'rxjs';
import { Facade } from '..';
import { CattleSelectorLeftActions } from '../left/left.actions';
import { CattleSelectorRightActions } from './right.actions';
import { State } from './right.reducer';
import { CattleSelectorRightSelectors } from './right.selectors';

@Injectable()
export class CattleSelectorRightFacade implements Facade {
  totalLength$: Observable<number> = this.store.select(
    CattleSelectorRightSelectors.totalLength
  );
  availableLength$ = this.totalLength$;
  list$: Observable<AnimalSelectorItemDto[]> = this.store.select(
    CattleSelectorRightSelectors.selectFilteredList
  );
  all$: Observable<AnimalSelectorItemDto[]> = this.store.select(
    CattleSelectorRightSelectors.selectAll
  );
  entities$: Observable<Dictionary<AnimalSelectorItemDto>> = this.store.select(
    CattleSelectorRightSelectors.selectAllEntites
  );
  selected$: Observable<AnimalSelectorItemDto[]> = this.store.select(
    CattleSelectorRightSelectors.selectAllSelected
  );
  isLoading$: Observable<boolean> = this.store.select(
    CattleSelectorRightSelectors.selectLoading
  );
  isLoaded$: Observable<boolean> = this.store.select(
    CattleSelectorRightSelectors.selectLoaded
  );
  selectedLength$: Observable<number> = this.store.select(
    CattleSelectorRightSelectors.selectedLength
  );

  filter$: Observable<AnimalSelectorRequestDto> = this.store.select(
    CattleSelectorRightSelectors.selectFilter
  );
  filterApplied$: Observable<boolean> = this.store.select(
    CattleSelectorRightSelectors.selectFilterApplied
  );

  selectedAnimalsLocationTypes$: Observable<SersiSelectListItem[]> = this.referenceDataFacade.homeLocations$
    .pipe(
      withLatestFrom(this.entities$),
      map(([refData, entities]) => {
        return refData.filter(item => {
          return Object.values(entities).some(entity => entity?.homePenId === item.id);
        });
      })
    );

  selectedAnimalsCurrentPen$: Observable<SersiSelectListItem[]> = this.referenceDataFacade.locations$
    .pipe(
      withLatestFrom(this.entities$),
      map(([refData, entities]) => {
        return refData.filter(item => {
          return Object.values(entities).some(entity => entity?.currentPenId === item.id);
        });
      })
    );

  selectedAnimalsLots$: Observable<SersiSelectListItem[]> = this.referenceDataFacade.lots$
    .pipe(
      withLatestFrom(this.entities$),
      map(([refData, entities]) => {
        return refData.filter(item => {
          return Object.values(entities).some(entity => entity?.lotId === item.id);
        });
      })
    );

  constructor(private store: Store<State>,
    private referenceDataFacade: ReferenceDataFacade) {
  }

  replace(animals: AnimalSelectorItemDto[]): void {
    this.store.dispatch(CattleSelectorRightActions.replace({ animals }));
  }

  add(animals: AnimalSelectorItemDto[]): void {
    this.store.dispatch(CattleSelectorRightActions.add({ animals }));
  }

  select(animal: AnimalSelectorItemDto): void {
    this.store.dispatch(
      CattleSelectorRightActions.select({
        animal: { ...animal, changes: { selected: !animal.selected } }
      })
    );
  }

  selectMultiple(animals: AnimalSelectorItemDto[]): void {
    this.store.dispatch(
      CattleSelectorLeftActions.selectMultiple({
        animals: animals.map((animal) => {
          return { ...animal, changes: { selected: true } };
        })
      })
    );
  }

  selectAll(): void {
    this.store.dispatch(CattleSelectorRightActions.selectAll());
  }

  filter(filter: AnimalSelectorRequestDto): void {
    this.store.dispatch(CattleSelectorRightActions.filter({ filter }));
  }

  clearFilter(): void {
    this.store.dispatch(CattleSelectorRightActions.clearFilter());
  }

  remove(): void {
    this.store.dispatch(CattleSelectorRightActions.remove());
  }
}
