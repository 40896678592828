<p-table
  [value]="animals"
  *transloco="let t; read: translateScope"
  [scrollable]="true"
  scrollHeight="calc(50vh + 5rem)"
  [virtualScrollItemSize]="50"
  [virtualScroll]="true"
  styleClass="p-datatable-lg p-datatable-striped"
>
  <ng-template pTemplate="caption" style="background-color: inherit">
    <div class="flex justify-content-between align-items-center md:h-7rem lg:h-5rem">
      {{ heading }}
      <span class="flex flex-row gap-2 pl-2 flex-wrap">
        <p-button #menuButton
                *ngIf="allowUpload"
                (click)="menu.toggle($event)"
                [attr.data-ele]="'file-btn'"
                [label]="t('file')"
                class="justify-content-end search"
                icon="pi pi-chevron-down"
                iconPos="right"
                [rounded]="true"
                outlined="true"
                severity="secondary"
                type="button"
        >
        </p-button>
        <p-menu
          #menu
          [model]="actionMenuItems"
          [popup]="true"
          appendTo="body"
        ></p-menu>

        <button
          [attr.data-ele]="'clear-search-btn'"
          pButton
          *ngIf="(facade.filterApplied$ | async)"
          icon="pi pi-times"
          class="clear p-button-rounded"
          iconPos="left"
          [label]="t('clear-search')"
          (click)="clearSearch()"
        ></button>

        <ifhms-cattle-selector-table-search
          [filter]="facade.filter$ | async"
          [facade]="facade"
          [woAttributes]="woAttributes"
        ></ifhms-cattle-selector-table-search>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th class="text-center sersi-width-75">
        <ifhms-cattle-selector-checkbox
          [attr.data-ele]="'cattle-selector-header-checkbox'"
          [checked]="status | async"
          (check)="selectAll()"
          [disabled]="!!(facade.availableLength$ | async) === false"
        ></ifhms-cattle-selector-checkbox>
      </th>
      <th class="text-center">{{ t('tag') }}</th>
      <th class="text-center">{{ t('current-pen') }}</th>
      <th class="text-center">{{ t('home-pen') }}</th>
      <th class="text-center">{{ t('lot') }}</th>
      <th class="text-center">{{ t('sub-group') }}</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6" class="justify-content-center border-bottom-none empty">
        <ng-content></ng-content>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-animal let-rowIndex="rowIndex">
    <tr [ngClass]="animal.selected ? 'selected' : (!animal.isAvailable && showAnimalAvailability) ? 'tool-tip' : ''">
      <td class="text-center p-0">
        @if (animal.isAvailable === false) {
          <sersi-icon
            *ngIf="showAnimalAvailability"
            class="cursor-pointer"
            [name]="'exclamation'"
            (click)="showAnimalAvailabilityModal(animal.id)"
            style="color: var(--custom-orange-attention)"
          ></sersi-icon>
        } @else {
          <ifhms-cattle-selector-checkbox
            [attr.data-cattle-selector-checkbox-ele]="animal.id"
            [checked]="animal.selected ? true : null"
            [disabled]="!animal.isAvailable && showAnimalAvailability"
            (check)="toggleAnimalSelection(animal, rowIndex, $event)"
          ></ifhms-cattle-selector-checkbox>
        }
      </td>
      <td class="py-0 px-1 text-center">{{ animal.tagId }}</td>
      <td class="py-0 px-1 text-center">{{ animal.currentPenName }}</td>
      <td class="py-0 px-1 text-center">{{ animal.homePenName }}</td>
      <td class="py-0 px-1 text-center">{{ animal.lotNumber }}</td>
      <td class="py-0 px-1 text-center">{{ animal.subGroup }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div
      class="flex align-items-center justify-content-between footer"
      *ngIf="facade.totalLength$ | async"
      [ngStyle]="{
        background:
          (facade.totalLength$ | async) ? 'var(--alabaster)' : 'transparent'
      }"
    >

      @if (animals.length === 1) {
        {{
          ((facade.selectedLength$ | async) || t('total')) +
          ' ' +
          t('of') +
          ' ' +
          (facade.totalLength$ | async) +
          ' ' +
          t('animal') +
          ' ' +
          ((facade.selectedLength$ | async) ? t('selected') : '')
        }}
      } @else {
        {{
          ((facade.selectedLength$ | async) || t('total')) +
          ' ' +
          t('of') +
          ' ' +
          (facade.totalLength$ | async) +
          ' ' +
          t('animals') +
          ' ' +
          ((facade.selectedLength$ | async) ? t('selected') : '')
        }}
      }
    </div>
  </ng-template>
</p-table>
