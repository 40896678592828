import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { scopeLoader } from '@common/angular/translation';
import { CommonAngularUtilsModule } from '@common/angular/utils';
import { SortGroupsService } from '@ifhms/common/angular/data-access/feedlot-api';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FormlyModule } from '@ngx-formly/core';
import { SersiAngularFormlyCoreModule } from '@sersi/angular/formly/core';
import { SersiAngularUiCoreModule } from '@sersi/angular/ui/core';
import { SersiAngularUiIconModule } from '@sersi/angular/ui/icon';
import { SersiAngularUiSearchModule } from '@sersi/angular/ui/search';
import { SersiAngularUiSpinModule } from '@sersi/angular/ui/spin';
import { SersiAngularUiTableModule } from '@sersi/angular/ui/table';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { FEATURE_NAME, reducer } from './+state';
import { SortGroupDetailEffects } from './+state/sort-group-detail/sort-group-detail.effects';
import { SortGroupDetailFacade } from './+state/sort-group-detail/sort-group-detail.facade';
import { SortGroupListEffects } from './+state/sort-group-list/sort-group-list.effects';
import { SortGroupListFacade } from './+state/sort-group-list/sort-group-list.facade';
import { SortGroupOutcomeCopyGridListEffects } from './+state/sort-group-outcome-copy-grid-list/sort-group-outcome-copy-grid-list.effects';
import { SortGroupOutcomeCopyGridListFacade } from './+state/sort-group-outcome-copy-grid-list/sort-group-outcome-copy-grid-list.facade';
import { SortGroupOutcomeDetailViewEffects } from './+state/sort-group-outcome-detail-view/sort-group-outcome-detail-view.effects';
import { SortGroupOutcomeDetailViewFacade } from './+state/sort-group-outcome-detail-view/sort-group-outcome-detail-view.facade';
import { SortGroupOutcomeDetailEffects } from './+state/sort-group-outcome-detail/sort-group-outcome-detail.effects';
import { SortGroupOutcomeDetailFacade } from './+state/sort-group-outcome-detail/sort-group-outcome-detail.facade';
import { SortGroupOutcomeListEffects } from './+state/sort-group-outcome-list/sort-group-outcome-list.effects';
import { SortGroupOutcomeListFacade } from './+state/sort-group-outcome-list/sort-group-outcome-list.facade';
import { SortGroupCheckboxComponent } from './components/sort-group-checkbox/sort-group-checkbox.component';
import { SortGroupFormModalComponent } from './components/sort-group-form-modal/sort-group-form-modal.component';
import { SortGroupFormComponent } from './components/sort-group-form/sort-group-form.component';
import { SortGroupOutcomeGridModalComponent } from './components/sort-group-outcome-grid-modal/sort-group-outcome-grid-modal.component';
import { SortGroupModalComponent } from './components/sort-group-outcome-modal/sort-group-outcome-modal.component';
import { SortGroupOutcomeTableFilterFormComponent } from './components/sort-group-outcome-table-filter-form/sort-group-outcome-table-filter-form.component';
import { SortGroupOutcomeTableFilterComponent } from './components/sort-group-outcome-table-filter/sort-group-outcome-table-filter.component';
import { SortGroupOutcomeTableMenuComponent } from './components/sort-group-outcome-table-menu/sort-group-outcome-table-menu.component';
import { SortGroupOutcomeTableSortComponent } from './components/sort-group-outcome-table-sort/sort-group-outcome-table-sort.component';
import { SortGroupTableComponent } from './components/sort-group-outcome-table/sort-group-outcome-table.component';
import { SortGroupOutcomeViewModalComponent } from './components/sort-group-outcome-view-modal/sort-group-outcome-view-modal.component';
import { SortGroupProductsTableComponent } from './components/sort-group-products-table/sort-group-products-table.component';
import { SortGroupSearchModalComponent } from './components/sort-group-search-modal/sort-group-search-modal.component';
import { SortGroupSearchTableFilterFormComponent } from './components/sort-group-search-table-filter-form/sort-group-search-table-filter-form.component';
import { SortGroupSearchTableFilterComponent } from './components/sort-group-search-table-filter/sort-group-search-table-filter.component';
import { SortGroupSearchTableComponent } from './components/sort-group-search-table/sort-group-search-table.component';
import { SortGroupInputComponent } from './containers/sort-group-input/sort-group-input.component';
import { SortGroupProductWrapperComponent } from './wrappers/sort-group-product-wrapper/sort-group-product-wrapper.component';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,

    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([
      SortGroupListEffects,
      SortGroupDetailEffects,

      SortGroupOutcomeListEffects,
      SortGroupOutcomeCopyGridListEffects,

      SortGroupOutcomeDetailEffects,
      SortGroupOutcomeDetailViewEffects
    ]),

    SersiAngularUiIconModule.forRoot(),
    SersiAngularFormlyCoreModule,
    SersiAngularUiCoreModule,
    SersiAngularUiSpinModule,
    SersiAngularUiTableModule,
    SersiAngularUiSearchModule,

    FormsModule,
    FormlyModule,
    ReactiveFormsModule,

    TableModule,
    ButtonModule,
    CardModule,
    TriStateCheckboxModule,
    OverlayPanelModule,
    DialogModule,
    MenuModule,
    ConfirmDialogModule,
    TriStateCheckboxModule,
    CheckboxModule,
    CommonAngularUtilsModule
  ],
  declarations: [
    SortGroupInputComponent,
    SortGroupModalComponent,
    SortGroupTableComponent,
    SortGroupFormComponent,
    SortGroupCheckboxComponent,
    SortGroupProductWrapperComponent,
    SortGroupSearchModalComponent,
    SortGroupSearchTableComponent,
    SortGroupFormModalComponent,
    SortGroupOutcomeGridModalComponent,
    SortGroupOutcomeTableMenuComponent,
    SortGroupOutcomeViewModalComponent,
    SortGroupOutcomeTableFilterComponent,
    SortGroupOutcomeTableSortComponent,
    SortGroupOutcomeTableFilterFormComponent,
    SortGroupSearchTableFilterComponent,
    SortGroupSearchTableFilterFormComponent,
    SortGroupProductsTableComponent
  ],
  exports: [SortGroupInputComponent],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: FEATURE_NAME,
        alias: FEATURE_NAME,
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        )
      }
    },
    SortGroupsService,
    FeedlotFacade,

    SortGroupListFacade,
    SortGroupListEffects,

    SortGroupDetailFacade,
    SortGroupDetailEffects,

    SortGroupOutcomeListFacade,
    SortGroupOutcomeListEffects,

    SortGroupOutcomeCopyGridListFacade,
    SortGroupOutcomeCopyGridListEffects,

    SortGroupOutcomeDetailFacade,
    SortGroupOutcomeDetailEffects,

    SortGroupOutcomeDetailViewFacade,
    SortGroupOutcomeDetailViewEffects
  ]
})
export class IfhmsFeedlotFrontEndSharedDomainSortGroupModule {}
