import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DsortApiService } from './services';
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { AH_FMS_DSORT_CONFIG } from './tokens';
import { DSORT_CONFIG } from './config';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: AH_FMS_DSORT_CONFIG,
      useValue: DSORT_CONFIG,
      multi: false
    },
    DsortApiService,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class IfhmsCommonAngularDataAccessDosrtApiModule {}
