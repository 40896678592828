{{ sortLabel }}:
<ng-select
  appendTo="body"
  bindValue="id"
  data-ele="sort-dropdown"
  bindLabel="description"
  [ngClass]="className"
  [items]="items"
  [disabled]="disabled"
  [placeholder]="placeholder"
  [clearable]="false"
  [searchable]="false"
  (change)="sortChanged.emit($event)"
  (open)="onDropdownOpen()"
  (close)="onDropdownClose()"
  [(ngModel)]="value"
>
  <ng-template ng-option-tmp let-item="item">
    <div class="sort-item-text" [attr.title]="item.description">{{ item.description}}</div>
  </ng-template>
</ng-select>
