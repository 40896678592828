import { ChangeDetectionStrategy, Component, ViewChild, ViewEncapsulation } from '@angular/core';

import { AbstractTranslationComponent, scopeLoader } from '@common/angular/translation';
import { ProductMetadataDto } from '@ifhms/models/feedlot';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, of } from 'rxjs';

import { EventProductMetadataFacade } from '../../+state';
import { ProductMetadataDialogProduct } from '../../interfaces';
import { ProductMetadataFormComponent } from '../product-metadata-form/product-metadata-form.component';

@UntilDestroy()
@Component({
  selector: 'ifhms-product-metadata-dialog',
  templateUrl: './product-metadata-dialog.component.html',
  styleUrls: ['./product-metadata-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'product-metadata-shared',
        alias: 'product-metadata-shared',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class ProductMetadataDialogComponent extends AbstractTranslationComponent {
  protected override translationNamespace = 'components.product-metadata-dialog';

  @ViewChild(ProductMetadataFormComponent, { static: false }) formRef: ProductMetadataFormComponent;

  isLoading$ = this.eventProductMetadataFacade.isLoading$;
  productMetadata$: Observable<ProductMetadataDto>;

  constructor(
    private dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private eventProductMetadataFacade: EventProductMetadataFacade
  ) {
    super();
  }

  override onTranslationInit(): void {
    const { product, metadata, disabled } = this.config.data;
    this.productMetadata$ = metadata || disabled
      ? of(metadata || {})
      : this.loadProductsMetadata(product);
  }

  apply(): void {
    this.dialogRef.close({
      ...this.formRef.model,
      expireDate: this.formRef.model.expireDate?.toISOString()
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  private loadProductsMetadata(product: ProductMetadataDialogProduct): Observable<ProductMetadataDto | null> {
    const { productId } = product;
    const { eventId, eventType } = this.config.data;

    this.eventProductMetadataFacade.getProductMetadata(productId, product.facilityId, eventId, eventType);
    return this.eventProductMetadataFacade.metaData$;
  }

}
