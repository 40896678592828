import { Component, OnInit } from '@angular/core';
import { AppConfig } from '@ifhms/feedlot/web/core/config';
import { AppConfigService } from '../services';

@Component({
  selector: 'ifhms-feedlot-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  config: AppConfig;

  constructor(private configService: AppConfigService) {
  }

  ngOnInit(): void {
    this.config = this.configService.getConfig();
    if (this.config.theme) {
      const linkElement = document.getElementById('theme-link');
      this.setLink(linkElement, this.config.theme);
    }
  }

  setLink(linkElement: any, theme: string | undefined): void {
    if (linkElement) {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute(
        'href',
        linkElement.getAttribute('href').replace('sersi-light', <string>theme)
      );
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement?.parentNode?.insertBefore(
        cloneLinkElement,
        linkElement?.nextSibling
      );

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    }
  }
}
