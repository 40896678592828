import { Data, Router } from '@angular/router';
import { getCurrentRouteWithData } from '@common/angular/utils';
import { FeedlotSettingsGlobalDto } from '@ifhms/models/feedlot';

export function getFeedlotData(router : Router) : Data {
  const routeData =  getCurrentRouteWithData(router);
  return routeData['snapshot'].data.feedlotData;
}

export function getGlobalSettings(router : Router): FeedlotSettingsGlobalDto {
  const feedlotData = getFeedlotData(router);
  return feedlotData['globalSettings'];
}