import { createSelector } from '@ngrx/store';
import * as Reducer from './right.reducer';
import { selectFeatureState, State } from '..';
import {
  AnimalSelectorItemDto,
  AnimalSelectorRequestDto
} from '@ifhms/models/feedlot';

const selectState = createSelector(
  selectFeatureState,
  (state: State) => state.right
);

const selectLoading = createSelector(selectState, Reducer.getLoading);

const selectLoaded = createSelector(selectState, Reducer.getLoaded);

const selectAll = createSelector(selectState, Reducer.getAll);

const selectAllEntites = createSelector(selectState, Reducer.getEntities);

const selectFilter = createSelector(selectState, Reducer.getFilter);

const selectFilteredList = createSelector(
  selectAll,
  selectFilter,
  (items, filter) => filterList(items, filter)
);
function filterList(
  items: AnimalSelectorItemDto[],
  filter: AnimalSelectorRequestDto
): AnimalSelectorItemDto[] {
  return items.filter((item) => {
    let result = true;
    if (filter.currentPenId) {
      if (item.currentPenId != filter.currentPenId) result = false;
    }
    if (filter.homePenId) {
      if (item.homePenId != filter.homePenId) result = false;
    }
    if (filter.lotId) {
      if (item.lotId != filter.lotId) result = false;
    }
    if (filter.subGroupId) {
      if (item.subGroupId != filter.subGroupId) result = false;
    }
    return result;
  });
}

const totalLength = createSelector(selectFilteredList, (animals) => animals.length);

const selectFilterApplied = createSelector(
  selectState,
  Reducer.getFilterApplied
);

const selectAllSelected = createSelector(selectFilteredList, (animals) =>
  animals.filter((animal) => animal.selected)
);

const selectedLength = createSelector(
  selectAllSelected,
  (animals) => animals.length
);

export const CattleSelectorRightSelectors = {
  selectState,
  selectLoading,
  selectLoaded,
  selectAll,
  selectAllEntites,
  totalLength,
  selectFilter,
  selectFilteredList,
  selectFilterApplied,
  selectAllSelected,
  selectedLength
};
