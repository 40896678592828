import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { AbstractFormFieldConfigComponent, GridColumnDef, GridOptions } from '@sersi/angular/formly/core';
import { DosingGunGridItemComponent } from '../dosing-gun-grid-item/dosing-gun-grid-item.component';

@Component({ template: '',selector:'ifhms-abstract-dosing-gun-grid-component' })
export abstract class AbstractDosingGunGridComponent extends AbstractFormFieldConfigComponent {

  protected gridOptions: GridOptions;

  protected get fieldKey(): string {
    return this.fieldProps['valueKey'];
  }

  protected abstract setGridField(): FormlyFieldConfig;
  protected abstract getColumnDefs(): GridColumnDef[];

  protected getFieldGroupConfig(): FormlyFieldConfig[] {
    return [
      this.setGridField()
    ]
  }

  protected setGridRow(): FormlyFieldConfig {
    return {
      type: DosingGunGridItemComponent,
      props: {
        gridOptions: this.getGridOptions(),
        contextKey: this.fieldKey,
        translateFn: this.getParentTranslation.bind(this)
      }
    }
  }

  protected getGridOptions(): GridOptions {
    this.gridOptions ||= {
      context: this.fieldKey,
      columns: this.getColumnDefs(),
      addRow: new Subject<void>(),
      deleteRow: new Subject<number>()
    };
    return this.gridOptions;
  }

}
