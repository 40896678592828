import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { FeedlotListItemDto } from '@ifhms/models/feedlot';

import { feedlotsActions } from './feedlots.actions';

export interface State extends EntityState<FeedlotListItemDto> {
  loading: boolean;
  loaded: boolean;
  filter: string | null;
}

const adapter: EntityAdapter<FeedlotListItemDto> = createEntityAdapter<FeedlotListItemDto>({
  selectId: (entity: FeedlotListItemDto) => entity.id
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  filter: null
});

export const reducer = createReducer(
  initialState,

  on(feedlotsActions.get, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(feedlotsActions.getSuccess, (state, { feedlots }) =>
    adapter.setAll(feedlots, {
      ...state,
      loading: false,
      loaded: true
    })
  ),

  on(feedlotsActions.filter, (state, { filter }) => ({ ...state, filter })),

  on(feedlotsActions.getSuccessCached, (state) => ({
    ...state,
    loading: false,
    loaded: true
  })),

  on(feedlotsActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),

  on(feedlotsActions.resetFilters, (state) => ({
    ...state,
    filter: initialState.filter
  })),

  on(feedlotsActions.reset, () => initialState)
);

const { selectAll } = adapter.getSelectors();

export const getAll = selectAll;

export const getLoading = (state: State): boolean => state.loading;

export const getLoaded = (state: State): boolean => state.loaded;

export const getFilter = (state: State): string | null => state.filter;
