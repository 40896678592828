import { Injectable } from '@angular/core';
import { AbstractStaticListFacade } from '@common/angular/utils';
import { MovementWorkOrderActions } from '@ifhms/models/feedlot';

@Injectable()
export class WorkOrderMoveActionsFacade extends AbstractStaticListFacade {

  protected selectListType = 'work-order-move-actions';
  protected override useCustomTranslationForCode = true;

  protected selectList = [
    {
      id: MovementWorkOrderActions.MoveCurrentPen,
      description: '',
      code: 'MoveCurrentPen'
    },
    {
      id: MovementWorkOrderActions.MoveHomePen,
      description: '',
      code: 'MoveHomePen'
    },
    {
      id: MovementWorkOrderActions.SendBackHome,
      description: '',
      code: 'SendBackHome'
    },
    {
      id: MovementWorkOrderActions.SetNewHomePen,
      description: '',
      code: 'SetNewHomePen'
    }
  ];

}
