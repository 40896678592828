import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AbstractTranslationComponent, scopeLoader } from '@common/angular/translation';
import { UpcDosingGunsFacade } from '@ifhms/common/angular/upc/shared';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { DosingGunItem, DosingGunsListDto } from '@ifhms/models/feedlot';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyWrappersEnum } from '@sersi/angular/formly/core';
import { filter, Observable } from 'rxjs';
import { DosingGunsGridComponent } from '../dosing-guns-grid/dosing-guns-grid.component';
import { WorkOrderType } from '@ifhms/models/shared';
import { DosingGunFormModel } from '../interfaces/dosing-gun-form-model';

@UntilDestroy()
@Component({
  selector: 'ifhms-dosing-guns-grid-form',
  templateUrl: './dosing-guns-grid-form.component.html',
  styleUrls: ['./dosing-guns-grid-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'dosingguns',
        alias: 'dosingguns',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class DosingGunsGridFormComponent
  extends AbstractTranslationComponent
  implements OnInit
{
  readonly translationNamespace = 'components.dosing-guns-grid-form';

  form = new UntypedFormGroup({});
  fields: FormlyFieldConfig[];
  options: FormlyFormOptions = {};
  model: DosingGunFormModel;

  loading$: Observable<boolean> = this.upcDosingGunsFacade.loading$;

  @Output() gunSelected = new EventEmitter<DosingGunItem | null>();

  constructor(
    private upcDosingGunsFacade: UpcDosingGunsFacade,
    private referenceDataFacade: ReferenceDataFacade
  ) {
    super();
  }

  override onTranslationInit(): void {
    this.loadRefData();
    this.subscribeToDosingGunsFromUpc();
    this.setFields();
  }

  override onTranslationReady(): void {
    this.loadRefData();
    this.subscribeToDosingGunsFromUpc();
    this.setFields();
  }

  private subscribeToDosingGunsFromUpc(): void {
    this.upcDosingGunsFacade.getDosingGunsStatusFromUpc();
    this.upcDosingGunsFacade.dosingGunItems$
      .pipe(
        filter((dosingGuns) => !!dosingGuns),
        untilDestroyed(this)
      )
      .subscribe((dosingGuns) => {
        if(dosingGuns) {
          this.model = {
            dosingGuns: dosingGuns.map(dosingGun => ({
              ...dosingGun,
              dosingGunState: WorkOrderType[dosingGun.dosingGunState as number]
            })
            )
          };
        }

        this.cdr.markForCheck();
      });
  }

  private setFields(): void {
    this.fields = [
      {
        fieldGroupClassName: 'grid-auto-columns',
        fieldGroup: [...this.setDosingGunsSection()]
      }
    ];
  }

  private setDosingGunsSection(): FormlyFieldConfig[] {
    return [this.setGridTitle(), this.setDosingGunGrid()];
  }

  private setGridTitle(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      props: {
        label: this.getTranslation('dosing-guns-label')
      },
      className: 'dosingGuns__GridTitle'
    };
  }

  private setDosingGunGrid(): FormlyFieldConfig {
    return {
      className: 'dosingGuns__guns ag-theme-balham',
      type: DosingGunsGridComponent,
      props: {
        valueKey: 'dosingGuns',
        noDataMessage$: this.getTranslation$('no-guns-message'),
        translateFn: this.getTranslation.bind(this)
      }
    };
  }

  onUpdateDosingGuns(): void {
    let formVal: DosingGunsListDto = this.form.getRawValue();
    if(formVal.dosingGuns) {
      formVal = {
        ...formVal,
        dosingGuns: formVal.dosingGuns.map(dosingGun => ({
          ...dosingGun,
          dosingGunState: WorkOrderType[dosingGun.dosingGunState as unknown as keyof typeof WorkOrderType]
        }))
      };
    }
    
    this.upcDosingGunsFacade.updateDosingGunsSettings(formVal);
    this.form.markAsPristine();
  }

  private loadRefData(): void {
    this.referenceDataFacade.getProductList();
    this.referenceDataFacade.getProductTypes();
  }
}
