import { Component, Input } from '@angular/core';
import { scopeLoader } from '@common/angular/translation';
import { AnimalSelectorItemDto } from '@ifhms/models/feedlot';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { FEATURE_NAME } from '../../+state';

@Component({
  selector: 'ifhms-cattle-selector-data-table',
  templateUrl: './cattle-selector-data-table.component.html',
  styleUrls: ['./cattle-selector-data-table.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: FEATURE_NAME,
        alias: FEATURE_NAME,
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class CattleSelectorDataTableComponent {
  translateScope = `${FEATURE_NAME}.components.cattle-selector-table`;

  @Input() set data(data: AnimalSelectorItemDto[]) {
    this._data = [...data].sort((a, b) => this.collator.compare(a.tagId, b.tagId));
  }

  get data(): AnimalSelectorItemDto[] {
    return this._data;
  }

  @Input() disabled: boolean;
  @Input() showDialog: () => void;

  private _data: AnimalSelectorItemDto[] = [];
  private collator = new Intl.Collator('en');
}