import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs/internal/Observable';

import { AbstractTranslationComponent, scopeLoader } from '@common/angular/translation';
import { AhCommonDialogService } from '@common/angular/utils';
import { UpcDosingGunsFacade } from '@ifhms/common/angular/upc/shared';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { DosingGunItem } from '@ifhms/models/feedlot';
import { WorkOrderType } from '@ifhms/models/shared';
import { FormlyTypesEnum, SersiSelectListItem } from '@sersi/angular/formly/core';

import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { BehaviorSubject, distinctUntilChanged, filter, take } from 'rxjs';
import { ValidateDosingGunFormComponent } from '../validate-dosing-gun-form/validate-dosing-gun-form.component';

export interface AssignDosingGunFormModel {
  id: string | null;
  name: string | null;
  workOrderNumber: string | undefined;
  description: string | null;
  macAddress: string | null;
  productTypeId: string;
  productId: string;
  dosingGunState: WorkOrderType;
  validated: boolean;
}
@UntilDestroy()
@Component({
  selector: 'ifhms-assign-dosing-gun-form',
  templateUrl: './assign-dosing-gun-form.component.html',
  styleUrls: ['./assign-dosing-gun-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'dosingguns',
        alias: 'dosingguns',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class AssignDosingGunFormComponent
  extends AbstractTranslationComponent
  implements OnInit
{
  readonly translationNamespace = 'components.assign-dosing-gun-form';

  private _dosingGunSelectListItems = new BehaviorSubject<
  SersiSelectListItem[] | null
  >(null);
  dosingGunSelectListItems$: Observable<SersiSelectListItem[] | null>;

  dosingGuns: DosingGunItem[] | null = [];

  form = new UntypedFormGroup({});
  fields: FormlyFieldConfig[];
  options: FormlyFormOptions = {};
  model: AssignDosingGunFormModel;

  loading$: Observable<boolean>;

  workOrderNumber: number;

  constructor(
    private dosingGunsFacade: UpcDosingGunsFacade,
    private referenceDataFacade: ReferenceDataFacade,
    private dialogService: AhCommonDialogService,
    private dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    super();
    this.loading$ = this.dosingGunsFacade.loading$;
    this.dosingGunSelectListItems$ =
      this._dosingGunSelectListItems.asObservable();
  }

  override onTranslationInit(): void {
    this.setFields();
    this.subscribeToDosingGuns();
  }

  private subscribeToDosingGuns(): void {
    this.dosingGunsFacade.getDosingGunsStatusFromUpc();
    this.dosingGunsFacade.dosingGunItems$
      .pipe(
        filter((items) => !!items && items.length > 0),
        distinctUntilChanged((prevItems, currItems) => {
          if (!currItems) {
            return false;
          }
          if (prevItems?.length !== currItems?.length) {
            return false;
          }
          return prevItems?.every(
            (item, index) => item.id === currItems[index].id
          );
        }),
        untilDestroyed(this)
      )
      .subscribe((dosingGuns) => {
        this.dosingGuns = dosingGuns;
        const dosingGunItems: SersiSelectListItem[] = this.getDosingGunSelectList();
        this._dosingGunSelectListItems.next(dosingGunItems);
        this.patchForm();
      });
  }

  private setFields(): void {
    this.fields = [
      {
        fieldGroupClassName: 'assign-dosing-gun-form',
        fieldGroup: [
          this.setGunNameField(),
          this.setValidatedField(),
          this.setGunSelectField(),
          this.setWorkOrderNumberField(),
          this.setGunDescriptionField(),
          this.setMacAddressField(),
          this.setProductTypeField(),
          this.setProductField()
        ]
      }
    ];
  }

  private patchForm(): void {
    let gunToPatch = null;
    this.workOrderNumber = this.config.data.workOrderNumber;

    if (this.dosingGuns && this.config.data.productId) {
      const matchingGun = this.dosingGuns.find(
        (gun) => gun.productId === this.config.data.productId
            && gun.workOrderNumber === this.workOrderNumber
      );
      if (matchingGun) {
        gunToPatch = matchingGun;
      }
    }

    this.model = {
      id: gunToPatch?.id ?? null,
      name: gunToPatch?.name ?? null,
      workOrderNumber: this.config.data.workOrderNumber,
      dosingGunState: this.config.data.workOrderType,
      description: gunToPatch?.description ?? null,
      macAddress: gunToPatch?.macAddress ?? null,
      productTypeId: gunToPatch?.productType ?? this.config.data.productTypeId,
      productId: gunToPatch?.productId ?? this.config.data.productId,
      validated: gunToPatch?.validated ?? false
    };

    this.form.patchValue(this.model);
    this.cdr.detectChanges();
  }

  public setGunNameField(): FormlyFieldConfig {
    return {
      key: 'name'
    };
  }

  public setValidatedField(): FormlyFieldConfig {
    return {
      key: 'validated'
    };
  }

  private setGunSelectField(): FormlyFieldConfig {
    return {
      key: 'id',
      className: 'dosing-gun-name',
      type: FormlyTypesEnum.SINGLE_SELECT,
      props: {
        label: this.getTranslation('dosing-guns-name-label'),
        required: true,
        items$: this.dosingGunSelectListItems$,
        optionsLabel: 'CODE',
        selectedItemLabel: 'CODE',
        showClear: false,
        appendTo: null,
        change: (field, event): void => {
          if (event) {
            this.form.patchValue({
              description: event.description,
              macAddress: event.attributes.macAddress,
              name: event.attributes.name
            });
            this.cdr.markForCheck();
          }
        }
      }
    };
  }

  private setWorkOrderNumberField(): FormlyFieldConfig {
    return {
      key: 'workOrderNumber',
      className: 'work-order-number',
      type: FormlyTypesEnum.TEXT_READONLY,
      props: {
        label: this.getTranslation('work-order-number-label')
      }
    };
  }

  private setGunDescriptionField(): FormlyFieldConfig {
    return {
      key: 'description',
      className: 'gun-description',
      type: FormlyTypesEnum.TEXT_READONLY,
      props: {
        label: this.getTranslation('dosing-gun-description-label')
      }
    };
  }

  private setMacAddressField(): FormlyFieldConfig {
    return {
      key: 'macAddress',
      className: 'mac-address',
      type: FormlyTypesEnum.TEXT_READONLY,
      props: {
        label: this.getTranslation('mac-address-label')
      }
    };
  }

  private setProductTypeField(): FormlyFieldConfig {
    return {
      key: 'productTypeId',
      className: 'product-type',
      type: FormlyTypesEnum.TEXT_READONLY,
      props: {
        label: this.getTranslation('product-type-label'),
        items$: this.referenceDataFacade.productTypes$,
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE',
        required: true
      }
    };
  }

  private setProductField(): FormlyFieldConfig {
    return {
      key: 'productId',
      className: 'product',
      type: FormlyTypesEnum.TEXT_READONLY,
      defaultValue: this.config.data.product,
      props: {
        label: this.getTranslation('product-label'),
        items$: this.referenceDataFacade.productList$,
        optionsLabel: 'CODE_DESCRIPTION',
        selectedItemLabel: 'CODE',
        required: true
      }
    };
  }

  cancel(): void {
    this._dosingGunSelectListItems.next(null);
    this.form.reset();
    this.dialogRef.close();
  }

  onSave(): void {
    const dosingGunsDto: DosingGunItem = {
      ...this.form.value,
      dosingGunState: this.config.data.workOrderType,
      workOrderId: this.config.data.workOrderId,
      workOrderNumber: this.config.data.workOrderNumber
    };
    this.dosingGunsFacade.assignDosingGunToProduct(dosingGunsDto);
    this.form.markAsPristine();
    this.cancel();
  }

  isValidationDisabled(): boolean {
    const assignedProduct = this.dosingGuns?.find(
      (gun) => gun.productId === this.form.value.productId && gun.workOrderNumber === this.workOrderNumber
    );
    if (
      !this.form.value.id ||
      !this.dosingGuns ||
      this.form.value.validated ||
      !assignedProduct ||
      !this.form.value.productId
    ) {
      return true;
    }

    const selectedGun = this.dosingGuns.find(
      (gun) => gun.id === this.form.value.id
    );
    return !selectedGun || !selectedGun.productId;
  }

  isDosingGunAssigned(): boolean {
    if (!this.form.value.productId) return false;
    const assignedProduct = this.dosingGuns?.find(
      (gun) =>
        gun.productId === this.form.value.productId &&
        gun.workOrderNumber === this.workOrderNumber
    );
    return !!assignedProduct;
  }

  onValidate(): void {
    const dialogRef = this.dialogService.openModal(
      ValidateDosingGunFormComponent,
      {
        header: this.getTranslation('validate-dialog-title'),
        data: {
          macAddress: this.form.value.macAddress,
          gunId: this.form.value.id
        }
      }
    );

    this.cdr.detectChanges();

    dialogRef.onClose
      .pipe(take(1), untilDestroyed(this))
      .subscribe((result) => {
        if (result && result.validated == true) {
          this.model = {
            ...this.model,
            validated: true
          };
          this.cdr.markForCheck();
        }
      });
  }

  isSaveDisabled(): boolean {
    return !this.form.valid || !this.form.dirty;
  }

  unAssignDosingGun(): void {
    this.dosingGunsFacade.UnassignDosingGunFromProduct(this.form.value.id);
    this.cdr.markForCheck();
    this.cancel();
  }

  private getDosingGunSelectList(): SersiSelectListItem[] {
    return this.dosingGuns
      ? this.dosingGuns.map((item) => {
        const isProductAssignedAndValidated = item.productId && item.validated;
       
        return {
          id: item.id,
          code: (item.name ? item.name : item.macAddress) + (isProductAssignedAndValidated ? ' *' : ''),
          description: item.description || '',
          isActive: true,
          attributes: {
            macAddress: item.macAddress,
            name: item.name ?? ''
          }
        };
      })
      : [];
  }
}
