import { createReducer, on } from '@ngrx/store';
import { UpcScaleActions } from './upc-scale.actions';
import { DeviceStatusDto } from '@ifhms/models/feedlot';

export interface State {
  loading: boolean;
  loaded: boolean;
  scaleSettings: DeviceStatusDto | null;
  weight: number | null;
  weightCaptured: boolean;
  errMsg?: string | null;
}

const initialState: State = {
  loading: false,
  loaded: false,
  scaleSettings: null,
  weight: null,
  weightCaptured: false,
  errMsg: null
};

// todo ask future plan?
export const reducer = createReducer(
  initialState,

  on(UpcScaleActions.getScaleStatusFromUpc, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(UpcScaleActions.getScaleStatusFromUpcSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    scaleSettings: action.scaleSettings

  })),
  on(UpcScaleActions.captureWeight, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(UpcScaleActions.captureWeightSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    weight: action.weight,
    weightCaptured: action.weight != null
  })),
  on(UpcScaleActions.resetWeightCapture, (state) => ({
    ...state,
    weightCaptured: false
  })),

  on(UpcScaleActions.error, (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    errMsg: action.errMsg
  })),
  on(UpcScaleActions.resetError, (state) => ({
    ...state,
    errMsg: null
  }))
);

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;

export const getError = (state: State): string | undefined | null => state.errMsg;

export const getScaleSettings = (state: State): DeviceStatusDto | null => state.scaleSettings;

export const getWeightCaptured = (state: State): boolean => state.weightCaptured;
export const getWeight = (state: State): number | null => state.weight;

