import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { SersiIconConfig } from './interfaces';
import { SersiIconService } from './services/sersi-icon/sersi-icon.service';
import { SersiIconComponent } from './components';

@NgModule({
  declarations: [SersiIconComponent],
  exports: [SersiIconComponent],
  imports: [CommonModule, AngularSvgIconModule.forRoot()],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class SersiAngularUiIconModule {
  static forRoot(
    config?: SersiIconConfig
  ): ModuleWithProviders<SersiAngularUiIconModule> {
    return {
      ngModule: SersiAngularUiIconModule,
      providers: [
        SersiIconService,
        { provide: 'sersiIconConfig', useValue: config }
      ]
    };
  }
}
