<div class="grid-action-button-container inline-block"
     [pTooltip]="(toolTipMsg) ?? 'No status available'"
     appendTo="body"
     tooltipPosition="top"
     tooltipStyleClass="tooltip-class"
>
    <button pButton pRipple type="button" class="p-button-rounded p-button-text"
            [disabled]="isButtonDisabled"
            (click)="onClick($event)">
        <ng-container *ngIf="(dosingGunIcon) as dosingGunIcon" class="img-container">
            <img [src]="dosingGunIcon" alt="Dosing Gun Status" width="20" height="20"/>
        </ng-container>
    </button>
</div>
<p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>