import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest, map } from 'rxjs';

import { scopeLoader } from '@common/angular/translation';
import { SortGroupTemplateResultDto } from '@ifhms/models/feedlot';

import { FEATURE_NAME } from '../../+state';
import { SortGroupDetailFacade } from '../../+state/sort-group-detail/sort-group-detail.facade';
import { SortGroupListFacade } from '../../+state/sort-group-list/sort-group-list.facade';
import { SortGroupSearchTableComponent } from '../sort-group-search-table/sort-group-search-table.component';

@UntilDestroy()
@Component({
  selector: 'ifhms-sort-group-search-modal',
  templateUrl: './sort-group-search-modal.component.html',
  styleUrls: ['./sort-group-search-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: FEATURE_NAME,
        alias: FEATURE_NAME,
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class SortGroupSearchModalComponent implements OnInit {
  translateScope = `${FEATURE_NAME}.components.sort-group-search-modal`;

  display = true;
  table: Observable<SortGroupTemplateResultDto | null>;
  loading$: Observable<boolean>;

  @ViewChild(SortGroupSearchTableComponent, { static: false })
    formlyForm: SortGroupSearchTableComponent | null;

  constructor(
    public sortGroupListFacade: SortGroupListFacade,
    public sortGroupDetailFacade: SortGroupDetailFacade,
    private cdr: ChangeDetectorRef
  ) {
    this.loading$ = combineLatest([
      this.sortGroupDetailFacade.isLoading$,
      this.sortGroupListFacade.isLoading$
    ]).pipe(map(([conditionA, conditionB]) => conditionA || conditionB));
  }

  ngOnInit(): void {
    this.table = this.sortGroupListFacade.filteredList$;
    this.sortGroupListFacade.modal$
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.display = val;
        if (val) {
          this.sortGroupListFacade.get();
        }
        this.cdr.detectChanges();
      });
  }

  onCancel(): void {
    this.sortGroupListFacade.hideModal();
  }

  onSubmit(): void {
    if (this.formlyForm?.selectedId)
      this.sortGroupDetailFacade.apply(this.formlyForm?.selectedId);
  }

  onSearchTextChanged(event: string): void {
    this.sortGroupListFacade.search(event);
  }
}
