import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { scopeLoader } from '@common/angular/translation';
import { UpcDeviceManagerService, UpcThermometerService } from '@ifhms/common/angular/data-access/upc-api';
import { UpcTemperatureFormFieldUpdateService } from '@ifhms/common/angular/upc/shared';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { HubConnectionState } from '@microsoft/signalr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { SersiFormlyFieldProps } from '@sersi/angular/formly/core';
import { isEmpty } from 'lodash-es';

@UntilDestroy()
@Component({
  selector: 'ifhms-feedlot-upc-form-temperature-field',
  templateUrl: './form-temperature-field.component.html',
  styleUrls: ['./form-temperature-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'thermometer',
        alias: 'thermometer',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class FormTemperatureFieldComponent extends FieldType<FieldTypeConfig> implements OnInit {
  protected readonly translateScope = 'thermometer.components.temperature-field-form';

  private fieldConfig: FormlyFieldConfig;
  temperatureCaptured = false;
  eventId: string;
  temperatureValue: number | null = null;

  private thermometerConnectionStatus: HubConnectionState | null = null;
  readonly HOTKEY_CAPTURE_TEMPERATURE = 'F9';

  get fieldProps(): SersiFormlyFieldProps {
    if (!isEmpty(this?.fieldConfig?.props)) {
      return this?.fieldConfig?.props;
    }
    if (this.props) {
      return this.props
    }
    return {} as SersiFormlyFieldProps;
  }

  constructor(
    private upcThermometerService: UpcThermometerService, 
    private upcDeviceManagerService: UpcDeviceManagerService,
    private upcTemperatureFormFieldUpdateService: UpcTemperatureFormFieldUpdateService 
  ) {
    super();
  }

  ngOnInit(): void {
    this.upcDeviceManagerService.registerDevice(this.upcThermometerService).then(() => {
      this.subscribeToThermometerConnectionStatus();
      this.subscribeToThermometerData();
    }
    );

  }

  subscribeToThermometerData():void {
    this.upcThermometerService.thermometerData$
      .pipe(untilDestroyed(this))
      .subscribe((val : number | null) => {
        if (val) {
          this.formControl.setValue(val);
        }
      });
  }

  @HostListener('window:keydown', ['$event'])
  onCaptureTemperature(event: KeyboardEvent): void {
    const hotkey = event.key === this.HOTKEY_CAPTURE_TEMPERATURE;
    if (hotkey && this.thermometerConnectionStatus === HubConnectionState.Connected) {
      this.setTemperature();
    }
  }

  async setTemperature(): Promise<void>{
    const temperature = await this.upcThermometerService.getCalculatedTemp();
    if (temperature !== null) {
      this.formControl.patchValue(temperature);
      this.upcTemperatureFormFieldUpdateService.updateUPCTemperature(temperature);
    }
  }

  subscribeToThermometerConnectionStatus(): void {
    this.upcThermometerService.connectionState$
      .pipe(untilDestroyed(this))
      .subscribe((status) => {
        this.thermometerConnectionStatus = status;
      });
  }

}
