import { Component, HostBinding, Input } from '@angular/core';
import { HashMap } from '@common/angular/interfaces';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { GridColumnDef, GridOptions, GridRendererCellType } from '@sersi/angular/formly/core';

@Component({
  selector: 'sersi-grid-row-renderer',
  templateUrl: './grid-row-renderer.component.html'
})

export class GridRowRendererComponent {

  @HostBinding('class.grid-row') hostClass = true;

  @Input() disabled?: boolean;
  @Input() cells: FormlyFieldConfig[];
  @Input() rowIndex: number;

  @Input()
  set gridOptions(config: GridOptions) {
    this.gridConfig = config;
    this.columnsConfigMap = this.transformArrayConfigToHash(config.columns);
  }

  protected readonly GridRendererCellType = GridRendererCellType;
  columnsConfigMap: HashMap<GridColumnDef | null>;
  private gridConfig: GridOptions;

  private transformArrayConfigToHash(config: GridColumnDef[]): HashMap<GridColumnDef | null> {
    return config.reduce((memo: HashMap<GridColumnDef | null>, iter) => {
      memo[iter.fieldKey] = iter;
      return memo;
    }, {});
  }

  onRowDelete(): void {
    this.gridConfig.deleteRow?.next(this.rowIndex);
  }

  onRowInfo(e: FormlyFieldConfig): void {
    e.props?.['onClick']();
  }

  onRowAssignDosingGun(e: FormlyFieldConfig): void {
    e.props?.['onClick']();
  }
}
