import { createAction, props } from '@ngrx/store';
import {
  DeviceConnectionStatusEnum,
  DeviceStatusDto,
  DosingGunItem,
  DosingGunsListDto,
  DosingGunsSettingsRequestDto,
  SetDoseResponse
} from '@ifhms/models/feedlot';

const NAMESPACE = '[UPC HUB]';

const getDosingGunsStatusFromUpc = createAction(`${NAMESPACE} Get Dosing Guns Status`);

const getDosingGunsStatusFromUpcSuccess = createAction(`${NAMESPACE} Get Dosing Guns Status Success`, props<{ dosingGunsSettings: DeviceStatusDto | null }>());

const getDosingGunsListFromApi = createAction(`${NAMESPACE} Get Dosing Guns List`);

const getDosingGunsListFromApiSuccess = createAction(`${NAMESPACE} Update Dosing Guns Settings Success`, props<{ dosingGunsDto: DosingGunsListDto }>());

const getAvailableDosingGunsFromUpc = createAction(`${NAMESPACE} Get Available Dosing Guns`);

const getAvailableDosingGunsFromUpcSuccess = createAction(`${NAMESPACE} Get Available Dosing Guns Success`, props<{ availableDosingGuns: string[] | null }>());

const updateDosingGunsSettings = createAction(`${NAMESPACE} Update Dosing Guns Settings`, props<{ dosingGunsDto: DosingGunsListDto }>());

const updateDosingGunsSettingsSuccess = createAction(`${NAMESPACE} Update Dosing Guns Settings Success`, props<{ dosingGunsDto: DosingGunsListDto }>());

const getDosingGunsSettingsFromApi = createAction(`${NAMESPACE} Get Dosing Guns Settings`, props<{ dosingGunsDto: DosingGunsSettingsRequestDto }>());
const getDosingGunsSettingsFromApiSuccess = createAction(`${NAMESPACE} Get Dosing Guns Settings Success`, props<{ dosingGuns: DosingGunItem[] | null }>());

const assignDosingGunToProduct = createAction(`${NAMESPACE} Assign Dosing Gun To Product`, props<{ dosingGun: DosingGunItem }>());
const assignDosingGunToProductSuccess = createAction(`${NAMESPACE} Assign Dosing Gun To Product Success`, props<{ dosingGunsDto: DosingGunsListDto }>());

const updateConnectionStatus = createAction(`${NAMESPACE} Update Connection Status`, props<{ connectionStatus: DeviceConnectionStatusEnum }>());

const setDose = createAction(`${NAMESPACE} Set Dose`, props<{ macAddress: string, dose: number }>());
const setDoseSuccess = createAction(`${NAMESPACE} Set Dose Success`, props<{ response: SetDoseResponse | null }>());

const setOneTimeDose = createAction(`${NAMESPACE} Set One Time Dose`, props<{ macAddress: string, dose: number, gunName?: string | null }>());
const setOneTimeDoseSuccess = createAction(`${NAMESPACE} Set One Time Dose Success`, props<{ response: SetDoseResponse | null }>());
const resetOneTimeDose = createAction(`${NAMESPACE} Reset One Time Dose`);

const validateGun = createAction(`${NAMESPACE} Validate Gun`, props<{ gunId: string }>());
const validateGunSuccess = createAction(`${NAMESPACE} Validate Gun Success`, props<{ dosingGun: DosingGunItem }>());

const unassignDosingGunFromProduct = createAction(`${NAMESPACE} Unassign Dosing Gun From Product`, props<{  gunId: string }>());
const unassignDosingGunFromProductSuccess = createAction(`${NAMESPACE} Unassign Dosing Gun From Product Success`, props<{ dosingGun: DosingGunItem }>());

const error = createAction(
  `${NAMESPACE} Error`,
  props<{ errMsg: string }>()
);
const resetError = createAction(`${NAMESPACE} Reset Error`);

export const UpcDosingGunsActions = {
  error,
  resetError,
  getDosingGunsStatusFromUpc,
  getDosingGunsStatusFromUpcSuccess,
  getDosingGunsListFromApi,
  getDosingGunsListFromApiSuccess,
  getAvailableDosingGunsFromUpc,
  getAvailableDosingGunsFromUpcSuccess,
  getDosingGunsSettingsFromApi,
  getDosingGunsSettingsFromApiSuccess,
  updateDosingGunsSettings,
  updateDosingGunsSettingsSuccess,
  assignDosingGunToProduct,
  assignDosingGunToProductSuccess,
  updateConnectionStatus,
  setDose,
  setDoseSuccess,
  setOneTimeDose,
  setOneTimeDoseSuccess,
  resetOneTimeDose,
  validateGun,
  validateGunSuccess,
  unassignDosingGunFromProduct,
  unassignDosingGunFromProductSuccess
};
