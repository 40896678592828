import { Inject, Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, map, of } from 'rxjs';

import { AbstractNavigationService } from '@ifhms/common/angular/features/navigation';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { FeedlotFeaturePermission, FeedlotPermissionsService } from '@ifhms/feedlot/shared/domain/permissions';
import { SersiNavListItem } from '@sersi/angular/ui/side-nav';
import { SHOW_DEV_FEATURE } from '@common/angular/config';

import { FeedlotMenuItemsFn, FeedlotNavigationMenu } from '../../interfaces';
import { getFeedlotOverviewNavigationMenuItems } from './feedlot-overview-navigation.constants';

@UntilDestroy()
@Injectable()
export class FeedlotNavigationService extends AbstractNavigationService {
  readonly translationScope = 'navigation';
  readonly translationNamespace = 'services.feedlot-navigation';

  constructor(
    private feedlotFacade: FeedlotFacade,
    private feedlotPermissionService: FeedlotPermissionsService,
    @Inject(SHOW_DEV_FEATURE) private showDevFeature: boolean
  ) {
    super();
  }

  getNavigationItems<FeedlotNavigationMenu>(menuType: FeedlotNavigationMenu): Observable<SersiNavListItem[]> {
    let menuItems: Observable<SersiNavListItem[]>;
    switch (menuType) {
      case FeedlotNavigationMenu.FeedlotOverview:
        menuItems = this.withFeedlotData(getFeedlotOverviewNavigationMenuItems);
        break;
      default:
        menuItems = of([]);
    }
    return this.getLocalizedLabels(menuItems);
  }

  private withFeedlotData(menuItemsFn: FeedlotMenuItemsFn): Observable<SersiNavListItem[]> {
    return this.feedlotFacade.feedlotSlug$
      .pipe(
        map((feedlotSlug: string) => {
          const menuItems = menuItemsFn(feedlotSlug);
          return menuItems
            .filter(item => item.isVisible !== false || this.showDevFeature)
            .filter((item) => {
              if(!item.permissionList) return true;
              return item.permissionList.some(
                (permission) => this.feedlotPermissionService.hasPermissionSync(permission as FeedlotFeaturePermission)
              )
            })
        })

      )
  }

}
