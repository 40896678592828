import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  EventAnimalCountDto,
  EventsListDto,
  GoToEventDto,
  RailAnimalSearchDto,
  RailEventAnimalSearchResultDto,
  RailEventDto,
  RailEventListDto,
  RailEventListRequestDto,
  RailEventUpdateDto
} from '@ifhms/models/feedlot';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';
import { USE_DEFAULT_ERROR_HANDLER } from '@common/angular/error-handling';

@Injectable()
export class FeedlotEventsRailService {

  private context = new HttpContext().set(USE_DEFAULT_ERROR_HANDLER, false);

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API)  private readonly apiUrl: string
  ) {
  }

  getList(feedlotId: string, request: RailEventListRequestDto): Observable<RailEventListDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });
    return this.http.get<RailEventListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/Events/List`, {
      params
    });
  }

  getEvent(feedlotId: string, eventId: string, workOrderId: string): Observable<RailEventDto> {
    return this.http.get<RailEventDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder/${workOrderId}/Event/${eventId}/`);
  }

  getAllEvents(feedlotId: string, workOrderId: string): Observable<EventsListDto> {
    return this.http.get<EventsListDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder/${workOrderId}/Event/GoToSelectList`);
  }

  getEventsAnimalCount(feedlotId: string, workOrderId: string): Observable<EventAnimalCountDto[]> {
    return this.http.get<EventAnimalCountDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder/${workOrderId}/AnimalCountList`);
  }

  getActiveEventId(feedlotId: string, workOrderId: string): Observable<GoToEventDto> {
    return this.http.get<GoToEventDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Events/Rail/${workOrderId}/GoToEvent`);
  }

  getNextEventId(feedlotId: string, eventId: string, workOrderId: string): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder/${workOrderId}/Event/${eventId}/Next`);
  }

  getPreviousEventId(feedlotId: string, eventId: string, workOrderId: string): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder/${workOrderId}/Event/${eventId}/Back`);
  }

  createEvent(feedlotId: string, eventCreate: RailEventUpdateDto, workOrderId: string): Observable<RailEventDto> {
    return this.http.post<RailEventDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder/${workOrderId}/Event`,
      eventCreate,
      { context: this.context }
    );
  }

  updateEvent(feedlotId: string, eventId: string, eventUpdate: RailEventUpdateDto, workOrderId: string): Observable<RailEventDto> {
    return this.http.put<RailEventDto>(
      `${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder/${workOrderId}/Event/${eventId}`,
      eventUpdate,
      { context: this.context }
    );
  }

  deleteEvent(feedlotId: string, eventId: string, workOrderId: string): Observable<string> {
    return this.http.delete<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder/${workOrderId}/Event/${eventId}`);
  }

  animalSearch(feedlotId: string, searchParams: RailAnimalSearchDto): Observable<RailEventAnimalSearchResultDto> {
    const params = new HttpParams({ fromObject: searchParams as unknown as Record<string, any> });
    return this.http.get<RailEventAnimalSearchResultDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/AnimalSearch`, {
      params,
      context: this.context
    });
  }

}
