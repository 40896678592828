import { Injectable } from '@angular/core';
import { ErrorHandlingService } from '@common/angular/error-handling';

import { FeedlotWebDomainRootStateFacade } from './feedlot-web-domain-root.state.facade';

@Injectable()
export class FeedlotWebDomainRootStateEffects {

  constructor(
    private errorHandlingService: ErrorHandlingService,
    private feedlotWebFacade: FeedlotWebDomainRootStateFacade
  ) {
    this.errorHandlingService.error$
      .subscribe(val => this.feedlotWebFacade.error(val.error?.text));
  }
}
