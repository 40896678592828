<p-paginator
  [first]="first"
  [rows]="rows"
  [totalRecords]="totalRecords"
  [pageLinkSize]="pageLinkSize"
  [rowsPerPageOptions]="rowsPerPageOptions"
  [templateLeft]="templateLeft"
  (onPageChange)="pageChangeAction($event)"
>
  <ng-template #templateLeft let-state>
    <span class="paginator-report-label-showing">{{labels.labelShowing}}</span>
    <b class="paginator-report-val paginator-report-first">{{totalRecords === 0 ? state.first : state.first + 1}}</b>
    <span class="paginator-report-label-to">{{labels.labelTo}}</span>
    <b class="paginator-report-val paginator-report-last">{{(state.first + state.rows) | mathMin:totalRecords}}</b>
    <span class="paginator-report-label-of">{{labels.labelOf}}</span>
    <b class="paginator-report-val paginator-report-records">{{totalRecords}}</b>
    <span class="paginator-report-label-records">{{state.labelRecords || ''}}</span>
  </ng-template>
</p-paginator>
