import { createAction, props } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

const NAMESPACE = '[LOT_SUB_GROUPS_SELECT_LIST] - ';

const get = createAction(`${NAMESPACE} Get`);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ items: SersiSelectListItem[] }>()
);

const error = createAction(`${NAMESPACE} error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const LotSubGroupsActions = {
  error,
  get,
  getSuccess,
  reset
};
