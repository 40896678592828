<ng-container
  *ngIf="{
    isLoading: sortGroupOutcomeDetailViewFacade.isLoading$ | async,
    outcomeDetail: sortGroupOutcomeDetailViewFacade.detail$ | async
  } as obs"
>
  <p-dialog
    *transloco="let t; read: translateScope"
    [(visible)]="display"
    [modal]="display"
    [style]="{ width: '70vw', height: '90vh' }"
    [header]="t('view')"
    [closable]="true"
    (onHide)="onClose()"
    [closeOnEscape]="false"
    [draggable]="false"
    [maximizable]="true"
    appendTo="body"
  >
    <sersi-spin [spin]="obs.isLoading">
      <div class="flex flex-row gap-3">
        <ifhms-sort-group-form
          [detail]="obs.outcomeDetail"
          [isBulkOutput]="false"
          [viewMode]="true"
          [hasTagsInputs]="hasTagsInputs"
          class="w-full"
        ></ifhms-sort-group-form>
      </div>
    </sersi-spin>
  </p-dialog>
</ng-container>
