import { Injectable } from '@angular/core';
import { AbstractStaticListFacade } from '@common/angular/utils';
import { WorkOrderReHandlingAction } from '@ifhms/models/feedlot';

@Injectable()
export class ReHandlingWorkOrderActionsFacade extends AbstractStaticListFacade {

  protected override useCustomTranslationForCode = true;

  protected selectList = [
    {
      id: WorkOrderReHandlingAction.FromPen,
      description: '',
      code: 'FromPen'
    },
    {
      id: WorkOrderReHandlingAction.HomePen,
      description: '',
      code: 'HomePen'
    },
    {
      id: WorkOrderReHandlingAction.NewHomePen,
      description: '',
      code: 'NewHomePen'
    }
  ];
  protected selectListType = 're-handling-work-order-actions';

}
