import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractTranslationComponent } from '@common/angular/translation';
import { UpcThermometerService } from '@ifhms/common/angular/data-access/upc-api';
import { FormTemperatureFieldComponent } from '@ifhms/common/angular/upc/thermometer';
import { DeviceConnectionStatusEnum, DeviceStatusDto } from '@ifhms/models/feedlot';
import { HubConnectionState } from '@microsoft/signalr';
import { untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyTypesEnum, FormlyWrappersEnum } from '@sersi/angular/formly/core';
import { Observable, map } from 'rxjs';
import {
  ConnectionStatusLabelFormFieldComponent
} from '../connection-status-label-form-field/connection-status-label-form-field.component';

@Component({
  selector: 'ifhms-upc-thermometer-settings-form',
  templateUrl: './upc-thermometer-settings-form.component.html',
  styleUrls: ['./upc-thermometer-settings-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpcThermometerSettingsFormComponent extends AbstractTranslationComponent {

  translationNamespace = 'components.upc-thermometer-settings-form';

  form = new FormGroup({});
  model: DeviceStatusDto;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];

  thermometerConnectionStatus$: Observable<string>;

  constructor(private upcThermometerService: UpcThermometerService) {
    super();
  }

  protected override onTranslationInit():void {
    this.getConnectionStatus();
    this.getThermometerSettings();
    this.setFields();
  }

  private getConnectionStatus(): void {
    this.thermometerConnectionStatus$ = this.upcThermometerService.connectionState$.pipe(
      map((state) => {
        switch(state) {
          case HubConnectionState.Connected:
            return DeviceConnectionStatusEnum.Connected;
          case HubConnectionState.Disconnected:
            return DeviceConnectionStatusEnum.Disconnected;
          default:
            return DeviceConnectionStatusEnum.Disconnected;
        }
      })
    );
  }

  private getThermometerSettings(): void {
    this.upcThermometerService.deviceSettings$.pipe(
      untilDestroyed(this)
    ).subscribe(settings => {
      this.model = structuredClone(settings);
    });
  }

  private setFields(): void {

    this.fields = [{
      fieldGroupClassName: 'upc-thermometer-settings-form',
      fieldGroup: [
        {
          fieldGroupClassName: 'thermometer-wrapper',
          fieldGroup: [
            this.setThermometer(),
            this.setThermometerConnectionStatus()
          ]
        },
        this.setSettingsFields()
      ]
    }];

  }

  private setThermometer(): FormlyFieldConfig {
    return {
      type: FormTemperatureFieldComponent,
      className: 'upc-thermometer',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      templateOptions: {
        label$: this.getTranslation$('thermometer-label')
      },
      expressionProperties: {
        'props.readTempData': () : boolean => true
      }
    };
  }

  private setThermometerConnectionStatus(): FormlyFieldConfig {
    return {
      type: ConnectionStatusLabelFormFieldComponent,
      className: 'upc-thermometer-connection',
      wrappers: [FormlyWrappersEnum.DEFAULT_WRAPPER],
      templateOptions: {
        label: '',
        connectionStatus: this.thermometerConnectionStatus$
      }
    };
  }

  private setSettingsFields(): FormlyFieldConfig {
    return {
      fieldGroupClassName: 'thermometer-settings-fields',
      fieldGroup: [
        {
          key: 'settings.name',
          className: 'thermometer-name',
          type: FormlyTypesEnum.TEXT_READONLY,
          templateOptions: {
            label$: this.getTranslation$('thermometer-name')
          }
        },
        {
          key: 'settings.thermometerSettings.units',
          className: 'thermometer-units',
          type: FormlyTypesEnum.TEXT_READONLY,
          templateOptions: {
            label$: this.getTranslation$('thermometer-units')
          }
        }
      ]
    };
  }

}

