import { AnimalSelectorItemDto, AnimalSelectorRequestDto } from '@ifhms/models/feedlot';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import * as LeftReducer from './left';
import * as RightReducer from './right';

export const FEATURE_NAME = 'Cattle - Selector';

export const selectFeatureState = createFeatureSelector<State>(FEATURE_NAME);

export const reducer: ActionReducerMap<State> = {
  left: LeftReducer.reducer,
  right: RightReducer.reducer
};

export interface State {
  left: LeftReducer.State;
  right: RightReducer.State;
}

export interface Facade {
  list$: Observable<AnimalSelectorItemDto[]>;
  availableLength$: Observable<number>;
  selected$: Observable<AnimalSelectorItemDto[]>;
  isLoading$: Observable<boolean>;
  isLoaded$: Observable<boolean>;
  selectedLength$: Observable<number>;
  totalLength$: Observable<number>;
  filter$: Observable<AnimalSelectorRequestDto>;
  filterApplied$: Observable<boolean>;
  selectedAnimalsLocationTypes$: Observable<SersiSelectListItem[]>;
  selectedAnimalsLots$: Observable<SersiSelectListItem[]>;
  selectedAnimalsCurrentPen$: Observable<SersiSelectListItem[]>;

  add(animals: AnimalSelectorItemDto[]): void;

  select(animal: AnimalSelectorItemDto): void;

  selectMultiple(animal: AnimalSelectorItemDto[]): void;

  selectAll(): void;

  filter(filter: AnimalSelectorRequestDto): void;

  clearFilter(): void;

  remove(): void;
}
