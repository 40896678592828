import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import * as reducer from './acquisition-types.reducer';
import { AcquisitionTypesSelectors } from './acquisition-types.selectors';
import { AcquisitionTypesActions } from './acquisition-types.actions';
import { getLoadedSelectItems } from '@common/angular/utils';

@Injectable()
export class AcquisitionTypesFacade {

  loaded$ = this.store.select(AcquisitionTypesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(AcquisitionTypesSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  get(): Observable<SersiSelectListItem[]> {
    this.store.dispatch(AcquisitionTypesActions.get());
    return this.items$;
  }

  reset(): void {
    this.store.dispatch(AcquisitionTypesActions.reset());
  }
}
