import { AnimalAvailabilityDto } from '@ifhms/models/feedlot';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Animal Availability]';

const getAnimalAvailability = createAction(
  `${NAMESPACE} Get`,
  props<{ animalId: string }>()
);

const getAnimalAvailabilitySuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ animalAvailability: AnimalAvailabilityDto }>()
);

const reset = createAction(
  `${NAMESPACE} Reset`
);

const error = createAction(
  `${NAMESPACE} Error`,
  props<{ errMsg: string }>()
);

export const AnimalAvailabilityActions = {
  getAnimalAvailability,
  getAnimalAvailabilitySuccess,
  reset,
  error
};
