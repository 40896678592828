import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { FormlyModule } from '@ngx-formly/core';
import { PaginatorModule } from 'primeng/paginator';

import {
  TableBooleanFilterComponent,
  TableDateRangeFilterComponent,
  TableFilterComponent,
  TableMultiSelectFilterComponent,
  TableNumberFilterComponent,
  TablePaginatorComponent,
  TableSingleSelectFilterComponent,
  TableSortComponent,
  TableTextFilterComponent,
  TableTypeSelectComponent
} from './components';
import { DialogModule } from 'primeng/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonAngularUtilsModule } from '@common/angular/utils';

@NgModule({
  imports: [
    CommonModule,
    OverlayPanelModule,
    ButtonModule,
    FormlyModule,
    PaginatorModule,
    DialogModule,
    NgSelectModule,
    CommonAngularUtilsModule
  ],
  declarations: [
    TableFilterComponent,
    TableSortComponent,
    TableNumberFilterComponent,
    TableTextFilterComponent,
    TableSingleSelectFilterComponent,
    TableMultiSelectFilterComponent,
    TableDateRangeFilterComponent,
    TableBooleanFilterComponent,
    TablePaginatorComponent,
    TableTypeSelectComponent
  ],
  exports: [
    TableFilterComponent,
    TableSortComponent,
    TableNumberFilterComponent,
    TableTextFilterComponent,
    TableSingleSelectFilterComponent,
    TableMultiSelectFilterComponent,
    TableDateRangeFilterComponent,
    TableBooleanFilterComponent,
    TablePaginatorComponent,
    TableTypeSelectComponent
  ]
})
export class SersiAngularUiTableModule {
}
