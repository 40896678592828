import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';

import { SelectListItemDto } from '@ifhms/models/shared';
import { FeedlotSelectListNoTranslationType } from '@ifhms/models/feedlot';
import { FeedlotFacade } from '@ifhms/feedlot/front-end/shared/domain/state/feedlot';
import { SelectListsService } from '@ifhms/common/angular/data-access/feedlot-api';

import { TxGroupsActions } from './tx-groups.actions';

@Injectable({  providedIn: 'root' })
export class TxGroupsEffects {
  constructor(
    private actions$: Actions,
    private feedlotFacade: FeedlotFacade,
    private selectListsService: SelectListsService
  ) {}
  
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TxGroupsActions.get),
      withLatestFrom(this.feedlotFacade.feedlotId$),
      exhaustMap(([, feedlotId]) =>
        this.selectListsService.getSelectListData( feedlotId,
          FeedlotSelectListNoTranslationType.TxGroup)
          .pipe(
            map((items: SelectListItemDto[]) => {
              if (!items) return TxGroupsActions.error();
              return TxGroupsActions.getSuccess({ items });
            }),
            catchError(() => of(TxGroupsActions.error()))
          )
      )
    )
  );
}
  