import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UpcApiActions } from './upc-api.actions';
import { UpcApiSelectors } from './upc-api.selectors';
import { UpcSettingsDto, UpcUsageItemDto } from '@ifhms/models/feedlot';
import * as reducer from './upc-api.reducer';

@Injectable()
export class UpcApiFacade {

  loading$: Observable<boolean> = this.store.select(UpcApiSelectors.selectLoading);
  state$: Observable<reducer.State> = this.store.select(UpcApiSelectors.selectState);
  upcSettings$: Observable<UpcSettingsDto | null> = this.store.select(UpcApiSelectors.selectUpcSettings);
  upcConnectionStatus$: Observable<string> = this.store.select(UpcApiSelectors.selectUpcConnectionStatus);
  clientId$: Observable<string> = this.store.select(UpcApiSelectors.selectClientId);
  usages$: Observable<UpcUsageItemDto[]> = this.store.select(UpcApiSelectors.selectUpcUsages);

  constructor(private readonly store: Store) {
  }

  getUPCSettings(clientId: string): void {
    this.store.dispatch(UpcApiActions.getUPCSettings({ clientId }));
  }

  updateUPCSettings(upcSettings: Partial<UpcSettingsDto>): void {
    this.store.dispatch(UpcApiActions.updateUPCSettings({ upcSettings }));
  }

  resetGateController(): void {
    this.store.dispatch(UpcApiActions.resetGateController());
  }

  connectToUPC(url?: string): void {
    this.store.dispatch(UpcApiActions.connectToUPC({ url }));
  }

  getUPCUsages(): void {
    this.store.dispatch(UpcApiActions.getUPCUsages());
  }

}
